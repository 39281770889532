import { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import Patient from '../../../models/patient';
import DateUtils from '../../../shared/src/utils/dateUtils';
import Utils from '../../../shared/src/utils/utils';
import PatientDialog from '../../dialogs/patientDialog/patientDialog';
import { DataGridPro, deDE } from '@mui/x-data-grid-pro';
import { Campaign, CampaignStatus, CampaignType } from '../../../shared/src/models/campaign/campaign';
import CampaignsService from '../../../services/campaignsService';
import BackButton from '../../backButton';
import IconButton from '@mui/material/IconButton';
import BookingDialog from '../../dialogs/bookingDialog/bookingDialog';
import CampaignPatient from '../../../models/campaignPatient';
import CampaignDetailsCard from './campaignDetailsCard';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { GlobalContext } from '../../../GlobalContext';

const CampaignPage: React.FC = () => {


    const { campaignId } = useParams();

    const {currentUser} = useContext(GlobalContext);

    const [patients, setPatients] = useState<Patient[]>([]);
    const [campaignPatients, setCampaignPatients] = useState<CampaignPatient[]>([]);
    const [isPatientDialogVisible, setIsPatientDialogVisible] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(new Patient());

    const [isLoading, setIsLoading] = useState(true);

    const [campaign, setCampaign] = useState<Campaign | null>(null);

    const [isBookingDialogVisible, setIsBookingPanelVisible] = useState(false);


    useEffect(() => {

        if (campaignId && currentUser) {
            updateData(campaignId);
        }

        // eslint-disable-next-line
    }, [campaignId, currentUser]);


    async function updateData(campId: string) {

        if (!currentUser) {
            return;
        }

        try {
            const cId = currentUser.clientId;

            setIsLoading(true);

            const result = await CampaignsService.getCampaign(cId, currentUser.locationId, campId);

            if (result) {
                setCampaign(result);

                const patResult = await CampaignsService.getCampaignPatients(campId, cId, currentUser.locationId);

                if (patResult) {
                    setCampaignPatients(patResult);
                }
            } else {
                setCampaign(null);
            }


            setIsLoading(false);

        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }


    async function onCalledRowClick(patient) {
        if (currentUser && campaign && campaignId && !patient.appointmentMade) {
            patient.called = !patient.called;
            await CampaignsService.updateCampaignPatient(patient, campaignId, currentUser.clientId, currentUser.locationId);
            campaign.patientsReached = campaignPatients.filter((pat) => pat.called === true).length;
            CampaignsService.updateCampaign(campaign, currentUser.clientId, currentUser.locationId);

            setPatients([...patients]);
        }
    }

    async function onAppointmentMadeRowClick(patient) {
        if (currentUser && campaign) {
            setSelectedPatient(patient);
            setIsBookingPanelVisible(true);
        }
    }

    async function onAppointmentCreated(patient) {
        setIsBookingPanelVisible(false);

        if (currentUser && campaign && campaignId) {
            patient.called = true;
            patient.appointmentMade = true;
            await CampaignsService.updateCampaignPatient(patient, campaignId, currentUser.clientId, currentUser.locationId);

            campaign.patientsReached = campaignPatients.filter((pat) => pat.called === true).length;
            campaign.conversions = campaignPatients.filter((pat) => pat.appointmentMade === true).length;

            CampaignsService.updateCampaign(campaign, currentUser.clientId, currentUser.locationId);

            setPatients([...patients]);

        }
    }

    function getLastAppointmentDateString(lastAppointmentDate: Date) {
        const result = DateUtils.getDateString(lastAppointmentDate);

        if (result.indexOf("1900") !== -1) return "";

        return result;
    }

    const columns: any[] = [
        {
            field: "gender", headerName: "Anrede", width: 100, renderCell: (params) => (
                <span>{Utils.getGenderString(params.row.gender)}</span>
            )
        },
        {
            field: "firstName", headerName: "Vorname", width: 200, renderCell: (params) => (
                <Link onClick={() => handlePatientRowClick(params.row)} color="textPrimary" className="kt-underline">{params.row.firstName}</Link>
            )
        },
        {
            field: "lastName", headerName: "Nachname", width: 200, renderCell: (params) => (
                <Link onClick={() => handlePatientRowClick(params.row)} color="textPrimary" className="kt-underline">{params.row.lastName}</Link>
            )
        },
        {
            field: "phoneNumber", headerName: "Tel.", width: 200, renderCell: (params) => (
                <span>{params.row.getMobileOrPhoneNumber()}</span>
            )
        },
        {
            field: "birthDate", headerName: "Geburtstag", width: 200, renderCell: (params) => (
                <span>{DateUtils.getBirthdayString(params.row.birthDate)}</span>
            )
        },
        {
            field: "privateInsurance", headerName: "Priv.versichert", width: 120, renderCell: (params) => (
                <span>{params.row.privateInsurance ? <CheckRoundedIcon /> : null}</span>
            )
        },
        {
            field: "lastAppointmentDate", headerName: "letzter Termin", width: 120, renderCell: (params) => (
                <span>{getLastAppointmentDateString(params.row.lastAppointmentDate)}</span>
            )
        }
    ];

    if(campaign && (campaign.type === CampaignType.sms || campaign.type === CampaignType.email)) {
        columns.push(
            {
                field: 'smsSend', width: 128,
                headerName: campaign.type === CampaignType.email ? "Email gesendet" : "SMS gesendet",
                renderCell: (params) => (

                    (params.row.smsSend || params.row.emailSend) ?
                        <CheckRoundedIcon style={{ color: "green" }} />
                        :
                        <IconButton disabled><i className='fal fa-square' /></IconButton>
                )
            }
        );

        columns.push(
            {
                field: 'visitedLandingPage', width: 128,
                headerName: "LP besucht",
                renderCell: (params) => (

                    params.row.visitedLandingPage ?
                        <CheckRoundedIcon style={{ color: "green" }} />
                        :
                        <IconButton disabled><i className='fal fa-square' /></IconButton>
                )
            }
        );
    }

    columns.push(
        {
            field: 'called', width: 64,
            renderHeader: () => (<i className='fal fa-phone' />),
            renderCell: (params) => (

                campaign?.status === CampaignStatus.completed ?
                    <IconButton disabled>
                        {params.row.called ?
                            <i className='fal fa-check-square' />
                            :
                            <i className='fal fa-square' />
                        }
                    </IconButton>
                    :
                    <IconButton onClick={(e) => onCalledRowClick(params.row)}>
                        {params.row.called ?
                            <i className='fal fa-check-square' />
                            :
                            <i className='fal fa-square' />
                        }
                    </IconButton>

            )
        }
    );

    columns.push(
        {
            field: 'appointmentMade', width: 64,
            renderHeader: () => (<i className='fal fa-calendar-alt' />),
            renderCell: (params) => (

                campaign?.status === CampaignStatus.completed ?

                    <>{params.row.appointmentMade ?
                        <CheckRoundedIcon style={{ color: "green" }} />
                        :
                        <IconButton disabled><i className='fal fa-square' /></IconButton>
                    }</>
                    :
                    params.row.appointmentMade ?
                        <CheckRoundedIcon style={{ color: "green" }} />
                        :
                        <IconButton onClick={(e) => onAppointmentMadeRowClick(params.row)}>
                            <i className='fal fa-calendar-alt' />
                        </IconButton>

            )
        }
    );


    function handlePatientRowClick(patient: Patient) {
        setSelectedPatient(patient);
        setIsPatientDialogVisible(true);
    }



    if (isLoading) {
        return <div className="kt-campaign-page"><Box className="kt-page"><h2>Kampagne wird geladen...</h2></Box></div>
    }

    if (!campaign) {
        return <div className="kt-campaign-page"><Box className="kt-page"><h2>Kampagne wurde nicht gefunden!</h2></Box></div>
    }

    return (
        <div>

            <BookingDialog
                patient={selectedPatient}
                visible={isBookingDialogVisible}
                onClose={() => setIsBookingPanelVisible(false)}
                onAppointmentCreated={pat => onAppointmentCreated(pat)}
            />

            <PatientDialog
                visible={isPatientDialogVisible}
                patient={selectedPatient}
                onClose={() => setIsPatientDialogVisible(false)}
                onSave={() => setIsPatientDialogVisible(false)}
            />



            <Box className="kt-page kt-campaign-page">

                {(campaignId && campaign) &&
                    <>
                        <h2>{campaign.getTypeString()}-Kampagne {campaign?.name} - {campaign?.visitMotiveName}</h2>

                        <Box className="kt-page">

                            <CampaignDetailsCard campaign={campaign} showActionButtons={false} />

                            <Box className="kt-data-grid-container" mb={2}>
                                <DataGridPro
                                    loading={isLoading}
                                    rows={campaignPatients}
                                    columns={columns}
                                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                                />
                            </Box>

                            <div className="kt-page-footer">
                                <BackButton location='/recaller' />
                            </div>

                        </Box>
                    </>
                }

            </Box>


        </div>
    )
}


export default CampaignPage;
