import { useEffect, useState, useContext } from 'react';

import DateUtils from '../../shared/src/utils/dateUtils';
import { GlobalContext } from '../../GlobalContext';
import PhoneCall, { PhoneCallCategory, PhoneCallStatus } from '../../shared/src/models/phoneCall';
import PhoneCallsService from '../../services/phoneCallsService';
import Utils from '../../shared/src/utils/utils';
import PatientDialog from '../dialogs/patientDialog/patientDialog';
import Patient from '../../models/patient';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid'
import PatientsService from '../../services/patientsService';
import PhoneCallDialog from '../dialogs/phoneCallDialog';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import SendSmsDialog from '../dialogs/sendSmsDialog';
import { Toast } from '../toaster';
import Grid from '@mui/material/Grid';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { t } from 'i18next';
import { getDataGridLocaleText } from '../dataGridLocaleText';

const PhoneCallsPage: React.FC = () => {

    const currentLocaleText = getDataGridLocaleText();
    
    const [phoneCalls, setPhoneCalls] = useState<PhoneCall[]>([]);
    const [filteredPhoneCalls, setFilteredPhoneCalls] = useState<PhoneCall[]>([]);
    const [selectedPhoneCall, setSelectedPhoneCall] = useState<PhoneCall>(new PhoneCall());

    const { currentClient, currentUser, setNewToast } = useContext(GlobalContext);

    const [isPhoneCallDialogVisible, setIsPhoneCallDialogVisible] = useState(false);
    const [isSmsDialogVisible, setIsSmsDialogVisible] = useState(false);
    const [isPatientDialogVisible, setIsPatientDialogVisible] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(new Patient());
    const [defaultSmsText, setDefaultSmsText] = useState('');
    const [statusFilter, setStatusFilter] = useState<"all" | "open" | "closed">("all");
    const [categoryFilter, setCategoryFilter] = useState<PhoneCallCategory | "all">("all");


    useEffect(() => {

        if (currentClient && currentUser && currentUser.locationId) {

            const statusFilter = localStorage.getItem("phoneCallsPageStatusFilter");
            if (statusFilter) setStatusFilter(statusFilter as "all" | "open" | "closed");

            const categoryFilter = localStorage.getItem("phoneCallsPageCategoryFilter");
            if (categoryFilter) setCategoryFilter(categoryFilter as PhoneCallCategory | "all");

            const unsubscribePhoneCalls = PhoneCallsService.startListenForPhoneCalls(
                currentClient.id,
                currentUser.locationId,
                (result) => handlePhoneCallsChange(result, statusFilter as "all" | "open" | "closed", categoryFilter as PhoneCallCategory | "all")
            );

            return () => {
                unsubscribePhoneCalls();
            }
        }


    }, [currentClient]);


    function getDefaultText(phoneCall: PhoneCall) {
        let text = "";
        text += `Danke für Ihren Anruf.\n`;

        const filteredCategories = phoneCall.categories.filter(
            cat => cat === PhoneCallCategory.prescription
                || cat === PhoneCallCategory.medicalReferral
                || cat === PhoneCallCategory.medicalReport
        );

        if (filteredCategories.length === 1) {
            text += `Wir haben Ihre Anfrage bezüglich:\n - ${PhoneCall.getCategoryString(filteredCategories[0])}\nerhalten.\n`;
        } else if (filteredCategories.length > 1) {
            text += `Wir haben Ihre Anfragen bezüglich:\n`;
            filteredCategories.forEach(cat => {
                text += ` - ${PhoneCall.getCategoryString(cat)}\n`;
            });
            text += `erhalten.\n`;
        }

        text += `Wir melden uns in Kürze.\n`;
        text += `Ihr Praxis Team`;

        return text;
    }

    function handleDeleteRowClick(phoneCall: PhoneCall) {
        PhoneCallsService.deletePhoneCall(phoneCall);
    }

    function handleSendSmsRowClick(phoneCall: PhoneCall) {
        setDefaultSmsText(getDefaultText(phoneCall));

        setSelectedPhoneCall(phoneCall);
        setIsSmsDialogVisible(true);
    }

    function handleOnSendSms(smsText: string) {
        setIsSmsDialogVisible(false);
    }
    function handleOnSendSmsFinished(smsText: string) {
        setNewToast(new Toast("success", t("components.toaster.smsHasBeenSent")));
    }


    function handlePhoneCallRowClick(phoneCall: PhoneCall) {
        setSelectedPhoneCall(phoneCall);
        setIsPhoneCallDialogVisible(true);
    }

    async function handlePatientRowClick(phoneCall: PhoneCall) {
        if (phoneCall.patientId) {
            const patient = await PatientsService.getPatient(phoneCall.patientId, phoneCall.clientId, phoneCall.locationId);

            if (patient) {
                setSelectedPatient(patient);
                setIsPatientDialogVisible(true);
            }
        }
    }

    function handlePhoneCallsChange(result: PhoneCall[], activeStatusFilter: "all" | "open" | "closed", activeCategotieFilter: PhoneCallCategory | "all") {

        setPhoneCalls(result);

        const filteredResult = getFilteredPhoneCalls(result, activeStatusFilter, activeCategotieFilter);

        setFilteredPhoneCalls(filteredResult);
    }

    function handleStatusFilterChange(event: SelectChangeEvent) {
        const newFilter = event.target.value as "all" | "open" | "closed";
        setStatusFilter(newFilter);

        localStorage.setItem("phoneCallsPageStatusFilter", newFilter);

        const result = getFilteredPhoneCalls(phoneCalls, newFilter, categoryFilter);

        setFilteredPhoneCalls(result);
    }

    function handleCategoryFilterChange(event: SelectChangeEvent) {
        const newFilter = event.target.value as PhoneCallCategory | "all";
        setCategoryFilter(newFilter);

        localStorage.setItem("phoneCallsPageCategoryFilter", newFilter.toString());

        const result = getFilteredPhoneCalls(phoneCalls, statusFilter, newFilter);

        setFilteredPhoneCalls(result);
    }

    function getCategoryColor(category: PhoneCallCategory) {
        switch (category) {
            case PhoneCallCategory.appointment: return 'dodgerblue';
            case PhoneCallCategory.cancellation: return 'tomato';
            case PhoneCallCategory.medicalReferral: return 'darkorange';
            case PhoneCallCategory.medicalReport: return 'mediumseagreen';
            case PhoneCallCategory.message: return 'slateblue';
            case PhoneCallCategory.prescription: return 'darkcyan';
            case PhoneCallCategory.question: return 'orange';
            default: return 'gray';
        }
    }

    function getCategoryIcon(category: PhoneCallCategory) {
        switch (category) {
            case PhoneCallCategory.appointment: return <i className="fal fa-calendar-alt"></i>
            case PhoneCallCategory.cancellation: return <i className="fal fa-calendar-times"></i>
            case PhoneCallCategory.medicalReferral: return <i className="fal fa-external-link-alt"></i>
            case PhoneCallCategory.medicalReport: return <i className="fal fa-notes-medical"></i>
            case PhoneCallCategory.message: return <i className="fal fa-voicemail"></i>
            case PhoneCallCategory.prescription: return <i className="fal fa-pills"></i>
            case PhoneCallCategory.question: return <i className="fas fa-question"></i>
            default: <></>
        }
    }


    function getCategories(categories: PhoneCallCategory[]) {

        return categories.map((category, index) => {
            return <Chip
                key={index}
                icon={getCategoryIcon(category)}
                label={PhoneCall.getCategoryString(category)}
                size="small"
                style={{ backgroundColor: getCategoryColor(category) }}
                className='kt-phone-call-category-chip'
            />
        })
    }


    function getStatusIcon(status: PhoneCallStatus) {

        switch (status) {
            case PhoneCallStatus.callCompleted: return <i className="far fa-phone-rotary kt-orange-color"></i>
            case PhoneCallStatus.inProgress: return <i className="far fa-phone kt-primary-color"></i>
            case PhoneCallStatus.noAnswer: return <i className="far fa-phone-slash kt-red-color"></i>
            case PhoneCallStatus.busy: return <i className="far fa-phone-slash kt-red-color"></i>
            case PhoneCallStatus.done: return <i className="far fa-check-circle kt-green-color"></i>
            case PhoneCallStatus.transcribing: return <i className="far fa-cog fa-spin"></i>
            default: <></>
        }
    }

    async function handleTaskCompletionChange(isDone: boolean, taskId: string, pCall: PhoneCall) {
        const index = pCall.tasks.findIndex(t => t.id === taskId);
        if (index > -1) pCall.tasks[index].done = isDone;

        if (pCall.tasks.every(task => task.done)) {
            pCall.status = PhoneCallStatus.done;
        } else {
            pCall.status = PhoneCallStatus.callCompleted;
        }

        await PhoneCallsService.updatePhoneCall(pCall);
    }

    function renderPatientCell(phoneCall: PhoneCall) {
        return <span onClick={() => handlePatientRowClick(phoneCall)} className='kt-link'>
            {(phoneCall.callerGender && (phoneCall.callerFirstName || phoneCall.callerLastName)) ? Utils.getGenderString(phoneCall.callerGender) + " " : ""}
            {phoneCall.callerFirstName} {phoneCall.callerLastName}
            {(phoneCall.callerFirstName || phoneCall.callerLastName) && <br></br>}
            {(!phoneCall.callerPhoneNumber || phoneCall.callerPhoneNumber === 'anonymous') ? t("pages.phoneCallsPage.suppressedNumber") : phoneCall.callerPhoneNumber}
        </span>
    }

    const columns: any[] = [
        {
            field: "status", headerName: t("pages.phoneCallsPage.phoneCallStatus.status"), width: 170, renderCell: (params) => (
                <span>{getStatusIcon(params.row.status)} {PhoneCall.getStatusString(params.row.status)}</span>
            )
        },
        {
            field: "categories", headerName: t("pages.phoneCallsPage.phoneCallCategory.category"), width: 150, renderCell: (params) => (
                <Box
                    sx={{
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                        lineHeight: 1.2, // Optional: Adjust line spacing
                    }}
                >
                    {getCategories(params.row.categories)}
                </Box>
            )
        },
        {
            field: "callDuration", headerName: t("pages.phoneCallsPage.duration"), width: 100, renderCell: (params) => (
                <span>{DateUtils.getDurationStringFromSeconds(params.row.callDuration)}</span>
            )
        },
        {
            field: "callerLastName", headerName: t("pages.phoneCallsPage.patient"), width: 200, renderCell: (params) => (
                <span>
                    {renderPatientCell(params.row)}
                </span>
            )
        },
        {
            field: "tasks", headerName: t("pages.phoneCallsPage.task"), flex: 3, renderCell: (params: { row: PhoneCall }) => (
                <div>
                    {params.row.tasks.map(task => {
                        return <div key={task.id}>
                            <FormControlLabel
                                className={task.done ? "kt-line-through" : ""}
                                control={
                                    <Checkbox
                                        checked={task.done}
                                        onChange={(event, checked) => handleTaskCompletionChange(checked, task.id, params.row)}
                                        size="small"
                                    />
                                }
                                label={task.name}
                            />
                        </div>
                    })}
                </div>
            )
        },
        {
            field: "createdAt", headerName: t("pages.phoneCallsPage.date"), width: 200, renderCell: (params) => (
                <span>{DateUtils.getDateTimeString(params.row.createdAt, true)}</span>
            )
        },
        {
            field: 'actions',
            headerName: " ",
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                    <Button
                        variant='contained'
                        size='small'
                        color='secondary'
                        onClick={() => handlePhoneCallRowClick(params.row)}
                        disabled={!(params.row.status === PhoneCallStatus.callCompleted || params.row.status === PhoneCallStatus.done)}
                    >
                        <i className="far fa-headphones"></i>&nbsp;{t("pages.phoneCallsPage.listen")}
                    </Button>
                    <Button
                        variant="contained"
                        color='secondary'
                        size='small'
                        onClick={() => handleSendSmsRowClick(params.row)}
                        disabled={(params.row.callerPhoneNumber === "anonymous" || !params.row.callerPhoneNumber)}
                    >

                        <i className="far fa-envelope"></i>&nbsp;{t("pages.phoneCallsPage.sendSms")}
                    </Button>
                    {/* <Button onClick={() => handleDeleteRowClick(params.row)} variant='contained' size='small' color='error'>Löschen</Button> */}
                </div>
            )

        }
    ];


    return (
        <>
            <PatientDialog
                visible={isPatientDialogVisible}
                patient={selectedPatient}
                onClose={() => setIsPatientDialogVisible(false)}
                onSave={() => setIsPatientDialogVisible(false)}
            />

            <PhoneCallDialog
                visible={isPhoneCallDialogVisible}
                phoneCall={selectedPhoneCall}
                onClose={() => setIsPhoneCallDialogVisible(false)}
            />

            <SendSmsDialog
                visible={isSmsDialogVisible}
                defaultSmsText={defaultSmsText}
                mobilePhoneNumber={selectedPhoneCall?.callerPhoneNumber}
                firstName={selectedPhoneCall?.callerFirstName}
                lastName={selectedPhoneCall?.callerLastName}
                onSendSms={handleOnSendSms}
                onSendSmsFinished={handleOnSendSmsFinished}
                onClose={() => setIsSmsDialogVisible(false)}
            />

            <Box className="kt-page kt-phone-calls-page">

                <h2>{t("pages.phoneCallsPage.title")}</h2>

                <Grid container alignItems="center">

                    <Grid item>
                        <FormControl size="small" style={{ minWidth: "200px" }}>
                            <InputLabel id="status-filter-select-label">{t("pages.phoneCallsPage.phoneCallStatus.status")}</InputLabel>
                            <Select
                                labelId="status-filter-select-label"
                                label={t("pages.phoneCallsPage.phoneCallStatus.status")}
                                value={statusFilter}
                                displayEmpty
                                onChange={handleStatusFilterChange}
                                required
                            >
                                <MenuItem value={"all"}>{t("pages.phoneCallsPage.phoneCallStatus.all")}</MenuItem>
                                <MenuItem value={"open"}>{t("pages.phoneCallsPage.phoneCallStatus.open")}</MenuItem>
                                <MenuItem value={"closed"}>{t("pages.phoneCallsPage.phoneCallStatus.closed")}</MenuItem>
                            </Select>
                        </FormControl>

                    </Grid>

                    <Grid item>
                        <FormControl size="small" style={{ minWidth: "200px" }}>
                            <InputLabel id="category-filter-select-label">{t("pages.phoneCallsPage.phoneCallCategory.categories")}</InputLabel>
                            <Select
                                labelId="category-filter-select-label"
                                label={t("pages.phoneCallsPage.phoneCallCategory.categories")}
                                value={categoryFilter.toString()}
                                displayEmpty
                                onChange={handleCategoryFilterChange}
                                required
                            >
                                <MenuItem value={"all"}>{t("pages.phoneCallsPage.phoneCallCategory.all")}</MenuItem>
                                <MenuItem value={PhoneCallCategory.appointment}>{PhoneCall.getCategoryString(PhoneCallCategory.appointment)}</MenuItem>
                                <MenuItem value={PhoneCallCategory.message}>{PhoneCall.getCategoryString(PhoneCallCategory.message)}</MenuItem>
                                <MenuItem value={PhoneCallCategory.cancellation}>{PhoneCall.getCategoryString(PhoneCallCategory.cancellation)}</MenuItem>
                                <MenuItem value={PhoneCallCategory.medicalReport}>{PhoneCall.getCategoryString(PhoneCallCategory.medicalReport)}</MenuItem>
                                <MenuItem value={PhoneCallCategory.medicalReferral}>{PhoneCall.getCategoryString(PhoneCallCategory.medicalReferral)}</MenuItem>

                            </Select>
                        </FormControl>

                    </Grid>


                </Grid>

                <Box className="kt-data-grid-container">
                    <DataGrid
                        pagination
                        rows={filteredPhoneCalls}
                        columns={columns}
                        localeText={currentLocaleText}
                        pageSizeOptions={[10, 25, 50, 100]}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        getRowHeight={() => "auto"} // minimum height for rows
                        autoHeight
                    />
                </Box>

            </Box>

        </>
    );
}

export default PhoneCallsPage;

function getFilteredPhoneCalls(result: PhoneCall[], statusFilter: string, categoryFilter: PhoneCallCategory | "all") {

    // remove invalid phone calls
    result = result.filter(phoneCall => (phoneCall.callDuration > 7 || phoneCall.status !== PhoneCallStatus.callCompleted));

    // apply user filters
    switch (statusFilter) {
        case "open":
            result = result.filter(phoneCall => phoneCall.status !== PhoneCallStatus.done);
            break;

        case "closed":
            result = result.filter(phoneCall => phoneCall.status === PhoneCallStatus.done);
            break;

        default:
            break;
    }

    console.log("categoryFilter", categoryFilter);
    if (categoryFilter !== undefined && categoryFilter !== null && categoryFilter !== "all") {
        result = result.filter(phoneCall => phoneCall.categories.includes(categoryFilter));
    }

    return result;
}
