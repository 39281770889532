import Paper from '@mui/material/Paper';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { GlobalContext } from '../../GlobalContext';
import ClonRAvatar from '../../shared/src/models/clonR/clonRAvatar';
import ClonRScript from '../../shared/src/models/clonR/clonRScript';
import ClonRVoice from '../../shared/src/models/clonR/clonRVoice';
import MessageDialog from '../dialogs/messageDialog';
import ClonRScriptsService from '../../services/clonRScriptsService';
import ClonRVoicesService from '../../services/clonRVoicesService';
import ClonRAvatarsService from '../../services/clonRAvatarsService';
import { Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import ClonRScriptCtrl from './clonRScriptCtrl';
import CloseIcon from "@mui/icons-material/Close";

import ClonRAvatarListCtrl from './clonRAvatarListCtrl';
import ClonRVoiceListCtrl from './clonRVoiceListCtrl';
import ClonRVoiceCardCtrl from './clonRVoiceCardCtrl';
import ClonRSettings from '../../shared/src/models/clonR/clonRSettings';
import ClonRConfirmVideoGenerationDlg from '../dialogs/clonRDialog/clonRConfirmVideoGenerationDlg';
import TextToSpeech from '../../shared/src/models/clonR/textToSpeech';
import ProgressDialog from '../dialogs/progressDialog';

interface Props {
    clonRSettings: ClonRSettings;
}

const ClonRMergeCtrl: React.FC<Props> = ({ clonRSettings }) => {

    const unsubscribeAvatarsListenerRef = useRef<any>();

    const { currentUser, currentClient } = useContext(GlobalContext);

    const [ownVoices, setOwnVoices] = useState<ClonRVoice[]>([]);
    const [publicVoices, setPublicVoices] = useState<ClonRVoice[]>([]);

    const [ownAvatars, setOwnAvatars] = useState<ClonRAvatar[]>([]);
    const [publicAvatars, setPublicAvatars] = useState<ClonRAvatar[]>([]);

    const [selectedClonRAvatar, setSelectedClonRAvatar] = useState(new ClonRAvatar());
    const [selectedClonRVoice, setSelectedClonRVoice] = useState(new ClonRVoice());
    const [selectedClonRScript, setSelectedClonRScript] = useState(new ClonRScript(clonRSettings.defaultLanguage));

    const [selectedLanguage, setSelectedLanguage] = useState("de");
    const [selectedLanguageIndex, setSelectedLanguageIndex] = useState(0); // we need this for the previous and next buttons in the confirm dialog

    const [isStartVideoCreationConfirmDialogOpen, setIsStartVideoCreationConfirmDialogOpen] = useState(false);

    const [isPlayingVoiceAudio, setIsPlayingVoiceAudio] = useState(false);
    const [isPlayingScriptAudio, setIsPlayingScriptAudio] = useState(false);
    const [isGeneratingScriptAudio, setIsGeneratingScriptAudio] = useState(false);

    const [isAvatarListPopupVisible, setIsAvatarListPopupVisible] = useState(false);
    const [isVoiceListPopupVisible, setIsVoiceListPopupVisible] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        loadVoices();
        loadScripts();
        loadSystemAvatars();

        function setNoPlayingState() {
            setIsPlayingVoiceAudio(false);
            setIsPlayingScriptAudio(false)
        }
        const audioPlayer = document.getElementById("voice-preview") as HTMLAudioElement;
        audioPlayer.addEventListener("ended", setNoPlayingState);
        return () => audioPlayer.removeEventListener('click', setNoPlayingState);

    }, []);


    // listen for Avatars changes
    useEffect(() => {

        unsubscribeAvatarsListenerRef.current = ClonRAvatarsService.startListenForClonRAvatars(currentClient.id, _avatars => {
            if (_avatars) {
                const _own = _avatars.filter(a => a.isSystem === false);
                setOwnAvatars(_own);

                if (localStorage) {
                    const selectedClonRAvatarId = localStorage.getItem("selectedClonRAvatar");

                    if (selectedClonRAvatarId) {                        
                        const avatar = _own.find(a => a.id === selectedClonRAvatarId);
                        if (avatar) {
                            setSelectedClonRAvatar(avatar);
                            return;
                        }
                    }
                }

            }
        });

        return () => {
            if (unsubscribeAvatarsListenerRef.current) {
                unsubscribeAvatarsListenerRef.current();
            }
        }

    }, []);

    const loadSystemAvatars = async () => {
        const _publicAvatars = await ClonRAvatarsService.getSystemAvatars();
        if (_publicAvatars) setPublicAvatars(_publicAvatars);

        if (localStorage && _publicAvatars) {
            const selectedClonRAvatarId = localStorage.getItem("selectedClonRAvatar");

            if (selectedClonRAvatarId) {                        
                const avatar = _publicAvatars.find(a => a.id === selectedClonRAvatarId);
                if (avatar) {
                    setSelectedClonRAvatar(avatar);
                    return;
                }
            }
        }
    }

    const loadScripts = async () => {

        if (localStorage) {
            const selectedClonRScriptId = localStorage.getItem("selectedClonRScript");
            const selectedClonRScriptLanguage = localStorage.getItem("selectedClonRScriptLanguage");

            if (selectedClonRScriptId) {
                const _scripts = await ClonRScriptsService.getScripts(currentUser.clientId);
                const script = _scripts?.find(a => a.id === selectedClonRScriptId);
                if (script) {
                    setSelectedClonRScript(script);
                    if (selectedClonRScriptLanguage) setSelectedLanguage(selectedClonRScriptLanguage);

                    return;
                }
            }
        }

    }

    const loadVoices = async () => {
        const _voices = await ClonRVoicesService.getVoices(currentUser.clientId);
        if (_voices) {
            const _own = _voices.filter(v => v.isSystem === false);
            const _public = _voices.filter(v => v.isSystem === true);
            setOwnVoices(_own);
            setPublicVoices(_public);

            if (localStorage) {
                const selectedClonRAvatarId = localStorage.getItem("selectedClonRVoice");

                if (selectedClonRAvatarId) {
                    const allVoices = [..._own, ..._public];
                    const voice = allVoices.find(a => a.id === selectedClonRAvatarId);
                    if (voice) {
                        setSelectedClonRVoice(voice);
                        return;
                    }
                }
            }

            const dummyVoice = new ClonRVoice();
            setSelectedClonRVoice(dummyVoice);
        }
    }


    const handlePlayVoiceClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, voice: ClonRVoice) => {
        e.stopPropagation();

        setSelectedClonRVoice(voice);

        const audioPlayer = document.getElementById("voice-preview") as HTMLAudioElement;

        audioPlayer.src = voice.audioUrl;
        audioPlayer.play();

        setIsPlayingVoiceAudio(true);
    }

    const handleStopVoiceClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();

        stopVoiceAudio();
    }

    const stopVoiceAudio = () => {
        const audioPlayer = document.getElementById("voice-preview") as HTMLAudioElement;

        audioPlayer.pause();
        audioPlayer.currentTime = 0;

        setIsPlayingVoiceAudio(false);
        setIsPlayingScriptAudio(false)
    }


    const handleOnPreviewScriptAudioClick = () => {

        if (isPlayingScriptAudio) {
            stopVoiceAudio();

        } else if (isInputValid(false)) {

            stopVoiceAudio();

            setIsGeneratingScriptAudio(true);
            ClonRScriptsService.getOrGenerateScriptAudio(selectedClonRScript, selectedLanguage, selectedClonRVoice, currentUser.clientId, handleOnTTSFinished);

        }

    }


    const handleOnTTSFinished = async (tts: TextToSpeech) => {
        const _script = selectedClonRScript.clone();
        const translation = _script.translations.find(t => t.language === selectedLanguage);
        const audioPlayer = document.getElementById("voice-preview") as HTMLAudioElement;

        if (tts && tts.audioUrl && tts.status === "finished") {

            audioPlayer.src = tts.audioUrl;
            audioPlayer.play();

            if (translation) {
                translation.audioUrl = tts.audioUrl;
                await ClonRScriptsService.updateScript(_script, currentClient.id);
                handleOnSelectedClonRScriptChange(_script);
            }

            setIsGeneratingScriptAudio(false);
            setIsPlayingScriptAudio(true);

        } else if (tts.status === "fromcache" && translation && translation.audioUrl) {
            audioPlayer.src = translation.audioUrl;
            audioPlayer.play();

            setIsGeneratingScriptAudio(false);
            setIsPlayingScriptAudio(true);
        }

    }

    const isInputValid = (checkVideoClone: boolean): boolean => {

        if (checkVideoClone && selectedClonRAvatar.videoUrl === "") {
            setErrorMessage("Bitte wählen Sie einen Video Clone.");
            return false;
        }

        if (selectedClonRVoice.name.trim() === "") {
            setErrorMessage("Bitte wählen Sie eine Stimme.");
            return false;
        }

        const translation = selectedClonRScript.translations.find(t => t.language === selectedLanguage);

        if (selectedClonRScript.name.trim() === "") {
            setErrorMessage("Bitte geben Sie einen Skriptnamen ein.");
            return false;

        } else if (!translation || translation.text.trim().length < 5) {
            setErrorMessage("Bitte geben Sie einen Skripttext ein, mit mindestens 5 Zeichen.");
            return false;

        }

        setErrorMessage("");
        return true;
    }

    const handleOnGenerateVideoClick = () => {

        stopVoiceAudio();

        if (isInputValid(true)) {
            ClonRScriptsService.updateScript(selectedClonRScript, currentUser.clientId);

            // now create a list of all already created videos for that same avatar and script

            const i = selectedClonRScript.translations.findIndex(t => t.language === selectedLanguage);
            setSelectedLanguageIndex(i === -1 ? 0 : i);
            setIsStartVideoCreationConfirmDialogOpen(true);
        }
    }

    const handleOnSelectedClonRAvatarChange = (newSelection: ClonRAvatar) => {
        setSelectedClonRAvatar(newSelection);
        setIsAvatarListPopupVisible(false);

        if (localStorage) {
            localStorage.setItem("selectedClonRAvatar", newSelection.id);
        }
    }

    const handleOnSelectedClonRVoiceChange = (newSelection: ClonRVoice) => {
        setSelectedClonRVoice(newSelection);
        setIsVoiceListPopupVisible(false);
        stopVoiceAudio();

        if (localStorage) {
            localStorage.setItem("selectedClonRVoice", newSelection.id);
        }
    }

    const handleOnSelectedClonRScriptChange = (newSelection: ClonRScript) => {
        setSelectedClonRScript(newSelection);

        if (localStorage) {
            localStorage.setItem("selectedClonRScript", newSelection.id);
            localStorage.setItem("selectedClonRScriptLanguage", selectedLanguage);
        }
    }

    const handleOnSelectedLanguageChange = (newSelection: string) => {
        setSelectedLanguage(newSelection);

        if (localStorage) {
            localStorage.setItem("selectedClonRScriptLanguage", newSelection);
        }
    }


    return (
        <>

            <ProgressDialog
                visible={isGeneratingScriptAudio}
                title="Audio wird generiert"
                message='Das Audio für Ihr Skript wird nun erstellt. Dies kann einen Moment dauern...'
            />

            <MessageDialog
                visible={errorMessage.length > 0}
                title="Fehler"
                titleIconFaClassName='fa fa-exclamation'
                message={errorMessage}
                onClose={() => setErrorMessage("")}
            />

            <ClonRConfirmVideoGenerationDlg
                visible={isStartVideoCreationConfirmDialogOpen}
                onClose={() => setIsStartVideoCreationConfirmDialogOpen(false)}
                onScriptChanged={(newScript) => handleOnSelectedClonRScriptChange(newScript)}
                clonRScript={selectedClonRScript}
                clonRAvatar={selectedClonRAvatar}
                clonRVoice={selectedClonRVoice}
                language={selectedLanguage}
            />

            <Dialog
                open={isAvatarListPopupVisible}
                fullScreen={true}
            >
                <DialogTitle id="customized-dialog-title">
                    Clone auswählen
                </DialogTitle>

                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => setIsAvatarListPopupVisible(false)}
                    aria-label="close"
                    style={{
                        position: 'absolute',
                        right: 20,
                        top: 8
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent>
                    <ClonRAvatarListCtrl
                        ownAvatars={ownAvatars}
                        publicAvatars={publicAvatars}
                        selectedClonRAvatar={selectedClonRAvatar}
                        onSelectionChange={(newSelection) => handleOnSelectedClonRAvatarChange(newSelection)}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                open={isVoiceListPopupVisible}
                fullScreen={true}
            >
                <DialogTitle id="customized-dialog-title">
                    Stimme auswählen
                </DialogTitle>

                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => setIsVoiceListPopupVisible(false)}
                    aria-label="close"
                    style={{
                        position: 'absolute',
                        right: 20,
                        top: 8
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent>
                    <ClonRVoiceListCtrl
                        ownVoices={ownVoices}
                        publicVoices={publicVoices}
                        selectedClonRVoice={selectedClonRVoice}
                        isPlayingVoiceAudio={isPlayingVoiceAudio}
                        showSelectionBorder={true}
                        handleStopVoiceClick={handleStopVoiceClick}
                        handlePlayVoiceClick={handlePlayVoiceClick}
                        onSelectionChange={(newSelection) => handleOnSelectedClonRVoiceChange(newSelection)}
                    />
                </DialogContent>
            </Dialog>



            <audio id="voice-preview" />

            <div className='kt-merge-grid'>

                <ClonRAvatarListCtrl
                    className="kt-hide-on-mobile"
                    ownAvatars={ownAvatars}
                    publicAvatars={publicAvatars}
                    selectedClonRAvatar={selectedClonRAvatar}
                    onSelectionChange={(newSelection) => handleOnSelectedClonRAvatarChange(newSelection)}
                />

                <Paper className='kt-paper kt-video-avatar'>

                    <h3 className='kt-hide-on-mobile'>Video erstellen</h3>
                    <div><strong className='kt-hide-on-desktop'>Video erstellen</strong></div>
                    {selectedClonRAvatar.videoUrl ?
                        <video
                            id="clonRAvatarPreviewVideo"
                            src={selectedClonRAvatar.videoUrl}
                            playsInline
                            controls
                            crossOrigin='anonymous'
                            preload='metadata'
                            poster={selectedClonRAvatar.thumbnailUrl}
                            autoPlay
                            loop
                            muted
                        />
                        :
                        <>
                            <p><strong className='kt-primary-color'>1. <i className='fal fa-user'></i></strong> Wählen Sie zunächst einen Video Clone</p>

                        </>

                    }
                    <div className='kt-clone-voice-select-button-mobile kt-hide-on-desktop' style={{ marginBottom: "10px" }}>
                        <Button variant='outlined' size='small' onClick={() => setIsAvatarListPopupVisible(true)}>Clone laden</Button>
                    </div>



                    {selectedClonRAvatar.videoUrl &&
                        <>
                            {selectedClonRVoice.name ?
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <ClonRVoiceCardCtrl
                                        voice={selectedClonRVoice}
                                        showSelectionBorder={false}
                                        selectedClonRVoice={selectedClonRVoice}
                                        isPlayingVoiceAudio={isPlayingVoiceAudio}
                                        handleStopVoiceClick={handleStopVoiceClick}
                                        handlePlayVoiceClick={handlePlayVoiceClick}
                                        onSelectionChange={(newSelection) => handleOnSelectedClonRVoiceChange(newSelection)}
                                    />

                                </div>
                                :
                                <>
                                    <p><strong className='kt-primary-color'>2. <i className='fal fa-waveform-path'></i></strong> Wählen Sie nun eine Stimme</p>
                                </>
                            }

                            <div className='kt-clone-voice-select-button-mobile kt-hide-on-desktop'>
                                <Button variant='outlined' size='small' onClick={() => setIsVoiceListPopupVisible(true)}>Stimme laden</Button>
                            </div>
                        </>}

                </Paper>

                <ClonRVoiceListCtrl
                    className="kt-hide-on-mobile"
                    ownVoices={ownVoices}
                    publicVoices={publicVoices}
                    selectedClonRVoice={selectedClonRVoice}
                    isPlayingVoiceAudio={isPlayingVoiceAudio}
                    showSelectionBorder={true}
                    handleStopVoiceClick={handleStopVoiceClick}
                    handlePlayVoiceClick={handlePlayVoiceClick}
                    onSelectionChange={(newSelection) => handleOnSelectedClonRVoiceChange(newSelection)}
                />

                {(selectedClonRAvatar.videoUrl && selectedClonRVoice.name) &&
                    <>
                        <div className='kt-video-script-wrapper'>
                            <ClonRScriptCtrl
                                clonRSettings={clonRSettings}
                                selectedScript={selectedClonRScript}
                                selectedLanguage={selectedLanguage}
                                onSelectedScriptChange={s => handleOnSelectedClonRScriptChange(s)}
                                onSelectedLanguageChange={l => handleOnSelectedLanguageChange(l)}
                            />
                        </div>

                        <div className='kt-actions'>
                            <Paper className='kt-paper kt-text-center kt-generate-clonr-video-btn-wrapper'>
                                <Button
                                    className="kt-generate-clonr-video-btn"
                                    variant="outlined"
                                    color='secondary'
                                    onClick={handleOnPreviewScriptAudioClick}
                                    disabled={isGeneratingScriptAudio}
                                >
                                    {isGeneratingScriptAudio ?
                                        <span><i className='fal fa-spinner-third fa-spin'></i> Wird generiert...</span>
                                        :
                                        <>
                                            {isPlayingScriptAudio ?
                                                <span><i className='fal fa-stop'></i> Stop</span>
                                                :
                                                <span><i className='fal fa-volume'></i> Anhören</span>
                                            }
                                        </>
                                    }

                                </Button>
                                <Button
                                    className="kt-generate-clonr-video-btn"
                                    variant="contained"
                                    color='primary'
                                    onClick={handleOnGenerateVideoClick}>
                                    <span><i className='fal fa-video'></i> Generieren</span>
                                </Button>
                            </Paper>
                        </div>
                    </>
                }
            </div>


        </>
    );
}

export default ClonRMergeCtrl;