import firebase from 'firebase/compat/app';
import database from "../components/database";
import User from '../models/user';
import Calendar, { CalendarLicense } from '../models/calendar';
import GlobalSettings from '../shared/src/models/globalSettings';
import Client from '../models/client';


const db = database.firestore();

const functions = firebase.app().functions('europe-west3');


const PaymentsService = {

    async createStripeCheckout(quantity: number, clientId: string, userId: string):Promise<string> {

        const createStripeCheckoutFunc = functions.httpsCallable("createStripeCheckout");

        const response = await createStripeCheckoutFunc({
            quantity: quantity,
            clientId: clientId,
            userId: userId
        });
    
        console.log(response.data?.url);

        return response.data?.url ?? "";
    },

    startListenForCredits(clientId: string, changeCallback: (credits: number)=>void): () => void {

        return db.collection("clients").doc(clientId)
            .onSnapshot(function(documentSnapshot) {
                
                if(documentSnapshot.exists){
                    const client = new Client();
                    client.fromObject(clientId, documentSnapshot.data());
                                    
                    changeCallback(client.credits);
                }
            });
    },

    async createStripeSubscriptionCheckout(calendar: Calendar, newLicense: CalendarLicense, clientId: string, userId: string):Promise<string> {

        const createStripeSubscriptionCheckoutFunc = functions.httpsCallable("createStripeSubscriptionCheckout");

        const response = await createStripeSubscriptionCheckoutFunc({            
            calendarId: calendar.id,
            clientId: clientId,
            locationId: calendar.locationId,
            userId: userId,
            license: newLicense
        });
    
        console.log(response.data?.url);

        return response.data?.url ?? "";
    },

    async updateStripeSubscription(calendar: Calendar, newLicense: CalendarLicense, clientId: string, userId: string):Promise<any> {
        const updateStripeSubscriptionFunc = functions.httpsCallable("updateStripeSubscription");

        const response = await updateStripeSubscriptionFunc({
            calendarId: calendar.id,
            clientId: clientId,
            locationId: calendar.locationId,
            userId: userId,
            license: newLicense
        });

        return response;
    },

    async cancelStripeSubscription(calendarId: string, clientId: string, locationId: string):Promise<any> {
        const cancelStripeSubscriptionFunc = functions.httpsCallable("cancelStripeSubscription");

        const response = await cancelStripeSubscriptionFunc({
            calendarId: calendarId,
            clientId: clientId,
            locationId: locationId           
        });

        return response;
    },

    getVideoPriceInCredits(globalSettings: GlobalSettings, audioLengthInSeconds: number): number {

        if(!globalSettings) return 0;
        
        // how many credits per 10 seconds of video we charge the client
        const creditsPerTenSeconds = globalSettings.clonRVideoCreditsPerTenSeconds;

        // calculate how many 10 seconds blocks the audio has
        // example: 12.3 seconds = 2 blocks of 10 seconds
        const tenSecondsBlocks = Math.ceil(audioLengthInSeconds / 10);
        const creditsPrice = tenSecondsBlocks * creditsPerTenSeconds;

        return creditsPrice;
    },

    getClonRAvatarPriceInCredits(globalSettings: GlobalSettings): number {
        if(!globalSettings) return 0;

        return globalSettings.clonRAvatarCredits;
    }

}

export default PaymentsService;




