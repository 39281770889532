import Paper from '@mui/material/Paper';
import React, { useContext, useEffect, useState, useRef } from 'react';
import ClonRVideo, { VideoGroup } from '../../shared/src/models/clonR/clonRVideo';
import ConfirmDialog from '../dialogs/confirmDialog';

import { GlobalContext } from '../../GlobalContext';
import ClonRVideosService from '../../services/clonRVideosService';
import MessageDialog from '../dialogs/messageDialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import ClonRVideoGroupCardCtrl from './clonRVideoGroupCardCtrl';

interface Props {
    videoId?: string
}


const ClonRVideosCtrl: React.FC<Props> = ({ videoId }) => {

    const unsubscribeVideosListenerRef = useRef<any>();

    const [isVideoDialogOpen, setIsVideoDialogOpen] = useState(false);
    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
    
    const [selectedVideo, setSelectedVideo] = useState(new ClonRVideo());
    const [selectedGroup, setSelectedGroup] = useState<VideoGroup | null>(null);

    const { currentUser } = useContext(GlobalContext);
    const { currentClient } = useContext(GlobalContext);

    const [videoGroups, setVideoGroups] = useState<VideoGroup[]>([]);

    const [isLoading, setIsLoading] = useState(true);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        
        unsubscribeVideosListenerRef.current = ClonRVideosService.startListenForClonRVideos(currentClient.id, _videos => {            
            setIsLoading(false);

            const groups = ClonRVideosService.buildVideoGroups(_videos);
            setVideoGroups(groups);
            openVideoFromUrl(_videos, groups);
        });

        return () => {
            if (unsubscribeVideosListenerRef.current) {
                unsubscribeVideosListenerRef.current();
            }
        }

    }, []);

    const openVideoFromUrl = (_videos: ClonRVideo[], _videoGroups: VideoGroup[]) => {
        console.log("param: " + videoId);
        
        if (videoId && _videos && _videos.length > 0) {
            const vid = _videos.find(v => v.id === videoId);
            if (vid && vid.status !== "inprogress") {

                // find group
                const g = _videoGroups.find(g => g.id === vid.getGroupId());
                if(g) {
                    setSelectedGroup(g);
                } else {
                    setSelectedGroup(null);
                }

                setSelectedVideo(vid);
                setIsVideoDialogOpen(true);
                return;
            }
        }

        setIsVideoDialogOpen(false);
    }


    const handleOnClickVideo = (v: ClonRVideo, g: VideoGroup) => {
        setSelectedVideo(v);
        setSelectedGroup(g);

        if (v.status !== "inprogress") {
            setIsVideoDialogOpen(true);
            window.history.pushState({}, "", `/clonr/video/${v.id}`);
        }
        console.log(`clonRVideo id: ${v.id}`);
    }

    const handleOnCloseClickVideo = () => {
        setIsVideoDialogOpen(false);
        window.history.pushState({}, "", `/clonr/video`);
    }

    const handleOnDeleteVideoClick = (v: ClonRVideo) => {
        setSelectedVideo(v);
        setIsConfirmDeleteDialogOpen(true);
    }

    const deleteVideo = async () => {
        await ClonRVideosService.deleteVideo(selectedVideo, currentUser.id, currentClient.id);
        setSelectedVideo(new ClonRVideo())
        setIsConfirmDeleteDialogOpen(false);
    }


    return (
        <>
            <ConfirmDialog
                visible={isConfirmDeleteDialogOpen}
                title='Videoklon löschen?'
                onReject={() => setIsConfirmDeleteDialogOpen(false)}
                onConfirm={deleteVideo}
                label={<>
                    <img
                        src={selectedVideo.thumbnailUrl}
                        width={128}
                        height={128}
                        alt="video avatar thumbnail"
                        style={{ borderRadius: "10px", border: "1px solid grey", objectFit: "contain", display: "flex", margin: "auto" }}
                    ></img>
                    <br />
                    <div>Wollen Sie den Videoklon {selectedVideo.name} wirklich löschen?</div>
                </>}
            />

            <MessageDialog
                title={`ClonRVideo`}
                titleIconFaClassName='none'
                visible={isVideoDialogOpen}
                size='xl'
                onClose={handleOnCloseClickVideo}
                fullScreen={fullScreen}
                message={<div style={{ textAlign: "center" }}>
                    <video
                        id="clonRVideo"
                        src={selectedVideo.videoUrl}
                        height={480}
                        autoPlay
                        playsInline
                        style={{ borderRadius: "10px", margin: "auto" }}
                        controls
                        controlsList="nofullscreen nodownload"
                    />
                    <div>{selectedVideo.name}</div>
                    {selectedGroup && <div>
                        {selectedGroup.videos.filter(v => v.status === "finished").map(v => v.id === selectedVideo.id ? 
                            <span key={v.id} className='kt-language-button kt-large kt-selected' onClick={()=>setSelectedVideo(v)}>{v.language.toUpperCase()}</span>
                            :
                            <span key={v.id} className='kt-language-button kt-large' onClick={()=>setSelectedVideo(v)}>{v.language.toUpperCase()}</span>
                        )}
                        </div>}
                </div>}
            />

            <Paper className="kt-paper">
                <div className='kt-paper-title'>Videos</div>

                <Link to="/clonr/newvideo" className='kt-big-add-button'>
                    <img src="/images/plus-icon.png"></img>
                    <Typography variant="body2">
                        <strong>Neues Video erstellen</strong>
                    </Typography>
                </Link>


                {(!isLoading && videoGroups.length === 0) && <div style={{textAlign:"center", marginTop:"20px"}}>Es sind noch keine Videos vorhanden. Kombinieren Sie einen Avatar, eine Stimme und ein Skript um ein Video zu erstellen.</div>}
                {(isLoading) && <div className='kt-loading'>Videos werden geladen... <i className='fas fa-spinner-third fa-spin'></i></div>}

                <br />

                <Box display={"flex"} flexWrap={"wrap"} gap={1} className="kt-justify-content-center-mobile">
                    {/* {videos.map(vid => <Box key={vid.id}>{VideoCard(vid)}</Box>

                    )} */}
                    {videoGroups.map(vGroup =>
                        <Box key={vGroup.id}>
                            <ClonRVideoGroupCardCtrl 
                                videoGroup={vGroup} 
                                onClickVideo={handleOnClickVideo}
                                onDeleteVideo={handleOnDeleteVideoClick}
                            />
                        </Box>

                    )}
                </Box>

            </Paper>
        </>
    );
}

export default ClonRVideosCtrl;