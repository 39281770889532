import { motion } from "framer-motion";
import { Avatar, Card, CardContent } from "@mui/material";
import { TranscriptItem } from "../shared/src/models/phoneCall";
import DateUtils from "../shared/src/utils/dateUtils";
import { useEffect, useRef } from "react";

interface ChatMessageProps {
  chatItem: TranscriptItem,
  nextChatItem: TranscriptItem | null,
  currentTime: number,
  onTimeClick: (time: number) => void,
}

const ChatMessage: React.FC<ChatMessageProps> = ({ chatItem, nextChatItem, currentTime, onTimeClick }) => {
  const chatMessageRef = useRef<HTMLDivElement | null>(null);
  const isUser = chatItem.role === "user";
  const isActive = currentTime >= chatItem.timeInCallSecs && (!nextChatItem || currentTime < nextChatItem.timeInCallSecs);

  useEffect(() => {
    if (isActive && chatMessageRef.current) {
      chatMessageRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [isActive]);

  if (!chatItem.message) return null;

  return (
    <motion.div
      ref={chatMessageRef}
      initial={{ opacity: 0, x: isUser ? 50 : -50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.3 }}
      className={`chat-message ${isUser ? "chat-message-user" : ""}`}
    >
      {!isUser && <Avatar src="/images/chatbot-40.png" alt="Agent" />}
      <Card
        className={`chat-bubble ${isUser ? "chat-bubble-user" : "chat-bubble-bot"} ${isActive ? "kt-active" : ""}`}
      >
        <CardContent style={{ padding: "5px 15px" }}>
          <p>{chatItem.message}</p>
          <span
            className="chat-time noselect"
            onClick={() => onTimeClick(chatItem.timeInCallSecs)}
          >
            {DateUtils.getDurationStringFromSecondsNoText(chatItem.timeInCallSecs)} <i className="far fa-play-circle" />
          </span>
        </CardContent>
      </Card>
      {isUser && <Avatar src="/user-avatar.png" alt="Patient" />}
    </motion.div>
  );
};

interface Props {
  transcript: TranscriptItem[];
  currentTime: number;
  onTimeClick: (time: number) => void;
}

const PhoneCallTranscriptChatView: React.FC<Props> = ({ transcript, currentTime, onTimeClick }) => {
  return (
    <div className="kt-phoneCallTranscriptChatView">
      {transcript.map((item, index) => (
        <ChatMessage
          key={index}
          chatItem={item}
          nextChatItem={transcript[index + 1] || null}  // Pass the next item or null if it doesn't exist
          onTimeClick={onTimeClick}
          currentTime={currentTime}
        />
      ))}
    </div>
  );
};

export default PhoneCallTranscriptChatView;