import DateUtils from "../utils/dateUtils";

export type Attachment = {
    id: string,
    name: string,
    documentId?: string,    
    path?: string,
    url?: string,
    createdOn?: Date
}

export class Email {

    id: string = "";

    senderEmail: string = "";
    senderName: string = "";

    toAddresses: string[] = [];
    ccAddresses: string[] = [];
    bccAddresses: string[] = [];

    subject: string = "";
    htmlBody: string = "";
    textBody: string = "";

    attachments: Attachment[] = [];

    sentOn: Date = new Date();
    
    patientId: string = "";
    createdByUserId: string = "";
    clientId: string = "";
    locationId: string = "";
    
    clone(): Email {

        const c = new Email();
        c.fromObject(this.toJSON());

        return c;
    }

    toJSON(): object {

        const obj: any = {
            id: this.id,

            senderEmail: this.senderEmail,
            senderName: this.senderName,

            toAddresses: this.toAddresses,
            ccAddresses: this.ccAddresses,
            bccAddresses: this.bccAddresses,

            subject: this.subject,
            htmlBody: this.htmlBody,
            textBody: this.textBody,

            attachments: this.attachments,

            sentOn: this.sentOn,

            patientId: this.patientId,
            createdByUserId: this.createdByUserId,
            clientId: this.clientId,
            locationId: this.locationId
        };

        return obj;
    }

    fromObject(o: any) {

        if (o.id) {
            this.id = o.id;
        }

        this.senderEmail = o.senderEmail ?? "";
        this.senderName = o.senderName ?? "";

        this.toAddresses = o.toAddresses ? [...o.toAddresses] : [];
        this.ccAddresses = o.ccAddresses ? [...o.ccAddresses] : [];
        this.bccAddresses = o.bccAddresses ? [...o.bccAddresses] : [];

        this.subject = o.subject ?? "";
        this.htmlBody = o.htmlBody ?? "";
        this.textBody = o.textBody ?? "";

        this.attachments = o.attachments ? [...o.attachments] : [];
        
        this.sentOn = DateUtils.getDate(o.sentOn) ?? new Date();

        this.patientId = o.patientId ?? "";
        this.createdByUserId = o.createdByUserId ?? "";
        this.clientId = o.clientId ?? "";
        this.locationId = o.locationId ?? "";
    }

}


export default Email;