import LandingPageData from "../shared/src/models/landingPageData";
import Speciality from "./speciality";


class VisitMotive {
    id: string = "";
    clientId: string = "";
    name: string = "";
    allowOnlineBooking: boolean = true;
    specialityId: string = "";
    color: string = "";
    cardinality: number = 1;
    __reorder__: string = "";
    icon: string = "tooth";
    duration: number = 15;
    calendarIds: string[] = []; // only visible in this calendars

    successorEnabled: boolean = false;
    successorInterval: string = "6-m"; // 1-d, 1-w, 1-m, 1-y
    successorId: string = "";
    successorSmsText: string = "";
    successorLandingPage: LandingPageData = new LandingPageData();

    recurrenceInterval: string = "6-m"; // 1-d, 1-w, 1-m, 1-y
    recurrenceCount: number = 0; // 0, 1..n, -1 = infinite

    patientInfo: string = "";
    nameForPatient: string = "";
    videoLink: string = "";

    landingPage: LandingPageData = new LandingPageData();

    selected: boolean = true;

    recallId: string = "";
    recallSmsText: string = "";
    recallLandingPage: LandingPageData = new LandingPageData();

    reminderSmsCustomTextEnabled: boolean = false;
    reminderSmsText: string = "";

    documentIds: string[] = []; // document ids of mandatory documents for this visit motive


    clone(): VisitMotive {
        const v = new VisitMotive();

        v.id = this.id;
        v.clientId = this.clientId;
        v.name = this.name;
        v.allowOnlineBooking = this.allowOnlineBooking;
        v.specialityId = this.specialityId;
        v.color = this.color;
        v.cardinality = this.cardinality;
        v.__reorder__ = this.__reorder__;
        v.icon = this.icon;
        v.duration = this.duration;
        v.calendarIds = [...this.calendarIds];

        v.successorEnabled = this.successorEnabled;
        v.successorInterval = this.successorInterval;
        v.successorId = this.successorId;
        v.successorSmsText = this.successorSmsText;
        v.successorLandingPage = this.successorLandingPage.clone();

        v.recurrenceInterval = this.recurrenceInterval;
        v.recurrenceCount = this.recurrenceCount;

        v.patientInfo = this.patientInfo;
        v.nameForPatient = this.nameForPatient;
        v.videoLink = this.videoLink;

        v.landingPage = this.landingPage.clone();

        v.selected = this.selected;

        v.recallId = this.recallId;
        v.recallSmsText = this.recallSmsText;
        v.recallLandingPage = this.recallLandingPage.clone();

        v.reminderSmsCustomTextEnabled = this.reminderSmsCustomTextEnabled;
        v.reminderSmsText = this.reminderSmsText;

        v.documentIds = [...this.documentIds];

        return v;
    }

    toJSON(): object {
        return {
            id: this.id,
            clientId: this.clientId,
            name: this.name,
            allowOnlineBooking: this.allowOnlineBooking,
            specialityId: this.specialityId,
            cardinality: parseInt(this.cardinality + ""),
            icon: this.icon,
            duration: this.duration,
            calendarIds: this.calendarIds,

            successorEnabled: this.successorEnabled,
            successorId: this.successorId,
            successorInterval: this.successorInterval,
            successorSmsText: this.successorSmsText,
            successorLandingPage: this.successorLandingPage.toJSON(),

            recurrenceInterval: this.recurrenceInterval,
            recurrenceCount: this.recurrenceCount,

            patientInfo: this.patientInfo,
            nameForPatient: this.nameForPatient,
            videoLink: this.videoLink,

            landingPage: this.landingPage.toJSON(),

            recallId: this.recallId,
            recallSmsText: this.recallSmsText,
            recallLandingPage: this.recallLandingPage.toJSON(),

            reminderSmsCustomTextEnabled: this.reminderSmsCustomTextEnabled,
            reminderSmsText: this.reminderSmsText,

            documentIds: this.documentIds
        }
    }

    fromObject(id:string|null, o:any) {

        this.id = id !== null ? id : o.id;

        this.clientId = o.clientId;
        this.name = o.name;
        this.allowOnlineBooking = o.allowOnlineBooking === true;
        this.specialityId = o.specialityId ?? "";
        this.cardinality = o.cardinality ? o.cardinality : 1;
        this.__reorder__ = o.name;
        this.icon = o.icon ?? "tooth";
        this.duration = o.duration ? parseInt(o.duration) : 0;
        this.calendarIds = o.calendarIds ? [...o.calendarIds] : [];

        this.successorEnabled = o.successorEnabled === true;
        this.successorId = o.successorId ?? "";
        this.successorInterval = o.successorInterval ?? "6-m";
        this.successorSmsText = o.successorSmsText ?? "";
        this.successorLandingPage = new LandingPageData();
        if(o.successorLandingPage) {
            this.successorLandingPage.fromObject(o.successorLandingPage);
        }

        this.recurrenceInterval = o.recurrenceInterval ?? "6-m";
        this.recurrenceCount = o.recurrenceCount ? parseInt(o.recurrenceCount) : 0;

        this.patientInfo = o.patientInfo ?? "";
        this.nameForPatient = o.nameForPatient ?? "";
        this.videoLink = o.videoLink ?? "";
        this.landingPage = new LandingPageData();
        if(o.landingPage){
            this.landingPage.fromObject(o.landingPage);
        }

        this.recallId = o.recallId ?? "";
        this.recallSmsText = o.recallSmsText ?? "";
        this.recallLandingPage = new LandingPageData();
        if(o.recallLandingPage){
            this.recallLandingPage.fromObject(o.recallLandingPage);
        }

        this.reminderSmsCustomTextEnabled = o.reminderSmsCustomTextEnabled === true;
        this.reminderSmsText = o.reminderSmsText ?? "";

        this.documentIds = o.documentIds ? [...o.documentIds] : [];
    }

    setColorFromSpeciality(specialities: Speciality[]) {
        for (let i = 0; i < specialities.length; i++) {
            const speciality = specialities[i];
            if(speciality.id === this.specialityId){
                this.color = speciality.color;
            }
        }
    }

    getErrors(): string[] {
        const errors: string[] = [];

        if(this.successorEnabled) {
            if(!this.successorId) errors.push("Folgetermin fehlt.");
            if(!this.successorSmsText) errors.push("Folgetermin SMS Text fehlt.")
        }

        if(this.recurrenceCount !== 0) {
            if(!this.recallId) errors.push("Recalltermin fehlt.")
            if(!this.recallSmsText) errors.push("Recall SMS Text fehlt.")
        }

        return errors;
    }

};



export default VisitMotive;