
class CalendarViewSettings {
    slotMinTime: string = "08:00";
    slotMaxTime: string = "18:00";

    clone(): CalendarViewSettings {
        const s = new CalendarViewSettings();
        s.fromObject(this.toJSON());        
        return s;
    }

    toJSON(): object {
        return {
            slotMinTime: this.slotMinTime,
            slotMaxTime: this.slotMaxTime
        };
    }

    fromObject(o:any){
        this.slotMinTime = o.slotMinTime ?? "08:00";
        this.slotMaxTime = o.slotMaxTime ?? "18:00";
    }
};

export default CalendarViewSettings;