import Box from "@mui/material/Box";

const LoadingPage:React.FC = () => {

    return (
        <Box className="kt-page">
            <h2>Daten werden geladen <i className="fal fa-spinner-third fa-spin"></i></h2>
        </Box>
    );
}

export default LoadingPage;