import { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import ClientLocationsService from "../../../services/clientLocationsService";
import LoadingPage from "../administration/loadingPage";
import ClientLocation from '../../../models/clientLocation';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from "@mui/icons-material/Delete";

import { DataGrid, deDE } from '@mui/x-data-grid'
import { GlobalContext } from '../../../GlobalContext';

function ClientLocationsPage() {

    const [clientLocations, setClientLocations] = useState<ClientLocation[]>([]);

    const [isLoading, setIsLoading] = useState(false);
    const {currentClient} = useContext(GlobalContext);

    useEffect( () => {

        updateLocations();

        // eslint-disable-next-line
    }, [currentClient]);

    async function updateLocations(){
        setIsLoading(true);

        try {
            const result = await ClientLocationsService.getAllLocations(currentClient.id);

            setIsLoading(false);
            if(result){
                setClientLocations(result);
            }

        } catch(error) {
            setIsLoading(false);
        }
    }

    const columns: any[] = [
        { field: 'name', headerName: 'Name', width: 300, renderCell: (params) => (
            <Link to={`/settings/clientlocation/${params.row.id}`}>{params.row.name}</Link>
        )},
        { field: 'delete', headerName: " ", width: 64, renderCell: (params) => (
            <IconButton onClick={ (e) => onDeleteRowClick(params.row)}>
                <DeleteIcon/>
            </IconButton>)
        }
      ];


    function onDeleteRowClick(row){
        const reallyDelete = window.confirm(`Wollen Sie den Standort ${row.name} wirklich löschen?`);
        if(reallyDelete) {
            //ClientsService.deleteClient(row.id);
        }
    }

    if(isLoading) return <LoadingPage/>;

    return (
        <Box className="kt-page">
            <h2>Standorte</h2>

            <Grid container>
                <Grid item>
                    <ButtonGroup>
                        <Button
                            component={Link}
                            to="/settings/clientlocation/create"
                            startIcon={<AddCircleOutlineIcon/>}
                        >
                            Standort anlegen
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>

            <Box className="kt-data-grid-container">
                <DataGrid
                    rows={clientLocations}
                    columns={columns}
                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>

        </Box>
    );
}

export default ClientLocationsPage;