import React, { useState, useEffect, useContext } from 'react';

import DateUtils from "./../../../shared/src/utils/dateUtils";

import AppointmentsService, { EventType } from "./../../../services/appointmentsService";
import CalendarsService from "./../../../services/calendarsService";


import moment from 'moment';
import PublicHoliday from '../../../models/publicHoliday';
import Appointment from '../../../models/appointment';
import Patient from '../../../models/patient';
import { Toast } from '../../toaster';
import { GlobalContext } from '../../../GlobalContext';


interface Props {
    minDate?: Date | null;
    doctor: any;
    patient: Patient;
    appointment?: any;
    appointments: any[];
    publicHolidays: PublicHoliday[];
    locationId: any;
    visitMotiveId: any;
    visitMotives: any[];
    locationOpeningHours: any;
    onAppointmentCreated: (patient: Patient) => void;
}

const FreeTimeSlotsCtrl: React.FC<Props> = ({ minDate, doctor, patient, appointment, appointments, publicHolidays, locationId, visitMotiveId, visitMotives, locationOpeningHours, onAppointmentCreated }) => {

    const {currentClient} = useContext(GlobalContext);
    const {currentLocation} = useContext(GlobalContext);
    const {currentUser} = useContext(GlobalContext);

    const {setNewToast} = useContext(GlobalContext);

    const [weekDays, setWeekDays] = useState<moment.Moment[]>([]);
    const [startDate, setStartDate] = useState(moment().startOf('week'));
    const [currentDate, setCurrentDate] = useState<moment.Moment>();
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<moment.Moment | null>(null);
    const [freeTimeSlots, setFreeTimeSlots] = useState<any[]>([]);
    const [gotoNextWeekCounter, setGotoNextWeekCounter] = useState(0);
    const [isPreviousButtonEnabled, setIsPreviousButtonEnabled] = useState(false);

    useEffect(() => {

        const _startDate = DateUtils.getDate(minDate ?? new Date());
        setStartDate(moment(_startDate).startOf("week"));

        const _currentDate = moment(_startDate).startOf("week");
        setCurrentDate(_currentDate);

        updateWeekDays(_currentDate);


    }, []);

    useEffect(() => {

        if (currentDate) updateWeekDays(currentDate);

    }, [currentDate]);


    function updateWeekDays(_currentDate: moment.Moment) {
        const tempWeekDays: moment.Moment[] = [];
        for (var i = 0; i < 7; i++) {
            let day = moment(_currentDate).add(i, "day");
            tempWeekDays.push(day);
        }
        setWeekDays(tempWeekDays);

        setIsPreviousButtonEnabled(_currentDate > startDate.startOf('week'));
    }

    function isPublicHoliday(date: moment.Moment): boolean {

        if (publicHolidays) {
            for (let i = 0; i < publicHolidays.length; i++) {
                const pH = publicHolidays[i].start;

                if (DateUtils.isSameDay(pH, date.toDate())) {
                    return true;
                }

            }
        }
        return false;
    }

    useEffect(() => {

        if (doctor) console.log(doctor.id + ": " + doctor.lastName);
        if (minDate) console.log("minDate: " + minDate.toString());
        console.log("visitMotiveId: " + visitMotiveId);


        function getFreeTimeSlots(appointments) {

            const _minDate = minDate ? DateUtils.getDate(minDate) : null;

            const openingHours = (doctor.openingHours && doctor.openingHours.enabled) ? doctor.openingHours : locationOpeningHours;

            if (!appointments || !openingHours || !visitMotiveId) {
                return [];
            }


            const slotsWeek: any[] = [];
            const slotDuration = 30; // selectedMotive ? selectedMotive.duration : 30; // minutes

            const now = moment();

            let freeSlotsCount = 0;

            // create days
            // d = 0 => monday
            for (let d = 0; d < 7; d++) {
                const slotsDay: moment.Moment[] = [];

                const dayOpeningHours = DateUtils.getOpeningHoursByDayIndex(openingHours, d, 1);

                // skip day if doctor has closed
                if (dayOpeningHours && dayOpeningHours.hasOpen) {

                    const workingMinutesPerDay = (dayOpeningHours.open.end.hour * 60 + dayOpeningHours.open.end.minute) - (dayOpeningHours.open.start.hour * 60 + dayOpeningHours.open.start.minute);

                    const slotsPerDay = workingMinutesPerDay / slotDuration;

                    const currentDayDate = moment(currentDate).hour(dayOpeningHours.open.start.hour).minute(dayOpeningHours.open.start.minute).add(d, "days");

                    // skip day if its a public holiday
                    // or if day is before minDate
                    if (!isPublicHoliday(currentDayDate) && ((_minDate && currentDayDate >= moment(_minDate)) || !_minDate)) {

                        // create slots per day
                        for (let s = 0; s < slotsPerDay; s++) {
                            const timeSlotDateStart = moment(currentDayDate).add(s * slotDuration, "minutes");
                            const timeSlotDateEnd = moment(timeSlotDateStart).add(slotDuration, "minutes");

                            // skip slots which where in the past
                            // now check if there is an appointment already for that time slot interval
                            if ((!minDate || timeSlotDateStart > moment(minDate)) && timeSlotDateStart > now && !isSlotIntersectingWithAnyAppointments(timeSlotDateStart, timeSlotDateEnd, appointments)
                                && !(dayOpeningHours.hasPause && isSlotIntersectingPause(timeSlotDateStart, timeSlotDateEnd, dayOpeningHours.pause))) {

                                freeSlotsCount++;
                                slotsDay.push(timeSlotDateStart);
                            }

                        }
                    }

                }

                slotsWeek.push(slotsDay);
            }

            if (freeSlotsCount === 0) {
                // if no free appointments in this week, goto next week
                // try this only two times
                if (gotoNextWeekCounter < 2) {
                    setCurrentDate(moment(currentDate).add(1, "week"));
                    setGotoNextWeekCounter(gotoNextWeekCounter + 1);
                }
            }

            return slotsWeek;
        }

        setFreeTimeSlots(getFreeTimeSlots(appointments));
        // eslint-disable-next-line
    }, [visitMotiveId, appointments, doctor, currentDate]);


    useEffect(() => {
        setGotoNextWeekCounter(0);
    }, [visitMotiveId, doctor]);


    function isSlotIntersectingWithAnyAppointments(timeSlotDateStart: moment.Moment, timeSlotDateEnd: moment.Moment, appointments) {
        let isIntersecting = false;

        for (let a = 0; a < appointments.length; a++) {
            const appointment = appointments[a];

            if (isSlotIntersectingAppointment(timeSlotDateStart, timeSlotDateEnd, appointment)) {
                isIntersecting = true;
                break;
            }
        }

        return isIntersecting;
    }

    function isSlotIntersectingAppointment(timeSlotDateStart: moment.Moment, timeSlotDateEnd: moment.Moment, appointment) {

        const appointmentStart = moment(appointment.start);
        const appointmentEnd = moment(appointment.end);

        return (timeSlotDateStart >= appointmentStart && timeSlotDateStart < appointmentEnd) ||
            (timeSlotDateEnd > appointmentStart && timeSlotDateEnd < appointmentEnd) ||
            (appointmentStart >= timeSlotDateStart && appointmentStart < timeSlotDateEnd) ||
            (appointmentEnd > timeSlotDateStart && appointmentEnd < timeSlotDateEnd);
    }


    function isSlotIntersectingPause(timeSlotDateStart: moment.Moment, timeSlotDateEnd: moment.Moment, pause) {

        const pauseStart = moment(timeSlotDateStart).hour(pause.start.hour).minute(pause.start.minute);
        const pauseEnd = moment(timeSlotDateEnd).hour(pause.end.hour).minute(pause.end.minute);

        return (timeSlotDateStart >= pauseStart && timeSlotDateStart < pauseEnd) ||
            (timeSlotDateEnd > pauseStart && timeSlotDateEnd < pauseEnd) ||
            (pauseStart >= timeSlotDateStart && pauseStart < timeSlotDateEnd) ||
            (pauseEnd > timeSlotDateStart && pauseEnd < timeSlotDateEnd);
    }


    async function createAppointment(timeSlot, selectedMotive, calendarIds) {

        if (locationId && selectedMotive && timeSlot && calendarIds && calendarIds.length > 0) {

            // get calendar name for that doctor
            const calendar = await CalendarsService.getCalendar(doctor.clientId, locationId, calendarIds[0])

            if (!calendar) return;

            const appointment = new Appointment();
            appointment.start = timeSlot.toDate();
            appointment.end = moment(timeSlot).add(selectedMotive.duration, "minutes").toDate();
            appointment.clientId = calendar.clientId;
            appointment.locationId = locationId;
            appointment.calendar = calendar;
            appointment.patient = patient;
            appointment.visitMotive = selectedMotive;
            appointment.status = "confirmed";
            appointment.createdAt = new Date();

            appointment.createdBy = "phoneCampaign";
            appointment.resourceId = calendar.id;

            await AppointmentsService.updateAppointment(appointment, patient, currentClient, currentLocation, currentUser.id, undefined, undefined, (eventType, newAppointment) => {
                if (eventType === EventType.create) {
                    switch (newAppointment.createdBy) {
                        case "recaller":
                            appointment.recallId = newAppointment.id;
                            setNewToast(new Toast("success", `Recall am ${moment(newAppointment.start).format("DD.MM.YYYY")} erstellt`));
                            break;

                        case "predecessor":
                            appointment.predecessorId = newAppointment.id;
                            setNewToast(new Toast("success", `Folgetermin am ${moment(newAppointment.start).format("DD.MM.YYYY")} erstellt`));
                            break;

                        default:
                            setNewToast(new Toast("success", `Termin am ${moment(newAppointment.start).format("DD.MM.YYYY")} erstellt`));
                            break;
                    }

                }
            });


            onAppointmentCreated(patient);

        }
    }


    async function handleTimeSlotClick(timeSlot: moment.Moment) {

        if (doctor && doctor.clientId) {

            let calendarIds: any[] = [];

            if (doctor && doctor.calendarIds) {
                calendarIds = doctor.calendarIds;

            } else if (doctor && doctor.calendarIds) {
                calendarIds = doctor.calendarIds;
            }

            const selectedMotive = visitMotives.find(m => m.id === visitMotiveId);

            setSelectedTimeSlot(timeSlot);

            try {
                await createAppointment(timeSlot, selectedMotive, calendarIds);

            } catch (err) {
                console.error(err);
                alert("Fehler beim Erstellen des Termins.");
            }

        }

    }

    function gotoPreviousWeek() {
        const newDateMoment = moment(currentDate).add(-1, "week");

        setCurrentDate(newDateMoment);
    }

    function gotoNextWeek() {
        const newDateMoment = moment(currentDate).add(1, "week");

        setCurrentDate(newDateMoment);
    }


    function renderFreeTimeSlots() {
        return (
            <div>
                <div className="kt-booking-calendar-header">
                    {weekDays.map((day, index) =>
                        <div key={index}><div>{day.format("dd")}</div><div>{`${day.date()}.${day.month() + 1}`}</div></div>
                    )}
                </div>

                <div className="kt-booking-calendar-time-slots">
                    {freeTimeSlots.map((dayTimeSlots, dayIndex) =>
                        <div key={dayIndex} className="kt-day-slots-column">
                            {dayTimeSlots.map((timeSlot, slotIndex) =>
                                <div key={slotIndex} onClick={() => handleTimeSlotClick(timeSlot)} className={selectedTimeSlot === timeSlot ? "kt-selected" : ""}>
                                    {timeSlot.format("HH:mm")}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    if (!currentDate) {
        return <div></div>
    }

    return (

        <div className="kt-booking-calendar">
            <div className="kt-booking-calendar-navigation">
                {isPreviousButtonEnabled ?
                    <div className="kt-btn-enabled" onClick={gotoPreviousWeek}><i className="fal fa-chevron-circle-left" aria-hidden="true"></i></div>
                    :
                    <div><i className="fal fa-chevron-circle-left" aria-hidden="true"></i></div>
                }

                <div>{`${currentDate.date()}.${currentDate.format("MMMM")} - ${moment(currentDate).add(6, "day").date()}.${moment(currentDate).add(6, "day").format("MMMM")} ${currentDate.format("YYYY")}`}</div>
                <div className="kt-btn-enabled" onClick={gotoNextWeek}><i className="fal fa-chevron-circle-right" aria-hidden="true"></i></div>
            </div>
            {renderFreeTimeSlots()}
        </div>

    );
}

export default FreeTimeSlotsCtrl;