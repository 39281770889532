import React, {useEffect, useContext, useState} from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

import Calendar from '../models/calendar';
import CalendarsService from '../services/calendarsService';
import Avatar from './avatar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Speciality from '../models/speciality';
import SpecialitiesService from '../services/specialitiesService';
import Room from '../models/room';
import Device from '../models/device';
import RoomsService from '../services/roomsService';
import DevicesService from '../services/devicesService';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Badge from '@mui/material/Badge';
import { GlobalContext } from '../GlobalContext';
import { t } from 'i18next';


interface Props {
    mode: "calendar" | "dashboard" | "campaign";
    showOnlyCalendars: boolean;
    onViewChange?: (newView: "calendars" | "devices" | "rooms") => void;
}


const ResourceFilter:React.FC<Props> = ({mode, showOnlyCalendars, onViewChange}) => {

    const [expandedPanel, setExpandedPanel] = useState(restorePanelSelection());
    const [selectedView, setSelectedView] = useState(restoreViewSelection());

    const {calendars, setCalendars} = useContext(GlobalContext);
    const {specialities, setSpecialities} = useContext(GlobalContext);
    const {rooms, setRooms} = useContext(GlobalContext);
    const {devices, setDevices} = useContext(GlobalContext);

    const [areAllSpecialitiesSelected, setAreAllSpecialitiesSelected] = useState(true);

    useEffect(() => {
        if(onViewChange){
            onViewChange(restoreViewSelection() as any);
        }
    }, []);

    useEffect(() => {

        if(specialities){
            const selectionCount = specialities.filter( speciality => speciality.selected);
            setAreAllSpecialitiesSelected(selectionCount.length === specialities.length);
        }

    }, [specialities]);


    interface SelectableListItem {
        id: string,
        name: string,
        selected: boolean,
        clone(): any
    }

    function setList<T extends SelectableListItem>(list: T[]) {

        if(list.length > 0) {

            const newList = list.map( item => item.clone()); // clone to force a rerender

            if(list[0] instanceof Calendar){
                setCalendars(newList as any);

            } else if(list[0] instanceof Speciality){
                setSpecialities(newList as any);

            } else if(list[0] instanceof Room){
                setRooms(newList as any);

            } else if(list[0] instanceof Device){
                setDevices(newList as any);

            }

        }
    }

    function getPropertyName(){
        switch (mode) {
            case "dashboard":
                return "selectedOnDashboard";

            case "campaign":
                return "selectedOnCampaign";

            default:
                return "selected";
        }
    }

    function selectAll<T extends SelectableListItem>(list: T[]) {
        const propertyName = getPropertyName();

        for (let i = 0; i < list.length; i++) {
            list[i][propertyName] = true;
        }

        setList<T>(list);
        handleListSelectionChange<T>(list);
    }

    function toggleItem<T extends SelectableListItem>(itemId: string, list: T[]) {

        const propertyName = getPropertyName();

        const selectedCount = list.filter(itm => itm[propertyName]).length;

        for (let i = 0; i < list.length; i++) {
            if(list[i].id === itemId){

                // always make sure that we have at least one item selected
                if(selectedCount > 1) {
                    list[i][propertyName] = !list[i][propertyName];
                } else if(!list[i][propertyName]) {
                    list[i][propertyName] = true;
                }
            }
        }

        setList<T>(list);
        handleListSelectionChange<T>(list);
    }

    function selectOnlyThisItem<T extends SelectableListItem>(itemId: string, list: T[]) {

        const propertyName = getPropertyName();

        for (let i = 0; i < list.length; i++) {
            if(list[i].id === itemId){
                list[i][propertyName] = true;
            }else {
                list[i][propertyName] = false;
            }
        }

        setList<T>(list);
        handleListSelectionChange<T>(list);
    }


    function handleListSelectionChange<T extends SelectableListItem>(list: T[]){
        if(list.length > 0) {

            if(list[0] instanceof Calendar){
                CalendarsService.saveCalendarsSelection(list as any);

            } else if(list[0] instanceof Speciality){
                SpecialitiesService.saveSpecialitiesSelection(list as any);

            } else if(list[0] instanceof Room){
                RoomsService.saveRoomsSelection(list as any);

            } else if(list[0] instanceof Device){
                DevicesService.saveDevicesSelection(list as any);

            }

        }
    }



    function renderCalendarsList(list: Calendar[]){
        const propertyName = getPropertyName();

        return (
            <ul>
                <li className="kt-resource-filter-li" key={"all-calendars"}>
                        <label className="kt-resource-filter-label" onClick={(e) => selectAll<Calendar>(calendars)}>{t("components.resourceFilter.all")}</label>
                </li>
                {list.filter(c=>c.license !== "disabled").map((res) => <li className="kt-resource-filter-li" key={res.id}>
                                        <Avatar src={res.avatarUrl} width={32}/>
                                        <label className="kt-resource-filter-label" onClick={(e) => selectOnlyThisItem<Calendar>(res.id, calendars)}>{res.name}</label>

                                        <Checkbox checked={res[propertyName]} onChange={(e )=> toggleItem<Calendar>(res.id, calendars)} color="default"/>
                                </li>
                            )}
            </ul>
        );

    }

    function renderSpecialitiesList(list: Speciality[]){
        const propertyName = getPropertyName();

        return (
            <ul>
                <li className="kt-resource-filter-li" key={"all-specialities"}>
                        <label className="kt-resource-filter-label" onClick={(e) => selectAll<Speciality>(specialities)}>{t("components.resourceFilter.all")}</label>
                </li>
                {list?.map((res) => <li className="kt-resource-filter-li kt-speciality-li" key={res.id}>
                                        <div className="kt-speciality-color-rect" style={{backgroundColor:res.color}}></div>
                                        <label className="kt-resource-filter-label" onClick={(e) => selectOnlyThisItem<Speciality>(res.id, specialities)}>{res.name}</label>

                                        <Checkbox checked={res[propertyName]} onChange={(e )=> toggleItem<Speciality>(res.id, specialities)} color="default"/>
                                    </li>
                            )}
            </ul>
        );

    }

    function renderRoomsList(list: Room[]){
        return (
            <ul>
                <li className="kt-resource-filter-li" key={"all-rooms"}>
                        <label className="kt-resource-filter-label" onClick={(e) => selectAll<Room>(rooms)}>{t("components.resourceFilter.all")}</label>
                </li>
                {list?.map((room) => <li className="kt-resource-filter-li kt-two-columns-li" key={room.id}>
                                        <label className="kt-resource-filter-label" onClick={(e) => selectOnlyThisItem<Room>(room.id, rooms)}>{room.name}</label>

                                        <Checkbox checked={room.selected} onChange={(e )=> toggleItem<Room>(room.id, rooms)} color="default"/>
                                </li>)}
            </ul>
        );

    }

    function renderDevicesList(list: Device[]){
        return (<ul>
            <li className="kt-resource-filter-li" key={"all-devices"}>
                    <label className="kt-resource-filter-label" onClick={(e) => selectAll<Device>(devices)}>{t("components.resourceFilter.all")}</label>
            </li>
            {list?.map((device) => <li className="kt-resource-filter-li kt-two-columns-li" key={device.id}>
                                    <label className="kt-resource-filter-label" onClick={(e) => selectOnlyThisItem<Device>(device.id, devices)}>{device.name}</label>

                                    <Checkbox checked={device.selected} onChange={(e )=> toggleItem<Device>(device.id, devices)} color="default"/>
                               </li>)}
        </ul>);

    }


    function onPanelChange(newPanel: string){
        const _panel = newPanel === expandedPanel ? "none" : newPanel;
        setExpandedPanel(_panel);
        savePanelSelection(_panel);

        if(_panel === "calendars" || _panel === "rooms" || _panel === "devices") {
            setSelectedView(_panel);
            saveViewSelection(_panel);
            if(onViewChange){
                onViewChange(_panel);
            }
        }
    }

    function savePanelSelection(panel: string){
        if(localStorage){
            localStorage.setItem("selectedResourceFilterPanel", panel);
        }
    }

    function restorePanelSelection(): string {
        try{
            if(localStorage){
                const _panel = localStorage.getItem("selectedResourceFilterPanel");
                return _panel ? _panel : "calendars";
            }
        } catch(error){
            console.log(error);
        }

        return "calendars";
    }

    function saveViewSelection(view: string){
        if(localStorage){
            localStorage.setItem("selectedResourceView", view);
        }
    }

    function restoreViewSelection(): string {
        try{
            if(localStorage){
                const _view = localStorage.getItem("selectedResourceView");
                return _view ? _view : "calendars";
            }
        } catch(error){
            console.log(error);
        }

        return "calendars";
    }


    if(calendars.length === 0 || specialities.length === 0) return null;

    console.log("render resourceFilter");

    return (
        <div className="kt-resource-filter">

            <Accordion expanded={expandedPanel === 'calendars'} onChange={() => onPanelChange('calendars')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panelCalendars-content"
                    id="panelCalendars-header"
                >
                    <Typography><i className={selectedView === "calendars" ? "fal fa-calendar-alt kt-primary-color" : "fal fa-user-md"} ></i> Kalender</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {renderCalendarsList(calendars)}
                </AccordionDetails>
            </Accordion>

            {!showOnlyCalendars &&
                <React.Fragment>
                    {(mode === "calendar" && rooms && rooms.length > 1 ) && <Accordion expanded={expandedPanel === 'rooms'} onChange={() => onPanelChange('rooms')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panelRooms-content"
                            id="panelRooms-header"
                        >
                            <Typography><i className={selectedView === "rooms" ? "fal fa-door-closed kt-primary-color" : "fal fa-door-closed"}></i> {t("components.resourceFilter.rooms")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {renderRoomsList(rooms)}
                        </AccordionDetails>
                    </Accordion>}

                    {(mode === "calendar" && devices && devices.length > 1 ) && <Accordion expanded={expandedPanel === 'devices'} onChange={() => onPanelChange('devices')} className="kt-no-border-bottom">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panelDevices-content"
                            id="panelDevices-header"
                        >
                            <Typography><i className={selectedView === "devices" ? "fal fa-pager kt-primary-color" : "fal fa-pager"}></i> {t("components.resourceFilter.resources")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {renderDevicesList(devices)}
                        </AccordionDetails>
                    </Accordion>}

                    <Box mt={5}></Box>

                    <Accordion expanded={expandedPanel === 'specialities'} onChange={() => onPanelChange('specialities')} className="kt-no-border-bottom">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panelSpecialities-content"
                            id="panelSpecialities-header"
                        >
                            <Badge color="primary" anchorOrigin={{ vertical: 'top', horizontal: 'right' }} variant={areAllSpecialitiesSelected ? "standard" : "dot"}>
                                <Typography>{t("components.resourceFilter.visitMotives")}</Typography>
                            </Badge>
                        </AccordionSummary>
                        <AccordionDetails>
                            {renderSpecialitiesList(specialities)}
                        </AccordionDetails>
                    </Accordion>
                </React.Fragment>
            }

        </div>
    );
}

export default ResourceFilter;