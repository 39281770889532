import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from "@mui/material";
import PhoneCall, { PhoneCallCategory } from "../../shared/src/models/phoneCall";
import DateUtils from "../../shared/src/utils/dateUtils";
import PhoneCallTranscriptChatView from "../phoneCallTranscriptChatView";
import { t } from "i18next";
import { useCallback, useContext, useEffect, useState } from "react";
import SendSmsDialog from "./sendSmsDialog";
import { Toast } from "../toaster";
import { GlobalContext } from "../../GlobalContext";

interface Props {
    phoneCall: PhoneCall;
    visible: boolean;
    onClose: () => void;
}

const PhoneCallDialog: React.FC<Props> = ({ visible, phoneCall, onClose }) => {

    const { setNewToast } = useContext(GlobalContext);

    const [currentTime, setCurrentTime] = useState(0);
    const [audioElement, setAudioElement] = useState<HTMLAudioElement | null>(null);
    const [defaultSmsText, setDefaultSmsText] = useState('');
    const [isSmsDialogVisible, setIsSmsDialogVisible] = useState(false);


    // Define the function that will be used as a callback ref
    const setAudioRef = useCallback((node: HTMLAudioElement | null) => {
        setAudioElement(node);
    }, []);


    useEffect(() => {
        if (audioElement) {
            // This is where the audio element is definitely available
            const updateTime = () => {
                setCurrentTime(audioElement.currentTime);
            };

            audioElement.addEventListener('timeupdate', updateTime);

            // Optionally set initial time
            setCurrentTime(audioElement.currentTime);

            // Ensure to remove the event listener when the component unmounts
            return () => {
                audioElement.removeEventListener('timeupdate', updateTime);
            };
        }
    }, [audioElement]);


    function jumpToTime(time: number) {
        if (audioElement) {
            audioElement.currentTime = time;
            audioElement.play();
            setCurrentTime(time);
        }
    }

    function getDefaultText(phoneCall: PhoneCall) {
        let text = "";
        text += `Danke für Ihren Anruf.\n`;

        const filteredCategories = phoneCall.categories.filter(
            cat => cat === PhoneCallCategory.prescription
                || cat === PhoneCallCategory.medicalReferral
                || cat === PhoneCallCategory.medicalReport
        );

        if (filteredCategories.length === 1) {
            text += `Wir haben Ihre Anfrage bezüglich:\n - ${PhoneCall.getCategoryString(filteredCategories[0])}\nerhalten.\n`;
        } else if (filteredCategories.length > 1) {
            text += `Wir haben Ihre Anfragen bezüglich:\n`;
            filteredCategories.forEach(cat => {
                text += ` - ${PhoneCall.getCategoryString(cat)}\n`;
            });
            text += `erhalten.\n`;
        }

        text += `Wir melden uns in Kürze.\n`;
        text += `Ihr Praxis Team`;

        return text;
    }

    function handleOnSendSmsClick() {
        setDefaultSmsText(getDefaultText(phoneCall));

        setIsSmsDialogVisible(true);
    }

    function handleOnSendSms(smsText: string) {
        setIsSmsDialogVisible(false);
    }
    function handleOnSendSmsFinished(smsText: string) {
        setNewToast(new Toast("success", `SMS wurde versendet`));
    }

    return <>

        <SendSmsDialog
            visible={isSmsDialogVisible}
            defaultSmsText={defaultSmsText}
            mobilePhoneNumber={phoneCall.callerPhoneNumber}
            firstName={phoneCall.callerFirstName}
            lastName={phoneCall.callerLastName}
            onSendSms={handleOnSendSms}
            onSendSmsFinished={handleOnSendSmsFinished}
            onClose={() => setIsSmsDialogVisible(false)}
        />

        <Dialog
            open={visible}
            onClose={() => onClose()}
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle>{t("dialogs.phoneCallDialog.title")}{phoneCall.callerFirstName} {phoneCall.callerLastName}</DialogTitle>

            <DialogContent>

                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <audio
                            ref={setAudioRef}
                            controls
                            src={phoneCall.audioRecordingUrl}
                            style={{
                                width: '100%',
                                marginBottom: '20px'
                            }}
                        />
                        {
                            phoneCall.transcript &&
                            <PhoneCallTranscriptChatView
                                transcript={phoneCall.transcript}
                                currentTime={currentTime}
                                onTimeClick={jumpToTime}
                            />
                        }
                    </Grid>
                    <Grid item xs={4}>
                        <table style={{ width: '100%', borderSpacing: '0', borderCollapse: 'collapse' }}>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '8px', border: '1px solid #ccc' }}>
                                        {t("dialogs.phoneCallDialog.calledAt")}
                                    </td>
                                    <td style={{ padding: '8px', border: '1px solid #ccc' }}>
                                        {DateUtils.getDateTimeString(phoneCall.createdAt)}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '8px', border: '1px solid #ccc' }}>
                                        {t("dialogs.phoneCallDialog.duration")}
                                    </td>
                                    <td style={{ padding: '8px', border: '1px solid #ccc' }}>
                                        {DateUtils.getDurationStringFromSeconds(phoneCall.callDuration)}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '8px', border: '1px solid #ccc' }}>
                                        {t("dialogs.phoneCallDialog.patient")}
                                    </td>
                                    <td style={{ padding: '8px', border: '1px solid #ccc' }}>
                                        {phoneCall.callerFirstName} {phoneCall.callerLastName}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '8px', border: '1px solid #ccc' }}>
                                        {t("dialogs.phoneCallDialog.birthdate")}
                                    </td>
                                    <td style={{ padding: '8px', border: '1px solid #ccc' }}>
                                        {DateUtils.getDateString(phoneCall.callerBirthDate)}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '8px', border: '1px solid #ccc' }}>
                                        {t("dialogs.phoneCallDialog.phoneNumber")}
                                    </td>
                                    <td style={{ padding: '8px', border: '1px solid #ccc' }}>
                                        {phoneCall.callerPhoneNumber}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                </Grid>


            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => handleOnSendSmsClick()}
                    color="primary"
                    variant="outlined"
                    disabled={(phoneCall.callerPhoneNumber === "anonymous" || !phoneCall.callerPhoneNumber)}
                >
                    {t("components.buttons.sendSms")}
                </Button>
                <Button onClick={() => onClose()} color="primary" variant="contained">
                    {t("components.buttons.close")}
                </Button>
            </DialogActions>
        </Dialog>
    </>

}


export default PhoneCallDialog;