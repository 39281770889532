import firebase from 'firebase/compat/app';

const functions = firebase.app().functions('europe-west3');

const ShortUrlsService = {

    async createShortUrl(longUrl: string, expiresAt: Date): Promise<string> {
        const createShortUrlFunc = functions.httpsCallable("createShortUrl");
        const result = await createShortUrlFunc(
            {
                longUrl: longUrl,
                expiresAt: expiresAt.getTime() // we can't send a date object, only json data types
            });

        return result.data;
    }

}

export default ShortUrlsService;