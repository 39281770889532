
import AnswerFormItem from "./answerFormItem";
import FormItem, { FormItemEnum, InputFormItemInterface, InputUserType } from "./formItem";


export class RadioFormItem extends FormItem implements InputFormItemInterface {

    answers: AnswerFormItem[] = [];

    required: boolean = true;

    inputUser: InputUserType = "patient";

    value: string = "";

    constructor(rowId: string) {
        super(rowId);

        this.type = FormItemEnum.radio;

        this.setLanguageProperty("de", "Formulieren Sie Ihre Frage", "labels");

        // add some sample ansers
        const answer1 = new AnswerFormItem(this.id);
        answer1.type = FormItemEnum.radioAnswer;
        answer1.setLanguageProperty("de", "Ja", "labels");

        const answer2 = new AnswerFormItem(this.id);
        answer2.type = FormItemEnum.radioAnswer;
        answer2.setLanguageProperty("de", "Nein", "labels");

        this.answers = [answer1, answer2];
    }

    clone(): RadioFormItem {

        const c = new RadioFormItem(this.parentId);
        c.fromObject(null, this.toJSON(true));

        c.answers = this.answers.map( answer => answer.clone());

        c.value = this.value;

        return c;
    }

    toJSON(includeUserInputs: boolean): object {
        const obj: any = super.toJSON(includeUserInputs);

        obj.required = this.required;

        obj.inputUser = this.inputUser;

        obj.answers = this.answers.map( answer => answer.toJSON(includeUserInputs));

        if(includeUserInputs) {
            obj.value = this.value;
        }

        return obj;
    }

    fromObject(id: string|null, o:any){
        super.fromObject(id, o);

        this.required = o.required === true;

        this.inputUser = o.inputUser ?? "patient";

        this.value = o.value ?? "";

        this.answers = [];

        if(o.answers) {
            for (let i = 0; i < o.answers.length; i++) {
                const oAnswer = o.answers[i];

                if(oAnswer){
                    const answer = new AnswerFormItem(this.id);
                    answer.fromObject(null, oAnswer);

                    this.answers.push(answer);
                }
            }
        }
    }


}


export default RadioFormItem;