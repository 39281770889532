import DateUtils from "../../utils/dateUtils";
import InvoiceItem, { InvoiceItemType } from "./invcoiceItem";

export enum InvoiceStatus {
    unpaid,
    paid,
    overdue
}

export type InvoicePrices = {
    appointment: number,
    appointmentVirtual: number,
    appointmentVirtualConfirmed: number,
    document: number,
    smsConfirmation: number,
    smsESign: number,
    smsMarketing: number,
    smsRating: number,
    smsRecall: number,
    smsReminder: number,
    smsSuccessor: number
}

export class Invoice {

    id: string = "";

    invoiceNumber: string = "";

    forYear: number = 0;
    forMonth: number = 0; // January = 0 ...

    clientId: string = "";
    locationId: string = "";

    createdAt: Date = new Date();
    dueDate: Date = new Date();

    discount: number = 0; // percentage

    taxRate: number = 0; // percentage
    private taxes: number = 0; // absolute

    private subTotal: number = 0;
    private subTotalAfterDiscount: number = 0;
    private total: number = 0;

    status: InvoiceStatus = InvoiceStatus.unpaid;



    private items: InvoiceItem[] = [];

    constructor() {
        this.items = [];
    }


    clone(): Invoice {

        const c = new Invoice();
        c.fromObject(null, this.toJSON(true));

        return c;
    }

    toJSON(useDate: boolean): object {
        const obj: any = {};

        obj.id = this.id;

        obj.invoiceNumber = this.invoiceNumber;

        obj.forYear = this.forYear;
        obj.forMonth = this.forMonth;

        obj.clientId = this.clientId;
        obj.locationId = this.locationId;

        if(useDate){
            obj.createdAt = this.createdAt;
            obj.dueDate = this.dueDate;
        } else {
            obj.createdAt = this.createdAt.toJSON();
            obj.dueDate = this.dueDate.toJSON();
        }

        obj.discount = this.discount;

        obj.taxRate = this.taxRate;
        obj.taxes = this.taxes;

        obj.subTotal = this.subTotal;
        obj.subTotalAfterDiscount = this.subTotalAfterDiscount;
        obj.total = this.total;

        obj.status = this.status;

        obj.items = [];

        for (let i = 0; i < this.items.length; i++) {
            obj.items.push(this.items[i].toJSON(useDate));
        }

        return obj;
    }

    fromObject(id: string | null, o: any) {
        this.id = id !== null ? id : o.id;

        this.invoiceNumber = o.invoiceNumber ?? "";

        this.forYear = o.forYear ? parseInt(o.forYear) : 0;
        this.forMonth = o.forMonth ? parseInt(o.forMonth) : 0;

        this.clientId = o.clientId ?? "";
        this.locationId = o.locationId ?? "";

        this.createdAt = DateUtils.getDate(o.createdAt) ?? new Date();
        this.dueDate = DateUtils.getDate(o.dueDate) ?? new Date();

        this.discount = o.discount ?? 0;

        this.taxRate = o.taxRate ?? 0;
        this.taxes = o.taxes ?? 0;

        this.subTotal = o.subTotal ?? 0;
        this.subTotalAfterDiscount = o.subTotalAfterDiscount ?? 0;
        this.total = o.total ?? 0;

        this.status = o.status ?? InvoiceStatus.unpaid;

        this.items = [];

        if (o.items) {
            for (let i = 0; i < o.items.length; i++) {
                const oItems = o.items[i];

                const newInvoiceItem = new InvoiceItem("", InvoiceItemType.appointment, 0, 0);
                newInvoiceItem.fromObject(oItems);

                this.items.push(newInvoiceItem);
            }
        }
    }

    // discount item has to be added always as last item
    addInvoiceItem(newItem: InvoiceItem) {
        this.items.push(newItem);

        if(newItem.type !== InvoiceItemType.discount){
            this.subTotal += newItem.total;
        }

        this.calculateTaxesAndTotal();
    }

    getInvoiceItems(): InvoiceItem[] {
        return this.items;
    }

    calculateTaxesAndTotal() {

        this.subTotalAfterDiscount = this.subTotal;

        if(this.discount > 0){
            this.subTotalAfterDiscount -= this.subTotal * (this.discount /100);
        }

        this.taxes = this.subTotalAfterDiscount * this.taxRate / 100;
        this.total = this.subTotalAfterDiscount + this.taxes;
    }

    getDiscountAsPercent(): number {
        return this.discount;
    }

    getDiscountAsAbsolut(): number {
        return this.subTotal * (this.discount /100);
    }

    getSubTotalAfterDiscount(): number {
        return this.subTotalAfterDiscount;
    }

    getSubTotal(): number {
        return this.subTotal;
    }

    getTotal(): number {
        return this.total;
    }

    getTaxes(): number {
        return this.taxes;
    }

    calculateInvoiceStatus(): InvoiceStatus {
        switch (this.status) {

            case InvoiceStatus.paid:
                return InvoiceStatus.paid;

            case InvoiceStatus.unpaid:
                if (new Date() > this.dueDate) {
                    return InvoiceStatus.overdue;
                } else {
                    return InvoiceStatus.unpaid;
                }

            default:
                return InvoiceStatus.overdue;
        }
    }

    getStatusLabel(): string {
        switch (this.calculateInvoiceStatus()) {
            case InvoiceStatus.overdue:
                return "überfällig";

            case InvoiceStatus.paid:
                return "bezahlt";

            default:
                return "offen";

        }
    }

    getClientNumber(): string {

        if(this.clientId.length > 5 && this.locationId.length > 3){
            return `${this.clientId.slice(0,4).toUpperCase()}-${this.clientId.slice(4,6).toUpperCase()}-${this.locationId.slice(0,4).toUpperCase()}`;
        }

        return "";
    }

}


export default Invoice;