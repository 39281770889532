import { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { Attachment } from '../../shared/src/models/email';
import PDocument from '../../shared/src/models/formEditor/pdocument';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import DateUtils from '../../shared/src/utils/dateUtils';

function not(a: readonly any[], b: readonly any[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly any[], b: readonly any[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}


interface Props {
    visible: boolean;
    selectedAttachments: Attachment[];
    allDocuments: PDocument[];
    onConfirm: (attachments: Attachment[]) => void;
    onAbort: () => void;
}

const EmailAttachmentsDialog: React.FC<Props> = ({ visible, selectedAttachments, allDocuments, onConfirm, onAbort }) => {

    const [checked, setChecked] = useState<Attachment[]>([]);
    const [left, setLeft] = useState<Attachment[]>([]);
    const [right, setRight] = useState<Attachment[]>([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    useEffect(() => {

        const docAttachments = allDocuments.map(d => {
            const a: Attachment = {
                id: d.id,
                documentId: d.id,
                name: d.name,
                createdOn: d.pdfCreatedAt ?? undefined
            };

            return a;
        });

        docAttachments.sort((a, b) => {
            return b.createdOn!.getTime() - a!.createdOn!.getTime();
        });


        setLeft(docAttachments);
        setRight(selectedAttachments);
    }, [visible]);


    function handleConfirm() {
        onConfirm([...right]);
    }

    const handleToggle = (value: Attachment) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    const customList = (items: Attachment[]) => (
        <Paper sx={{ height: "100%", maxHeight: "430px", width: "360px", overflow: 'auto' }}>
            <List dense component="div" role="list">
                {items.map((item: Attachment) => {
                    const labelId = `transfer-list-item-${item.id}-label`;

                    return (
                        <ListItemButton
                            key={item.id}
                            role="listitem"
                            onClick={handleToggle(item)}
                            style={{ height: "50px" }}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(item) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={item.id} primary={item.name} secondary={DateUtils.getDateTimeString(item.createdOn)} />
                        </ListItemButton>
                    );
                })}
            </List>
        </Paper>
    );



    return (
        <Dialog
            onClose={onAbort}
            aria-labelledby="customized-dialog-title"
            open={visible}
            fullWidth={true}
            maxWidth="md"
            disableEnforceFocus={true}
        >
            <DialogTitle id="customized-dialog-title">
                Anhänge auswählen
            </DialogTitle>

            <DialogContent dividers style={{ minHeight: '500px', minWidth: '800px' }}>

                <div className='kt-transfer-list'>
                    <div>
                        Dokumente
                        {customList(left)}
                    </div>
                    <div className='kt-transfer-buttons'>

                        <Button
                            sx={{ my: .5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleAllRight}
                            disabled={left.length === 0}
                            aria-label="move all right"
                        >
                            ≫
                        </Button>
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                        >
                            &gt;
                        </Button>
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                        >
                            &lt;
                        </Button>
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleAllLeft}
                            disabled={right.length === 0}
                            aria-label="move all left"
                        >
                            ≪
                        </Button>
                    </div>
                    <div>
                        Ihre Auswahl
                        {customList(right)}
                    </div>
                </div>

            </DialogContent>

            <DialogActions>
                <Button onClick={onAbort} color="secondary">
                    Abbrechen
                </Button>
                <Button onClick={handleConfirm} color="primary">
                    Übernehmen
                </Button>
            </DialogActions>
        </Dialog>

    );
}

export default EmailAttachmentsDialog;
