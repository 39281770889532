import firebase from 'firebase/compat/app';
import firebaseApp from "./../components/database";
import { getDownloadURL, getStorage, ref, uploadString } from "firebase/storage";

import UserRole from "../models/userRole";
import { urlToFile } from "../utils";

import User from "./../models/user";
import CalendarsService from "./calendarsService";

const db = firebaseApp.firestore();
const functions = firebase.app().functions('europe-west3');

const UsersService = {

    cache: {} as { [key: string]: User },

    async getUser(clientId: string, userId: string, ignoreCache: boolean = false): Promise<User | null> {

        if (!ignoreCache && UsersService.cache[userId]) {
            return UsersService.cache[userId];
        }

        const doc = await db.collection("clients").doc(clientId)
            .collection("users").doc(userId)
            .get();

        try {

            if (doc.exists) {

                const user = new User();
                user.fromObject(userId, doc.data());

                // update cache
                UsersService.cache[user.id] = user;

                return user;

            } else {
                console.log("getUser: No such document: " + userId);
                return null;
            }

        } catch (error) {
            console.log("Error getting user: ", error);
            return null;
        };

    },

    async getUserByCalendarId(clientId: string, locationId: string, calendarId: string): Promise<User | null> {

        try {
            const calendar = await CalendarsService.getCalendar(clientId, locationId, calendarId);
            if (calendar) {
                return UsersService.getUser(clientId, calendar.userId);
            }

        } catch (error) {
            console.log("error getting user by calendarId: ", error)
            return null;
        }

        return null;
    },

    async getUsers(clientId: string): Promise<User[]> {

        const users: User[] = [];

        const querySnapshot = await db.collection("clients").doc(clientId)
            .collection("users")
            .get();

        try {

            querySnapshot.forEach((doc) => {

                const user = new User();
                user.fromObject(doc.id, doc.data());

                if (!user.hidden) {
                    // update cache
                    UsersService.cache[user.id] = user;

                    users.push(user);

                }

            });

            users.sort((a, b) => a.cardinality > b.cardinality ? 1 : -1);

            return users;

        } catch (error) {
            console.log("Error getting users: ", error);
            return [];
        }

    },

    async getUsersByLocationId(clientId: string, locationId: string): Promise<User[]> {

        const users: User[] = [];

        const querySnapshot = await db.collection("clients")
            .doc(clientId)
            .collection("users")
            .where("locationId", "==", locationId)
            .get();

        try {

            querySnapshot.forEach((doc) => {

                const user = new User();
                user.fromObject(doc.id, doc.data());

                // update cache
                UsersService.cache[user.id] = user;

                users.push(user);

            });

            return users;

        } catch (error) {
            console.log("Error getting users: ", error);
            return [];
        }

    },

    async getUsersByRole(clientId: string, role: UserRole): Promise<User[]> {

        const users: User[] = [];

        const querySnapshot = await db.collection("clients")
            .doc(clientId)
            .collection("users")
            .where("role", "==", role)
            .get();

        try {

            querySnapshot.forEach((doc) => {

                const user = new User();
                user.fromObject(doc.id, doc.data());

                // update cache
                UsersService.cache[user.id] = user;

                users.push(user);

            });

            return users;

        } catch (error) {
            console.log("Error getting getUsersByRole: ", error);
            return [];
        }

    },

    // async getUsers(userIds: string[]): Promise<User[]>{

    //     const promises: Promise<User | null>[] = [];
    //     userIds.forEach(id => {
    //         promises.push(UsersService.getUser(id));
    //     });

    //     const tempResult = await Promise.all(promises);

    //     return tempResult.filter(item => item !== null) as User[];
    // },

    async getUserAvatarUrl(clientId: string, userId: string): Promise<string> {

        try {

            const storage = getStorage();

            const storageRef = ref(storage, `clients/${clientId}/users/${userId}.jpg`);

            const url = await getDownloadURL(storageRef);
            return url;

        } catch (error) {
            console.log("Error getting user avatar url: " + (error as any).message);
            return "";
        }
    },

    async getUserAvatarUrls(clientId: string, userIds: string[]): Promise<string[]> {
        const promises: Promise<string>[] = [];
        userIds.forEach(id => {
            promises.push(UsersService.getUserAvatarUrl(clientId, id));
        });

        return Promise.all(promises);
    },

    async updateUser(user: User, clientId: string, password?: string): Promise<string | null> {

        try {

            if (user.id) {

                user.avatarUrl = await this.getUserAvatarUrl(clientId, user.id);

                const userObj = user.toJSON();

                await db.collection("clients").doc(clientId)
                    .collection("users").doc(user.id).set(userObj, { merge: true });

                const setUserClaims = functions.httpsCallable('setUserClaims');
                await setUserClaims({ user: userObj });

                await this.updateAuthUser(user, password);

                // update cache
                UsersService.cache[user.id] = user;

                // now update all calendar images
                const calendars = await CalendarsService.getCalendarsByUserId(user.id, true, clientId, user.locationId);
                if (calendars) {
                    for (let i = 0; i < calendars.length; i++) {
                        const calendar = calendars[i];
                        calendar.avatarUrl = user.avatarUrl;

                        CalendarsService.updateCalendar(clientId, user.locationId, calendar);
                    }
                }

                return user.id;

            } else if (clientId && password) {
                // create user
                const userObj = user.toJSON();

                const createUser = functions.httpsCallable('createNewUser');
                const result = await createUser(
                    {
                        user: userObj,
                        password: password
                    }
                );


                return result.data.id;

            }

        } catch (error) {
            console.log("Error updating user: ", error);

            throw new Error(error as any);
        };

        return null;

    },

    async updateUserCalendarIds(calendarIds: string[], userId: string, clientId: string): Promise<void> {

        try {

            await db.collection("clients")
                .doc(clientId)
                .collection("users")
                .doc(userId)
                .update("calendarIds", calendarIds);

        } catch (error) {
            console.log("Error updating user calendar ids: ", error);
        }
    },

    async updateUserAvatar(avatarBase64: string, userId: string, clientId: string): Promise<string | null> {

        try {

            if (avatarBase64 && avatarBase64.indexOf("data:image") === 0) {

                console.log("uploading user avatar...");

                const storage = getStorage();

                const storageRef = ref(storage, `clients/${clientId}/users/${userId}.jpg`);

                await uploadString(storageRef, avatarBase64, 'data_url');

                const url = await getDownloadURL(storageRef);

                await db.collection("clients")
                    .doc(clientId)
                    .collection("users")
                    .doc(userId)
                    .update("avatarUrl", url);


            }

        } catch (error) {
            console.log("Error updating user avatar: ", error);
        }

        return null;
    },

    async updateAuthUser(user: User, newPassword?: string): Promise<void> {

        try {

            const updateAuthUser = functions.httpsCallable('updateAuthUser');
            await updateAuthUser({ user: user, newPassword: newPassword });

        } catch (error) {
            console.log("Error updateting auth user: ", error);

            throw new Error(error as any);
        };

    },

    isSuperUser(user: User | null): boolean {
        return user !== null && user.role && user.role.toLowerCase() === "superuser";
    },

    async deleteUser(userId: string, clientId: string): Promise<void> {

        try {
            console.log(`Deleting user: ${userId}`);

            await db.collection("clients")
                .doc(clientId)
                .collection("users")
                .doc(userId)
                .delete();


            // delete also auth user
            const deleteAuthUser = functions.httpsCallable('deleteAuthUser');
            await deleteAuthUser({ userId: userId });

        } catch (error) {
            console.log("Error deleting user: ", error);
        };
    },

    getAuthErrorMessage(errorCode: string): string {

        const code = errorCode.toLowerCase().replace("error: ", "");

        switch (code) {
            case "auth/email-already-exists":
                return "Diese Email Adresse ist bereits registriert! Bitte benutzen Sie eine andere Email Adresse.";

            case "auth/invalid-email":
                return "Bitte geben Sie eine gültige Email Adresse an.";

            case "auth/invalid-password":
                return "Ihr Passwort muss aus mindestens 6 Zeichen bestehen.";

            case "auth/invalid-phone-number":
                return "Bitte geben Sie eine gültige Telefonnummer an."

            default:
                return errorCode;
        }

    },

    async sendAuthEmail(clientId: string, userId: string, email: string): Promise<void> {
        const sendAuthEmailFunc = functions.httpsCallable("sendAuthEmail");

        await sendAuthEmailFunc(
            {
                clientId: clientId,
                uid: userId,
                email: email
            });

    },


    async createNewSupportUser(clientId: string, locationId: string): Promise<{ email: string, password: string }> {

        const createNewSupportUserFunc = functions.httpsCallable('createNewSupportUser');
        const result = await createNewSupportUserFunc(
            {
                clientId: clientId,
                locationId: locationId
            }
        );

        return result.data;
    }

}

export default UsersService;