// import it with: import Utils from "shared/lib/src/utils/utils";

import moment from 'moment';
import "moment/min/locales.min";

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export const debounce = (fn: Function, ms = 300) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (this: any, ...args: any[]) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
};



export function scrollToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}


export function getElementByClassNameSearchUp(element, className) {
    if (element && element.className && typeof className === "string") {
        if (element.classList.contains(className)) {
            return element;
        } else if (element.parentElement) {
            return getElementByClassNameSearchUp(element.parentElement, className);
        } else {
            return null;
        }
    } else {
        return null;
    }
}




export function upperCaseFirst(value) {
    if (typeof value === "string") {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    return value;
}

export function isMobileDevice() {
    var check = false;
    (function (a) { if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|iphone|ipad|ipod|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0, 4))) check = true; })(navigator.userAgent || navigator.vendor);
    return check;
}

export function trimPhoneNumberWithoutCountryCode(phoneNumber) {

    if (phoneNumber && typeof phoneNumber === "string") {

        // remove all white space
        phoneNumber = phoneNumber.replace(/\s+/g, '');

        // remove all - / . , # characters
        phoneNumber = phoneNumber.replace(/-+/g, '');
        phoneNumber = phoneNumber.replace(/\/+/g, '');
        phoneNumber = phoneNumber.replace(/\.+/g, '');
        phoneNumber = phoneNumber.replace(/,+/g, '');
        phoneNumber = phoneNumber.replace(/#+/g, '');

    }

    return phoneNumber;
}

// returns allways the phone number with german country code +49
export function trimPhoneNumber(phoneNumber) {

    if (phoneNumber && typeof phoneNumber === "string") {

        phoneNumber = trimPhoneNumberWithoutCountryCode(phoneNumber);

        if (phoneNumber.indexOf("0049") === 0) {
            phoneNumber = "+49" + phoneNumber.slice(4, phoneNumber.length);
        } else if (phoneNumber.slice(0, 1) === "0") {
            phoneNumber = "+49" + phoneNumber.slice(1, phoneNumber.length);
        }
    }

    return phoneNumber;
}

export function isGermanMobileNumber(phoneNumber) {
    const mobileCodes = ["0151", "0160", "0170", "0171", "0175", "0152", "0162", "0172", "0173", "0174", "0157", "0163", "0177", "0178", "0159", "0176", "0179"];

    if (phoneNumber && typeof phoneNumber === "string" && phoneNumber.length > 8) {
        phoneNumber = trimPhoneNumber(phoneNumber);

        phoneNumber = phoneNumber.replace("0049", "0");
        phoneNumber = phoneNumber.replace("+49", "0");

        for (let i = 0; i < mobileCodes.length; i++) {
            const code = mobileCodes[i];

            if (phoneNumber.indexOf(code) === 0) {
                return true;
            }
        }
    }

    return false;
}

export function isEmail(email) {

    if (email && typeof (email) === "string") {
        return /\S+@\S+\.\S+/.test(email);
    }

    return false;
}

// inserts text into a textfield or textarea at the cursor position
export function insertAtCursor(textField, textToInsert) {
    //IE support
    if ((document as any).selection) {
        textField.focus();
        const sel = (document as any).selection.createRange();
        sel.text = textToInsert;
    }
    //MOZILLA and others
    else if (textField.selectionStart || textField.selectionStart == '0') {
        const startPos = textField.selectionStart;
        const endPos = textField.selectionEnd;
        textField.value = textField.value.substring(0, startPos)
            + textToInsert
            + textField.value.substring(endPos, textField.value.length);
    } else {
        textField.value += textToInsert;
    }

    return textField.value;
}

export function convertHexToRGBA(hexCode, opacity) {
    let hex = hexCode.replace('#', '');

    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity})`;
};

export function logDbReadOperations(source, newValue) {
    (window as any).readCounter = (window as any).readCounter === undefined ? newValue : (window as any).readCounter += newValue;

    const color = newValue > 50 ? "orange" : "lightblue";
    console.log(`%cdb read operations for ${source}: ${newValue} and total: ${(window as any).readCounter}`, `color: ${color};`);
}

export async function urlToFile(url: string, filename: string, mimeType?: string) {
    return (fetch(url)
        .then(function (res) { return res.arrayBuffer(); })
        .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
}

export function getFileExtension(fileName: string) {

    if (typeof fileName !== "string") return "";

    const a = fileName.split(".");
    if (a.length === 1 || (a[0] === "" && a.length === 2)) {
        return "";
    }

    return a.pop()?.toLowerCase();
}


export function dataURLtoFile(dataUrl: string, fileName: string): File | null {

    const arr = dataUrl.split(',');

    if (arr.length > 0 && arr[0]) {
        const _mime = arr[0].match(/:(.*?);/);
        if (!_mime) return null;

        const mime = (_mime.length > 1 && _mime[1]) ? _mime[1] : null;
        if (!mime) return null;

        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], fileName, { type: mime });

    }

    return null;
}


export function getBase64Image(url, callback) {
    const img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.onload = function () {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx!.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL("image/png");
        console.log(dataURL);

        if (callback) {
            callback(dataURL);
        }
    };
    img.src = url;
}

// the video element must have the property: crossOrigin='anonymous'
export async function getBase64ImageForVideoElement(videoElement) {
    const video = videoElement;
    const canvas = document.createElement("canvas");
    canvas.style.display = "none";

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    // force the video player to show the first frame, other wise on iPhone you het a blank image
    video.currentTime = 0.2;

    video.setAttribute('crossOrigin', 'anonymous');

    if (canvas) {
        // Draw the thumbnailz
        const renderingContext = canvas.getContext("2d");
        if (renderingContext) {
            renderingContext.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
            const imageUrl = canvas.toDataURL("image/png");

            return imageUrl;
        }
    }

    return "";
}


export function isCrawlerUserAgent(): boolean {
    const botPattern = "(googlebot\/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)";
    const re = new RegExp(botPattern, 'i');
    const userAgent = navigator.userAgent;
    return re.test(userAgent);
}

export function updateRowPosition<T>(initialIndex, newIndex, rows: T[]) {
    const rowsClone = [...rows];
    const row = rowsClone.splice(initialIndex, 1)[0];
    rowsClone.splice(newIndex, 0, row);
    return rowsClone;
}

export function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

// we cannot directly download files with <a href="..." download> if file is not same origin
export function downloadFile(fileSrc, fileName) {

    // download image directly via url
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) => {
        var blob = xhr.response;
        //create a file from the returned blob
        var file = new File([blob], fileName, { type: blob.type });

        let element = document.createElement('a');
        //set the download attribute of the a tag to the name stored in the file
        element.setAttribute('download', file.name);

        //generate a temp url to host the image for download
        element.setAttribute('href', URL.createObjectURL(file));

        document.body.appendChild(element);
        element.click();

        document.body.removeChild(element);

    };
    xhr.open('GET', fileSrc);
    xhr.send();

}

export function printPDF(fileSrc, fileName) {

    // download pdf directly via url
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) => {
        var blob = xhr.response;
        //create a file from the returned blob
        var file = new File([blob], fileName, { type: blob.type });

        let element = document.getElementById("pdfDocument") as HTMLIFrameElement;
        
        if(element === null) element = document.createElement('iframe');

        element.setAttribute('id', 'pdfDocument');

        //generate a temp url to host the image for download
        element.setAttribute('src', URL.createObjectURL(file));

        element.setAttribute('width', '100px');

        document.body.appendChild(element);

        setTimeout(()=>{
            const iFrame = document.getElementById("pdfDocument") as HTMLIFrameElement;
            iFrame.contentWindow?.print();
        }, 500);
        

    };
    xhr.open('GET', fileSrc);
    xhr.send();

}
