
class Room {
    id: string = "";
    name: string = "";
    selected: boolean = true;
    cardinality: number = 0;
    __reorder__: string = "";

    clone(): Room {
        const s = new Room();

        s.id = this.id;
        s.name = this.name;
       
        s.selected = this.selected;
        s.cardinality = this.cardinality;
        s.__reorder__ = this.__reorder__;
        
        return s;
    }

    toJSON(): object {
        return {
            id: this.id,
            name: this.name,
            cardinality: this.cardinality
        };
    }

    fromObject(id: string|null, o:any){

        this.id = id !== null ? id : o.id;

        this.name = o.name;

        this.cardinality = o.cardinality ?? 0;
        this.__reorder__ = o.name;

    }

    static cloneList(rooms: Room[]): Room[] {
        const clonedRooms: Room[] = [];

        rooms.forEach(ro => {
            const clonedRo = ro.clone();
            clonedRooms.push(clonedRo);
        });

        return clonedRooms;
    }
};

export default Room;