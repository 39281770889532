import { useState, useEffect, useContext } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';

import LoadingPage from "../administration/loadingPage";

import SaveStatus from "../../../models/saveStatus";
import SaveButton from "../../saveButton";

import BackButton from '../../backButton';
import SmsEditor from '../../smsEditor';
import LocationsDropDown from '../../locationsDropDown';
import ClientLocation from '../../../models/clientLocation';
import ClientLocationsService from '../../../services/clientLocationsService';
import { GlobalContext } from '../../../GlobalContext';

function NotificationsPage() {


    const {isLoading} = useContext(GlobalContext);
    const {currentUser} = useContext(GlobalContext);
    const {currentLocation} = useContext(GlobalContext);

    const [customSenderName, setCustomSenderName] = useState("");
    const [useCustomSenderName, setUseCustomSenderName] = useState(false);
    const [confirmationSmsEnabled, setConfirmationSmsEnabled] = useState(true);
    const [reminderSmsEnabled, setReminderSmsEnabled] = useState(true);
    const [recallSmsEnabled, setRecallSmsEnabled] = useState(true);
    const [successorSmsEnabled, setSuccessorSmsEnabled] = useState(true);
    const [marketingSmsEnabled, setMarketingSmsEnabled] = useState(true);

    const [ratingSmsEnabled, setRatingSmsEnabled] = useState(true);
    const [ratingEmailEnabled, setRatingEmailEnabled] = useState(true);

    const [saveStatus, setSaveStatus] = useState(SaveStatus.none);

    const [confirmationSmsText, setConfirmationSmsText] = useState("");
    const [reminderSmsText, setReminderSmsText] = useState("");

    const [selectedLocation, setSelectedLocation] = useState(new ClientLocation());

    useEffect( () => {
        if(currentLocation) setSelectedLocation(currentLocation);
    }, [currentLocation]);



    useEffect( () => {

        if(selectedLocation.id !== ""){
            setCustomSenderName(selectedLocation.notificationsSettings.customSenderName);
            setUseCustomSenderName(selectedLocation.notificationsSettings.useCustomSenderName);
            setConfirmationSmsEnabled(selectedLocation.notificationsSettings.confirmationSmsEnabled);
            setReminderSmsEnabled(selectedLocation.notificationsSettings.reminderSmsEnabled);
            setSuccessorSmsEnabled(selectedLocation.notificationsSettings.successorSmsEnabled);
            setRecallSmsEnabled(selectedLocation.notificationsSettings.recallSmsEnabled);
            setMarketingSmsEnabled(selectedLocation.notificationsSettings.marketingSmsEnabled);

            setRatingSmsEnabled(selectedLocation.notificationsSettings.ratingSmsEnabled);
            setRatingEmailEnabled(selectedLocation.notificationsSettings.ratingEmailEnabled);

            setConfirmationSmsText(selectedLocation.notificationsSettings.confirmationSmsText);
            setReminderSmsText(selectedLocation.notificationsSettings.reminderSmsText);
        }

        // eslint-disable-next-line
    }, [selectedLocation]);




    async function handleSubmit(event) {
        event.preventDefault();

        setSaveStatus(SaveStatus.isSaving);

        try {
            const tempLocation = selectedLocation.clone();
            tempLocation.notificationsSettings.customSenderName = customSenderName;
            tempLocation.notificationsSettings.useCustomSenderName = useCustomSenderName;
            tempLocation.notificationsSettings.confirmationSmsEnabled = confirmationSmsEnabled;
            tempLocation.notificationsSettings.reminderSmsEnabled = reminderSmsEnabled;
            tempLocation.notificationsSettings.recallSmsEnabled = recallSmsEnabled;
            tempLocation.notificationsSettings.successorSmsEnabled = successorSmsEnabled;
            tempLocation.notificationsSettings.marketingSmsEnabled = marketingSmsEnabled;

            tempLocation.notificationsSettings.ratingSmsEnabled = ratingSmsEnabled;
            tempLocation.notificationsSettings.ratingEmailEnabled = ratingEmailEnabled;

            tempLocation.notificationsSettings.confirmationSmsText = confirmationSmsText;
            tempLocation.notificationsSettings.reminderSmsText = reminderSmsText;

            await ClientLocationsService.updateClientLocation(tempLocation);

            setSaveStatus(SaveStatus.saved);

        } catch(error){
            setSaveStatus(SaveStatus.none);
            alert("Die Änderungen konnte nicht gespeichert werden! " + (error as any).message);
        }

    }

    if(isLoading || !currentUser || selectedLocation.id === "") return <LoadingPage/>;

    return (
        <Box className="kt-page">
            <h2>SMS Einstellungen für {selectedLocation.name}</h2>


            <form onSubmit={handleSubmit} autoComplete="turnitoff">

                <div className="kt-form-section kt-bold-labels">

                    <Grid container>

                        <Grid item xs={12}>
                            <LocationsDropDown
                                clientId={selectedLocation.clientId}
                                selectedId={selectedLocation.id}
                                onChange={loc => setSelectedLocation(loc)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <p>Alles SMS Texte können nochmal in den einzelnen Besuchsgründen individuell überschrieben werden.</p>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                control={<Checkbox checked={useCustomSenderName} onChange={e => setUseCustomSenderName(e.target.checked)} name="useCustomSenderName" color="default"/>}
                                label="Eigener Absendername"
                            />
                            <p>Diesen Namen sehen Ihre Patienten als Absender der SMS.</p>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                name="customSenderName"
                                label="Absendername"
                                helperText="Keine Leer- oder Sonderzeichen"
                                value={customSenderName}
                                inputProps={{maxLength: 11, pattern: "[a-zA-Z0-9]+"}}
                                onChange={e => setCustomSenderName(e.target.value.replace(/\s/g,''))}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={<Checkbox checked={confirmationSmsEnabled} onChange={e => setConfirmationSmsEnabled(e.target.checked)} name="confirmationSmsEnabled" color="default"/>}
                                label="Sofort-Bestätigungs-SMS senden"
                            />
                            <p>Ihre Patienten erhalten noch in der Praxis eine Bestätigungs SMS für ihren nächsten Termin.</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {confirmationSmsEnabled &&
                                <SmsEditor templateMode={true} smsText={confirmationSmsText} clientId={currentUser.clientId} locationId={currentUser.locationId} onValueChange={setConfirmationSmsText} name="confirmationSmsText"/>
                            }
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={<Checkbox checked={reminderSmsEnabled} onChange={e => setReminderSmsEnabled(e.target.checked)} name="reminderSmsEnabled" color="default"/>}
                                label="Erinnerungs-SMS senden"
                            />
                            <p>24 Stunden vor dem Termin wird eine Erinnerungs SMS an den Patienten verschickt. Die Terminausfälle reduzieren sich dadurch im Durchschnitt um ca. 40%</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {reminderSmsEnabled &&
                                <SmsEditor templateMode={true} smsText={reminderSmsText} clientId={currentUser.clientId} locationId={currentUser.locationId} onValueChange={setReminderSmsText} name="reminderSmsText"/>
                            }
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={<Checkbox checked={recallSmsEnabled} onChange={e => setRecallSmsEnabled(e.target.checked)} name="recallSmsEnabled" color="default"/>}
                                label="Recall-SMS senden"
                            />
                            <p>Aktivieren Sie diese Option und wir erinnern Ihre Patienten daran einen Termin zur Vorsorge zu buchen.</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={<Checkbox checked={successorSmsEnabled} onChange={e => setSuccessorSmsEnabled(e.target.checked)} name="successorSmsEnabled" color="default"/>}
                                label="Folgetermin-SMS senden"
                            />
                            <p>Wir erinnern Ihre Patienten daran auch einen Folgetermin zu buchen, wenn das im Besuchgrund eingestellt wurde.</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={<Checkbox checked={ratingEmailEnabled} onChange={e => setRatingEmailEnabled(e.target.checked)} name="ratingEmailEnabled" color="default"/>}
                                label="Bewertungsanfrage-Email senden"
                            />
                            <p>Aktivieren Sie diese Option und wir bitten Ihre Patienten nach einer Behandlung um eine Bewertung per Email. Wir senden keine erneute Anfrage innerhalb von 90 Tagen.</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={<Checkbox checked={ratingSmsEnabled} onChange={e => setRatingSmsEnabled(e.target.checked)} name="ratingSmsEnabled" color="default"/>}
                                label="Bewertungsanfrage-SMS senden (nur wenn dies per Email nicht möglich ist)"
                            />
                            <p>Aktivieren Sie diese Option und wir bitten Ihre Patienten nach einer Behandlung um eine Bewertung per SMS. Wir senden keine erneute Anfrage innerhalb von 90 Tagen.</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        </Grid>


                    </Grid>

                </div>


                <div className="kt-page-footer">
                    <BackButton />

                    <SaveButton saveStatus={saveStatus} />
                </div>

            </form>

        </Box>
    );
}

export default NotificationsPage;