
export type FileImporterConfig = {
    tags?: string[],
    targetColumns: TargetColumn[]
}

export type TargetColumn = {
    field: string,
    headerName: string    // displayName
}

export type ImportedColumn = {
    field: string,              // column name found in the csv file
    sourceIndex: number,        // source column index found in the csv file
    targetField: string
}

// allow = All data from the file will be imported. Duplicate detection will not be performed.
// replace = All fields in duplicate entity will be updated using imported data.
// merge = If a duplicate is detected during import, only previously empty fields will be updated using imported data.
// skip = Duplicate records will be skipped
export type DuplicateHandling = "allow" | "replace" | "merge" | "skip";

export default class FileImporter {

    tags: string[] = []; // rows can be tagged
    targetColumns: TargetColumn[] = []; // expected (target) columns
    targetRows: any[] = [];

    importedColumns: ImportedColumn[] = [];
    importedRows: any[] = [];

    isFirstRowColumnHeader = false;
    rowCount = 0;       // all rows
    validRowCount = 0; // with no errors
    lastNameCount = 0;
    firstNameCount = 0;

    duplicateHandling = "merge";


    constructor(config: FileImporterConfig) {
        this.targetColumns = [...config.targetColumns];

        if (config.tags) this.tags = [...config.tags]
    }

    resetValues() {
        this.targetRows = [];
        this.rowCount = 0;
        this.validRowCount = 0;
        this.lastNameCount = 0;
        this.firstNameCount = 0;
        this.importedRows = [];
        this.importedColumns = [];
    }

    readCSVFile(file: File, encoding: "utf-8" | "windows-1252" = "utf-8", autoFixRowData = true, onFileLoad: () => void): void {

        this.resetValues();

        // FileReader Object
        var reader = new FileReader();

        // Read file as string
        reader.readAsText(file, encoding);

        const that = this;

        // Load event
        reader.onload = function (event) {

            if (!event.target || !event.target.result) return;

            // Read file data
            const csvData = event.target.result as string;

            // check if its really an UTF-8 file,
            // if not try to read it again as WINDOWS-1252
            if (csvData.indexOf("�") !== -1) {
                that.readCSVFile(file, "windows-1252", autoFixRowData, onFileLoad);
                return;
            }

            // split by line break to get rows Array
            const rowData = csvData.split('\n');

            const _columns: ImportedColumn[] = [];
            const _rows: any[] = [];

            if (rowData.length === 0) return;

            // try to auto detect column separator character
            const columnSeparator = rowData[0].split(';').length > rowData[0].split(',').length ? ';' : ',';

            // Loop on the row Array (change row=0 if you also want to read 1st row)
            for (let rowIndex = 0; rowIndex < rowData.length; rowIndex++) {

                // Split by columnSeparator to get column Array
                const rowColData = rowData[rowIndex].split(columnSeparator);

                if (rowIndex === 0) {
                    // read column headers
                    for (let colIndex = 0; colIndex < rowColData.length; colIndex++) {
                        const fieldValue = rowColData[colIndex].replace(/[\n\r]+/g, '').trim();
                        _columns.push({ field: fieldValue, sourceIndex: colIndex, targetField: "ignore" });
                    }
                    that.importedColumns = _columns;

                    that.computeMappings();

                }

                if (rowIndex > 0 || (!that.isFirstRowColumnHeader)) {


                    if (rowColData.length === that.importedColumns.length) {

                        // first create an empty row
                        const newRow = { id: rowIndex };

                        // loop on the row column Array
                        for (let colIndex = 0; colIndex < rowColData.length; colIndex++) {
                            const fieldValue = rowColData[colIndex].replace(/[\n\r"']+/g, '');

                            const col = that.importedColumns[colIndex];
                            newRow[col.field] = fieldValue;

                            newRow['hasErrors'] = false;
                            newRow['rowIndex'] = that.rowCount;

                        }

                        if (autoFixRowData) that.fixRowData(newRow);

                        if (!that.hasRowError(newRow)) that.validRowCount++;


                        that.rowCount++;

                        _rows.push(newRow);
                        that.importedRows.push(rowColData);
                    }

                }


                // just show the first rows and not all for preview
                //if (rowIndex > 99) break;
            }

            that.targetRows = _rows;

            onFileLoad();

        };
    }

    recomputeRows(inputRows: any[], autoFixRowData: boolean = true): any[] {
        const targetRows: any[] = [];

        this.validRowCount = 0;
        this.firstNameCount = 0;
        this.lastNameCount = 0;

        for (let rowIndex = 0; rowIndex < inputRows.length; rowIndex++) {
            const inputRow = inputRows[rowIndex];

            // first create an empty row and do the column mapping to analyse errors
            let newRow = { id: rowIndex };
            newRow['hasErrors'] = false;
            newRow['rowIndex'] = rowIndex;

            // loop on the row column Array
            for (let colIndex = 0; colIndex < this.importedColumns.length; colIndex++) {

                const importedCol = this.importedColumns[colIndex];
                const fieldValue = inputRow[importedCol.sourceIndex];

                newRow[importedCol.targetField] = fieldValue;
            }

            if (autoFixRowData) this.fixRowData(newRow);

            const hasRowError = this.hasRowError(newRow);
            if (!hasRowError) this.validRowCount++;


            // recreate the original imported row again to display in the UI
            // we do the mapping later while doing the real import
            newRow = { id: rowIndex };
            newRow['hasErrors'] = hasRowError;
            newRow['rowIndex'] = rowIndex;

            // loop on the row column Array
            for (let colIndex = 0; colIndex < this.importedColumns.length; colIndex++) {

                const importedCol = this.importedColumns[colIndex];
                const fieldValue = inputRow[importedCol.sourceIndex];

                newRow[importedCol.field] = fieldValue;
            }

            targetRows.push(newRow);

        }

        return targetRows;
    }

    hasRowError(row: any): boolean {

        const firstNameColumn = this.importedColumns.find(c => c.targetField === "firstName");
        const lastNameColumn = this.importedColumns.find(c => c.targetField === "lastName");

        row['hasErrors'] = false;

        if (firstNameColumn && row[firstNameColumn.field] && row[firstNameColumn.field] !== "") {
            this.firstNameCount++;
        } else if(row['firstName'] && row['firstName'] !== ""){
            this.firstNameCount++;
        } else {
            row['hasErrors'] = true;
        }

        if (lastNameColumn && row[lastNameColumn.field] && row[lastNameColumn.field] !== "") {
            this.lastNameCount++;
        } else if(row['lastName'] && row['lastName'] !== "") {
            this.lastNameCount++
        } else {
            row['hasErrors'] = true;
        }

        return row['hasErrors'];
    }


    computeMappings() {

        console.log("importedColumns: ");
        console.log(this.importedColumns);

        if (this.computeIsFirstRowColumnHeader()) {

            for (let i = 0; i < this.importedColumns.length; i++) {
                const importedColumn = this.importedColumns[i];

                const targetFieldIndex = this.targetColumns.findIndex(c => this.isSameFieldName(c.field, importedColumn.field));

                if (targetFieldIndex !== -1) {
                    importedColumn.targetField = this.targetColumns[targetFieldIndex].field;
                }
            }

        } else {

            console.log("no column header in csv-file detected");

            // we could not find column headers in the first row of the file
            // so we name the columns A, B, C ....
            for (let i = 0; i < this.importedColumns.length; i++) {
                const importedColumn = this.importedColumns[i];

                importedColumn.field = String.fromCharCode(65 + i); //(A-Z is 65-90)

                importedColumn.sourceIndex = i;

            }
        }

        console.log("targetColumns after mapping: ");
        console.log(this.targetColumns);

    }

    isSameFieldName(fieldName: string, importedFieldName: string): boolean {

        const importIdFieldAlternatives = ['id', 'importid'];
        const titleFieldAlternatives = ['title', 'titel'];
        const genderFieldAlternatives = ['gender', 'anrede'];
        const firstNameFieldAlternatives = ['firstname', 'vorname'];
        const lastNameFieldAlternatives = ['name', 'lastname', 'nachname'];
        const emailFieldAlternatives = ['email', 'mail', 'loginemail', 'loginmail'];
        const phoneNumberFieldAlternatives = ['phonenumber', 'phone', 'tel', 'telephone', 'telefon', 'telefonnr', 'telefonnummer'];
        const mobilePhoneNumberFieldAlternatives = ['mobile', 'mobilephone', 'mobilephonenumber', 'handy', 'handynr', 'handynummer'];
        const privateInsuranceFieldAlternatives = ['privateinsurance', 'private', 'privat', 'pversichert', 'privatversichert', 'privversichert', 'priv.versichert'];
        const streetFieldAlternatives = ['street', 'strasse', 'straße'];
        const postalCodeFieldAlternatives = ['postalcode', 'postal', 'plz', 'postleitzahl'];
        const cityFieldAlternatives = ['city', 'town', 'stadt', 'ort'];
        const birthDateFieldAlternatives = ['birthdate', 'geburtstag'];

        const _importedFieldName = importedFieldName.replaceAll('-', '').replaceAll('_', '').replaceAll('.', '').replaceAll(' ', '').toLowerCase();
        const _fieldName = fieldName.replaceAll('-', '').replaceAll('_', '').replaceAll('.', '').replaceAll(' ', '').toLowerCase();

        switch (_fieldName) {

            case 'importid':
                return importIdFieldAlternatives.includes(_importedFieldName);

            case 'title':
                return titleFieldAlternatives.includes(_importedFieldName);

            case 'gender':
                return genderFieldAlternatives.includes(_importedFieldName);

            case 'firstname':
                return firstNameFieldAlternatives.includes(_importedFieldName);

            case 'lastname':
                return lastNameFieldAlternatives.includes(_importedFieldName);

            case 'email':
                return emailFieldAlternatives.includes(_importedFieldName);

            case 'phonenumber':
                return phoneNumberFieldAlternatives.includes(_importedFieldName);

            case 'mobilephonenumber':
                return mobilePhoneNumberFieldAlternatives.includes(_importedFieldName);

            case 'privateinsurance':
                return privateInsuranceFieldAlternatives.includes(_importedFieldName);

            case 'street':
                return streetFieldAlternatives.includes(_importedFieldName);

            case 'postalcode':
                return postalCodeFieldAlternatives.includes(_importedFieldName);

            case 'city':
                return cityFieldAlternatives.includes(_importedFieldName);

            case 'birthdate':
                return birthDateFieldAlternatives.includes(_importedFieldName);

            default:
                return false;
        }

    }


    fixRowData(row: any) {

        // let firstLastName = "";

        // if(row['firstName'] !== undefined) firstLastName += row['firstName'].toLowerCase();
        // if(row['lastName'] !== undefined) firstLastName += " " + row['lastName'].toLowerCase();

        // if(row['gender'] !== undefined && row['gender'] === "") {

        //     if(firstLastName.indexOf('herr') !== -1 || firstLastName.indexOf('mister') !== -1) row['gender'] = 'm';
        //     if(firstLastName.indexOf('frau') !== -1 || firstLastName.indexOf('misses') !== -1 || firstLastName.indexOf('miss') !== -1) row['gender'] = 'f';
        // }

        // if(row['title'] !== undefined && row['title'] === "") {

        //     if(firstLastName.indexOf('dr. med. dent.') !== -1 || firstLastName.indexOf('dr. med.dent.') !== -1 || firstLastName.indexOf('dr.med. dent.') !== -1  || firstLastName.indexOf('dr.med.dent.') !== -1) {
        //         row['title'] = 'Dr. med. dent.';

        //     } else if(firstLastName.indexOf('dr.') !== -1) {
        //         row['title'] = 'Dr.';
        //     }
        // }

        // if(row['firstName'] !== undefined) {
        //     row['firstName'] = row['firstName'].replace(/Dr. med. dent./gi, "");
        //     row['firstName'] = row['firstName'].replace(/Dr.med. dent./gi, "");
        //     row['firstName'] = row['firstName'].replace(/Dr. med.dent./gi, "");
        //     row['firstName'] = row['firstName'].replace(/Dr.med.dent./gi, "");
        //     row['firstName'] = row['firstName'].replace(/Dr./gi, "");
        //     row['firstName'] = row['firstName'].replace(/Herr/gi, "");
        //     row['firstName'] = row['firstName'].replace(/Frau/gi, "");
        // }

        // if(row['lastName'] !== undefined) {
        //     row['lastName'] = row['lastName'].replace(/Dr. med. dent./gi, "");
        //     row['lastName'] = row['lastName'].replace(/Dr.med. dent./gi, "");
        //     row['lastName'] = row['lastName'].replace(/Dr. med.dent./gi, "");
        //     row['lastName'] = row['lastName'].replace(/Dr.med.dent./gi, "");
        //     row['lastName'] = row['lastName'].replace(/Dr./gi, "");
        //     row['lastName'] = row['lastName'].replace(/Herr/gi, "");
        //     row['lastName'] = row['lastName'].replace(/Frau/gi, "");
        // }

        // // if firstName contains full name then remove the last name part from it and move it to the field lastName
        // if(row['firstName'] !== undefined) {
        //     const names: string[] = row['firstName'].split(" ");
        //     if(names.length > 1 &&  (row['lastName'] === undefined || row['lastName'] === "")){
        //         row['lastName'] = names.pop();
        //         row['firstName'] = names.join(" ");
        //     }
        // }

        // if lastName contains full name then remove the last name part from firstName and move it to the field lastName
        // if(row['lastName'] !== undefined) {
        //     const names: string[] = row['lastName'].split(" ");
        //     if(names.length > 1 &&  (row['firstName'] === undefined || row['firstName'] === "")){
        //         row['lastName'] = names.pop();
        //         row['firstName'] = names.join(" ");
        //     }
        // }

        if (row['privateInsurance'] !== undefined) {
            row['privateInsurance'] = row['privateInsurance'].replace(/true/gi, true);
            row['privateInsurance'] = row['privateInsurance'].replace(/false/gi, false);
            row['privateInsurance'] = row['privateInsurance'].replace(/ja/gi, true);
            row['privateInsurance'] = row['privateInsurance'].replace(/nein/gi, false);
            row['privateInsurance'] = row['privateInsurance'].replace(/yes/gi, true);
            row['privateInsurance'] = row['privateInsurance'].replace(/no/gi, false);
            row['privateInsurance'] = row['privateInsurance'].replace(/private/gi, true);
            row['privateInsurance'] = row['privateInsurance'].replace(/privat/gi, true);
            row['privateInsurance'] = row['privateInsurance'].replace(/notprivate/gi, false);
            row['privateInsurance'] = row['privateInsurance'].replace(/nichtprivat/gi, false);
            row['privateInsurance'] = row['privateInsurance'].replace(/gesetzlich/gi, false);
        }
    }

    computeIsFirstRowColumnHeader(): boolean {

        for (let i = 0; i < this.importedColumns.length; i++) {
            const field = this.importedColumns[i].field.toLowerCase();

            if (field.indexOf("name") !== -1) {
                this.isFirstRowColumnHeader = true;
                return true;
            }

        }

        this.isFirstRowColumnHeader = false;
        return false;
    }

    getMissingFirstNameCount(): number {
        return this.rowCount - this.firstNameCount;
    }

    getMissingLastNameCount(): number {
        return this.rowCount - this.lastNameCount;
    }

    hasErrors(): boolean {

        if (this.getMissingFirstNameCount() > 0) return true;
        if (this.getMissingLastNameCount() > 0) return true;

        return false;
    }

    setNewColumnMapping(sourceColumnName: string, targetColumnName: string) {
        const importedColumn = this.importedColumns.find(c => c.field === sourceColumnName);
        let targetColumn = targetColumnName === "ignore" ? { field: "ignore", sourceIndex: -1 } : this.targetColumns.find(tc => tc.field === targetColumnName);


        if (targetColumn && importedColumn) {

            // first check if targetColumn is already used somewhere and set it to ignore
            // it can be only used once
            for (let i = 0; i < this.importedColumns.length; i++) {
                if (this.importedColumns[i].targetField === targetColumnName) {
                    this.importedColumns[i].targetField = "ignore";
                }

            }

            importedColumn.targetField = targetColumn.field;

            this.targetRows = this.recomputeRows(this.importedRows);
        }
    }

}