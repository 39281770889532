import { useEffect } from 'react';

import firebaseApp from "./../database";


function LogoutPage() {

    useEffect(() => {
        logoutAndShowLoginPage();
    }, [])

    async function logoutAndShowLoginPage() {
        await firebaseApp.auth().signOut();

        const targetPath = getTargetPath();

        window.location.href = "/login" + (targetPath ? "?target=" + targetPath : "");
    }

    function getTargetPath() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get('target');
    }

    return (
        <div className="kt-login-page">
            <form>
                <h1>Pickadoc</h1>

                <img src="/images/logo.svg" alt="logo" className='kt-logo' />
            </form>
        </div>
    );

}

export default LogoutPage;