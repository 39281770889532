import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/table';
import "tinymce/langs/de.js";
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import { Editor } from "@tinymce/tinymce-react";

import React, { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';


interface Props {
    visible: boolean;
    richText: string;
    onSave: (richText: string) => void;
    onClose: () => void;
}

const RichTextEditDlg:React.FC<Props> = ({ visible, richText, onSave, onClose}) => {

    const [text, setText] = useState("");

    useEffect(() => {
        setText(richText);
    }, [richText]);

    function handleSave() {
        onSave(text);
    }

    function handleClose(){
        onClose();
    }

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={visible}
            maxWidth="lg"
            fullWidth={true}
            disableEnforceFocus={true}
            className="kt-rich-text-edit-dlg"
        >
            <DialogTitle id="customized-dialog-title">
                Text bearbeiten
            </DialogTitle>

            <DialogContent dividers style={{minHeight:'600px'}}>

                <Grid container>
                    <Grid item xs={12}>

                        <Editor
                            value={text}
                            onEditorChange={ content => setText(content)}
                            init={{
                                height:400,
                                menubar:false,
                                default_link_target: "_blank",
                                plugins: [
                                    'link image imagetools',
                                    'table paste'
                                    ],
                                toolbar:
                                    'undo redo | formatselect | bold italic forecolor backcolor | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | image | removeformat',
                                language: "de"
                            }}
                        />
                    </Grid>

                </Grid>


            </DialogContent>

            <DialogActions>

                <Button onClick={handleClose} color="secondary">
                    Abbrechen
                </Button>
                <Button onClick={handleSave} color="primary" >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>

    );
}

export default RichTextEditDlg;
