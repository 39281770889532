import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/table';
import "tinymce/langs/de.js";
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import { Editor } from "@tinymce/tinymce-react";

import { useEffect, useContext, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import { GlobalContext } from '../../GlobalContext';
import Chip from '@mui/material/Chip';

import { Email, Attachment } from '../../../src/shared/src/models/email';
import EmailService from '../../services/emailService';
import Utils from '../../shared/src/utils/utils';
import { Tooltip } from '@mui/material';
import EmailAttachmentsDialog from './emailAttachmentsDialog';
import PDocument from '../../shared/src/models/formEditor/pdocument';
import PDFService from '../../services/pdfService';
import { Toast } from '../toaster';
import LoggingService from '../../services/loggingService';
import { LogItemType } from '../../shared/src/models/logItem';

interface Props {
    visible: boolean;
    dialogTitle: string;
    email: Email;
    allDocuments: PDocument[];
    onSend: (email: Email) => void;
    onClose: () => void;
}

const EmailDialog: React.FC<Props> = ({ visible, dialogTitle, email, allDocuments, onSend, onClose }) => {

    const { currentLocation, setNewToast } = useContext(GlobalContext);
    const { currentUser } = useContext(GlobalContext);

    const [emailTo, setEmailTo] = useState("");
    const [subject, setSubject] = useState("");
    const [htmlBody, setHtmlBody] = useState("");
    const [emailAttachments, setEmailAttachments] = useState<Attachment[]>([]);

    const [isEmailAttachmentsDialogVisible, setIsEmailAttachmentsDialogVisible] = useState(false);

    useEffect(() => {

        if (email.toAddresses.length > 0) {
            setEmailTo(email.toAddresses.join(", "));
        }

        if (email.attachments) {
            setEmailAttachments(email.attachments);
        }

        setSubject(email.subject);
        setHtmlBody(email.htmlBody);


    }, [email])

    function handleDeleteAttachment(event: any, attachment: Attachment) {
        event.preventDefault();

        const _attachments = Utils.removeItemFromArray<Attachment>(attachment, emailAttachments);

        setEmailAttachments(_attachments);
    }

    async function handleSendEmail() {

        const _email = email.clone();
        _email.toAddresses = emailTo.split(",");
        _email.subject = subject;
        _email.htmlBody = htmlBody;
        _email.textBody = "";
        _email.attachments = [...emailAttachments];

        for (let i = 0; i < _email.attachments.length; i++) {
            const att = emailAttachments[i];
            if(att.documentId && !att.url){
                att.url = await PDFService.getPatientPDF(email.clientId, email.locationId, email.patientId, att.documentId);
                if(att.name.indexOf(".pdf") === -1) att.name = att.name + ".pdf";
            }
        }

        EmailService.sendEmail(_email);

        setNewToast(new Toast("success", `Dokumenten Email wird versendet`));
        
        const message = `Email an ${email.toAddresses.join(", ")} gesendet.`;
        LoggingService.log(LogItemType.emailHasBeenSent, "", _email.attachments.join(","), email.patientId, "", currentUser.id, message, email.clientId, email.locationId);

        onSend(_email);
    }

    async function handleConfirmAttachments(newSelection: Attachment[]){

        for (let i = 0; i < newSelection.length; i++) {
            const att = newSelection[i];
            if(att.documentId && !att.url){
                att.url = await PDFService.getPatientPDF(email.clientId, email.locationId, email.patientId, att.documentId);
                if(att.name.indexOf(".pdf") === -1) att.name = att.name + ".pdf";
            }
        }

        setEmailAttachments(newSelection);
        setIsEmailAttachmentsDialogVisible(false);
    }

    function handleClose() {
        onClose();
    }


    return (<>
        <EmailAttachmentsDialog
            visible={isEmailAttachmentsDialogVisible}
            selectedAttachments={emailAttachments}
            allDocuments={allDocuments}
            onConfirm={handleConfirmAttachments}
            onAbort={() => setIsEmailAttachmentsDialogVisible(false)}
        />

        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={visible}
            maxWidth="lg"
            fullWidth={true}
            disableEnforceFocus={true}
        >
            <DialogTitle id="customized-dialog-title">
                {dialogTitle}
            </DialogTitle>

            <DialogContent dividers style={{ minHeight: '500px' }}>

                <Grid container>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Von:"
                            value={`${currentLocation.name} <${currentLocation.email}>`}
                            disabled
                        />
                        <TextField
                            fullWidth
                            label="An:"
                            value={emailTo}
                            onChange={e => setEmailTo(e.currentTarget.value)}
                        />
                        <TextField
                            fullWidth
                            label="Betreff:"
                            value={subject}
                            onChange={e => setSubject(e.currentTarget.value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Editor
                            value={htmlBody}
                            onEditorChange={content => setHtmlBody(content)}
                            init={{
                                height: 300,
                                menubar: false,
                                default_link_target: "_blank",
                                plugins: [
                                    'link image imagetools',
                                    'table paste'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic forecolor backcolor | \
                                        alignleft aligncenter alignright alignjustify | \
                                        bullist numlist outdent indent | image | removeformat',
                                language: "de",
                                content_style: ".mce-content-body {font-size:15px;font-family:Arial,sans-serif;}"
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Tooltip title="Anhang hinzufügen">
                            <Button onClick={()=>setIsEmailAttachmentsDialogVisible(true)} variant='outlined' style={{ marginRight: "10px", width: "10px" }}><i className='fa fa-paperclip'></i></Button>
                        </Tooltip>

                        {emailAttachments.map(attachment => <Chip
                            label={attachment.name}
                            component="a"
                            href={attachment.url}
                            target='_blank'
                            onDelete={(e) => handleDeleteAttachment(e, attachment)}
                        />)
                        }
                    </Grid>
                </Grid>

            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Abbrechen
                </Button>
                <Button onClick={handleSendEmail} color="primary">
                    Senden
                </Button>
            </DialogActions>
        </Dialog>
    </>
    );
}

export default EmailDialog;
