import firebase from 'firebase/compat/app';
import Email from '../../src/shared/src/models/email';

const functions = firebase.app().functions('europe-west3');

const EmailService = {


    async sendEmail(email: Email): Promise<void> {

        try {

            const sendEmail = functions.httpsCallable('sendEmail');
            await sendEmail(
                {
                    email: email.toJSON()
                }
            );


        } catch (error: any) {
            console.log("Error sending Email: ", error);

            throw new Error(error);
        };

    }

}

export default EmailService;