import { Link } from "react-router-dom";

import { useState, useEffect, useContext } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Campaign } from '../../../shared/src/models/campaign/campaign';
import CampaignsService from '../../../services/campaignsService';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Divider, Grid } from "@mui/material";
import CampaignDetailsCard from "./campaignDetailsCard";
import { GlobalContext } from "../../../GlobalContext";


function RecallerPage(props) {

    const {currentUser} = useContext(GlobalContext);
    const [isLoading, setIsLoading] = useState(false);
    const [campaigns, setCampaigns] = useState<Campaign[]>([]);


    useEffect(() => {
        loadCampaigns();
    }, []);


    async function loadCampaigns() {

        if (currentUser) {

            setIsLoading(true);

            const _campaigns = await CampaignsService.getCampaigns(currentUser?.clientId, currentUser?.locationId);
            if (_campaigns) {
                setCampaigns(_campaigns);
            }

            setIsLoading(false);
        }
    }

    async function onDeleteCampaignClick(c: Campaign) {
        if (currentUser && window.confirm(`Wollen Sie die Kampagne "${c.name}" wirklich löschen?`)) {
            await CampaignsService.deleteCampaign(c.id, currentUser.clientId, currentUser.locationId);
            loadCampaigns();
        }
    }


    return (
        <>
            <div className="kt-recaller-page">

                <Box className="kt-page">

                    <div>
                        <img className="kt-recall-logo" src="../images/recaller-gear.svg" alt="Recaller" />
                    </div>

                    <Button
                        component={Link}
                        to="/createcampaign"
                        variant="outlined"
                        startIcon={<AddCircleOutlineIcon />}
                    >
                        Neue Kampagne anlegen
                    </Button>

                    <Box mt={2} mb={2}>
                        <Divider />
                    </Box>

                    <Grid container className="kt-cards-container">
                        {campaigns.map(campaign =>
                            <Grid key={campaign.id} item sx={{ minWidth: 400 }} m={1}>
                                <CampaignDetailsCard campaign={campaign} showActionButtons={true} onDeleteCampaignClick={onDeleteCampaignClick} />
                            </Grid>
                        )}
                    </Grid>

                </Box>

            </div>
        </>
    )
}


export default RecallerPage;

