import React, { useState, useRef, useEffect, useContext } from 'react';
import LogItem from '../shared/src/models/logItem';
import DateUtils from '../shared/src/utils/dateUtils';
import { GlobalContext } from '../GlobalContext';
import LoggingService from '../services/loggingService';
import AppointmentsService from '../services/appointmentsService';
import PatientsService from '../services/patientsService';



const LogItemList: React.FC = () => {

    const { currentUser, currentClient, currentLocation } = useContext(GlobalContext);
    const { setIsAppointmentDialogVisible, setSelectedAppointment } = useContext(GlobalContext);
    const { setIsPatientDialogVisible, setSelectedPatient } = useContext(GlobalContext);

    const unsubscribeLogItemsRef = useRef<any>();
    const [logItems, setLogItems] = useState<LogItem[]>([]);

    useEffect(() => {

        if (currentUser && currentUser.locationId) {

            if (unsubscribeLogItemsRef.current === undefined) {
                unsubscribeLogItemsRef.current = LoggingService.startListenFor24hLogItems(currentUser.clientId, currentUser.locationId, (result) => setLogItems(result));
            }

            return () => {
                if (typeof unsubscribeLogItemsRef.current === "function" && unsubscribeLogItemsRef.current !== undefined) {
                    console.log("unsubscribe logItems");
                    unsubscribeLogItemsRef.current();

                    unsubscribeLogItemsRef.current = undefined;
                }
            }
        }

    }, []);

    async function openLogItem(logItem: LogItem) {
        if (logItem.appointmentId) {

            const _appointment = await AppointmentsService.getAppointment(logItem.appointmentId, currentClient.id, currentLocation.id);

            if(_appointment){
                setSelectedAppointment(_appointment);
                setIsAppointmentDialogVisible(true);
            } else {
                alert("Dieser Termin wurde gelöscht.");
            }
            
        } else if (logItem.patientId) {
            const _patient = await PatientsService.getPatient(logItem.patientId, currentClient.id, currentLocation.id);
            if(_patient){                
                setSelectedPatient(_patient);
                setIsPatientDialogVisible(true);
            }            
        }
    }

    return (
        <>
            <div className="kt-logItem-list" id="kt-logItem-list">

                {logItems.map(logItem =>
                    <div
                        key={logItem.id}
                        className={`kt-logItem kt-logItem-bot ${(logItem.appointmentId || logItem.patientId) && "kt-clickable"}`}
                        style={{ borderLeftColor: logItem.getColor() }}
                        onClick={() => openLogItem(logItem)}
                    >
                        <div className='kt-left-column'>
                            <div className="kt-time">{DateUtils.getTimeString(logItem.createdAt)}</div>
                            <div style={{ color: logItem.getColor() }}><i className={logItem.getCssIconString()}></i></div>
                        </div>
                        <div className="kt-message">
                            {logItem.message.indexOf("Notfall:") !== -1 && <i className='fa fa-exclamation-triangle kt-red-color' style={{ marginRight: "3px" }}></i>}
                            {logItem.toStringShort()}
                        </div>

                        {/* <button onClick={()=> LoggingService.deleteLogItem(logItem.id, currentClient.id, currentLocation.id)}><i className="fa fa-trash"></i></button> */}
                    </div>
                )}
            </div>

        </>

    );
}

export default LogItemList;