import React, { useContext } from 'react';
import Avatar from '../avatar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { GlobalContext } from '../../GlobalContext';
import Calendar from '../../models/calendar';

interface Props {
    visible: boolean;
    title: string;
    message: string | JSX.Element;
    onCalendarSelect: (selectedCalendar: Calendar) => void;
    onClose: () => void;
}

const CalendarSelectorDlg: React.FC<Props> = ({ visible, title, message, onCalendarSelect, onClose }) => {

    const { calendars } = useContext(GlobalContext);
  
    function handleClose() {
        onClose();
    }

   
    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={visible}
            disableEnforceFocus={true}
        >
            <DialogTitle id="customized-dialog-title">
                {title}
            </DialogTitle>

            <DialogContent dividers style={{ minHeight: '500px' }}>

                <Grid container style={{margin: "0"}}>
                    <Grid item xs={12} mb={2}>
                        {message}
                    </Grid>
                    {(calendars as Calendar[]).map((calendar) =>
                        <div 
                            key={calendar.id} 
                            className='kt-primary-hover'
                            style={{ 
                                width: "100%", 
                                display: "flex", 
                                alignItems: "center", 
                                padding: "10px 20px"                                
                            }}
                            onClick={()=> onCalendarSelect(calendar)}
                        >
                            <Grid item xs={1}>
                                <Avatar src={calendar.avatarUrl} width={32} />
                            </Grid>
                            <Grid item xs={3}>
                                {calendar.name}
                            </Grid>
                        </div>
                    )}
                    <Grid item xs={12} sm={6}>
                    </Grid>

                </Grid>


            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Abbrechen
                </Button>                
            </DialogActions>
        </Dialog >

    );
}

export default CalendarSelectorDlg;
