import OpeningHours from "./openingHours";
import Gender from "../../src/shared/src/models/gender";
import UserRole, {getUserRoleCardinality, getUserRoleString} from "./userRole";
import Utils from "../../src/shared/src/utils/utils";

class User {
    id: string;
    clientId: string;
    locationId: string; // the default location id, later we can allow users to have multiple locations
    locationName: string;
    role: UserRole;
    email: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    title:string;
    abbreviation:string;
    languages: string[];
    isEnabled: boolean;
    profile: string;
    openingHours: OpeningHours;
    calendarIds: string[];
    allowOnlineAppointments: boolean;
    isAdmin: boolean;

    avatarUrl: string;

    signature: string; // base64 png image

    roomIds: string[];

    showTour: boolean;

    cardinality: number;
    __reorder__: string;

    hidden: boolean; // used for filtering in the user list, pickadoc support users are hidden

    constructor () {
        this.id = "";
        this.clientId = "";
        this.locationId = "";
        this.locationName = "";
        this.role = UserRole.patient;
        this.email = "";
        this.firstName = "";
        this.lastName = "";
        this.gender = Gender.female;
        this.title = "";
        this.abbreviation = "";
        this.languages = [];
        this.isEnabled = true;
        this.profile = "";
        this.openingHours = new OpeningHours();
        this.calendarIds = [];
        this.allowOnlineAppointments = false;
        this.isAdmin = false;

        this.avatarUrl = "";

        this.signature = "";

        this.roomIds = [];

        this.showTour = true;

        this.cardinality = 0;
        this.__reorder__ = "";

        this.hidden = false;
    }

    clone(): User {
        const u = new User();

        u.id = this.id;
        u.clientId = this.clientId;
        u.locationId = this.locationId;
        u.locationName = this.locationName;
        u.role = this.role;
        u.email = this.email;
        u.firstName = this.firstName;
        u.lastName = this.lastName;
        u.gender = this.gender;
        u.title = this.title;
        u.abbreviation = this.abbreviation;
        u.languages = [...this.languages];
        u.isEnabled = this.isEnabled === true;
        u.profile = this.profile;
        u.isAdmin = this.isAdmin === true;

        if(this.openingHours){
            u.openingHours = this.openingHours.clone();
        } else {
            u.openingHours = new OpeningHours();
        }

        u.calendarIds = [...this.calendarIds];
        u.allowOnlineAppointments = this.allowOnlineAppointments === true;

        u.avatarUrl = this.avatarUrl;

        u.signature = this.signature;

        u.roomIds = [...this.roomIds];

        u.showTour = this.showTour;

        u.cardinality = this.cardinality;
        u.__reorder__ = this.__reorder__;

        u.hidden = this.hidden === true;

        return u;
    }

    toJSON(): object {
        return {
            id: this.id,
            clientId: this.clientId,
            locationId: this.locationId,
            locationName: this.locationName,
            role: this.role ? this.role : UserRole.doctor,
            email: this.email ? this.email : "",
            firstName: this.firstName ? this.firstName : "",
            lastName: this.lastName ? this.lastName : "",
            gender: this.gender ? this.gender : Gender.female,
            title: this.title ? this.title : "",
            abbreviation: this.abbreviation ? this.abbreviation : "",
            languages: this.languages ? this.languages : [],
            isEnabled: this.isEnabled === true,
            profile: this.profile ? this.profile : "",
            openingHours: this.openingHours.toJSON(),
            calendarIds: this.calendarIds ? this.calendarIds : [],
            allowOnlineAppointments: this.allowOnlineAppointments === true,
            isAdmin: this.isAdmin === true,
            avatarUrl: this.avatarUrl ? this.avatarUrl : "",
            signature: this.signature ?? "",
            roomIds: this.roomIds ? this.roomIds : [],
            showTour: this.showTour === true,
            cardinality: this.cardinality,
            hidden: this.hidden === true
        };
    }

    fromObject(id:string|null, o:any) {

        this.id = id !== null ? id : o.id;
        this.clientId = o.clientId;
        this.locationId = o.locationId;
        this.locationName = o.locationName;
        this.role = o.role;
        this.email = o.email;
        this.firstName = o.firstName;
        this.lastName = o.lastName;
        this.gender = o.gender;
        this.title = o.title;
        this.abbreviation = o.abbreviation;
        this.languages = o.languages ? o.languages : [];
        this.isEnabled = o.isEnabled === true;
        this.profile = o.profile;

        if(o.openingHours){
            this.openingHours.fromObject(o.openingHours);
        } else {
            this.openingHours = new OpeningHours();
        }

        this.calendarIds = o.calendarIds;
        this.allowOnlineAppointments = o.allowOnlineAppointments === true;

        this.isAdmin = o.isAdmin === true;

        this.avatarUrl = o.avatarUrl;

        this.signature = o.signature ?? "";

        this.roomIds = o.roomIds ? o.roomIds : [];

        this.showTour = o.showTour === undefined ? true : o.showTour === true;

        this.cardinality = o.cardinality ?? 0;
        this.__reorder__ = this.getFullName();

        this.hidden = o.hidden === true;
    }

    getFullName(): string {
        let result = `${this.firstName} ${this.lastName}`;

        if(this.title){
            result = `${this.title} ${result}`;
        }

        return result;
    }

    getFullNameWithGender(): string {
        return `${Utils.getGenderString(this.gender)} ${this.getFullName()}`;
    }
};

export interface UserGroup {
    cardinality: number,
    name: string,
    users: User[]
}

export function getGroupedSortedList(list: User[], onlyWithAbbreviation: boolean): UserGroup[] {
    const result : UserGroup[] = [];

    const doctors : UserGroup = {cardinality:getUserRoleCardinality(UserRole.doctor), name:getUserRoleString(UserRole.doctor), users:[]};
    const assistants : UserGroup = {cardinality:getUserRoleCardinality(UserRole.assistant), name:getUserRoleString(UserRole.assistant), users:[]};
    const technicians : UserGroup = {cardinality:getUserRoleCardinality(UserRole.technician), name:getUserRoleString(UserRole.technician), users:[]};
    const others : UserGroup = {cardinality:10000, name: "Andere", users:[]};

    list.forEach(user => {

        if(!onlyWithAbbreviation || (onlyWithAbbreviation && user.abbreviation !== "")){
            switch (user.role) {
                case UserRole.doctor:
                    doctors.users.push(user);
                    break;

                case UserRole.assistant:
                    assistants.users.push(user);
                    break;

                case UserRole.technician:
                    technicians.users.push(user);
                    break;

                default:
                    others.users.push(user);
                    break;
            }
        }


    });

    if(doctors.users.length > 0) result.push(doctors);
    if(assistants.users.length > 0) result.push(assistants);
    if(technicians.users.length > 0) result.push(technicians);
    if(others.users.length > 0) result.push(others);

    return result;
}

export default User;

