import React, { useEffect, useState } from "react";

interface Props {
    listItems: string[];
    onListItemsChange: (listItems: string[]) => void;
}

const SimpleList:React.FC<Props> = ({listItems, onListItemsChange}) => {

    const [newItem, setNewItem] = useState("");
    const [internalListItems, setInternalListItems] = useState(listItems);

    useEffect(() => {
        if(listItems) {
            setInternalListItems([...listItems]);
        } else {
            setInternalListItems([]);
        }
    }, [listItems]);

    const handleAddItemClick = (e: React.FormEvent) => {
        e.preventDefault();

        if(newItem.trim() !== "" && internalListItems.indexOf(newItem) === -1){
            internalListItems.push(newItem);
            onListItemsChange(internalListItems);
        }

        setNewItem("");
    }

    const handleDeleteItemClick = (index:number) => {
        if(internalListItems){
            internalListItems.splice(index, 1);
            setInternalListItems([...internalListItems]);
            onListItemsChange([...internalListItems]);
        }
    }


    return (
        <div className="kt-simple-list">
            {<div className="kt-items-wrapper">
                {internalListItems.map((item, index) => <div key={index}><span>{item}</span><i className="fa fa-trash kt-red-hover kt-delete-item" onClick={()=>(handleDeleteItemClick(index))}></i></div>)}
            </div>}

            <div className="kt-input-column">
                <input type="text" value={newItem} onChange={e => setNewItem(e.target.value)}/>
                <button type="button" className="kt-btn kt-btn-small" onClick={handleAddItemClick}><i className="fal fa-plus-circle"></i> Hinzufügen</button>
            </div>

        </div>
    );
}

export default SimpleList;