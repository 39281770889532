import CalendarItem, { CalendarItemType } from "./calendarItem";
import moment from "moment";
import MessageStatus from "./messageStatus";
import DateUtils from "../../src/shared/src/utils/dateUtils";

export function getStatusColor(status: MessageStatus): string {
    switch (status) {
        case MessageStatus.notDelivered:
        case MessageStatus.notDeliveredWithinTwoDays:
        case MessageStatus.unknown:
            return "red";

        case MessageStatus.notDeliveredYet:
        case MessageStatus.successfullySendToGateway:
            return "orange";

        case MessageStatus.successfullyDispatched:
            return "green";

        default:
            return "red";
    }
}

class Reminder implements CalendarItem{
    id: string;
    clientId: string;
    clientName: string;
    locationId: string;
    appointmentId: string;

    appointmentStart: Date;

    toPhoneNumber: string;
    sendAt: Date;
    sendBy: "" | "email" | "sms" = "";
    calendarId: string;
    messageStatus: MessageStatus;
    price: number; // in euros (netto)

    visitMotiveId: string;
    visitMotiveName: string;

    emailHtml: string;
    emailText: string;
    emailSubject: string;
    from: string;
    to: string;
    smsText: string;

    patient: {
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        mobilePhoneNumber: string
    }

    // now CalendarItem interface properties
    start: Date = new Date();
    end: Date = new Date();
    title: string = "";
    resourceId: string = "";
    calendarItemType: CalendarItemType = "reminder";
    color: string = "";
    comments: string = "";

    constructor () {
        this.id = "";
        this.clientId = "";
        this.clientName = "";
        this.locationId = "";
        this.appointmentId = "";

        this.appointmentStart = new Date();

        this.toPhoneNumber = "";
        this.sendAt = new Date();
        this.sendBy = "";
        this.calendarId = "";
        this.messageStatus = MessageStatus.pending;
        this.price = 0;

        this.visitMotiveId = "";
        this.visitMotiveName = "";

        this.emailHtml = "";
        this.emailText = "";
        this.emailSubject = "";
        this.from = "";
        this.to = "";
        this.smsText = "";

        this.patient = {
            id: "",
            firstName: "",
            lastName: "",
            email: "",
            mobilePhoneNumber: ""
        }
    }

    clone(): Reminder {
        const r = new Reminder();

        r.id = this.id;
        r.clientId = this.clientId;
        r.clientName = this.clientName;
        r.locationId = this.locationId;
        r.appointmentId = this.appointmentId;

        r.appointmentStart = new Date(this.appointmentStart);

        r.toPhoneNumber = this.toPhoneNumber;
        r.sendAt = new Date(this.sendAt);
        r.sendBy = this.sendBy;
        r.calendarId = this.calendarId;
        r.messageStatus = this.messageStatus;
        r.price = this.price;

        r.visitMotiveId = this.visitMotiveId;
        r.visitMotiveName = this.visitMotiveName;

        r.emailHtml = this.emailHtml;
        r.emailText = this.emailText;
        r.emailSubject = this.emailSubject;
        r.from = this.from;
        r.to = this.to;
        r.smsText = this.smsText;

        r.patient = {
            id: this.patient.id,
            firstName: this.patient.firstName,
            lastName: this.patient.lastName,
            email: this.patient.email,
            mobilePhoneNumber: this.patient.mobilePhoneNumber
        }

        r.start = new Date(this.start);
        r.end = new Date(this.end);
        r.title = this.title;
        r.resourceId = this.resourceId;
        r.color = this.color;
        r.comments = this.comments;

        return r;
    }

    toJSON(): object {
        return {
            id: this.id,
            clientId: this.clientId,
            clientName: this.clientName,
            locationId: this.locationId,
            appointmentId: this.appointmentId,

            appointmentStart: this.appointmentStart,

            toPhoneNumber: this.toPhoneNumber ?? "",
            sendAt: this.sendAt,
            sendBy: this.sendBy,
            calendarId: this.calendarId,
            status: this.messageStatus,
            price: this.price ?? 0,

            emailHtml: this.emailHtml ?? "",
            emailText: this.emailText ?? "",
            emailSubject: this.emailSubject ?? "",
            from: this.from,
            to: this.to,
            smsText: this.smsText ?? "",

            patient: {
                id: this.patient.id,
                firstName: this.patient.firstName,
                lastName: this.patient.lastName,
                email: this.patient.email ?? "",
                mobilePhoneNumber: this.patient.mobilePhoneNumber ?? ""
            },

            visitMotiveId: this.visitMotiveId ?? "",
            visitMotiveName: this.visitMotiveName ?? ""
        };
    }

    fromObject(id:string|null, o:any) {

        this.id = id !== null ? id : o.id;
        this.clientId = o.clientId ?? "";
        this.clientName = o.clientName ?? "";
        this.locationId = o.locationId ?? "";
        this.appointmentId = o.appointmentId ? o.appointmentId : "";

        this.appointmentStart = DateUtils.getDate(o.appointmentStart) ?? new Date();
        this.toPhoneNumber = o.toPhoneNumber ? o.toPhoneNumber : "";
        this.sendAt = DateUtils.getDate(o.sendAt) ?? new Date();
        this.sendBy = o.sendBy ?? "sms";

        this.calendarId = o.calendarId ? o.calendarId : "";
        this.messageStatus = o.status ? o.status : MessageStatus.unknown;
        this.price = o.price ? o.price : 0;

        this.start = this.sendAt ?? new Date();
        this.end = moment(this.start).add(20, "minutes").toDate();

        this.visitMotiveId = o.visitMotiveId ?? "";
        this.visitMotiveName = o.visitMotiveName ?? "";

        this.emailHtml = o.emailHtml ?? "";
        this.emailText = o.emailText ?? "";
        this.emailSubject = o.emailSubject ?? "";
        this.from = o.from ?? "";
        this.to = o.to ?? "";
        this.smsText = o.smsText ?? "";

        this.patient = {
            id: (o.patient && o.patient.id) ? o.patient.id : "",
            firstName: (o.patient && o.patient.firstName) ? o.patient.firstName : "",
            lastName: (o.patient && o.patient.lastName) ? o.patient.lastName : "",
            email: (o.patient && o.patient.email) ? o.patient.email : "",
            mobilePhoneNumber: (o.patient && o.patient.mobilePhoneNumber) ? o.patient.mobilePhoneNumber : ""
        }

        // fix for old reminders
        if(this.id !== "" && this.sendBy === ""){
            if(this.patient.mobilePhoneNumber !== "" && this.smsText !== ""){
                this.sendBy = "sms";
            } else if(this.patient.email !== "" && this.emailHtml !== ""){
                this.sendBy = "email";
            }
        }

        this.title = `${this.patient.lastName} ${this.patient.firstName}`;

        if(o.client && o.client.id && o.client.name){
            this.clientId = o.client.id;
            this.clientName = o.client.name;
        }

        this.comments = this.getTitle();
    }

    getStatusAsString(): string {
        switch (this.messageStatus) {
            case MessageStatus.pending:
                return "wird noch gesendet";

            case MessageStatus.notDeliveredYet:
                return "ausstehend";

            case MessageStatus.successfullyDispatched:
                return "erfolgreich";

            case MessageStatus.successfullySendToGateway:
                return "ausstehend";

            case MessageStatus.notDelivered:
                return "konnte nicht zugestellt werden";

            default:
                return "";
        }
    }

    getStatusColor(): string {
        return getStatusColor(this.messageStatus);
    }

    getTitle(): string {

        return `${this.sendBy.toUpperCase()} Terminerinnerung - ${this.visitMotiveName}`;

    }

};

export default Reminder;

