class GlobalSettings {
    clonRVideoCreditsPerTenSeconds: number = 0; // for each generated 10 seconds of video, this amount of credits will be charged
    clonRAvatarCredits: number = 0; // for each avatar, this amount of credits will be charged

    clone(): GlobalSettings {
        const s = new GlobalSettings();

        s.fromObject(s.toJSON());

        return s;
    }

    toJSON(): object {
        return {
            clonRVideoCreditsPerTenSeconds: this.clonRVideoCreditsPerTenSeconds,
            clonRAvatarCredits: this.clonRAvatarCredits
        };
    }

    fromObject(o: any) {
        this.clonRVideoCreditsPerTenSeconds = o.clonRVideoCreditsPerTenSeconds ?? 0;
        this.clonRAvatarCredits = o.clonRAvatarCredits ?? 0;
    }
}

export default GlobalSettings;