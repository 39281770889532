
import FormItem, { FormItemEnum, FormItemType, InputFormItemInterface, InputUserType } from "./formItem";


export class InputTextFormItem extends FormItem implements InputFormItemInterface {

    required: boolean = true;

    inputUser: InputUserType = "patient";

    value: string = "";

    constructor(rowId: string) {
        super(rowId);

        this.type = FormItemEnum.inputText;

        this.setLanguageProperty("de", "Formulieren Sie Ihre Frage", "labels");
    }

    clone(): FormItemType {

        const c = new InputTextFormItem(this.parentId);

        c.fromObject(null, this.toJSON(true));

        c.value = this.value;

        return c;
    }

    toJSON(includeUserInputs: boolean): object {
        const obj: any = super.toJSON(includeUserInputs);

        obj.required = this.required;

        obj.inputUser = this.inputUser;

        if(includeUserInputs){
            obj.value = this.value;
        }

        return obj;
    }

    fromObject(id: string | null, o: any) {
        super.fromObject(id, o);

        this.required = o.required === true;

        this.inputUser = o.inputUser ?? "patient";

        this.value = o.value ?? "";
    }


}


export default InputTextFormItem;