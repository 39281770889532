import Paper from '@mui/material/Paper';
import React, { useEffect, useState } from 'react';
import LicenseCard from '../licenseCard';
import Calendar from '../../models/calendar';
import Grid from '@mui/material/Grid';
import MessageDialog from '../dialogs/messageDialog';

const ClonRNoPremiumLicenseCtrl: React.FC = () => {

    const [isErrorMessageVisible, setIsErrorMessageVisible] = useState(false);

    useEffect(() => {
        setIsErrorMessageVisible(false);
    },[]);

    return (
        <>
            <MessageDialog
                visible={isErrorMessageVisible}
                title="Fehler"
                message="Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
                titleIconFaClassName='far fa-exclamation-triangle'
                onClose={() => setIsErrorMessageVisible(false)}
            />

            <Paper className="kt-paper" style={{ textAlign: "center" }}>
                <div className='kt-paper-title'>Unlock ClonR - für mehr Patienten und steigenden Umsatz!</div>

                <p>
                    Mit ClonR können Sie nicht nur einzigartige AI-Videos erstellen, die Ihr Gesicht und Ihre Stimme in über 30 Sprachen integrieren,
                    sondern auch effektiv neue Patienten ansprechen.
                    <br />
                    Eine "Premium"-Lizenz eröffnet Ihnen die Möglichkeit, innovative Marketingvideos zu gestalten,
                    um Ihren Praxis-Kalender zu füllen und den Umsatz zu steigern.
                </p>

                <h4>Vorteile der "Premium"-Lizenz:</h4>
                <ul style={{ listStyle: "none" }}>
                    <li><strong>Zielgerichtete AI-Videos:</strong> Präsentieren Sie Ihrer Praxis in ansprechenden Videos, die Ihre Persönlichkeit widerspiegeln und potenzielle Patienten in deren Muttersprache erreichen.</li>
                    <li><strong>Umsatzsteigerung:</strong> Füllen Sie Ihren Kalender durch bessere Patientenakquise und optimierte Termineinplanung.</li>
                    <li><strong>Innovation und Wachstum:</strong> Nutzen Sie die neueste Technologie, um Ihr Praxiswachstum zu fördern und im Wettbewerb herauszustechen.</li>
                </ul>

                Investieren Sie in Ihre Praxiszukunft mit einer "Premium"-Lizenz und entdecken Sie, wie ClonR Ihre Praxis auf das nächste Level bringt!

                <Grid container justifyContent="center" alignItems="center" style={{ marginTop: "20px" }}>
                    <Grid item xs={4} justifyItems={"center"}>
                        <LicenseCard
                            calendar={new Calendar()}
                            license='premium'
                            price={199}
                            description='inkl. Termine, Dokumente, KI Erklärvideos und KI Callcenter mit 2 KI-Telefonisten.'
                            features='✔ CalendR ✔ SignR ✔ ClonR ✔ CallR'
                            dropshadow={true}
                            onError={() => setIsErrorMessageVisible(true)}
                        />
                    </Grid>
                </Grid>

            </Paper>
        </>
    );
}

export default ClonRNoPremiumLicenseCtrl;