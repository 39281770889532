import database from "./../components/database";
import { logDbReadOperations } from "../utils";
import ToDo from "../shared/src/models/todo";

const db = database.firestore();

const ToDosService = {

    cache: {} as {[key: string]: ToDo},

    startListenForToDos(clientId: string, locationId: string, changeCallback: (toDos: ToDo[])=>void): () => void {

        return db.collection("clients").doc(clientId)
            .collection("locations").doc(locationId)
            .collection("toDos")
            .orderBy("done")
            .orderBy("createdAt")
            .onSnapshot(function(querySnapshot) {
                const toDos: ToDo[] = [];

                querySnapshot.forEach((doc) => {
                    const toDo = new ToDo();
                    toDo.fromObject(doc.id, doc.data());

                    toDos.push(toDo);
                    ToDosService.cache[toDo.id] = toDo;
                });

                toDos.sort((a, b) => {
                    if (a.done === b.done) {
                        return (b.createdAt ? b.createdAt.getTime() : 0) - (a.createdAt ? a.createdAt?.getTime() : 0);
                    }
                    return a.done ? 1 : -1;
                });

                logDbReadOperations("startListenForToDos", toDos.length);

                changeCallback(toDos);
            });
    },

    async getToDos(clientId: string): Promise<ToDo[] | null> {

        const toDos: ToDo[] = [];

        const querySnapshot = await db.collection("clients").doc(clientId)
                                        .collection("toDos")
                                        .orderBy("done")
                                        .orderBy("createdAt")
                                        .get();

        try {

            querySnapshot.forEach((doc) => {

                const toDo = new ToDo();
                toDo.fromObject(doc.id, doc.data());

                // update cache
                ToDosService.cache[toDo.id] = toDo;

                toDos.push(toDo);

            });

            logDbReadOperations("getToDos", toDos.length);

            return toDos;

        } catch(error) {
            console.log("Error getting toDos: ", error);
            return null;
        }

    },



    async updateToDo(toDo: ToDo, clientId: string, locationId: string): Promise<string | null> {

        try {

            const json: any = toDo.toJSON(true);
            json.updatedAt = new Date();//firestore.FieldValue.serverTimestamp();

            if(toDo.id) {

                await db.collection("clients").doc(clientId)
                    .collection("locations").doc(locationId)
                    .collection("toDos").doc(toDo.id)
                    .set(json, { merge: true });

                // update cache
                ToDosService.cache[toDo.id] = toDo;

                return toDo.id;

            } else {
                // create a new ToDo
                json.createdAt = new Date(); //firestore.FieldValue.serverTimestamp();
                const docRef = await db.collection("clients").doc(clientId)
                                        .collection("locations").doc(locationId)
                                        .collection("toDos").add(json);

                // update cache
                ToDosService.cache[docRef.id] = toDo;

                return docRef.id;
            }


        } catch (error) {
            console.log("Error updating toDo: ", error);
        };

        return null;
    },


    async deleteToDo(toDo: ToDo, clientId: string, locationId: string): Promise<void> {

        try {

            await db.collection("clients").doc(clientId)
                    .collection("locations").doc(locationId)
                    .collection("toDos").doc(toDo.id)
                    .delete();

        } catch(error) {
            console.log("Error deleting toDo: ", error);
        };
    },

}

export default ToDosService;