import { Typography, Grid, Card, CardMedia, CardContent, Button, Checkbox, FormControlLabel } from '@mui/material';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ClonRVideo from '../../shared/src/models/clonR/clonRVideo';
import { t } from 'i18next';

function SettingsPage() {

    const [startPage, setStartPage] = useState("settings");

    useEffect(() => {
        const _startPage = localStorage.getItem('startPage');
        if (_startPage) setStartPage(_startPage);
    }, []);

    const tiles = [
        {
            id: "calendr",
            title: "CalendR",
            description: t("pages.settingsHomePage.calendRDescription"),
            image: "images/logos/Logo-CalendR.svg",
            linkTitle: t("components.buttons.start"),
            link: "/calendr",
            showStartPageOption: true           
        },
        {
            id: "signr",
            title: "SignR",
            description: t("pages.settingsHomePage.signRDescription"),
            image: "images/logos/Logo-SignR.svg",
            linkTitle: t("components.buttons.start"),
            link: "/settings/documents",
            showStartPageOption: false
        },
        {
            id: "clonr",
            title: "ClonR",
            description: t("pages.settingsHomePage.clonRDescription"),
            image: "images/logos/Logo-ClonR.svg",
            linkTitle: t("components.buttons.start"),
            link: "/clonr",
            showStartPageOption: true
        },
        {
            id: "callr",
            title: "CallR",
            description: t("pages.settingsHomePage.callRDescription"),
            image: "images/logos/Logo-CallR.svg",
            linkTitle: t("components.buttons.start"),
            link: "/phoneCalls",
            showStartPageOption: true           
        }        
    ];

    function handleStartPageClick(tileId: string) {
        setStartPage(tileId);
        localStorage.setItem('startPage', tileId);
    }

    return <div className="kt-page-content">
        <div
            style={{
                textAlign: "center",
            }}
        >
            <h1><img src="/images/logo.png" width={40} alt="PickADoc Logo" style={{ marginBottom: "-7px" }} /> Pick-A-Doc</h1>

            <h3>{t("pages.settingsHomePage.title")}</h3>
            
            <br/>

            <Grid container spacing={3} justifyContent="center">
                {tiles.map((tile, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <Card
                            style={{
                                maxWidth: 300,
                                margin: "0 auto",
                                boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                            }}
                        >
                            <CardMedia
                                component="img"
                                image={tile.image}
                                alt={tile.title}
                                style={{ maxHeight: 150, objectFit: "contain" }}
                                
                            />

                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    {tile.title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {tile.description}
                                </Typography>
                                <Button
                                    component={NavLink}
                                    variant="contained"
                                    color="primary"
                                    style={{ marginTop: "1rem" }}
                                    to={tile.link}
                                >
                                    {tile.linkTitle}
                                </Button>
                                
                                <br/>

                                {tile.showStartPageOption ? <FormControlLabel
                                    control={<Checkbox size='small' onClick={() => handleStartPageClick(tile.id)} checked={startPage === tile.id} />}
                                    label={<Typography variant="body2" color="textSecondary">{t("pages.settingsHomePage.setAsHomePage")}</Typography>}
                                    style={{ marginTop: "1rem" }}
                                />
                                :
                                <div style={{height: "54px"}}></div>
                                }

                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    </div>;
}

export default SettingsPage;