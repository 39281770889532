import { useState, useEffect, useRef, useContext } from 'react';

import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Appointment from '../../models/appointment';
import AppointmentsService from '../../services/appointmentsService';
import { Doughnut, Bar, HorizontalBar } from 'react-chartjs-2';
import moment from 'moment';
import UsersService from '../../services/usersService';
import CalendarsService from '../../services/calendarsService';
import Patient from '../../models/patient';
import NumberWidget from '../numberWidget';
import VisitMotivesService from '../../services/visitMotivesService';
import SpecialitiesService from '../../services/specialitiesService';
import { convertHexToRGBA, debounce } from "../../utils";
import CardWidget from '../cardWidget';
import Gender from "../../../src/shared/src/models/gender";
import StarsCtrl from '../starsCtrl';
import Rating from '../../models/rating';
import RatingsService from '../../services/ratingsService';
import { DataGrid, deDE } from '@mui/x-data-grid'
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Avatar from '../avatar';
import Tooltip from '@mui/material/Tooltip';
import { GlobalContext } from '../../GlobalContext';
import DashboardSideMenu from './dashboardSideMenu';

const _appointmentsData = {
    labels: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    datasets: [{
        label: 'Termine 2021',
        data: [12, 19, 3, 5, 2, 3, 0, 0, 0, 0, 0, 0],
        backgroundColor: [
            'rgba(216, 58, 195, 0.2)',
            'rgba(36, 55, 78, 0.2)',
            'rgba(216, 58, 195, 0.2)',
            'rgba(36, 55, 78, 0.2)',
            'rgba(216, 58, 195, 0.2)',
            'rgba(36, 55, 78, 0.2)',
            'rgba(216, 58, 195, 0.2)',
            'rgba(36, 55, 78, 0.2)',
            'rgba(216, 58, 195, 0.2)',
            'rgba(36, 55, 78, 0.2)',
            'rgba(216, 58, 195, 0.2)',
            'rgba(36, 55, 78, 0.2)'
        ],
        borderColor: [
            'rgba(216, 58, 195, 1)',
            'rgba(36, 55, 78, 1)',
            'rgba(216, 58, 195, 1)',
            'rgba(36, 55, 78, 1)',
            'rgba(216, 58, 195, 1)',
            'rgba(36, 55, 78, 1)',
            'rgba(216, 58, 195, 1)',
            'rgba(36, 55, 78, 1)',
            'rgba(216, 58, 195, 1)',
            'rgba(36, 55, 78, 1)',
            'rgba(216, 58, 195, 1)',
            'rgba(36, 55, 78, 1)',
            'rgba(216, 58, 195, 1)',
            'rgba(36, 55, 78, 1)',
            'rgba(216, 58, 195, 1)',
            'rgba(36, 55, 78, 1)'
        ],
        borderWidth: 1
    }]
};

const _userData = {
    labels: [] as string[],
    datasets: [{
        label: 'Termine nach Behandler',
        data: [] as number[],
        backgroundColor: [
            'rgba(216, 58, 195, 0.2)',
            'rgba(36, 55, 78, 0.2)',
            'rgba(216, 58, 195, 0.4)',
            'rgba(36, 55, 78, 0.4)',
            'rgba(216, 58, 195, 0.6)',
            'rgba(36, 55, 78, 0.6)',
            'rgba(216, 58, 195, 0.8)',
            'rgba(36, 55, 78, 0.8)',
            'rgba(216, 58, 195, 1)',
            'rgba(36, 55, 78, 1)'
        ],
        borderColor: [
            'rgba(216, 58, 195, 1)',
            'rgba(36, 55, 78, 1)',
            'rgba(216, 58, 195, 1)',
            'rgba(36, 55, 78, 1)',
            'rgba(216, 58, 195, 1)',
            'rgba(36, 55, 78, 1)',
            'rgba(216, 58, 195, 1)',
            'rgba(36, 55, 78, 1)',
            'rgba(216, 58, 195, 1)',
            'rgba(36, 55, 78, 1)',
            'rgba(216, 58, 195, 1)',
            'rgba(36, 55, 78, 1)',
            'rgba(216, 58, 195, 1)',
            'rgba(36, 55, 78, 1)',
            'rgba(216, 58, 195, 1)',
            'rgba(36, 55, 78, 1)'
        ],
        borderWidth: 1
    }]
};

const _visitMotivesData = {
    labels: [''],
    datasets: [] as any
};

const _ratingsRatioData = {
    labels: ["Beantwortet", "Unbeantwortet"],
    datasets: [{
        label: 'Antwortverhalten',
        data: [0, 0] as number[],
        backgroundColor: [
            'rgba(216, 58, 195, 0.2)',
            'rgba(36, 55, 78, 0.2)'
        ],
        borderColor: [
            'rgba(216, 58, 195, 1)',
            'rgba(36, 55, 78, 1)'
        ],
        borderWidth: 1

    }]
};

//https://github.com/reactchartjs/react-chartjs-2

const DashboardPage: React.FC = () => {

    const [startDate, setStartDate] = useState<Date | undefined>(new Date());
    const [endDate, setEndDate] = useState<Date | undefined>(new Date());


    const [tabIndex, setTabIndex] = useState(0);

    const [tabIndexRatingsList, setTabIndexRatingsList] = useState(0);

    const [appointments, setAppointments] = useState<Appointment[]>([]);
    const [filteredAppointmentsCount, setFilteredAppointmentsCount] = useState(0);
    const [patients, setPatients] = useState<Patient[]>([]);

    const [numberOfNewPatients, setNumberOfNewPatients] = useState(0);
    const [numberOfPrivateInsurances, setNumberOfPrivateInsurances] = useState(0);

    const [numberOfAppointments, setNumberOfAppointments] = useState(0); // real appointments which including confirmed recalls
    const [numberOfOnlineAppointments, setNumberOfOnlineAppointments] = useState(0);

    const [numberOfRecallerAppointments, setNumberOfRecallerAppointments] = useState(0);
    const [numberOfConfirmedRecallerAppointments, setNumberOfConfirmedRecallerAppointments] = useState(0);

    const [numberOfSuccessorAppointments, setNumberOfSuccessorAppointments] = useState(0);
    const [numberOfConfirmedSuccessorAppointments, setNumberOfConfirmedSuccessorAppointments] = useState(0);


    const [topVisitMotive, setTopVisitMotive] = useState({ id: "", name: "", count: 0 });
    const [numberOfDays, setNumberOfDays] = useState(0);
    const [appointmentsPerDay, setAppointmentsPerDay] = useState(0);
    const [numberOfWoman, setNumberOfWoman] = useState(0);
    const [numberOfPatients, setNumberOfPatients] = useState(0);
    const [btcPrice, setBtcPrice] = useState(0);

    const [appointmentsData, setAppointmentsData] = useState<any>();
    const [userData, setUserData] = useState<any>();
    const [visitMotivesData, setVisitMotivesData] = useState<any>();

    const [ratings, setRatings] = useState<Rating[]>([]);
    const [filteredRatings, setFilteredRatings] = useState<Rating[]>([]);
    const [numberOfRatings, setNumberOfRatings] = useState(0);
    const [numberOfFilteredRatings, setNumberOfFilteredRatings] = useState(0);
    const [numberOfOpenRatingRequests, setNumberOfOpenRatingRequests] = useState(0);
    const [totalRating, setTotalRating] = useState(0);
    const [ratingStars, setRatingStars] = useState<number[]>([0, 0, 0, 0, 0]); // index 0 = number of 1 star ... index 4 = number of 5 stars
    const [doctorRatings, setDoctorRatings] = useState<any[]>([]);
    const [ratingsRatioData, setRatingsRatioData] = useState<any>();

    const { currentClient } = useContext(GlobalContext);
    const { currentUser } = useContext(GlobalContext);
    const { calendars } = useContext(GlobalContext);
    const { specialities } = useContext(GlobalContext);

    const googleMap = useRef();
    const mapMarkers = useRef<any>();

    useEffect(() => {

    }, []);

    useEffect(() => {

        // only update data if tab is visible
        if (tabIndex === 0) {
            updateRatingsDebounced(startDate, endDate);

        } else if (tabIndex === 1) {
            updateAppointmentsDebounced(startDate, endDate);

        }

    }, [tabIndex, startDate, endDate, currentClient, currentUser]);

    useEffect(() => {

        async function updateDashboards() {
            if (calendars && calendars.length && specialities && specialities.length) {
                if (tabIndex === 0) {
                    updateRatingsDashboard(await getFilteredRatings());

                } else if (tabIndex === 1) {
                    updateAppointmentsDashboard(getFilteredAppointments());

                }
            }
        }

        updateDashboards();

    }, [appointments, ratings, calendars, specialities, tabIndex]);


    function getFilteredAppointments(): Appointment[] {

        if (calendars && calendars.length) {

            const filteredSpecialitiesIds = specialities.filter(s => s.selectedOnDashboard).map(s => s.id);

            const filteredResourcesIds = calendars.filter(r => r.selectedOnDashboard).map(r => r.id);
            const _filteredAppointments: Appointment[] = [];

            for (let i = 0; i < appointments.length; i++) {
                const appointment = appointments[i];

                let isItemFiltered = false;

                isItemFiltered = !(appointment.calendar && appointment.calendar.id && filteredResourcesIds.includes(appointment.calendar.id));

                if (!(appointment.visitMotive && appointment.visitMotive.specialityId && filteredSpecialitiesIds.includes(appointment.visitMotive.specialityId))) {
                    isItemFiltered = true;
                }

                if (appointment.status !== "confirmed") {
                    //isItemFiltered = true;
                }


                if (!isItemFiltered) {
                    _filteredAppointments.push(appointment);
                }

            }

            setFilteredAppointmentsCount(_filteredAppointments.length);

            return _filteredAppointments;
        }

        return [];
    }


    async function getFilteredRatings(): Promise<Rating[]> {

        const _filteredRatings: Rating[] = [];

        if (currentUser && calendars && calendars.length) {

            const filteredSpecialitiesIds = specialities.filter(s => s.selectedOnDashboard).map(s => s.id);

            const filteredResourcesIds = calendars.filter(r => r.selectedOnDashboard).map(r => r.id);

            for (let i = 0; i < ratings.length; i++) {
                const rating = ratings[i];

                let isItemFiltered = true;

                const _calendars = await CalendarsService.getCalendarsByUserId(rating.doctorId, true, currentClient.id, currentUser?.locationId);
                if (_calendars) {
                    for (let i = 0; i < _calendars.length; i++) {
                        const cal = _calendars[i];
                        if (filteredResourcesIds.includes(cal.id)) {
                            isItemFiltered = false;
                            break;
                        }
                    }
                }

                const visitMotive = await VisitMotivesService.getVisitMotive(rating.visitMotiveId, false, currentClient.id, currentUser?.locationId);
                if (!(visitMotive && visitMotive.specialityId && filteredSpecialitiesIds.includes(visitMotive.specialityId))) {
                    isItemFiltered = true;
                }

                if (!isItemFiltered) {
                    _filteredRatings.push(rating);
                }

            }

            setNumberOfFilteredRatings(_filteredRatings.length);
            setFilteredRatings(_filteredRatings);
        }

        return _filteredRatings;
    }


    useEffect(() => {

        async function updateBtcPrice() {

            console.log("updating BTC price");

            const response = await fetch("https://api.coinbase.com/v2/prices/BTC-USD/sell/");

            if (response.ok) {
                const price = await response.json();
                setBtcPrice(parseFloat(price.data.amount));
            }
        }
        const timer = setInterval(async () => {
            updateBtcPrice();
        }, 1000 * 60);

        updateBtcPrice();

        return () => clearInterval(timer);
    }, []);

    const updateAppointmentsDebounced = useRef(debounce((_startDate, _endDate) => {
        if (_startDate && _endDate && currentClient && currentUser) {
            updateAppointments(_startDate, _endDate, currentClient.id, currentUser.locationId);
        }
    }, 200)
    ).current;


    async function updateAppointments(_startDate: Date, _endDate: Date, clientId: string, locationId: string) {
        if (currentUser && currentClient) {

            if (!_startDate || !_endDate) {
                return;
            }

            const apps = await AppointmentsService.getAppointmentsByDate(_startDate, _endDate, clientId, locationId, null);

            if (apps) {
                setAppointments(apps);
            }

        }
    }

    async function updateAppointmentsDashboard(_filteredAppointments: Appointment[]) {

        if (!currentUser) {
            return;
        }

        const userStats = {} as any;
        const months = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let newPatiens = 0;
        let privateInsurances = 0;
        let onlineAppointments = 0;
        let visitMotivesCounters = {};
        let userCounter = 0;
        let womanCounter = 0;

        let numberOfAppointments = 0;  // real appointments which including confirmed recalls
        let recallerAppointments = 0;
        let confirmedRecallerAppointments = 0;

        let successorAppointments = 0;
        let confirmedSuccessorAppointments = 0;

        const patientIds: string[] = [];
        const _patients: Patient[] = [];

        for (let i = 0; i < _filteredAppointments.length; i++) {
            const appointment = _filteredAppointments[i];


            if (appointment.createdBy === "online" && appointment.status === "confirmed") {
                onlineAppointments++;
            }

            if (appointment.parentRecallId && appointment.parentRecallId !== "") {
                recallerAppointments++;
                if (appointment.status === "confirmed") {
                    confirmedRecallerAppointments++;
                }
            }

            if (appointment.predecessorId && appointment.predecessorId !== "") {
                successorAppointments++;
                if (appointment.status === "confirmed") {
                    confirmedSuccessorAppointments++;
                }
            }



            if (appointment.status === "confirmed") {

                numberOfAppointments++;


                // check if this patient was already counted
                if (patientIds.indexOf(appointment.patient.id) === -1 && appointment.status === "confirmed") {
                    patientIds.push(appointment.patient.id);

                    _patients.push(appointment.patient);

                    if (appointment.patient.gender === Gender.female) {
                        womanCounter++;
                    }

                    if (appointment.patient.newPatient) {
                        newPatiens++;
                    }

                    if (appointment.patient.privateInsurance) {
                        privateInsurances++;
                    }
                }



                if (visitMotivesCounters[appointment.visitMotive.id]) {
                    visitMotivesCounters[appointment.visitMotive.id].counter++;
                } else {
                    visitMotivesCounters[appointment.visitMotive.id] = {
                        counter: 1
                    }
                }


                const calendar = await CalendarsService.getCalendar(currentClient.id, currentUser.locationId, appointment.calendar.id);
                const userId = calendar?.userId;

                if (userId) {
                    const _user = await UsersService.getUser(currentClient.id, userId);

                    // count total appointments for each user
                    if (userStats[userId]) {
                        userStats[userId].count += 1;
                    } else {
                        userStats[userId] = {
                            name: _user?.abbreviation ?? _user?.lastName,
                            count: 1
                        };

                        userCounter++;
                    }

                    // now count visit motives groups for each user
                    if (userStats[userId][appointment.visitMotive.specialityId]) {
                        userStats[userId][appointment.visitMotive.specialityId]++;
                    } else {
                        userStats[userId][appointment.visitMotive.specialityId] = 1;
                    }
                }



                const month = appointment.start.getMonth();
                months[month]++;
            }

        }

        //initMap(_patients);

        setPatients(_patients);
        setNumberOfWoman(womanCounter);
        setNumberOfPatients(patientIds.length);
        setNumberOfNewPatients(newPatiens);
        setNumberOfPrivateInsurances(privateInsurances);
        setNumberOfOnlineAppointments(onlineAppointments);

        setNumberOfAppointments(numberOfAppointments);

        setNumberOfRecallerAppointments(recallerAppointments);
        setNumberOfConfirmedRecallerAppointments(confirmedRecallerAppointments);

        setNumberOfSuccessorAppointments(successorAppointments);
        setNumberOfConfirmedSuccessorAppointments(confirmedSuccessorAppointments);

        let diffDays = moment(endDate).diff(moment(startDate), "days");
        diffDays += 1; // because we always filter from start of a day to the end of a day
        setNumberOfDays(diffDays);

        const _appointmentsPerDay = numberOfAppointments / diffDays;
        setAppointmentsPerDay(_appointmentsPerDay);


        _appointmentsData.datasets[0].label = `Anzahl Termine`;
        _appointmentsData.datasets[0].data = months;
        setAppointmentsData(_appointmentsData);


        _userData.labels = [];
        _userData.datasets[0].data = [];

        _visitMotivesData.labels = [];
        _visitMotivesData.datasets = [];
        let userIndex = 0;
        for (const key in userStats) {
            _userData.labels.push(userStats[key].name);
            _userData.datasets[0].data.push(userStats[key].count);

            _visitMotivesData.labels.push(userStats[key].name);

            for (const specialityId in userStats[key]) {

                if (specialityId !== "count" && specialityId !== "name") {
                    const speciality = await SpecialitiesService.getSpeciality(currentClient.id, currentUser.locationId, specialityId);
                    if (speciality) {
                        let ds = _visitMotivesData.datasets.find(d => d.label === speciality.shortName);

                        if (!ds) {

                            // create an array with the length of the number of users
                            const data: number[] = new Array(userCounter).fill(0);

                            ds = {
                                label: speciality.shortName,
                                data: data,
                                backgroundColor: convertHexToRGBA(speciality.color, 1),
                                cardinality: speciality.cardinality
                            };
                            _visitMotivesData.datasets.push(ds);
                        }

                        ds.data[userIndex] = userStats[key][specialityId];
                    }
                }
            }

            userIndex++;
        }

        _visitMotivesData.datasets.sort((a, b) => {
            return a.cardinality - b.cardinality;
        });


        setUserData(_userData);
        setVisitMotivesData(_visitMotivesData);



        // get the top visit motive
        let maxCount = 0;
        let maxVisitMotiveId = "";
        for (const key in visitMotivesCounters) {
            const motive = visitMotivesCounters[key];

            if (motive.counter > maxCount) {
                maxCount = motive.counter;
                maxVisitMotiveId = key;
            }
        }
        if (maxVisitMotiveId) {
            const maxVisitMotive = await VisitMotivesService.getVisitMotive(maxVisitMotiveId, false, currentClient.id, currentUser.locationId);
            if (maxVisitMotive) {
                setTopVisitMotive({
                    id: maxVisitMotiveId,
                    name: maxVisitMotive.name,
                    count: maxCount
                })
            }
        }
    }

    const updateRatingsDebounced = useRef(debounce((_startDate, _endDate) => {
        if (_startDate && _endDate && currentClient && currentUser) {
            updateRatings(_startDate, _endDate, currentClient.id, currentUser.locationId);
        }
    }, 200)
    ).current;

    async function updateRatings(startDate: Date, endDate: Date, clientId: string, locationId: string) {

        const _ratings = await RatingsService.getRatingsByDate(startDate, endDate, clientId, locationId, false);
        if (_ratings) {
            setRatings(_ratings);
        } else {
            setRatings([]);
        }
    }

    async function updateRatingsDashboard(filteredRatings: Rating[]) {
        let diffDays = moment(endDate).diff(moment(startDate), "days");
        diffDays += 1; // because we always filter from start of a day to the end of a day
        setNumberOfDays(diffDays);

        _ratingsRatioData.datasets[0].data = [0, 0];

        if (filteredRatings) {

            let ratedCounter = 0;
            const _ratingStars = [0, 0, 0, 0, 0];
            const _userRatings = {};
            let totalSum = 0;

            for (let i = 0; i < filteredRatings.length; i++) {
                const rating = filteredRatings[i];

                if (rating.rating > 0 && rating.rating <= 5) {
                    ratedCounter++;
                    totalSum += rating.rating;

                    const starIndex = Math.floor(rating.rating) - 1;
                    _ratingStars[starIndex]++;

                    if (_userRatings[rating.doctorId]) {
                        _userRatings[rating.doctorId].numberOfRatings++;
                        _userRatings[rating.doctorId].sumRating += rating.rating;
                        // with a score we can sort better if someone has an avgRating of 5 and 10 ratings and someone else an avgRating of 5 but only 2 ratings,
                        // then the one with more ratings wins
                        _userRatings[rating.doctorId].score += rating.rating * rating.rating;
                    } else {
                        _userRatings[rating.doctorId] = {
                            numberOfRatings: 1,
                            sumRating: rating.rating,
                            avgRating: 0,
                            score: rating.rating * rating.rating,
                            doctorId: rating.doctorId,
                            doctorName: rating.doctorName
                        }
                    }
                }
            }

            setTotalRating(ratedCounter ? totalSum / ratedCounter : 0);

            // make an array out of the dictionary
            const _userRatingsArray: any[] = [];
            for (const key in _userRatings) {
                _userRatings[key].avgRating = _userRatings[key].sumRating / _userRatings[key].numberOfRatings;
                _userRatings[key].avatarUrl = await UsersService.getUserAvatarUrl(currentClient.id, _userRatings[key].doctorId);
                _userRatingsArray.push(_userRatings[key]);
            }
            // sort by score
            _userRatingsArray.sort((a, b) => {
                return b.score - a.score;
            });
            // now sort by avg rating
            _userRatingsArray.sort((a, b) => {
                return b.avgRating - a.avgRating;
            });
            setDoctorRatings(_userRatingsArray);

            setNumberOfRatings(ratedCounter);
            const _numOpenRequests = filteredRatings.length - ratedCounter;
            setNumberOfOpenRatingRequests(_numOpenRequests);
            setRatingStars(_ratingStars);

            _ratingsRatioData.datasets[0].data = [ratedCounter, _numOpenRequests];

        }

        // we need this JSON hack, otherwise the chartjs doughnut will display always data from the request before this one
        setRatingsRatioData(JSON.parse(JSON.stringify(_ratingsRatioData)));
    }



    // Initialize and add the map
    function initMap(_patients) {

        try {

            const google = (window as any).google;

            let pos = { lat: 51.226980, lng: 6.789229 };

            if (!googleMap.current) {
                googleMap.current = new google.maps.Map(document.getElementById('googleMap'), { zoom: 13, center: pos });
            }

            const marker = new google.maps.Marker({ position: pos, map: googleMap.current, title: "CeraWhite", icon: "images/default-marker.png" });

            if (mapMarkers.current) {

                // first delete old markers
                for (let i = 1; i < mapMarkers.current.length; i++) {
                    mapMarkers.current[i].setMap(null);
                    mapMarkers.current[i] = undefined;
                    delete mapMarkers.current[i];
                }

            }

            mapMarkers.current = [marker];

            for (let index = 0; index < _patients.length; index++) {
                const patient = _patients[index];

                if (patient.location && patient.location.latitude && patient.location.longitude) {

                    pos = { lat: patient.location.latitude, lng: patient.location.longitude };

                    const patMarker = new google.maps.Marker({
                        id: patient.id,
                        position: pos,
                        map: googleMap.current,
                        title: `${patient.lastName} ${patient.firstName}`,
                        icon: "images/patient-marker.png"
                    });

                    patMarker.addListener("click", (e) => {
                        //onPatientClick(e, patMarker.id);
                    });

                    mapMarkers.current.push(patMarker);
                }

            }


        } catch (error) {
            console.error("Cannot initiate Google Maps: ", error);
        }
    }

    function onToggleRatingVisibilityRowClick(row) {
        if (row && row.id) {
            // update in db
            RatingsService.updateVisibility(row.id, !row.public);

            // update the UI
            const rating = ratings.find(rating => {
                return rating.id === row.id;
            });

            if (rating) {
                rating.public = !rating.public;
            }

            setRatings([...ratings]);
        }
    }


    function onDeleteRatingRowClick(row) {
        if (row && row.id) {
            // delete from db
            RatingsService.deleteRating(row.id);

            // update the UI
            const _ratings = ratings.filter(rating => {
                return rating.id != row.id;
            });

            setRatings(_ratings);
        }
    }

    function getRatingClassName(ratingValue: number): string {
        switch (ratingValue) {
            case 1:
            case 2:
                return "kt-red-rating";

            case 3:
                return "kt-orange-rating";

            case 4:
                return "kt-yellow-rating";

            case 5:
                return "kt-green-rating";

            default:
                return "";
        }
    }

    function getCellStars(ratingValue: number) {
        const colorClass = getRatingClassName(ratingValue);

        const stars: any[] = [];
        for (let i = 0; i < ratingValue; i++) {
            stars.push(<i key={i} className={`fas fa-star ${colorClass}`}></i>);
        }
        return stars;
    }


    const ratingColumns: any[] = [
        { field: 'doctorName', headerName: 'Behandler', width: 300 },
        {
            field: 'patientLastName', headerName: 'Patient', width: 150, renderCell: (params) => (
                <span>{params.row.patientFirstName} {params.row.patientLastName}</span>)
        },
        { field: 'patientPhone', headerName: 'Telefon', width: 150 },
        {
            field: 'rating', headerName: 'Bewertung', type: "number", width: 150, renderCell: (params) => (
                <div className="kt-stars-cell">
                    {getCellStars(params.row.rating)}
                    <span>{params.row.rating}</span>
                </div>
            )
        },
        {
            field: 'sendBy', headerName: 'Typ', width: 50, renderCell: (params) => (
                <span>
                    {params.row.sendBy === "email" && <i className="fal fa-at"></i>}
                    {params.row.sendBy === "sms" && <i className="fal fa-mobile-alt"></i>}
                </span>
            )
        },
        {
            field: 'comments', headerName: 'Kommentar', width: 700, renderCell: (params) => (
                <Tooltip title={params.row.comments}>
                    <span className="kt-row-comment">{params.row.comments}</span>
                </Tooltip>
            )
        },
        { field: 'ratedAt', headerName: 'Bewertet', type: 'date', width: 150 },
        {
            field: 'public', headerName: "Aktionen", width: 150, renderCell: (params) => (
                <div>
                    {params.row.public ?
                        <Tooltip title="Nicht öffentlich sichtbar machen">
                            <IconButton onClick={(e) => onToggleRatingVisibilityRowClick(params.row)}>
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>
                        :
                        <Tooltip title="Öffentlich sichtbar machen">
                            <IconButton onClick={(e) => onToggleRatingVisibilityRowClick(params.row)}>
                                <VisibilityOffIcon />
                            </IconButton>
                        </Tooltip>
                    }

                    <Tooltip title="Löschen">
                        <IconButton onClick={(e) => onDeleteRatingRowClick(params.row)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )
        }
    ];


    const requestColumns: any[] = [
        { field: 'doctorName', headerName: 'Behandler', width: 300 },
        {
            field: 'patientLastName', headerName: 'Patient', width: 300, renderCell: (params) => (
                <span>{params.row.patientFirstName} {params.row.patientLastName}</span>)
        },
        { field: 'patientPhone', headerName: 'Telefon', width: 200 },
        {
            field: 'sendBy', headerName: 'Typ', width: 50, renderCell: (params) => (
                <span>
                    {params.row.sendBy === "email" && <i className="fal fa-at"></i>}
                    {params.row.sendBy === "sms" && <i className="fal fa-mobile-alt"></i>}
                </span>
            )
        },
        { field: 'createdAt', headerName: 'Angefragt am', type: 'date', width: 300 },
        {
            field: 'delete', headerName: "Aktionen", width: 150, renderCell: (params) => (
                <div>
                    <Tooltip title="Löschen">
                        <IconButton onClick={(e) => onDeleteRatingRowClick(params.row)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )
        }
    ];

    return (
        <>

            <DashboardSideMenu
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={newDate => setStartDate(newDate)}
                onEndDateChange={newDate => setEndDate(newDate)}
            />

            <div className="kt-grey-page kt-dashboard-page">

                <h2>{`Dashboard - ${numberOfDays === 1 ? "1 Tag" : `${numberOfDays} Tage`}`}</h2>

                <Tabs value={tabIndex} onChange={(e, index) => setTabIndex(index)} >
                    <Tab label="Bewertungen" />
                    <Tab label="Termine" />
                </Tabs>


                {/* RATINGS */}
                {tabIndex === 0 &&
                    <div>
                        <Grid container className="margin-bottom" spacing={3}>

                            <Grid item xs={12} sm={4}>
                                <CardWidget title="Anfragen" icon="fal fa-mail-bulk" text={numberOfFilteredRatings.toString()} />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <CardWidget title="Antworten" icon="fal fa-vote-yea" text={numberOfRatings.toString()} />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <CardWidget title="Unbeantwortet" icon="fal fa-vote-nay" text={numberOfOpenRatingRequests.toString()} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <div className="card kt-rating-docs">

                                    <div className="kt-card-headline">PRAXISBEWERTUNG</div>
                                    <StarsCtrl rating={totalRating} />

                                    <div className="kt-rating-details">

                                        <div className="kt-number-of-ratings">BEWERTUNGEN</div>
                                        <div>{numberOfRatings}</div>

                                        <div>
                                            <i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i>
                                        </div>
                                        <div>{ratingStars[4]}</div>

                                        <div>
                                            <i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i>
                                        </div>
                                        <div>{ratingStars[3]}</div>

                                        <div>
                                            <i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i>
                                        </div>
                                        <div>{ratingStars[2]}</div>

                                        <div>
                                            <i className="fas fa-star"></i><i className="fas fa-star"></i>
                                        </div>
                                        <div>{ratingStars[1]}</div>

                                        <div>
                                            <i className="fas fa-star"></i>
                                        </div>
                                        <div>{ratingStars[0]}</div>


                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <div className="card kt-rating-docs">

                                    <div className="kt-card-headline">ANTWORTRATE</div>

                                    {ratingsRatioData &&
                                        <Doughnut
                                            width={200}
                                            height={200}
                                            data={ratingsRatioData}
                                            options={
                                                {
                                                    maintainAspectRatio: false,
                                                    tooltips: {
                                                        callbacks: {
                                                            label: function (tooltipItem, data) {
                                                                if (data && data.datasets && ratings) {
                                                                    const dataset = data.datasets[tooltipItem.datasetIndex];
                                                                    const total = numberOfFilteredRatings;
                                                                    const currentValue = dataset.data[tooltipItem.index];
                                                                    const percentage = total > 0 ? (currentValue / total * 100).toFixed(1) : 0;
                                                                    return `${percentage}% ${tooltipItem.index === 0 ? "beantwortet" : "unbeantwortet"}`;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        />
                                    }
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <div className="card kt-rating-docs">

                                    <div className="kt-card-headline">RANKING</div>

                                    <div className="kt-doctor-ranking">
                                        {doctorRatings.map((dRating, index) =>
                                            <div className="kt-doctor-ranking-row" key={dRating.doctorId}>
                                                <Avatar src={dRating.avatarUrl} width={32} />
                                                <div className="kt-doctor-name">{`${index + 1}. ${dRating.doctorName}`}</div>
                                                <div><i className="fal fa-vote-yea"></i> {dRating.numberOfRatings}</div>
                                                <div className="kt-big"><i className="fas fa-star"></i> {dRating.avgRating.toFixed(1)}</div>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <div className="card kt-rating-list">

                                    <Tabs value={tabIndexRatingsList} onChange={(e, index) => setTabIndexRatingsList(index)} >
                                        <Tab label="BEWERTUNGEN" />
                                        <Tab label="UNBEANTWORTETE ANFRAGEN" />
                                    </Tabs>

                                    {tabIndexRatingsList === 0 &&
                                        <div className="kt-list-wrapper">
                                            <DataGrid
                                                rows={filteredRatings.filter(rating => rating.rating > 0)}
                                                columns={ratingColumns}
                                                sortModel={[
                                                    {
                                                        field: 'ratedAt',
                                                        sort: 'desc'
                                                    }
                                                ]}
                                                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                                            />
                                        </div>
                                    }

                                    {tabIndexRatingsList === 1 &&
                                        <div className="kt-list-wrapper">
                                            <DataGrid
                                                rows={filteredRatings.filter(rating => rating.rating === 0)}
                                                columns={requestColumns}
                                                sortModel={[
                                                    {
                                                        field: 'createdAt',
                                                        sort: 'desc'
                                                    }
                                                ]}
                                                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                                            />
                                        </div>
                                    }
                                </div>
                            </Grid>

                        </Grid>

                    </div>
                }

                {/* APPOINTMENTS */}
                {tabIndex === 1 &&
                    <div>
                        <Grid container className="margin-bottom" spacing={3}>

                            <Grid item xs={6} sm={2}>
                                <CardWidget title="Termine" icon="fal fa-calendar-alt" text={numberOfAppointments.toString()} />
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <CardWidget title="Termine pro Tag" icon="fal fa-tachometer-average" text={appointmentsPerDay.toFixed(1)} />
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <CardWidget title="Patienten" icon="fal fa-address-card" text={numberOfPatients.toString()} />
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <CardWidget title="Frauen" icon="fal fa-female" text={numberOfWoman.toString()} />
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <CardWidget title="Männer" icon="fal fa-male" text={(numberOfPatients - numberOfWoman).toString()} />
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <CardWidget title="BTC" icon="fab fa-bitcoin" text={`${btcPrice.toFixed(0)} $`} />
                            </Grid>


                            <Grid item xs={6} sm={2}>
                                <CardWidget title="Recall Termine" icon="fal fa-recycle" text={numberOfRecallerAppointments.toString()} />
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <CardWidget
                                    title="Recall Termine Conversion"
                                    icon="fal fa-recycle"
                                    text={`${numberOfRecallerAppointments ? (numberOfConfirmedRecallerAppointments / numberOfRecallerAppointments * 100).toFixed(1) : "0.0"}%`}
                                />
                            </Grid>

                            <Grid item xs={6} sm={2}>
                                <CardWidget title="Folgetermine" icon="fal fa-arrow-square-right" text={numberOfSuccessorAppointments.toString()} />
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <CardWidget
                                    title="Folgetermine Conversion"
                                    icon="fal fa-arrow-square-right"
                                    text={`${numberOfSuccessorAppointments ? (numberOfConfirmedSuccessorAppointments / numberOfSuccessorAppointments * 100).toFixed(1) : "0.0"}%`}
                                />
                            </Grid>

                            <Grid item xs={6} sm={2}>
                                <CardWidget
                                    title="Gesamt-Conversion"
                                    icon="fal fa-analytics"
                                    text={`${(numberOfRecallerAppointments + numberOfSuccessorAppointments) ? ((numberOfConfirmedSuccessorAppointments + numberOfConfirmedRecallerAppointments) / (numberOfRecallerAppointments + numberOfSuccessorAppointments) * 100).toFixed(1) : ""}%`}
                                />
                            </Grid>


                            {/* <Grid item xs={12}>
                            <div className="kt-map card" id="googleMap"></div>
                        </Grid> */}

                            <Grid item xs={12} sm={6}>
                                <div className="card">
                                    {userData && <Doughnut
                                        width={300}
                                        height={300}
                                        data={userData}
                                        options={
                                            {
                                                maintainAspectRatio: false
                                            }
                                        }
                                    />}
                                </div>
                            </Grid>



                            <Grid item xs={12} sm={6}>
                                <div className="card">
                                    {(visitMotivesData && visitMotivesData.labels.length) && <HorizontalBar
                                        width={400}
                                        height={200}
                                        data={visitMotivesData}
                                        options={
                                            {
                                                maintainAspectRatio: false,
                                                scales: {
                                                    xAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            beginAtZero: true,
                                                        },
                                                        gridLines: {
                                                            color: "rgba(0, 0, 0, 0)",
                                                        }
                                                    }],
                                                    yAxes: [{
                                                        stacked: true,
                                                        gridLines: {
                                                            color: "rgba(0, 0, 0, 0)",
                                                        }
                                                    }]
                                                }
                                            }
                                        }
                                    />}
                                </div>
                            </Grid>




                            <Grid item xs={12} sm={6}>
                                <div className="card">
                                    {appointmentsData && <Bar
                                        width={400}
                                        height={200}
                                        data={appointmentsData}
                                        options={
                                            {
                                                maintainAspectRatio: false,
                                                scales: {
                                                    xAxes: [{
                                                        gridLines: {
                                                            color: "rgba(0, 0, 0, 0)",
                                                        }
                                                    }],
                                                    yAxes: [{
                                                        gridLines: {
                                                            color: "rgba(0, 0, 0, 0)",
                                                        }
                                                    }]
                                                }
                                            }
                                        }
                                    />}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container>
                                    <Grid item xs={12} sm={6}>
                                        <NumberWidget title="NEUPATIENTEN" icon="fal fa-user-plus" color="primary" absoluteNumber={numberOfNewPatients} percentage={filteredAppointmentsCount > 0 ? 100 / filteredAppointmentsCount * numberOfNewPatients : 0} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <NumberWidget title="PRIVATPATIENTEN" icon="fal fa-hand-holding-medical" color="secondary" absoluteNumber={numberOfPrivateInsurances} percentage={filteredAppointmentsCount > 0 ? 100 / filteredAppointmentsCount * numberOfPrivateInsurances : 0} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <NumberWidget title="ONLINE-TERMINE" icon="fal fa-globe" color="primary" absoluteNumber={numberOfOnlineAppointments} percentage={filteredAppointmentsCount > 0 ? 100 / filteredAppointmentsCount * numberOfOnlineAppointments : 0} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <NumberWidget title="TOP BESUCHSGRUND" subTitle={topVisitMotive.name.toUpperCase()} icon="fal fa-stethoscope" color="secondary" absoluteNumber={topVisitMotive.count} percentage={filteredAppointmentsCount > 0 ? 100 / filteredAppointmentsCount * topVisitMotive.count : 0} />
                                    </Grid>
                                </Grid>
                            </Grid>



                        </Grid>
                    </div>
                }


            </div>

        </>
    );

}

export default DashboardPage;