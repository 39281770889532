import React from 'react';

interface Props {
    countryCode: string,
    width?: number
}

const CountryFlag:React.FC<Props> = ({countryCode, width}) => {

    return (
        <img src={`/images/flags/${countryCode}.svg`} alt="country flag" width={width}/>
    );
}

export default CountryFlag;