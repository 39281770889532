
import React, { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Calendar from '../../models/calendar';
import LicenseCard from '../licenseCard';


interface Props {    
    calendar: Calendar;
    visible: boolean;
    onClose: () => void;
}

const LicenseDialog: React.FC<Props> = ({ calendar, visible, onClose }) => {

    const [isErrorMessageVisible, setIsErrorMessageVisible] = useState(false);


    useEffect(() => {

        setIsErrorMessageVisible(false);

        window.addEventListener('keydown', listenKeyboard, true);

        // clean up
        return () => {
            window.removeEventListener('keydown', listenKeyboard, true);
        }

    }, []);

    function listenKeyboard(event) {
        if (event.keyCode === 27) {
            onClose();
        }
    }

    


    return (
        <>
            

            <Dialog
                aria-labelledby="customized-dialog-title"
                open={visible}
                maxWidth="lg"
                fullWidth={true}
            >
                <DialogTitle id="customized-dialog-title">
                    Kalender-Lizens
                </DialogTitle>

                <DialogContent sx={{padding:0}}>

                    {isErrorMessageVisible ?
                        <h3 style={{textAlign:"center"}}><i className='far fa-exclamation-triangle' style={{ color: "red" }}></i> Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.</h3>
                        :
                        <Grid container justifyContent="center" alignItems="center" spacing={2} style={{padding:"20px", backgroundColor: "rgb(245, 245, 245)"}}>
                            <Grid item xs={4} justifyItems={"center"}>
                                <LicenseCard 
                                    calendar={calendar}
                                    license='free'
                                    price={0}
                                    description='Für Behandler, die von überall aus Termine erstellen und einsehen möchten.'
                                    features='✔ CalendR - free Version'
                                    onError={()=>setIsErrorMessageVisible(true)}
                                />                                
                            </Grid>
                            <Grid item xs={4} justifyItems={"center"}>
                                <LicenseCard 
                                    calendar={calendar}
                                    license='basic'
                                    price={159}
                                    description='Für Behandler, die Termine direkt mit digitalen Dokumenten versenden wollen.'
                                    features='✔ CalendR ✔ SignR'
                                    onError={()=>setIsErrorMessageVisible(true)}
                                />                                
                            </Grid>
                            <Grid item xs={4} justifyItems={"center"}>
                                <LicenseCard 
                                    calendar={calendar}
                                    license='premium'
                                    price={199}
                                    description='inkl. Termine, Dokumente, KI Erklärvideos und KI Callcenter mit 2 KI-Telefonisten.'
                                    features='✔ CalendR ✔ SignR ✔ ClonR ✔ CallR'
                                    onError={()=>setIsErrorMessageVisible(true)}
                                />                                
                            </Grid>                           
                        </Grid>
                    }



                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" onClick={onClose} color='primary'>
                        Schließen
                    </Button>
                </DialogActions>

            </Dialog>

        </>

    );
}

export default LicenseDialog;
