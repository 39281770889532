import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import DocumentsService from "../../../services/documentsService";

import LoadingPage from "../administration/loadingPage";
import SaveStatus from "../../../models/saveStatus";
import SaveButton from "../../saveButton";
import BackButton from "../../backButton";
import PDocument from "../../../../src/shared/src/models/formEditor/pdocument";
import ReactCountryFlag from "react-country-flag"
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DateUtils from "../../../../src/shared/src/utils/dateUtils";
import FormEditor from "../../formEditor/formEditor";
import FormRow from "../../../../src/shared/src/models/formEditor/formRow";
import FormPreview from "../../formEditor/formPreview";
import ClientLocationsService from "../../../../src/services/clientLocationsService";
import ListItemIcon from "@mui/material/ListItemIcon";
import { GlobalContext } from "../../../GlobalContext";

const availableLanguages = [
    { key: "de", name: "Deutsch" },
    { key: "gb", name: "Englisch" },
    { key: "gr", name: "Griechisch" },
    { key: "tr", name: "Türkisch" },
    { key: "cn", name: "Chinesisch" },
    { key: "jp", name: "Japanisch" },
    { key: "es", name: "Spanisch" },
    { key: "it", name: "Italienisch" },
    { key: "fr", name: "Französisch" },
    { key: "ma", name: "Arabisch" },
    { key: "ru", name: "Russisch" }

]

function DocumentPage() {

    const navigate = useNavigate();

    const { documentId } = useParams();

    const [pDocument, setPDocument] = useState<PDocument | null>(null);

    const [isLoading, setIsLoading] = useState(false);
    const {currentClient} = useContext(GlobalContext);
    const {currentUser} = useContext(GlobalContext);

    const [saveStatus, setSaveStatus] = useState(SaveStatus.none);
    const [selectedNewLanguage, setSelectedNewLanguage] = useState("de");
    const [selectedEditLanguage, setSelectedEditLanguage] = useState("de");
    const [newLanguageDialogVisible, setNewLanguageDialogVisible] = useState(false);

    const [formPreviewVisible, setFormPreviewVisible] = useState(false);

    const [editFormRows, setEditFormRows] = useState<FormRow[]>([]);

    const [clientLocationLogoUrl, setClientLocationLogoUrl] = useState("");

    useEffect(() => {

        async function updateLogo() {
            if (currentClient && currentUser) {
                const location = await ClientLocationsService.getLocation(currentClient.id, currentUser?.locationId);

                if (location) setClientLocationLogoUrl(location.logoUrl);
            }
        }

        updateLogo();

        if (documentId && currentClient) {
            updateData(documentId);
        }

        // eslint-disable-next-line
    }, [documentId, currentClient]);


    useEffect(() => {

    }, [selectedEditLanguage]);

    async function updateData(documentId: string) {

        if (!currentUser) {
            return;
        }

        try {
            const cId = currentClient.id;

            setIsLoading(true);

            if (documentId && documentId.toLowerCase() === "create" && cId) {
                const newDocument = new PDocument();

                setPDocument(newDocument);

                setEditFormRows(newDocument.formRows);

            } else {

                const result = await DocumentsService.getDocumentTemplate(currentClient.id, currentUser.locationId, documentId);
                if (result) {
                    setPDocument(result);

                    setEditFormRows(result.formRows);
                }
            }


            setIsLoading(false);

        } catch (error) {
            console.log(error);
            setIsLoading(false);
            setEditFormRows([]);
        }
    }

    function handleSubmit(event) {
        event.preventDefault();

        saveDocument();
    }

    async function saveDocument() {
        if (pDocument !== null && currentUser) {
            setSaveStatus(SaveStatus.isSaving);

            // if (editFormRows) {
            //     pDocument.formRows = editFormRows;
            // }

            const newId = await DocumentsService.updateDocumentTemplate(pDocument, currentClient.id, currentUser.locationId);

            if (newId) {

                pDocument.id = newId;
                setPDocument(pDocument);

                navigate('/settings/document/' + newId);

                setSaveStatus(SaveStatus.saved);

                //window.history.back();

            } else {
                setSaveStatus(SaveStatus.none);
                alert("Das Dokument konnte nicht gespeichert werden!");
            }
        }
    }

    function onInputChange(event) {
        if (pDocument) {
            let { name, value } = event.target;

            const doc = pDocument.clone();

            if (event.target.type === "checkbox") {
                value = event.target.checked;
            } else if (event.target.name === "expiresAfterValue") {
                const temp = DateUtils.parseInterval(doc.expiresAfter);
                doc.expiresAfter = `${value}-${temp.unit}`;
                setPDocument(doc);
                return;

            } else if (event.target.name === "expiresAfterUnit") {
                const temp = DateUtils.parseInterval(doc.expiresAfter);
                doc.expiresAfter = `${temp.value}-${value}`;
                setPDocument(doc);
                return;
            }

            const names = name.split(".");
            if (names.length === 3) {
                doc.setLanguageProperty(names[1], names[2], value);
            }

            doc[name] = value;

            setPDocument(doc);
        }
    }

    function handleCloseTab(e, lang: string) {
        e.stopPropagation();
        if (window.confirm(`Wollen Sie die Sprachvariation ${lang.toUpperCase()} mit Ihrer Übersetzung wirklich löschen?`)) {
            if (pDocument) {
                const doc = pDocument.clone();

                doc.removeLanguage(lang);

                setPDocument(doc);
            }
        }
    }


    function addNewLanguage(newLanguage) {
        if (pDocument) {
            const doc = pDocument.clone();

            doc.setLanguageProperty(newLanguage.key, "name", "");
            setNewLanguageDialogVisible(false);

            setPDocument(doc);
        }

    }


    function SelectLanguageDialog() {

        const languages: { key: string, name: string }[] = [];

        for (let i = 0; i < availableLanguages.length; i++) {
            const item = availableLanguages[i];

            if (pDocument && !pDocument.languages.find(l => l.key === item.key)) {
                languages.push(item);
            }
        }

        return (
            <Dialog
                onClose={() => setNewLanguageDialogVisible(false)}
                aria-labelledby="simple-dialog-title"
                open={newLanguageDialogVisible}>
                <DialogTitle id="simple-dialog-title">Wählen Sie eine neue Sprache aus</DialogTitle>
                <List>
                    {languages.map((lang) => (
                        <ListItem button onClick={() => addNewLanguage(lang)} key={lang.key}>
                            <ListItemIcon>
                                <ReactCountryFlag svg countryCode={lang.key} />
                            </ListItemIcon>
                            <ListItemText primary={lang.name} />
                        </ListItem>
                    ))}

                </List>
            </Dialog>
        );
    }


    function PreviewButton() {
        return (
            <Button
                variant="contained"
                startIcon={<VisibilityIcon />}
                onClick={() => setFormPreviewVisible(true)}
            >
                VORSCHAU
            </Button>
        )
    }

    function renderExpiresAfterIntervalDropdown() {
        return (
            <React.Fragment>
                <TextField style={{ marginLeft: 100 }}
                    type="number"
                    name="expiresAfterValue"
                    label="Gültig"
                    value={pDocument ? DateUtils.parseInterval(pDocument.expiresAfter).value : 0}
                    onChange={onInputChange}
                    inputProps={{ min: 1, max: 999 }}
                />
                <FormControl style={{ marginLeft: 40 }}>
                    <Select
                        name="expiresAfterUnit"
                        id="expiresAfterUnit-select"
                        value={pDocument ? DateUtils.parseInterval(pDocument.expiresAfter).unit : "m"}
                        onChange={onInputChange}
                    >
                        <MenuItem value="d">Tag/e</MenuItem>
                        <MenuItem value="w">Woche/n</MenuItem>
                        <MenuItem value="m">Monat/e</MenuItem>
                        <MenuItem value="y">Jahr/e</MenuItem>

                    </Select>
                </FormControl>
            </React.Fragment>
        );
    }

    function handleFormEditorChange(formRows: FormRow[]) {
        //setEditFormRows([...formRows]);

        if (pDocument) {
            pDocument.formRows = formRows;
        }
    }

    if (isLoading) return <LoadingPage />;

    if (!pDocument) return (
        <div className="kt-page-content">
            <h2>Dokument</h2>

            <div>Dieses Dokument existiert nicht. Bitte überprüfen Sie Ihre URL.</div>
        </div>
    );

    return (
        <Box className="kt-page kt-document-page">

            <SelectLanguageDialog />

            <h2>Dokument</h2>

            <form onSubmit={handleSubmit} autoComplete="turnitoff">

                <div className="kt-form-section">

                    <Grid container>

                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                name="name"
                                label="Interner Name"
                                value={pDocument.name}
                                onChange={onInputChange}
                                required
                                inputProps={{ minLength: 1 }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            {renderExpiresAfterIntervalDropdown()}
                        </Grid>

                        <Grid item xs={6}>
                            <FormControlLabel
                                control={<Checkbox checked={pDocument.mandatory} onChange={onInputChange} name="mandatory" color="default" />}
                                label="Pflichtdokument"
                            />

                        </Grid>

                    </Grid>

                </div>


                {availableLanguages.length > pDocument.languages.length && <Button
                    onClick={() => setNewLanguageDialogVisible(true)}
                    variant="outlined"
                    startIcon={<i className="fal fa-globe" />}
                >
                    Sprache hinzufügen
                </Button>}


                <Tabs
                    value={selectedEditLanguage}
                    onChange={(e, value) => setSelectedEditLanguage(value)}

                    indicatorColor="primary"
                    textColor="secondary"
                    aria-label="icon label tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {pDocument.languages.map(lang => (
                        <Tab
                            key={lang.key}
                            value={lang.key}
                            label={<div className="kt-language-select-label-wrapper">
                                <ReactCountryFlag
                                    svg
                                    countryCode={lang.key} />
                                <span className="kt-language-select-label">{lang.key.toUpperCase()}</span>
                                {pDocument.languages.length > 1 && <CloseIcon onClick={(e) => handleCloseTab(e, lang.key)} />}
                            </div>}
                        />
                    ))}
                </Tabs>
                <div className="kt-form-section">

                    <Grid container>

                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                name={`languages.${selectedEditLanguage}.name`}
                                label="Name für den Patienten"
                                value={pDocument.getLanguageProperty(selectedEditLanguage, "name")}
                                onChange={onInputChange}
                                required
                                inputProps={{ minLength: 1 }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <PreviewButton />
                        </Grid>

                        <Grid item xs={12}>
                            <FormEditor
                                documentId={pDocument.id}
                                languageKey={selectedEditLanguage}
                                formRowsProp={editFormRows}
                                onFormChange={handleFormEditorChange}
                                onPreviewClick={() => setFormPreviewVisible(true)}
                            />
                        </Grid>
                    </Grid>


                </div>

                <div className="kt-page-footer">
                    <BackButton />

                    <SaveButton saveStatus={saveStatus} />

                    <PreviewButton />
                </div>

            </form>


            <Dialog
                fullScreen
                onClose={() => setFormPreviewVisible(false)}
                aria-labelledby="simple-dialog-title"
                open={formPreviewVisible}
            >

                <AppBar>
                    <Toolbar>
                        <DialogTitle>Formular Vorschau</DialogTitle>

                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => setFormPreviewVisible(false)}
                            aria-label="close"
                            style={{
                                position: 'absolute',
                                right: 20,
                                top: 8
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <div>
                    <FormPreview
                        documentId={pDocument.id}
                        clientId={currentClient.id}
                        locationId={currentUser!.locationId}
                        clientLocationLogoUrl={clientLocationLogoUrl}
                        languageKey={selectedEditLanguage}
                        formRowsProp={[...pDocument.formRows]}
                        onFormChange={handleFormEditorChange}
                        showNextButton={false}
                        isSaving={false}
                        showRenderPDFButton={true}
                        formUser="patient"
                    />
                </div>

            </Dialog>

        </Box>

    );
}

export default DocumentPage;