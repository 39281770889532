import moment from "moment";
import { InvoiceItemType } from "../../src/shared/src/models/invoice/invcoiceItem";
import DateUtils from "../../src/shared/src/utils/dateUtils";
import database from "../components/database";
import Appointment from "../models/appointment";
import Client from "../models/client";
import ClientLocation from "../models/clientLocation";
import Patient from "../models/patient";
import Reminder from "../models/reminder";
import VisitMotive from "../models/visitMotive";
import { isGermanMobileNumber, logDbReadOperations } from "../utils";
import SmsService from "./smsService";
import TextEvaluationService from "./textEvaluationService";
import VisitMotivesService from "./visitMotivesService";

const db = database.firestore();

export const NewSmsLine = "\n";

const NotificationsService = {

    async getReminder(reminderId: string): Promise<Reminder | null> {

        try {
            const doc = await db.collection(`reminders`)
                .doc(reminderId)
                .get();

            if(doc.exists) {

                const reminder = new Reminder();
                reminder.fromObject(reminderId, doc.data());

                return reminder;

            } else {
                console.log("getReminder: No such document: " + reminderId);
                return null;
            }


        } catch (error) {
            console.log(`Error getting reminder: ${error}`);
            return null;
        }

    },

    async getSentReminder(reminderId: string, clientId: string, locationId: string): Promise<Reminder | null> {

        try {
            const doc = await db.collection("clients").doc(clientId)
                .collection("locations").doc(locationId)
                .collection(`reminders`).doc(reminderId)
                .get();

            if(doc.exists) {

                const reminder = new Reminder();
                reminder.fromObject(reminderId, doc.data());

                return reminder;

            } else {
                console.log("getSentReminder: No such document: " + reminderId);
                return null;
            }


        } catch (error) {
            console.log(`Error getting sent reminder: ${error}`);
            return null;
        }

    },

    startListenForReminders(fromDate: Date, toDate: Date, locationId: string, changeCallback: (reminders: Reminder[])=>void): () => void {

        if(!fromDate || !toDate || !locationId){
            return () => {
                console.log("error in startListenForReminders: empty paramter");
            };
        }

        console.log(`startListenForReminders fromDate: ${fromDate} toDate: ${toDate} locationId: ${locationId}`);

        return db.collection("reminders")
            .orderBy("sendAt")
            .where("sendAt", ">=", fromDate)
            .where("sendAt", "<=", toDate)
            .where("locationId", "==", locationId)
            .onSnapshot(function(querySnapshot) {
                const reminderList: Reminder[] = [];

                    querySnapshot.forEach((doc) => {
                        const reminder = new Reminder();
                        reminder.fromObject(doc.id, doc.data());
                        reminderList.push(reminder);
                    });

                    logDbReadOperations("startListenForReminders", reminderList.length);

                    changeCallback(reminderList);
            });
    },

    startListenForSentReminders(fromDate: Date, toDate: Date, clientId: string, locationId: string, changeCallback: (reminders: Reminder[])=>void): () => void {

        if(!fromDate || !toDate || !locationId || !clientId){
            return () => {
                console.log("error in startListenForSentReminders: empty paramter");
            };
        }

        console.log(`startListenForReminders fromDate: ${fromDate} toDate: ${toDate} locationId: ${locationId}`);

        return db.collection("clients").doc(clientId)
            .collection("locations").doc(locationId)
            .collection("reminders")
            .orderBy("sendAt")
            .where("sendAt", ">=", fromDate)
            .where("sendAt", "<=", toDate)
            .onSnapshot(function(querySnapshot) {
                const reminderList: Reminder[] = [];

                    querySnapshot.forEach((doc) => {
                        const reminder = new Reminder();
                        reminder.fromObject(doc.id, doc.data());
                        reminderList.push(reminder);
                    });

                    logDbReadOperations("startListenForSentReminders", reminderList.length);

                    changeCallback(reminderList);
            });
    },


    async getReminderSmsText(appointment: Appointment, clientLocation: ClientLocation): Promise<string> {
        if(appointment && clientLocation.notificationsSettings){

            if(appointment.parentRecallId && appointment.createdBy === "recaller" && appointment.status === "needsConfirmation"){

                const parentRecallVisitMotive = await VisitMotivesService.getParentRecallVisitMotive(appointment);

                if (parentRecallVisitMotive && parentRecallVisitMotive.recallSmsText) {
                    return parentRecallVisitMotive.recallSmsText;
                 }

            } else if(appointment.createdBy === "predecessor" && appointment.status === "needsConfirmation"){

                const predecessorVisitMotive = await VisitMotivesService.getPredecessorVisitMotive(appointment);

                if(predecessorVisitMotive &&  predecessorVisitMotive.successorSmsText){
                    return predecessorVisitMotive.successorSmsText;
                }

            } else {
                const visitMotive = await VisitMotivesService.getVisitMotive(appointment.visitMotive.id, false, clientLocation.id, appointment.locationId);

                if(visitMotive && visitMotive.reminderSmsCustomTextEnabled && visitMotive.reminderSmsText){
                    return visitMotive.reminderSmsText;
                } else {
                    return clientLocation.notificationsSettings.reminderSmsText;
                }

            }
        }

        return "";
    },

    // Just used to test and preview the visit motives
    async getPreviewReminderSmsText(appointment: Appointment, visitMotive: VisitMotive, clientLocation: ClientLocation): Promise<string> {
        if(appointment && clientLocation.notificationsSettings){

            if(appointment.createdBy === "recaller" && appointment.status === "needsConfirmation"){

                if (visitMotive && visitMotive.recallSmsText) {
                    return visitMotive.recallSmsText;
                 }

            } else if(appointment.createdBy === "predecessor" && appointment.status === "needsConfirmation"){

                if(visitMotive && visitMotive.successorSmsText){
                    return visitMotive.successorSmsText;
                }

            } else {

                if(visitMotive && visitMotive.reminderSmsCustomTextEnabled && visitMotive.reminderSmsText){
                    return visitMotive.reminderSmsText;
                } else {
                    return clientLocation.notificationsSettings.reminderSmsText;
                }

            }
        }

        return "";
    },

    getReminderSendDate(appointment: Appointment): Date | null{

        const startDate = DateUtils.getDate(appointment.start);
        if(!startDate) return null;

        const sendDate = new Date(startDate);

        if(appointment.parentRecallId && appointment.createdBy === "recaller" && appointment.status === "needsConfirmation"){

            sendDate.setHours( startDate.getHours());

        } else if(appointment.createdBy === "predecessor" && appointment.status === "needsConfirmation"){

            sendDate.setHours( startDate.getHours());

        } else {
            sendDate.setHours( startDate.getHours() - 24 * 1);
        }

        return sendDate;
    },

    // reminders is a list of email or sms items which have to be send in the future
    async updateReminders(appointment: Appointment, patient: Patient, clientLocation: ClientLocation): Promise<string | null> {
        try {

            const startDate = DateUtils.getDate(appointment.start);
            if(!startDate) return null;

            const sendDate = NotificationsService.getReminderSendDate(appointment);

            // for normal appointments we sent a reminder only if it is at least one day in the future
            const now = new Date();
            let isInFuture = DateUtils.getDiffInDays(startDate, now) >= 1;

            // for recalls or successor appointments which have not been confirmed by the patient (virtual appointments)
            // we use the appointment start date as the date to remind the patient
            if(appointment.isVirtual()){
                isInFuture = DateUtils.getDiffInMinutes(startDate, now) > 1;
            }

            const dateString = moment(startDate).format("DD.MM.YYYY");

            const timeOptions = { hour: "2-digit", minute: "2-digit" } as any;
            const timeString = startDate.toLocaleTimeString("de-DE", timeOptions);


            let smsText = await NotificationsService.getReminderSmsText(appointment, clientLocation);
            if(appointment.isVideoCall){
                smsText = `Ihre Videosprechstunde: ${dateString} ${timeString}${NewSmsLine}Ihr ${clientLocation.name}-Team!${NewSmsLine}https://cal.pickadoc.de/videocall/${appointment.id}/${appointment.patient.id}`;
            }

            let emailSubject = `Terminerinnerung von ${clientLocation.name}`;
            let emailText = `Wir möchten Sie an Ihren Termin am ${dateString} um ${timeString} erinnern. Ihr ${clientLocation.name}-Team!`;
            let emailHtml = `<p style="font-size: 16px">Wir möchten Sie an Ihren Termin am ${dateString} um ${timeString} erinnern.</p>
                            <p>Ihre Termine: https://pickadoc.de/appointments</p>
                            <p style="font-size: 16px">Ihr ${clientLocation.name}-Team!</p>`;

            if(appointment.isVideoCall){
                emailText = `Wir möchten Sie an Ihre Videosprechstunde am ${dateString} um ${timeString} erinnern. Ihr ${clientLocation.name}-Team!`;
                emailHtml = `<p style="font-size: 16px">Wir möchten Sie an Ihre Videosprechstunde am ${dateString} um ${timeString} erinnern.</p>
                            <p>Link zur Videosprechstunde: https://cal.pickadoc.de/videocall/${appointment.id}/${appointment.patient.id}</p>
                            <p>Ihre Termine: https://pickadoc.de/appointments</p>
                            <p style="font-size: 16px">Ihr ${clientLocation.name}-Team!</p>`;
            }

            const senderName = (clientLocation.notificationsSettings.useCustomSenderName && clientLocation.notificationsSettings.customSenderName) ? clientLocation.notificationsSettings.customSenderName : "Pickadoc";

            const reminder = new Reminder();
            reminder.id = appointment.id;
            reminder.clientId = appointment.clientId;
            reminder.clientName = clientLocation.name;
            reminder.locationId = appointment.locationId;
            reminder.appointmentId = appointment.id;

            reminder.calendarId = appointment.calendar.id;

            reminder.patient = {
                id: patient.id,
                firstName: patient.firstName,
                lastName: patient.lastName,
                email: "",
                mobilePhoneNumber: ""
            };
            reminder.visitMotiveId = appointment.visitMotive.id;
            reminder.visitMotiveName = appointment.visitMotive.name;
            reminder.appointmentStart = appointment.start;
            reminder.sendAt = sendDate ?? new Date();
            reminder.from = senderName;
            reminder.smsText = await TextEvaluationService.evaluate(smsText, appointment);
            reminder.emailSubject = emailSubject;
            reminder.emailHtml = emailHtml;
            reminder.emailText = emailText;



            if(patient.reminderAllowed && patient.smsAllowed && isGermanMobileNumber(patient.mobilePhoneNumber) && clientLocation.notificationsSettings.reminderSmsEnabled) {
                reminder.sendBy = "sms";
                reminder.patient.mobilePhoneNumber = patient.mobilePhoneNumber;

            } else if(patient.reminderAllowed && patient.emailAllowed && patient.email) {
                reminder.sendBy = "email";
                reminder.patient.email = patient.email;

            } else {
                // no way to send the reminder or not allowed
                // stop here and exit
                reminder.sendBy = "";

                console.error("Error creating reminder: not allowed by patient or no way to send via sms or email");
                return null;
            }


            if(isInFuture && (reminder.patient.email || reminder.patient.mobilePhoneNumber)){
                await db.collection("reminders").doc(appointment.id).set(reminder.toJSON());
                return appointment.id;

            } else {

                await db.collection("reminders").doc(appointment.id).delete();
                return appointment.id;
            }

        } catch (error) {
            console.log(`error in updateReminders: ${error}`);
            return null;
        }

    },

    async updateReminder(reminder: Reminder): Promise<string | null>{
        try {

            // update reminder
            if(reminder.id){
                await db.collection("reminders").doc(reminder.id)
                        .set(reminder.toJSON(), {merge: true});

                return reminder.id;

            } else {
                // create new reminder
                const docRef = await db.collection("reminders").add(reminder.toJSON());

                return docRef.id;

            }

        } catch(error) {
            console.log("Error updating reminder: ", error);
        };

        return null;
    },

    async deleteReminder(reminderId: string): Promise<void>{
        try {
            const documentPath = `reminders/${reminderId}`;
            await db.doc(documentPath).delete();

        } catch(error) {
            console.log("Error deleting reminder: ", error);
        };
    },

    async deleteSentReminder(reminderId: string, clientId: string, locationId: string): Promise<void>{
        try {
            await db.collection("clients").doc(clientId)
                .collection("locations").doc(locationId)
                .collection("reminders").doc(reminderId).delete();

        } catch(error) {
            console.log("Error deleting sent reminder: ", error);
        };
    },

    // a confirmation is send to the patient if a new appointment is created
    async sendConfirmation(appointment: Appointment, patient: Patient, client: Client, clientLocation: ClientLocation, dummyPreviewStatus?: string): Promise<void> {
        try {
            const startDate = DateUtils.getDate(appointment.start);
            if(!startDate) return;

            const sendDate = new Date(startDate);
            sendDate.setHours( startDate.getHours() - 24);

            const now = new Date();
            const isInFuture = startDate.getTime() > now.getTime();

            const dateString = moment(startDate).format("DD.MM.YYYY");

            const timeOptions = { hour: '2-digit', minute: '2-digit' };
            const timeString = startDate.toLocaleTimeString("de-DE", timeOptions as any);

            if(patient.smsAllowed && isGermanMobileNumber(patient.mobilePhoneNumber) && isInFuture && clientLocation.notificationsSettings.confirmationSmsEnabled){

                let smsText = `Bestätigung Ihres Termins am ${dateString} um ${timeString}.${NewSmsLine}Ihr ${client.name}-Team!${NewSmsLine}Hier klicken: [[TerminLink]]`;
                if(appointment.isVideoCall){
                    smsText = `Bestätigung Ihrer Videosprechstunde: ${dateString} ${timeString}${NewSmsLine}Ihr ${client.name}-Team!${NewSmsLine}https://cal.pickadoc.de/videocall/${appointment.id}/${appointment.patient.id}`;

                } else if(clientLocation && clientLocation.notificationsSettings.confirmationSmsText){
                    smsText = clientLocation.notificationsSettings.confirmationSmsText;
                }

                smsText = await TextEvaluationService.evaluate(smsText, appointment, dummyPreviewStatus);

                const senderName = (clientLocation.notificationsSettings.useCustomSenderName && clientLocation.notificationsSettings.customSenderName) ? clientLocation.notificationsSettings.customSenderName : "Pickadoc";

                await SmsService.sendSms(patient.mobilePhoneNumber, smsText, senderName, InvoiceItemType.smsConfirmation, appointment.clientId, appointment.locationId);
            }
        } catch(error){
            console.log(`error in sending confirmation ${error}`);
        }
    },


    // manually send a reminder to the patient
    async sendReminder(appointment: Appointment, patient: Patient, clientLocation: ClientLocation): Promise<void> {
        try {
            const startDate = DateUtils.getDate(appointment.start);
            if(!startDate) return;

            const sendDate = new Date(startDate);
            sendDate.setHours( startDate.getHours() - 24);

            const now = new Date();
            const isInFuture = startDate.getTime() > now.getTime();

            if(patient.smsAllowed && isGermanMobileNumber(patient.mobilePhoneNumber) && isInFuture && clientLocation.notificationsSettings.confirmationSmsEnabled){

                let smsText = await NotificationsService.getReminderSmsText(appointment, clientLocation);
                smsText = await TextEvaluationService.evaluate(smsText, appointment);

                const senderName = (clientLocation.notificationsSettings.useCustomSenderName && clientLocation.notificationsSettings.customSenderName) ? clientLocation.notificationsSettings.customSenderName : "Pickadoc";

                await SmsService.sendSms(patient.mobilePhoneNumber, smsText, senderName, InvoiceItemType.smsReminder, appointment.clientId, appointment.locationId);
            }
        } catch(error){
            console.log(`error in sending reminder ${error}`);
        }
    },

    async sendPreviewReminder(appointment: Appointment, visitMotive: VisitMotive, patient: Patient, clientLocation: ClientLocation, dummyPreviewStatus?: string): Promise<void> {
        try {

            if(isGermanMobileNumber(patient.mobilePhoneNumber) && clientLocation.notificationsSettings.confirmationSmsEnabled){

                let smsText = await NotificationsService.getPreviewReminderSmsText(appointment, visitMotive, clientLocation);
                smsText = await TextEvaluationService.evaluate(smsText, appointment, dummyPreviewStatus);

                const senderName = (clientLocation.notificationsSettings.useCustomSenderName && clientLocation.notificationsSettings.customSenderName) ? clientLocation.notificationsSettings.customSenderName : "Pickadoc";

                await SmsService.sendSms(patient.mobilePhoneNumber, smsText, senderName, InvoiceItemType.smsReminder, appointment.clientId, appointment.locationId);
            }
        } catch(error){
            console.log(`error in sending preview reminder ${error}`);
        }
    }

}

export default NotificationsService;