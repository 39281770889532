

import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState, useRef } from 'react';
import CanvasDraw from "../react-canvas-draw/index";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";



interface Props {
    visible: boolean;
    title: string;
    imgSrc: string;
    onSave: (image: string) => void; // returns a base64 image string
    onClose: () => void;
}


function fitImageSize(originalWidth: number, originalHeight: number, targetWidth: number, targetHeight: number): { width: number, height: number } {

    const vFact = targetHeight / originalHeight;
    const hFact = targetWidth / originalWidth;

    const im_fact = Math.min(vFact, hFact);
    const newHeight = originalHeight * im_fact;
    const newWidth = originalWidth * im_fact;

    return {
        width: newWidth,
        height: newHeight
    };
}

const ImageDrawDialog: React.FC<Props> = ({ visible, title, imgSrc, onClose, onSave }) => {

    const canvasDrawRef = useRef();

    const [strokeColor, setStrokeColor] = useState("#6B8CE6");
    const [strokeWidth, setStrokeWidth] = useState(5);
    const [mode, setMode] = useState<"drawing" | "erasing">("drawing");

    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [imgSize, setImgSize] = useState<{ width: Number, height: number }>({ width: 0, height: 0 });

    useEffect(() => {

        const img = new Image();
        img.onload = () => {

            const targetWidth = window.innerWidth - 150;
            const targetHeight = window.innerHeight - 150;

            const newSize = fitImageSize(img.width, img.height, targetWidth, targetHeight);

            setImgSize(newSize);

            setIsImageLoaded(true);
        }
        img.src = imgSrc;

    }, [imgSrc]);

    async function handleSave() {
        if (canvasDrawRef.current) {
            const canvasImage = await (canvasDrawRef.current as any).getDataURL("png", true);
            onSave(canvasImage);
        }
    }

    function handleClose() {
        onClose();
    }

    // clears the canvas
    function handleClearAll() {
        if (canvasDrawRef.current) {
            (canvasDrawRef.current as any).eraseAll();
        }
    }

    function handleUndo() {
        if (canvasDrawRef.current) {
            (canvasDrawRef.current as any).undo();
        }
    }

    function handleRedo() {
        if (canvasDrawRef.current) {
            (canvasDrawRef.current as any).redo();
        }
    }

    function switchToEraseMode(erase: boolean) {
        if (canvasDrawRef.current) {
            (canvasDrawRef.current as any).eraseMode(erase);
            setMode(erase ? "erasing" : "drawing");
        }
    }

    return (
        <Dialog
            fullScreen
            aria-labelledby="customized-dialog-title"
            open={visible}
            className="kt-image-draw-dialog"
        >
            <DialogTitle id="customized-dialog-title">
                {title}
            </DialogTitle>

            <IconButton
                edge="end"
                color="inherit"
                onClick={() => handleClose()}
                aria-label="close"
                style={{
                    position: 'absolute',
                    right: 20,
                    top: 8
                }}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent
                dividers
            >

                <div className='kt-image-wrapper'>

                    {isImageLoaded ?
                        <CanvasDraw
                            ref={(ref) => (canvasDrawRef.current as any) = ref}
                            imgSrc={imgSrc}
                            canvasWidth={imgSize.width}
                            canvasHeight={imgSize.height}
                            brushColor={strokeColor}
                            brushRadius={strokeWidth}
                            hideInterface={true}
                            lazyRadius={0}
                            //enablePanAndZoom={true}
                        />
                        :
                        <div>Loading image...</div>
                    }
                </div>

            </DialogContent>

            <DialogActions>
                <div className="kt-button-toolbar">

                    <div className="kt-color-button-wrapper">
                        <label className="kt-color-button-label" htmlFor="color">Farbe: </label>
                        <input className="kt-color-button" type="color" name="color" value={strokeColor} onChange={(e) => setStrokeColor(e.target.value)} />
                    </div>

                    <div className='kt-stroke-widths'>
                        <label>Breite: </label>
                        <div className={'kt-stroke-width-button' + (strokeWidth === 1 ? " kt-selected" : "")} onClick={() => setStrokeWidth(1)}><div className="kt-stroke-1"></div></div>
                        <div className={'kt-stroke-width-button' + (strokeWidth === 2 ? " kt-selected" : "")} onClick={() => setStrokeWidth(2)}><div className="kt-stroke-2"></div></div>
                        <div className={'kt-stroke-width-button' + (strokeWidth === 5 ? " kt-selected" : "")} onClick={() => setStrokeWidth(5)}><div className="kt-stroke-3"></div></div>
                        <div className={'kt-stroke-width-button' + (strokeWidth === 7 ? " kt-selected" : "")} onClick={() => setStrokeWidth(7)}><div className="kt-stroke-4"></div></div>
                        <div className={'kt-stroke-width-button' + (strokeWidth === 10 ? " kt-selected" : "")} onClick={() => setStrokeWidth(10)}><div className="kt-stroke-5"></div></div>
                        <div className={'kt-stroke-width-button' + (strokeWidth === 20 ? " kt-selected" : "")} onClick={() => setStrokeWidth(20)}><div className="kt-stroke-6"></div></div>
                    </div>

                    {/* <i className={mode === "drawing" ? "fal fa-pen kt-button kt-selected kt-margin-left" : 'fal fa-pen kt-button kt-margin-left'} onClick={() => switchToEraseMode(false)}></i>
                    <i className={mode === "erasing" ? "fal fa-eraser kt-button kt-selected" : 'fal fa-eraser kt-button'} onClick={() => switchToEraseMode(true)}></i> */}

                    <i className='fal fa-undo kt-button kt-margin-left' onClick={() => handleUndo()}></i>
                    {/* <i className='fal fa-redo kt-button' onClick={() => handleRedo()}></i> */}

                </div>
                <Button variant="outlined" onClick={handleClearAll}>Löschen</Button>
                <Button variant="contained" className="MuiButton-containedPrimary" onClick={handleSave}>Speichern</Button>
            </DialogActions>

        </Dialog>

    );
}

export default ImageDrawDialog;
