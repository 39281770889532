import React, { useEffect, useState, useContext } from "react";

import dayjs from "dayjs";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { InsuranceFilter } from "../../../shared/src/models/campaign/campaign";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid/Grid";
import VisitMotivePicker from "../../visitMotivePicker";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import PatientsService from "../../../services/patientsService";
import DateUtils from "../../../shared/src/utils/dateUtils";
import SearchIcon from '@mui/icons-material/Search';
import CampaignPatient from "../../../models/campaignPatient";
import Button from "@mui/material/Button";
import { GlobalContext } from "../../../GlobalContext";

interface Props {
    onSearchResultChange: (patients: CampaignPatient[]) => void
    onIsLoading: (_isLoading: boolean) => void
}

const RecallerPatientsFilter: React.FC<Props> = ({ onSearchResultChange, onIsLoading }) => {

    let startDate = new Date();
    startDate.setDate(new Date().getDate() - 180);

    const {currentUser} = useContext(GlobalContext);
    const {visitMotives} = useContext(GlobalContext);
    const {specialities} = useContext(GlobalContext);
    const {calendars} = useContext(GlobalContext);
    const {currentLocation} = useContext(GlobalContext);

    const [useAllVisitMotives, setUseAllVisitMotives] = useState(true);
    const [visitMotiveId, setVisitMotiveId] = useState("all");

    const [fromDate, setFromDate] = useState<Date | null>(startDate);
    const [insurance, setInsurance] = useState<InsuranceFilter>(InsuranceFilter.private);
    const [calendarId, setCalendarId] = useState("all");
    const [searchTag, setSearchTag] = useState("");

    const [showSearchInstructionText, setShowSearchInstructionText] = useState(true);
    const [showSearchHasBeenModifiedText, setShowSearchHasBeenModifiedText] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [patients, setPatients] = useState<CampaignPatient[]>([]);

    useEffect(()=>{
        if(calendars && calendars.length > 0){
            setCalendarId(calendars[0].id);
        }
    }, [calendars])

    async function startPatientSearch() {
        if (fromDate) await loadPatients(fromDate, insurance, calendarId, visitMotiveId, searchTag);
    }

    async function loadPatients(_fromDate: Date, _insurance: InsuranceFilter, _calendarFilterId: string, _visitMotiveFilterId: string, _searchTag: string) {

        if (currentUser && _fromDate) {

            if (showSearchInstructionText) setShowSearchInstructionText(false);
            if (showSearchHasBeenModifiedText) setShowSearchHasBeenModifiedText(false);

            setIsLoading(true);
            onIsLoading(true);

            const _patients = await PatientsService.getPatientsForRecall(null, _fromDate, _insurance, _calendarFilterId, _visitMotiveFilterId, _searchTag, currentUser?.clientId, currentUser?.locationId);
            if (_patients) {
                setPatients(_patients);
                onSearchResultChange(_patients);
            }

            setIsLoading(false);
            onIsLoading(false);
        }
    }

    function handleOnUseAllVisitMotivesChange(e) {

        setVisitMotiveId(e.target.checked ? "all" : visitMotiveId);

        setUseAllVisitMotives(e.target.checked);
    }

    function handleFromDateChange(newDate: dayjs.Dayjs | null) {
        if(newDate){
            setFromDate(newDate.toDate());
            setShowSearchHasBeenModifiedText(true);
        }
    }

    function handleInsuranceChange(newInsurance: InsuranceFilter) {
        setInsurance(newInsurance);
        setShowSearchHasBeenModifiedText(true);
    }

    function handleCalendarIdChange(newCalendarId: string) {
        setCalendarId(newCalendarId);
        setShowSearchHasBeenModifiedText(true);
    }

    function handleSearchTagChange(newSearchTag: string) {
        setSearchTag(newSearchTag);
        setShowSearchHasBeenModifiedText(true);
    }

    function handleVisitMotiveIdChange(newVisitMotiveId: string) {
        setVisitMotiveId(newVisitMotiveId);
        setShowSearchHasBeenModifiedText(true);
    }

    function getResultText() {

        if (isLoading) {
            return "Die Patientensuche läuft...";
        }

        if (showSearchInstructionText) {
            return "Setzen Sie Ihre gewünschten Filter und klicken Sie dann anschließend auf 'SUCHEN'."
        }

        if (showSearchHasBeenModifiedText) {
            return <strong>Sie haben Ihre Filter verändert, klicken Sie bitte erneut auf 'SUCHEN'.</strong>
        }

        const days = DateUtils.getDiffInDays(new Date(), fromDate).toFixed(0);

        const visitMotive = visitMotives.find(v => v.id === visitMotiveId);
        const visitMotiveName = visitMotive ? visitMotive.name : "";

        const calendar = calendars.find(c => c.id === calendarId);
        const calendarName = calendar ? calendar.name : "";

        let text = `${patients.length.toLocaleString()} `;

        if (insurance !== InsuranceFilter.all) {
            text += insurance === InsuranceFilter.private ? "privat versicherte " : "gesetzlich versicherte ";
        }


        if(fromDate === undefined || DateUtils.isInFuture(fromDate)){
            text += `Patienten gefunden, die das letzte Mal `;
        } else {
            text += `Patienten gefunden, die das letzte Mal vor ${days} Tagen `;
        }


        if (visitMotiveId === "" || visitMotiveId === "all") {
            text += `einen Termin `;
        } else {
            text += `einen Termin "${visitMotiveName}" `;
        }

        if (calendarId === "" || calendarId === "all") {
            text += `in Ihrer Praxis hatten. `;
        } else {
            text += `im Kalender "${calendarName}" hatten. `;
        }

        if (searchTag !== "") {
            text += `Und mit dem Such Tag '${searchTag}' markiert sind.`
        }

        return text;
    }

    return (
        <Grid container>

            <Grid item xs={12} sm={12}>
                <p>
                    Filtern und selektieren Sie zunächst die Patienten, die Sie erreichen wollen.
                </p>
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
                <DatePicker
                    maxDate={dayjs(Date.now())}
                    label="Letzter Termin vor:"
                    value={dayjs(fromDate)}
                    onChange={(d) => handleFromDateChange(d)}
                />
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
                <FormControl size="small" style={{ minWidth: "200px" }}>
                    <InputLabel id="insurance-select-label">Versicherungsart</InputLabel>
                    <Select
                        labelId="insurance-select-label"
                        label="Versicherungsart"
                        value={insurance}
                        onChange={e => handleInsuranceChange(e.target.value as InsuranceFilter)}
                    >
                        <MenuItem value={InsuranceFilter.all}>Alle</MenuItem>
                        <MenuItem value={InsuranceFilter.private}>Privat</MenuItem>
                        <MenuItem value={InsuranceFilter.statutory}>Gesetzlich</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={4} md={2}>

                {calendars.length === 0 && <span>Filter werden geladen...</span>}

                {calendars.length > 0 && <>
                    <FormControl size="small" style={{ minWidth: "200px" }}>
                        <InputLabel id="calendar-select-label">Kalender</InputLabel>
                        <Select
                            labelId="calendar-select-label"
                            label="Kalender"
                            value={calendarId}
                            displayEmpty
                            onChange={(e) => handleCalendarIdChange(e.target.value)}
                            required
                        >
                            {calendars.map(calendar => <MenuItem key={calendar.id} value={calendar.id}>{calendar.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </>}
            </Grid>
            <Grid item xs={12} sm={4} md={2}>

                {(currentLocation && currentLocation.searchTags.length > 0) && <>
                    <FormControl size="small" style={{ minWidth: "200px" }}>
                        <InputLabel id="tag-select-label">Such Tag</InputLabel>
                        <Select
                            labelId="tag-select-label"
                            label="Such Tag"
                            value={searchTag === "" ? "none" : searchTag}
                            displayEmpty
                            onChange={(e) => handleSearchTagChange(e.target.value === "none" ? "" : e.target.value)}
                            required
                        >
                            <MenuItem value="none">Ohne</MenuItem>
                            {currentLocation.searchTags.map((tag, index) => <MenuItem key={index} value={tag}>{tag.toUpperCase()}</MenuItem>)}
                        </Select>
                    </FormControl>
                </>}
            </Grid>

            <Grid item xs={12} sm={6} style={{ marginTop: "10px" }}>
                {(visitMotives.length === 0 || specialities.length === 0) && <span>Filter werden geladen...</span>}

                {(visitMotives.length > 0 && specialities.length > 0 && !useAllVisitMotives) && <>
                    <VisitMotivePicker
                        selectedVisitMotiveId={visitMotiveId}
                        visitMotives={visitMotives}
                        specialities={specialities}
                        appointmentStart={new Date()}
                        appointmentEnd={new Date()}
                        showDuration={false}
                        onVisitMotiveChange={vMotive => handleVisitMotiveIdChange(vMotive ? vMotive.id : "")}
                        onDurationChange={() => { }}
                        margin="none"
                    />
                </>}

                <FormControlLabel
                    control={<Checkbox checked={useAllVisitMotives} onChange={handleOnUseAllVisitMotivesChange} color="default" />}
                    label="Alle Besuchsgründe auswählen"
                />

            </Grid>

            <Grid item xs={12}>
                <Button disabled={isLoading} variant="contained" color="primary" startIcon={<SearchIcon />} onClick={startPatientSearch}>SUCHEN</Button>

                <p>{getResultText()}</p>
            </Grid>

        </Grid>
    );

}

export default RecallerPatientsFilter;