import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import User from "./../../../models/user";
import UsersService from "../../../services/usersService";

import UserRole, { getUserRoleString } from "../../../models/userRole";
import OpeningHours from "../../../models/openingHours";
import SimpleList from "../../simpleList";
import LoadingPage from "./../administration/loadingPage";
import SaveStatus from "../../../models/saveStatus";
import SaveButton from "../../saveButton";
import LocationsDropDown from "../../locationsDropDown";
import ClientLocation from "../../../models/clientLocation";
import OpeningHoursInput from "../../openingHoursInput";
import BackButton from "../../backButton";
import VpnKeyRoundedIcon from '@mui/icons-material/VpnKeyRounded';
import Room from "../../../models/room";
import RoomsService from "../../../services/roomsService";
import ImageInputCtrl from "../../imageInputCtrl";
import SignatureDialog from "../../../../src/components/dialogs/signatureDialog";
import { GlobalContext } from "../../../GlobalContext";


function UserPage() {

    const navigate = useNavigate();

    const { userId } = useParams();

    const [user, setUser] = useState<User | null>(null);
    const [avatarImage, setAvatarImage] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const {currentClient} = useContext(GlobalContext);
    const {currentUser, setCurrentUser} = useContext(GlobalContext);

    const [saveStatus, setSaveStatus] = useState(SaveStatus.none);

    const [newPassword, setNewPassword] = useState("");

    const [openingHours, setOpeningHours] = useState(new OpeningHours());

    const [selectedTab, setSelectedTab] = useState(0);

    const [isSignatureDialogVisible, setIsSignatureDialogVisible] = useState(false);

    const [rooms, setRooms] = useState<Room[]>([]);

    useEffect(() => {

        getRooms();

    }, [currentUser, user]);

    useEffect(() => {

        if (userId && currentClient) {
            getUser(userId);
        }

        // eslint-disable-next-line
    }, [userId, currentClient]);

    async function getUser(uid: string) {

        const cId = currentClient.id;

        if (uid && uid.toLowerCase() === "create" && cId) {
            const newUser: User = new User();
            newUser.clientId = cId;

            setUser(newUser);
            setOpeningHours(newUser.openingHours.clone());
            setAvatarImage("");

        } else {

            setIsLoading(true);
            const user = await UsersService.getUser(cId, uid);
            setIsLoading(false);

            if (user) {
                setUser(user);
                setOpeningHours(user.openingHours.clone());
                setAvatarImage(user.avatarUrl);
            }
        }
    }

    async function getRooms() {

        if (currentUser) {
            let _rooms = await RoomsService.getRooms(currentUser.clientId, currentUser.locationId, false);
            if (_rooms) {

                _rooms.forEach(r => {
                    r.selected = false;
                });

                // now check all rooms which the user has assigned to
                if (user && user.roomIds) {
                    for (let i = 0; i < user.roomIds.length; i++) {
                        const roomId = user.roomIds[i];

                        _rooms.forEach(r => {
                            if (r.id === roomId) {
                                r.selected = true;
                            }
                        });
                    }
                }

                setRooms(_rooms);
            }
        }
    }


    async function handleSubmit(event) {

        event.preventDefault();

        if (user !== null) {
            setSaveStatus(SaveStatus.isSaving);

            let newUserId: string | null = null;

            user.openingHours = openingHours.clone();

            user.avatarUrl = "";

            user.roomIds = [];
            rooms.forEach(r => {
                if (r.selected) {
                    user.roomIds.push(r.id);
                }
            });

            try {
                newUserId = await UsersService.updateUser(user, currentClient.id, newPassword);

                if (newUserId) {

                    // now check if we have to upload a new avatar image (base64)
                    if (avatarImage && avatarImage.indexOf("data:image") === 0) {
                        await UsersService.updateUserAvatar(avatarImage, newUserId, currentClient.id);
                    }

                    if (userId && userId.toLowerCase() === "create") {
                        setSaveStatus(SaveStatus.none);
                        navigate(`/settings/user/${newUserId}`);

                    } else {
                        setSaveStatus(SaveStatus.saved);
                    }

                    // if we update the loged in user, we need to update the global cached currentUser
                    if (currentUser?.id === newUserId) {
                        setCurrentUser(user);
                    }
                }

            } catch (error) {
                setSaveStatus(SaveStatus.none);
                alert("Der Mitarbeiter konnte nicht gespeichert werden! " + UsersService.getAuthErrorMessage((error as any).message));
            }

        }
    }

    async function onInputChange(event) {
        if (user) {
            const { name, value } = event.target;

            const u = user.clone();

            if (event.target.type === "checkbox") {
                u[name] = event.target.checked;
            } else {
                u[name] = value;
            }

            setUser(u);
        }
    }

    function onLocationChange(newLocation: ClientLocation) {
        if (newLocation && user) {
            const u = user.clone();

            u.locationId = newLocation.id;
            u.locationName = newLocation.name;

            setUser(u);
        }
    }

    async function changePassword() {
        const newValue = prompt("Geben Sie bitte das neue Passwort ein.", "");

        if (newValue && user) {

            try {
                setSaveStatus(SaveStatus.isSaving);

                await UsersService.updateAuthUser(user, newValue);
                setNewPassword(newValue);

                setSaveStatus(SaveStatus.saved);

            } catch (error) {
                setSaveStatus(SaveStatus.none);
                alert("Das Passwort konnte nicht geändert werden! " + UsersService.getAuthErrorMessage((error as any).message));
            }
        }
    }


    function handleLanguagesChange(languages: string[]) {
        if (user && languages) {
            user.languages = languages;
            setUser(user);
        }
    }

    function handleOpeningHoursChange(changedOpeningHours: OpeningHours) {
        if (changedOpeningHours) {
            setOpeningHours(changedOpeningHours.clone());
        }
    }

    function toggleRoomSelection(roomId: string) {

        const _rooms = [...rooms];

        for (let i = 0; i < _rooms.length; i++) {
            if (_rooms[i].id === roomId) {
                _rooms[i].selected = !_rooms[i].selected;
            }
        }

        setRooms(_rooms);
    }

    function handleAvatarChange(newBase64Image: string) {
        setAvatarImage(newBase64Image);
    }

    function handleSignatureChange(newSignature: string) {
        user!.signature = newSignature.length > 7800 ? newSignature : "";
        setIsSignatureDialogVisible(false);
    }

    if (isLoading) return <LoadingPage />;

    if (!user) return (
        <div className="kt-page-content">
            <h2>Mitarbeiter</h2>

            <div>Dieser Mitarbeiter existiert nicht. Bitte überprüfen Sie Ihre URL.</div>
        </div>
    );

    return (
        <Box className="kt-page">

            <SignatureDialog
                visible={isSignatureDialogVisible}
                title="Unterschrift"
                label="Bitte unterschreiben Sie hier"
                onSave={handleSignatureChange}
                onClose={() => setIsSignatureDialogVisible(false)}
            />


            <h2>{(user.firstName || user.lastName) ? `Mitarbeiter - ${user.firstName} ${user.lastName}` : "Mitarbeiter"}</h2>

            <form onSubmit={handleSubmit} autoComplete="turnitoff">

                <Tabs
                    value={selectedTab}
                    onChange={(e, newTab) => setSelectedTab(newTab)}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Allgemein" />
                    <Tab label="Arbeitszeiten" />
                    <Tab label="Räume" />
                </Tabs>



                <div className="kt-form-section" hidden={selectedTab !== 0}>

                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <LocationsDropDown
                                fullWidth={true}
                                clientId={user!.clientId}
                                selectedId={user!.locationId}
                                onChange={onLocationChange}
                                readOnly={user && user.id !== ""}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>

                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="role-select-label">Rolle</InputLabel>
                                <Select
                                    required
                                    name="role"
                                    labelId="role-select-label"
                                    label="Rolle"
                                    id="role-select"
                                    value={user!.role}
                                    onChange={onInputChange}
                                >
                                    <MenuItem value="">Wählen Sie eine Rolle</MenuItem>
                                    <MenuItem value={UserRole.doctor}>{getUserRoleString(UserRole.doctor)}</MenuItem>
                                    <MenuItem value={UserRole.assistant}>{getUserRoleString(UserRole.assistant)}</MenuItem>
                                    <MenuItem value={UserRole.hotline}>{getUserRoleString(UserRole.hotline)}</MenuItem>
                                    <MenuItem value={UserRole.secretary}>{getUserRoleString(UserRole.secretary)}</MenuItem>
                                    <MenuItem value={UserRole.technician}>{getUserRoleString(UserRole.technician)}</MenuItem>
                                    <MenuItem value={UserRole.administrativeAgent}>{getUserRoleString(UserRole.administrativeAgent)}</MenuItem>
                                    <MenuItem value={UserRole.manager}>{getUserRoleString(UserRole.manager)}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>



                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={user.id === currentUser?.id}
                                        checked={user!.isAdmin}
                                        onChange={onInputChange}
                                        name="isAdmin"
                                        color="default"
                                    />}
                                label="Administrator"
                            />
                        </Grid>


                        <Grid item xs={6} md={3}>

                            <FormControl component="fieldset">
                                <RadioGroup row aria-label="position" name="position" defaultValue="left" onChange={onInputChange}>
                                    <FormControlLabel
                                        value="m"
                                        control={<Radio color="default" />}
                                        label="Herr"
                                        labelPlacement="start"
                                        name="gender"
                                        checked={user!.gender === "m"}
                                    />
                                    <FormControlLabel
                                        value="f"
                                        control={<Radio color="default" />}
                                        label="Frau"
                                        labelPlacement="start"
                                        name="gender"
                                        checked={user!.gender === "f"}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <ImageInputCtrl
                                src={avatarImage}
                                width={64}
                                onValueChange={handleAvatarChange}
                            />
                        </Grid>


                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                name="title"
                                label="Titel"
                                value={user!.title}
                                onChange={onInputChange}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                name="firstName"
                                label="Vorname"
                                value={user!.firstName}
                                onChange={onInputChange}
                                required
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                name="lastName"
                                label="Nachname"
                                value={user!.lastName}
                                onChange={onInputChange}
                                required
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                name="abbreviation"
                                label="Kürzel"
                                value={user!.abbreviation}
                                onChange={onInputChange}
                                inputProps={{ maxLength: 20 }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                type="email"
                                fullWidth
                                name="email"
                                label="Email"
                                value={user!.email}
                                onChange={onInputChange}
                                required
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            {userId?.toLowerCase() === "create" &&
                                <TextField
                                    type="password"
                                    fullWidth
                                    name="password"
                                    label="Passwort"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    required
                                />
                            }
                        </Grid>


                        <Grid item xs={12} sm={6}>
                            <h4>Sprachen</h4>
                            <SimpleList listItems={user!.languages} onListItemsChange={handleLanguagesChange} />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <h4>Profil</h4>
                            <TextField
                                rows={10}
                                fullWidth
                                multiline
                                name="profile"
                                label="Profil"
                                value={user!.profile}
                                onChange={onInputChange}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <h4>Unterschrift</h4>
                            <p>Hiermit willige Ich ein, meine persönlichen Mitarbeiterdaten lt. Bundesdatenschutzgesetz (BDSG) zum Betrieb der Pickadoc Software zur Speicherung und Durchführung praxis- und terminbezogener Prozesse bereitzustellen.</p>
                            <p>Unterschriften von Behandlern werden bei Notwendigkeit einer schriftlichen Patientenaufklärung automatisch in Patientendokumenten zugestellt und gespeichert.</p>
                            <div className="kt-signature" onClick={() => setIsSignatureDialogVisible(true)}>
                                {user!.signature !== "" ?
                                    <img src={user!.signature} alt="Unterschrift" />
                                    :
                                    <React.Fragment>
                                        <i className="fal fa-hand-point-up" />
                                        <span>Klicken Sie hier zum Unterschreiben.</span>
                                    </React.Fragment>
                                }

                            </div>
                        </Grid>

                    </Grid>
                </div>



                <div className="kt-form-section" hidden={selectedTab !== 1}>
                    <Grid container>

                        <Grid item xs={12}>
                            <h4>Arbeitszeiten</h4>
                            <OpeningHoursInput
                                showEnabledCheckbox={true}
                                openingHours={openingHours}
                                onInputChange={handleOpeningHoursChange}
                            />
                        </Grid>

                    </Grid>
                </div>


                <div className="kt-form-section" hidden={selectedTab !== 2}>
                    <Grid container>

                        <Grid item xs={12}>
                            <h4>Räume</h4>
                        </Grid>

                        {rooms.map(room =>
                            <Grid key={room.id} item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={room.selected}
                                            onChange={(e) => toggleRoomSelection(room.id)}
                                        />
                                    }
                                    label={room.name}
                                />
                            </Grid>
                        )}


                    </Grid>
                </div>



                <div className="kt-page-footer">
                    <BackButton />

                    {userId?.toLowerCase() !== "create" &&
                        <Button
                            variant="contained"
                            startIcon={<VpnKeyRoundedIcon />}
                            onClick={changePassword}
                        >
                            NEUES PASSWORT
                        </Button>
                    }

                    <SaveButton saveStatus={saveStatus} />

                </div>


            </form>
        </Box>
    );
}

export default UserPage;