import FormItem, { Align, FormItemEnum, FormItemType } from "./formItem";

export class LineFormItem extends FormItem {

    lineWidth: number = 100; // in %
    lineThickness: number = 3; //px
    lineAlign: Align = Align.center;
    spaceBefore: number = 0; //px
    spaceAfter: number = 0; // px
    lineBorderColor: string = "#628aa8";
    lineBorderStyle: string = "solid";


    constructor(rowId: string) {
        super(rowId);

        this.type = FormItemEnum.line;
    }

    clone(): FormItemType {

        const c = new LineFormItem(this.parentId);
        c.fromObject(null, this.toJSON(true));

        c.lineWidth = this.lineWidth;
        c.lineThickness = this.lineThickness;
        c.lineAlign = this.lineAlign;
        c.spaceBefore = this.spaceBefore;
        c.spaceAfter = this.spaceAfter;
        c.lineBorderColor = this.lineBorderColor;
        c.lineBorderStyle = this.lineBorderStyle;

        return c;
    }

    toJSON(includeUserInputs: boolean): object {
        const obj: any = super.toJSON(includeUserInputs);

        obj.lineWidth = this.lineWidth;
        obj.lineThickness = this.lineThickness;
        obj.lineAlign = this.lineAlign;
        obj.spaceBefore = this.spaceBefore;
        obj.spaceAfter = this.spaceAfter;
        obj.lineBorderColor = this.lineBorderColor;
        obj.lineBorderStyle = this.lineBorderStyle;

        return obj;
    }

    fromObject(id: string|null, o:any){
        super.fromObject(id, o);

        this.lineWidth = o.lineWidth ? parseInt(o.lineWidth) : 100;
        this.lineThickness = o.lineThickness ? parseInt(o.lineThickness) : 3;
        this.lineAlign = o.lineAlign !== undefined ? o.lineAlign : Align.center;
        this.spaceBefore = o.spaceBefore ? parseInt(o.spaceBefore) : 0;
        this.spaceAfter = o.spaceAfter ? parseInt(o.spaceAfter) : 0;
        this.lineBorderColor = o.lineBorderColor ?? "#628aa8";
        this.lineBorderStyle = o.lineBorderStyle ?? "solid";
    }

}


export default LineFormItem;