import DateUtils from "../../utils/dateUtils";
import Utils from "../../utils/utils";


export type VideoGroup = {
    id: string,
    name: string,
    videos: ClonRVideo[],
    latestVideoDate: Date
}


export default class ClonRVideo {

    id: string = "";
    groupId: string = "";

    userId: string = "";

    name: string = "";

    avatarId: string = "";
    voiceId: string = "";
    scriptId: string = "";
    language: string = "";

    videoUrl: string = "";
    videoFileExtension: string = "";
    thumbnailUrl: string = "";
    isHQ: boolean = false; // High Quality

    length: number = 0; // video length in seconds
    credits: number = 0; // credits used to generate this video

    status: "none" | "error" | "inprogress" | "finished" = "none"
    progress: number = 0; // in percent

    createdAt: Date;
    createdBy: string = ""; // userId

    isDeleted: boolean = false;
    deletedAt: Date | null = null;
    deletedBy: string = ""; // userId

    emailSendAt: Date | null = null;
    emailSendTo: string = "";

    engine: string = "wav2lip";

    constructor() {
        this.id = Utils.getUUID();
        this.createdAt = new Date();
    }


    clone(): ClonRVideo {
        const c = new ClonRVideo();
        c.fromObject(this.id, this.toJSON());

        return c;
    }

    toJSON(): object {
        return {
            id: this.id,
            groupId: this.getGroupId(),

            userId: this.userId,
            name: this.name,

            avatarId: this.avatarId,
            voiceId: this.voiceId,
            scriptId: this.scriptId,
            language: this.language,

            videoUrl: this.videoUrl,
            videoFileExtension: this.videoFileExtension,
            thumbnailUrl: this.thumbnailUrl,
            isHQ: this.isHQ,

            length: this.length,
            credits: this.credits,

            status: this.status,
            progress: this.progress,

            createdAt: this.createdAt,
            createdBy: this.createdBy,

            isDeleted: this.isDeleted,
            deletedAt: this.deletedAt,
            deletedBy: this.deletedBy,

            emailSendAt: this.emailSendAt,
            emailSendTo: this.emailSendTo,

            engine: this.engine

        };
    }

    fromObject(id: string | null, o: any) {

        this.id = id !== null ? id : o.id;

        this.userId = o.userId ?? "";

        this.name = o.name ?? "";

        this.avatarId = o.avatarId ?? "";
        this.voiceId = o.voiceId ?? "";
        this.scriptId = o.scriptId ?? "";
        this.language = o.language ?? "";

        this.videoUrl = o.videoUrl ?? "";
        this.videoFileExtension = o.videoFileExtension ?? "";
        this.thumbnailUrl = o.thumbnailUrl ?? "";
        this.isHQ = o.isHQ === true;

        this.length = o.length ?? 0;
        this.credits = o.credits ?? 0;

        this.status = o.status ?? "none";
        this.progress = o.progress ?? 0;

        this.createdAt = DateUtils.getDate(o.createdAt) ?? new Date();
        this.createdBy = o.createdBy ?? "";

        this.isDeleted = o.isDeleted === true;
        this.deletedAt = o.deletedAt ? DateUtils.getDate(o.deletedAt) : null;
        this.deletedBy = o.deletedBy ?? "";

        this.emailSendAt = o.emailSendAt ? DateUtils.getDate(o.emailSendAt) : null;
        this.emailSendTo = o.emailSendTo ?? "";

        this.engine = o.engine ?? "wav2lip";

        this.groupId = o.groupId ?? this.getGroupId();
    }

    getEngineAbbreviation = () => {
        switch (this.engine) {
            case "synclabs":
                return "SL";

            case "video-retalking":
                return "VR"

            default:
                return "WL";
        }
    }

    // we can group a video by the script and avatar
    // but not by voice, because sometimes you upload an audio file instead of using TTS with a voice
    getGroupId = () => {
        return this.scriptId + this.avatarId;
    }

    // the video name is build by `${clonRAvatar.name} - ${clonRVoice.name} - ${clonRScript.name} - ${targetLanguage}`;
    // for the group name we need to remove the language part at the end
    getGroupName = () => {
        const names = this.name.split("-");
        names.pop();
        return names.join("-").trim();
    }

    // with this ID we can check, if we already have created a video in that language, script and avatar
    getVideoLanguageId = () => {
        return this.getGroupId() + this.language;
    }
}