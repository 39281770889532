import React, { useState } from 'react';
import FileUploadsService from '../services/fileUploadsService';
import BackupIcon from '@mui/icons-material/Backup';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';


interface Props {
    disabled?: boolean;
    id: string;
    src: string;
    alt?: string;
    width: number;
    clientId: string;
    locationId: string;
    folder: string;
    onValueChange: (url: string) => void
}

const ImageUploadCtrl: React.FC<Props> = ({ disabled, id, src, clientId, locationId, folder, alt, width, onValueChange }) => {

    const [isUploading, setIsUploading] = useState(false);

    async function onFileChange(e) {
        if (e.target.files.length > 0) {

            setIsUploading(true);

            const url = await FileUploadsService.uploadFileToLocation(e.target.files[0], clientId, locationId, folder);

            setIsUploading(false);

            if (url) {
                onValueChange(url);
            }

        }
    }

    function getIcon(): React.ReactNode {
        return isUploading ? <CircularProgress size={20} /> : <BackupIcon />;
    }


    return (
        <div className="kt-image-upload-ctrl">
            <img
                src={src}
                alt={alt}
                width={width}
            />

            <input
                type="file"
                onChange={onFileChange}
                accept="image/*"
                id={id}
                className="kt-hidden"
            />

            {!disabled &&
                <div>
                    <label htmlFor={id}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            component="span"
                            disabled={isUploading}
                            startIcon={getIcon()}
                        >
                            {isUploading ? "Bild wird hochgeladen" : "Bild hochladen"}
                        </Button>
                    </label>
                    <br />
                    <br />
                        <Button
                            variant="outlined"
                            color="secondary"
                            disabled={src === ""}
                            startIcon={<DeleteIcon />}
                            onClick={()=>onValueChange("")}
                        >
                            Bild entfernen
                        </Button>
                </div>
            }
        </div>
    );
}

export default ImageUploadCtrl;