
import FormItem, { Align, FormItemEnum, FormItemType } from "./formItem";


export class ImageFormItem extends FormItem {

    imageWidth: number = 200; // in pixel
    imageSource: string = "";
    imageAlign: Align = Align.center;

    imageSourceModified: string = ""; // image source of the modified image

    allowDrawing: boolean = false;

    constructor(rowId: string) {
        super(rowId);

        this.type = FormItemEnum.image;
    }

    clone(): FormItemType {

        const c = new ImageFormItem(this.parentId);
        c.fromObject(null, this.toJSON(true));

        return c;
    }

    toJSON(includeUserInputs: boolean): object {
        const obj: any = super.toJSON(includeUserInputs);

        obj.imageWidth = this.imageWidth;
        obj.imageSource = this.imageSource;
        obj.imageAlign = this.imageAlign;

        obj.allowDrawing = this.allowDrawing;

        if(includeUserInputs){
            obj.imageSourceModified = this.imageSourceModified;
        }

        return obj;
    }

    fromObject(id: string|null, o:any){
        super.fromObject(id, o);

        this.imageWidth = o.imageWidth ? parseInt(o.imageWidth) : 200;
        this.imageSource = o.imageSource ?? "";
        this.imageAlign = o.imageAlign !== undefined ? o.imageAlign : Align.center;

        this.allowDrawing = o.allowDrawing === true;

        this.imageSourceModified = o.imageSourceModified ?? "";
    }


}


export default ImageFormItem;