
export class ChainItem {
    id: string = "";
    visitMotiveId: string = "";
    specialityId: string = "";
    name: string = "";
    color: string = "";
    readOnly: boolean = false;
    
    clone(): ChainItem {
        const c = new ChainItem();

        c.id = this.id;
        c.visitMotiveId = this.visitMotiveId;
        c.specialityId = this.specialityId;
        c.name = this.name;
        c.color = this.color;
        c.readOnly = this.readOnly;
        
        return c;
    }

    toJSON(): object {
        return {
            id: this.id,
            visitMotiveId: this.visitMotiveId,
            specialityId: this.specialityId,
            name: this.name
        };
    }

    fromObject(id:string|null, o:any) {

        this.id = id !== null ? id : o.id;
        this.visitMotiveId = o.visitMotiveId ? o.visitMotiveId : "";
        this.specialityId = o.specialityId ? o.specialityId : "";
        this.name = o.name ? o.name : "";
    }
}


export class ChainColumn {
    id: string = "";
    timeTillNextItem = "";
    rows: ChainItem[] = [];

    clone(): ChainColumn {
        const c = new ChainColumn();

        c.id = this.id;
        c.timeTillNextItem = this.timeTillNextItem;

        c.rows = [];

        for (let i = 0; i < this.rows.length; i++) {
            const row = this.rows[i];
            c.rows.push(row.clone());
        }

        return c;
    }

    toJSON(): object {
        const obj = {
            id: this.id,
            timeTillNextItem: this.timeTillNextItem,
            rows: [] as object[]
        };

        for (let i = 0; i < this.rows.length; i++) {
            const row = this.rows[i];
            obj.rows.push(row.toJSON());
        }
        return obj;
    }

    fromObject(id:string|null, o:any) {

        this.id = id !== null ? id : o.id;
        this.timeTillNextItem = o.timeTillNextItem ? o.timeTillNextItem : "";

        this.rows = [];
        if(o.rows){
            for (let i = 0; i < o.rows.length; i++) {
                const rowObj = o.rows[i];
                
                const newRow = new ChainItem();
                newRow.fromObject(null, rowObj);

                this.rows.push(newRow);
            }
        }
    }
}

class VisitMotiveChain {
    id: string = "";
    appointmentId: string = "";
    patientId: string = "";
    columns: ChainColumn[] = [];

    clone(): VisitMotiveChain {
        const v = new VisitMotiveChain();

        v.id = this.id;
        v.appointmentId = this.appointmentId;
        v.patientId = this.patientId;
        v.columns = [];
        
        for (let c = 0; c < this.columns.length; c++) {
            const column = this.columns[c];

            v.columns.push(column.clone());
        }

        return v;
    }

    toJSON(): object {
        const obj = {
            id: this.id,
            appointmentId: this.appointmentId,
            patientId: this.patientId,
            columns: [] as any[]
        };

        for (let c = 0; c < this.columns.length; c++) {

            const column = this.columns[c];
            
            obj.columns.push(column.toJSON());
        }

        return obj;

    }

    fromObject(id:string|null, o:any) {

        this.id = id !== null ? id : o.id;

        this.appointmentId = o.appointmentId ? o.appointmentId : "";
        this.patientId = o.patientId ? o.patientId : "";
        this.columns = [];

        if(o.columns) {
            
            for (let c = 0; c < o.columns.length; c++) {
                const columnObj = o.columns[c];

                const newColumn = new ChainColumn();
                newColumn.fromObject(null, columnObj);
                this.columns.push(newColumn);
            }
        }
               
    }

    // this functions compares the columns with the columns of the parameter newChain
    // returns: an id list of deleted chain items
    getDeletedColumns(newChain: VisitMotiveChain): string[] {
        const ids = [] as string[];

        for (let i = 1; i < this.columns.length; i++) {
            const oldColumn = this.columns[i];
            
            let foundColumn = false;

            for (let newIndex = 1; newIndex < newChain.columns.length; newIndex++) {
                const newColumn = newChain.columns[newIndex];
                if(oldColumn.id === newColumn.id){
                    foundColumn = true;
                    break;
                }                
            }

            if(!foundColumn){
                ids.push(oldColumn.id);
            }
        }

        return ids;
    }

    
};



export default VisitMotiveChain;