
class Device {
    id: string = "";
    name: string = "";
    selected: boolean = true;
    cardinality: number = 0;
    __reorder__: string = "";

    clone(): Device {
        const s = new Device();

        s.id = this.id;
        s.name = this.name;
       
        s.selected = this.selected;
        s.cardinality = this.cardinality;
        s.__reorder__ = this.__reorder__;
        
        return s;
    }

    toJSON(): object {
        return {
            id: this.id,
            name: this.name,
            cardinality: this.cardinality
        };
    }

    fromObject(id: string|null, o:any){

        this.id = id !== null ? id : o.id;

        this.name = o.name;

        this.cardinality = o.cardinality ?? 0;
        this.__reorder__ = o.name;

    }

    static cloneList(devices: Device[]): Device[] {
        const clonedDevices: Device[] = [];

        devices.forEach(dev => {
            const clonedDev = dev.clone();
            clonedDevices.push(clonedDev);
        });

        return clonedDevices;
    }
};

export default Device;