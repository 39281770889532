import { useState, useContext } from 'react';

import { Navigate } from "react-router-dom";

import firebaseApp from "../database";
import { GlobalContext } from '../../GlobalContext';
import { t } from 'i18next';


function PasswordRecoveryPage() {

    const [loginEmail, setLoginEmail] = useState("");
    const [mailHasBeenSent, setEmailHasBeenSent] = useState(false);

    const { currentUser } = useContext(GlobalContext);

    function onLoginFormSubmit(event) {
        event.preventDefault();

        firebaseApp.auth().sendPasswordResetEmail(loginEmail, {url: "https://docgenda.web.app/login"})
        .then(()=>{
            setEmailHasBeenSent(true);
        })
        .catch((error) => {
            var errorCode = error.code;

            switch (errorCode) {
                case "auth/invalid-email":
                case "auth/user-not-found":
                    alert("Die von Ihnen eingegebene E-Mail-Adresse ist uns nicht bekannt. Bitte versuchen Sie es erneut");
                    break;

                case "auth/user-disabled":
                    alert("Dieser Account ist gesperrt, bitte wenden Sie Sich telefonisch an uns.");
                    break;

                default:
                    alert(error.message);
                    break;
            }

        });
    }

    if(currentUser) {
        return <Navigate replace to="/" />
    }

    return <div className="kt-login-page">

        <form name="login" onSubmit={onLoginFormSubmit}>

            <h1>Pickadoc</h1>

            <img src="/images/logo.svg" alt="logo" className='kt-logo' />

            {mailHasBeenSent ?
                <div className="kt-recovery-page-content">
                    <h2>{t("pages.passwordRecoveryPage.thankYou")}</h2>
                    <div>{t("pages.passwordRecoveryPage.youWillReceiveAnEmail")}</div>
                </div>
                :
                <div className="kt-recovery-page-content">
                    <h2>{t("pages.passwordRecoveryPage.title")}</h2>
                    <p>{t("pages.passwordRecoveryPage.description")}</p>
                    <input type="email" placeholder={t("pages.passwordRecoveryPage.email")} required value={loginEmail} onChange={e => setLoginEmail(e.target.value)}/>
                    <br/>
                    <input className="kt-btn-big kt-uppercase" type="submit" value={t("pages.passwordRecoveryPage.reset")} />
                </div>
            }

            <div className="kt-history-back-link" onClick={e => window.history.back()}><i className="far fa-angle-left"></i> {t("components.buttons.back")}</div>



        </form>

    </div>;
}

export default PasswordRecoveryPage;