import DateUtils from "../../utils/dateUtils";
import Utils from "../../utils/utils";

export default class TextToSpeech {

    id: string = "";

    clientId: string = "";

    voiceId: string = "";
    scriptId: string = "";
    language: string = "";

    audioUrl: string = "";

    length: number = 0; // audio length in seconds

    status: "none" | "error" | "inprogress" | "finished" | "fromcache" = "none"

    createdAt: Date;

    constructor() {
        this.id = Utils.getUUID();
        this.createdAt = new Date();
    }


    clone(): TextToSpeech {
        const c = new TextToSpeech();
        c.fromObject(this.id, this.toJSON());

        return c;
    }

    toJSON(): object {
        return {
            id: this.id,

            clientId: this.clientId,

            voiceId: this.voiceId,
            scriptId: this.scriptId,
            language: this.language,

            audioUrl: this.audioUrl,

            length: this.length,

            status: this.status,

            createdAt: this.createdAt
        };
    }

    fromObject(id: string | null, o: any) {

        this.id = id !== null ? id : o.id;
      
        this.clientId = o.clientId ?? "";

        this.voiceId = o.voiceId ?? "";
        this.scriptId = o.scriptId ?? "";
        this.language = o.language ?? "";

        this.audioUrl = o.audioUrl ?? "";
        
        this.length = o.length ?? 0;

        this.status = o.status ?? "none";

        this.createdAt = DateUtils.getDate(o.createdAt) ?? new Date();
    }

 
}