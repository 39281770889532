import React, { useEffect, useState } from "react";

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { VideoGroup } from "../shared/src/models/clonR/clonRVideo";
import ClonRVideosService from "../services/clonRVideosService";



interface Props {
    fullWidth?: boolean;
    readOnly?: boolean;
    videoGroupId: string;
    clientId: string;
    onChange: (videoGroupId: string, videoThumbnailUrl: string) => void;
}

const VideoGroupSelectorCtrl: React.FC<Props> = ({ fullWidth, readOnly, videoGroupId, clientId, onChange }) => {

    const [videoGroups, setVideoGroups] = useState<VideoGroup[]>([]);

    useEffect(() => {

        async function getVideos() {
            const _videoGroups = await ClonRVideosService.getVideoGroups(clientId);
            
            setVideoGroups(_videoGroups);
        }

        if (clientId) {
            getVideos();
        }

    }, [clientId]);

    

    const handleChange = (event: SelectChangeEvent) => {
        const newVideoGroupId = event.target.value;
        const videoThumbnailUrl = videoGroups.find(x => x.id === newVideoGroupId)?.videos[0]?.thumbnailUrl || "";
        onChange(newVideoGroupId, videoThumbnailUrl);
    };


    return (


        <FormControl fullWidth={fullWidth === true} className="kt-video-selector-ctrl">
            <InputLabel id="videoId-select-label">Video</InputLabel>
            <Select                
                disabled={readOnly === true}
                name="videoId"
                labelId="videoId-select-label"
                label="Video"
                id="videoId-select"
                value={videoGroupId}
                onChange={handleChange}
            >
                <MenuItem value=""><strong>Wählen Sie ein Video aus</strong></MenuItem>

                {videoGroups.map((group) =>
                    <MenuItem key={group.id} value={group.id}>
                        <div className="kt-video-selector-ctrl-popup kt-video">
                            <div className="kt-video-thumbnail" style={{ backgroundImage: `url("${group.videos[0]?.thumbnailUrl}")` }}></div>
                            <div>{group.name} -
                                <span style={{ fontSize: "12px" }}>{group.videos.map(video =>
                                    <span key={video.id} className='kt-language'>{video.language.toUpperCase()}</span>
                                )
                                }</span>
                            </div>
                        </div>
                    </MenuItem>
                )}

            </Select>
        </FormControl>

    );
}

export default VideoGroupSelectorCtrl;