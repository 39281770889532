import { Link } from "react-router-dom";
import { Campaign, CampaignStatus } from '../../../shared/src/models/campaign/campaign';
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import DateUtils from "../../../shared/src/utils/dateUtils";
import { Doughnut } from "react-chartjs-2";
import Button from "@mui/material/Button";


interface Props {
    campaign: Campaign,
    showActionButtons: boolean,
    onDeleteCampaignClick?: (c: Campaign) => Promise<void>
}


const CampaignDetailsCard: React.FC<Props> = ({ campaign, showActionButtons, onDeleteCampaignClick }) => {

    function renderCampaignName(campaign: Campaign) {

        if (campaign.status === CampaignStatus.created) {
            return <><span>{campaign.name}</span> - <span style={{ color: 'orange' }}>ENTWURF</span></>
        }

        if (campaign.status === CampaignStatus.started) {
            return <><span>{campaign.name}</span> - <span style={{ color: 'red' }}>GESTARTET</span></>
        }

        if (campaign.status === CampaignStatus.completed) {
            return <><span>{campaign.name}</span> - <span style={{ color: 'green' }}>BEENDET</span></>
        }

        return <span>{campaign.name}</span>
    }

    return <Card variant="outlined">
        <CardContent>
            <Typography variant="h6">
                {renderCampaignName(campaign)}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                <i className={campaign.getIconClassNameString()}></i> - {DateUtils.getDateString(campaign.createdAt)}
            </Typography>

            <div className="kt-two-columns">
                <table>
                    <tbody>
                        <tr>
                            <td><i className="fal fa-user"></i></td>
                            <td>{campaign.patientsSelected}</td>
                            <td>{campaign.patientsSelected === 1 ? "Patient " : `Patienten`}</td>
                        </tr>
                        <tr>
                            <td><i className="fal fa-phone"></i></td>
                            <td>{campaign.patientsReached}</td>
                            <td>Erreicht</td>
                        </tr>
                        <tr>
                            <td><i className="fal fa-calendar-alt"></i></td>
                            <td>{campaign.conversions}</td>
                            <td>{campaign.conversions === 1 ? "Termin erfolgt " : `Termine erfolgt`}</td>
                        </tr>
                    </tbody>
                </table>

                <Box sx={{
                    width: 120,
                    height: 120
                }} style={{ alignSelf: "flex-end" }}>
                    <Doughnut
                        width={120}
                        height={120}
                        data={{
                            datasets: [{
                                labels: ["Termine", "dfds"],
                                data: [campaign.conversions, campaign.patientsSelected - campaign.conversions] as number[],
                                backgroundColor: [
                                    'rgba(216, 58, 195, 0.2)',
                                    'rgba(36, 55, 78, 0.05)'
                                ],
                                borderColor: [
                                    'rgba(216, 58, 195, 1)',
                                    'rgba(36, 55, 78, 1)'
                                ],
                                borderWidth: 0
                            }]
                        }}
                        options={{
                            maintainAspectRatio: false,
                            tooltips: {
                                callbacks: {
                                    label: function (tooltipItem, data) {
                                        if (data && data.datasets) {
                                            const dataset = data.datasets[tooltipItem.datasetIndex];
                                            const total = campaign.patientsSelected;
                                            const currentValue = dataset.data[tooltipItem.index];
                                            const percentage = total > 0 ? (currentValue / total * 100).toFixed(1) : 0;
                                            return `${percentage}% ${tooltipItem.index === 0 ? "Erfolg" : ""}`;
                                        }
                                    }
                                }
                            }
                        }} />
                </Box>
            </div>

        </CardContent>

        {showActionButtons && <CardActions>
            <Button
                size="small"
                onClick={e => onDeleteCampaignClick && onDeleteCampaignClick(campaign)}
            >
                <i className="fal fa-times-circle"></i>&nbsp;Löschen
            </Button>

            {campaign.status === CampaignStatus.created &&
                <Button
                    size="small"
                    component={Link}
                    to={`/createcampaign/${campaign.id}`}
                >
                    <i className="fal fa-pen"></i>&nbsp;Bearbeiten
                </Button>}

            {(campaign.status === CampaignStatus.started || campaign.status === CampaignStatus.completed) &&
                <Button
                    size="small"
                    component={Link}
                    to={`/campaign/${campaign.id}`}
                >
                    <i className="fal fa-info-circle"></i>&nbsp;Details
                </Button>}
        </CardActions>}
    </Card>;
}

export default CampaignDetailsCard;