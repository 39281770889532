import { useEffect, useState, useContext, useRef } from 'react';

import { isMobileDevice } from "../../utils";

import ToDosService from "../../services/toDosService";
import DateUtils from '../../../src/shared/src/utils/dateUtils';
import { GlobalContext } from '../../GlobalContext';
import ToDo from '../../shared/src/models/todo';
import { t } from 'i18next';
import Utils from '../../shared/src/utils/utils';


const DashboardPage: React.FC = () => {

    const [dateTimeString, setDateTimeString] = useState<string>("");

    const { currentClient } = useContext(GlobalContext);
    const { currentUser } = useContext(GlobalContext);
    const { toDos, setToDos } = useContext(GlobalContext);
    const { setShowToDosNotification } = useContext(GlobalContext);
    

    useEffect(() => {
        handleToDosChange(toDos);
    }, [toDos]);

    useEffect(() => {
        setShowToDosNotification(false);

        const interval = setInterval(() => {
            updateDateTimeString();
        }, 1000);

        return () => {
            clearInterval(interval);
            setShowToDosNotification(false);
        }

    }, []);


    function updateDateTimeString() {
        const now = new Date();
        const weekday = now.toLocaleString("default", { weekday: "long" })
        const _dateTimeString = isMobileDevice() ? `${DateUtils.getDateString(now)} - ${DateUtils.getTimeString(now)}` : `${weekday} ${DateUtils.getDateString(now)} - ${DateUtils.getTimeString(now)}`
        setDateTimeString(_dateTimeString);
    }

    


    function handleToDosChange(result: ToDo[]) {
        setToDos(result);
    }



    function setToDoStatus(toDo: ToDo, isChecked: boolean) {
        toDo.done = isChecked;

        if (currentUser) {
            ToDosService.updateToDo(toDo, currentClient.id, currentUser.locationId);
        }
    }

    async function createToDo() {

        let name: string | null;

        name = prompt(t("pages.todosPage.describeYourTask"));

        if (name && currentUser) {

            // allow only 200 characters
            name = name.substring(0, 200);

            const newToDo = new ToDo();
            newToDo.id = Utils.getUUID();
            newToDo.name = name;

            const playedIds = new Set(JSON.parse(localStorage.getItem('playedIds') || '[]'));
            playedIds.add(newToDo.id);
            localStorage.setItem('playedIds', JSON.stringify(Array.from(playedIds)));

            console.log("creating new ToDo: " + newToDo.name);
            await ToDosService.updateToDo(newToDo, currentClient.id, currentUser.locationId);
            
        }
    }

    function editToDo(toDo: ToDo) {
        let name: string | null;

        name = prompt(t("pages.todosPage.editTask"), toDo.name);

        if (name && currentUser) {
            const newToDo = toDo.clone();
            newToDo.name = name;

            ToDosService.updateToDo(newToDo, currentClient.id, currentUser.locationId);
        }
    }

    function deleteToDo(toDo: ToDo) {
        const realyDelete = window.confirm(t("pages.todosPage.reallyDeleteTask"));

        if (realyDelete && currentUser) {
            ToDosService.deleteToDo(toDo, currentClient.id, currentUser.locationId);
        }
    }


    function renderToDos() {

        if (toDos.length === 0) {
            return <div>{t("pages.todosPage.noOpenTasks")}</div>
        }

        return <table className="kt-waiting-room">
            <tbody>
                {toDos.map((toDo) =>
                    <tr key={toDo.id} className={toDo.done ? "kt-line-through" : ""}>
                        <td style={{ width: "30px" }}><input type="checkbox" checked={toDo.done} onChange={(e) => setToDoStatus(toDo, e.target.checked)} /></td>
                        <td>{toDo.name.indexOf("Notfall:") !== -1 && <i className='fa fa-exclamation-triangle kt-red-color' style={{ marginRight: "3px" }}></i>}{toDo.name}</td>
                        <td>{DateUtils.getDateTimeString(toDo.createdAt)}</td>
                        <td className="kt-btn-table-column" style={{ width: "20px", textAlign: "right" }} onClick={(e) => editToDo(toDo)}><i className="fa fa-pencil"></i></td>
                        <td className="kt-btn-table-column" style={{ width: "20px", textAlign: "right" }} onClick={(e) => deleteToDo(toDo)}><i className="fa fa-trash"></i></td>
                    </tr>
                )}
            </tbody>
        </table>
    }


    return (
        <div className="kt-dashboard-page kt-grey-page">
            <h2>{t("pages.todosPage.title")} - {dateTimeString}</h2>

            <div className="card-container">

                <div className="card full card-todo">
                    <div className="card-head">
                        <span><i className="fal fa-clipboard-list-check"></i>{`AUFGABEN: ${toDos.length}`}</span>
                        <div className="kt-button-wrapper">
                            <div><button className="kt-btn kt-btn-tiny" onClick={createToDo}><i className="fal fa-plus-circle"></i> {t("components.buttons.create")}</button></div>
                        </div>
                    </div>

                    <div className="card-body">
                        {renderToDos()}
                    </div>
                </div>


            </div>
        </div>
    );
}

export default DashboardPage;