import firebaseApp from "../components/database";
import ImportResult from "../shared/src/models/importResult";
import { logDbReadOperations } from "../utils";


const db = firebaseApp.firestore();

const ImportResultsService = {


    startListenForImportResult(clientId: string, locationId: string, importId: string, changeCallback: (importResult: ImportResult) => void): () => void {

        if (!importId || !clientId || !locationId) {
            return () => {
                console.log("error in startListenForImportResult: empty paramter");
            };
        }

        console.log(`startListenForImportResult importId: ${importId}`);

        return db.collection("clients")
            .doc(clientId)
            .collection("locations")
            .doc(locationId)
            .collection("importResults")
            .doc(importId)

            .onSnapshot(function (doc) {

                if (doc && doc.data()) {
                    const importResult = new ImportResult();

                    importResult.fromObject(doc.data());

                    logDbReadOperations("startListenForImportResult", 1);

                    changeCallback(importResult);
                }
            });
    }

}

export default ImportResultsService;