import React, { useEffect, useContext, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

import PDocument from '../../../shared/src/models/formEditor/pdocument';

import PatientDocsService from '../../../services/patientDocsService';
import PDFService from '../../../services/pdfService';

import Patient from "../../../models/patient";
import DateUtils from '../../../shared/src/utils/dateUtils';
import Utils from "../../../shared/src/utils/utils";
import { GlobalContext } from '../../../GlobalContext';
import { downloadFile, printPDF } from '../../../utils';
import { Tooltip } from '@mui/material';
import EmailDialog from '../emailDialog';
import Email from '../../../shared/src/models/email';
import { DataGrid, deDE } from '@mui/x-data-grid';

interface Props {
    patient: Patient
}

const SignedDocumentsTab: React.FC<Props> = ({ patient }) => {

    const { currentUser } = useContext(GlobalContext);
    const { currentClient } = useContext(GlobalContext);
    const { currentLocation } = useContext(GlobalContext);

    const [signedDocuments, setSignedDocuments] = useState<PDocument[]>([]);

    const [isEmailDialogVisible, setIsEmailDialogVisible] = useState(false);

    const [email, setEmail] = useState(new Email());

    useEffect(() => {
        if (patient && patient.id) {
            updateTabData();
        }
    }, [patient]);


    async function updateTabData() {

        if (currentUser) {

            console.log("update document tab data...");

            const _signedDocs: PDocument[] = [];

            // get all patient documents
            const _patDocs = await PatientDocsService.getDocuments(patient.id, currentUser.clientId, currentUser.locationId);
            if (_patDocs) {

                let sortIndex = 0;

                for (let i = 0; i < _patDocs.length; i++) {
                    const doc = _patDocs[i];

                    if (doc.status === "signed" && doc.pdfCreatedAt) {
                        _signedDocs.push(doc);

                    }
                }

                _signedDocs.sort((a, b) => {
                    return b.pdfCreatedAt!.getTime() - a!.pdfCreatedAt!.getTime();
                });

            }

            setSignedDocuments(_signedDocs);
        }
    }


    async function openPatientPDF(document: PDocument) {

        if (currentUser) {
            let downloadUrl = await PDFService.getPatientPDF(currentUser.clientId, currentUser.locationId, patient.id, document.id);

            if (downloadUrl) {
                downloadUrl += "&rnd=" + Math.random();
                const newWindow = window.open(downloadUrl, '_blank', 'noopener,noreferrer');
                if (newWindow) newWindow.opener = null;
            }
        }
    }

    async function downloadPatientPDF(document: PDocument) {

        if (currentUser) {
            let downloadUrl = await PDFService.getPatientPDF(currentUser.clientId, currentUser.locationId, patient.id, document.id);

            if (downloadUrl) {
                downloadFile(downloadUrl, document.name + ".pdf");
            }
        }
    }

    async function printDocument(document: PDocument) {

        if (currentUser) {
            let downloadUrl = await PDFService.getPatientPDF(currentUser.clientId, currentUser.locationId, patient.id, document.id);

            if (downloadUrl) {
                printPDF(downloadUrl, document.name + ".pdf");
            }
        }
    }


    const sendPatientPDFByEmail = async (document: PDocument) => {
        if (currentUser) {
            let downloadUrl = await PDFService.getPatientPDF(currentUser.clientId, currentUser.locationId, patient.id, document.id);

            const _email = new Email();

            _email.senderEmail = currentLocation.email;
            _email.senderName = currentLocation.name;

            _email.subject = 'Ihre Gesundheitsunterlagen';
            _email.toAddresses = [patient.email];
            _email.htmlBody = `<p>${patient.getGenderAndFullName()},<br/> anbei erhalten Sie eine Kopie Ihres unterzeichneten Dokuments.</p><p>Mit freundlichen Grüßen,<br/>${currentLocation.name}.</p>`;
            _email.attachments = [{ id: Utils.getUUID(), documentId: document.id, name: document.name + ".pdf", url: downloadUrl, createdOn: document.pdfCreatedAt ?? undefined }];

            _email.patientId = patient.id;
            _email.clientId = currentClient.id;
            _email.locationId = currentLocation.id;
            _email.createdByUserId = currentUser.id;

            setEmail(_email);
            setIsEmailDialogVisible(true);
        }
    }

    function renderExpireDetails(document: PDocument) {

        return (<>
            {
                (document.expiresAt && document.expiresAt.getTime() < Date.now()) &&
                <span>Abgelaufen <i className="far fa-times kt-red-color"></i></span>
            }
            {
                (document.expiresAt && document.expiresAt.getTime() >= Date.now()) &&
                <span>{DateUtils.getDateString(document.expiresAt)} <i className="fa fa-check kt-green-color"></i></span>
            }
            {
                (document.expiresAt === null) &&
                <span>Unbefristet <i className="fa fa-check kt-green-color"></i></span>
            }
        </>)
    }

    const columns: any[] = [
        {
            field: 'pdfCreatedAt', headerName: 'Unterschrieben am', width: 200, type: "date", renderCell: (params) => (
                <div>
                    {params.row.pdfCreatedAt?.toLocaleString()}
                </div>
            )
        },
        {
            field: 'expiresAt', headerName: 'Gültig bis', width: 150, type: "date", renderCell: (params) => (
                <div>
                    {renderExpireDetails(params.row)}
                </div>
            )
        },
        { field: 'name', headerName: 'Name', minWidth: "250", flex: 1 },
        {
            field: 'public', headerName: "Aktionen", width: 170, renderCell: (params) => (
                <div>
                    <div style={{ display: "flex", gap: "5px" }}>
                        <Tooltip title="Dokument öffnen" arrow>
                            <IconButton onClick={() => openPatientPDF(params.row)}>
                                <i className='fa fa-file-pdf' style={{ fontSize: "18px" }}></i>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Dokument herunterladen" arrow>
                            <IconButton onClick={() => downloadPatientPDF(params.row)}>
                                <i className='fa fa-download' style={{ fontSize: "18px" }}></i>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Dokument per Email senden" arrow>
                            <IconButton onClick={() => sendPatientPDFByEmail(params.row)}>
                                <i className='fa fa-share' style={{ fontSize: "18px" }}></i>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Dokument drucken" arrow>
                            <IconButton onClick={() => printDocument(params.row)}>
                                <i className='fa fa-print' style={{ fontSize: "18px" }}></i>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            )
        }
    ];


    if (!currentUser) {
        return null;
    }

    return (
        <React.Fragment>

            <EmailDialog
                visible={isEmailDialogVisible}
                dialogTitle='Dokumente per Email versenden'
                email={email}
                allDocuments={signedDocuments}
                onSend={() => setIsEmailDialogVisible(false)}
                onClose={() => setIsEmailDialogVisible(false)}
            />

            <div className="kt-tab-page">
                <Box className="kt-form-section" style={{ height: 600, width: '100%' }}>
                    <DataGrid
                        rows={signedDocuments}
                        columns={columns}
                        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                    />
                </Box>
            </div>

        </React.Fragment >

    );
}

export default SignedDocumentsTab;
