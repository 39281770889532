import database from "./../components/database";
import Room from "../models/room";

const db = database.firestore();

const RoomsService = {

    cache: {} as {[key: string]: Room},

    async getRooms(clientId: string, locationId: string, withDummyRoom: boolean = true): Promise<Room[] | null> {

        const rooms: Room[] = [];

        if(withDummyRoom){
            const room = new Room();
            room.id = "without-room";
            room.name = "Ohne"

            rooms.push(room);
        }

        try {

            if(clientId && locationId){
                const querySnapshot = await db.collection("clients").doc(clientId)
                                            .collection("locations").doc(locationId)
                                            .collection("rooms")
                                            .get();


                querySnapshot.forEach((doc) => {

                    const room = new Room();
                    room.fromObject(doc.id, doc.data());

                    // update cache
                    RoomsService.cache[room.id] = room;

                    rooms.push(room);

                });
            }

            rooms.sort((a,b) => a.cardinality > b.cardinality ? 1 : -1);

            return rooms;

        } catch(error) {
            console.log("Error getting rooms: ", error);
            return null;
        }

    },

    async getRoom(clientId: string, locationId: string, roomId: string, ignoreCache:boolean = false): Promise<Room | null> {

        if(!roomId) return null;

        if(!ignoreCache && RoomsService.cache[roomId]){
            return RoomsService.cache[roomId];
        }

        const doc = await db.collection("clients").doc(clientId)
                            .collection("locations").doc(locationId)
                            .collection("rooms").doc(roomId)
                            .get();

        try {

            if (doc.exists) {

                const room = new Room();
                room.fromObject(roomId, doc.data());

                // update cache
                RoomsService.cache[room.id] = room;

                return room;

            } else {
                console.log("getRoom: No such document: " + roomId);
                return null;
            }

        } catch(error) {
            console.log("Error getting room: ", error);
            return null;
        };

    },



    async updateRoom(room: Room, clientId: string, locationId: string): Promise<string | null> {

        try {

            if(room.id) {
                await db.collection("clients").doc(clientId)
                        .collection("locations").doc(locationId)
                        .collection("rooms").doc(room.id)
                        .set(room.toJSON(), { merge: true });

                // update cache
                RoomsService.cache[room.id] = room;

                return room.id;

            } else {
                // create a new Room
                const docRef = await db.collection("clients").doc(clientId)
                                        .collection("locations").doc(locationId)
                                        .collection("rooms")
                                        .add(room.toJSON());

                // update cache
                RoomsService.cache[docRef.id] = room;

                return docRef.id;
            }


        } catch (error) {
            console.log("Error updating room: ", error);
        };

        return null;
    },

    async deleteRoom(roomId: string, clientId: string, locationId: string): Promise<void> {

        try {
            const documentPath = `clients/${clientId}/locations/${locationId}/rooms/${roomId}`;
            await db.doc(documentPath).delete();

        } catch(error) {
            console.log("Error deleting room: ", error);
        };
    },


    saveRoomsSelection(rooms: Room[]){
        if(localStorage){
            const selectedRooms: string[] = [];

            for (let i = 0; i < rooms.length; i++) {
                const ro = rooms[i];
                if(ro.selected){
                    selectedRooms.push(ro.id);
                }
            }

            localStorage.setItem("selectedRooms", JSON.stringify(selectedRooms));
        }
    },

    restoreRoomsSelection(rooms: Room[]): Room[] {

        let selectionCounter = 0;

        try{
            if(localStorage){
                const selectedRoomsString = localStorage.getItem("selectedRooms");
                if(selectedRoomsString){
                    for (let i = 0; i < rooms.length; i++) {
                        const ro = rooms[i];
                        ro.selected = selectedRoomsString.indexOf(ro.id) !== -1;

                        if(ro.selected) {
                            selectionCounter++;
                        }
                    }
                }
            }
        } catch(error){
            console.log(error);
        }

        // if localstorage does not contain any selection then select all rooms
        if(selectionCounter === 0) {
            for (let i = 0; i < rooms.length; i++) {
                const room = rooms[i];
                room.selected = true;
            }
        }

        return rooms;
    }

}

export default RoomsService;