import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/table';
import "tinymce/langs/de.js";
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import { Editor } from "@tinymce/tinymce-react";

import { useEffect, useContext, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import Patient from '../../models/patient';
import Rating from '../../models/rating';
import Reminder from '../../models/reminder';
import NotificationsService from '../../services/notificationsService';
import PatientsService from '../../services/patientsService';
import RatingsService from '../../services/ratingsService';
import { isEmail, isGermanMobileNumber, isMobileDevice } from '../../utils';
import SearchInput from '../searchInput';
import DateUtils from '../../shared/src/utils/dateUtils';
import { GlobalContext } from '../../GlobalContext';




interface Props {
    visible: boolean;
    notificationItem: Reminder | Rating;
    onSave: () => void;
    onClose: () => void;
}

const NotificationDialog:React.FC<Props> = ({ visible, notificationItem, onSave, onClose}) => {

    const maxSmsLength = 160;

    const {currentUser} = useContext(GlobalContext);
    const {currentLocation} = useContext(GlobalContext);
    const [patient, setPatient] = useState(new Patient());

    const [htmlText, setHtmlText] = useState("<p></p>");
    const [emailSubject, setEmailSubject] = useState("");
    const [smsText, setSmsText] = useState("");
    const [sendBy, setSendBy] = useState<"" | "sms" | "email">("");

    const [charactersLeft, setCharactersLeft] = useState(0);

    useEffect(() => {

        async function updatePatient() {
            if(notificationItem && currentUser){
                const patId = notificationItem.calendarItemType === "reminder" ? (notificationItem as Reminder).patient.id : (notificationItem as Rating).patientId;
                let _patient: null | Patient = null;
                if(patId){
                    _patient = await PatientsService.getPatient(patId, currentUser.clientId, currentUser.locationId);
                }

                setPatient(_patient ?? new Patient());
            }
        }


        if(notificationItem){

            updatePatient();

            setSendBy(notificationItem.sendBy);

            if(notificationItem.sendBy === "email"){
                setHtmlText(getMessageText());

            } else if (notificationItem.sendBy === "sms"){
                setSmsText(getMessageText());
            } else {
                setHtmlText("<p></p>");
                setSmsText("");
            }
        } else {
            setHtmlText("<p></p>");
            setSmsText("");
        }



    }, [notificationItem])

    useEffect(() => {
        setCharactersLeft(maxSmsLength - smsText.length);
    }, [smsText])

    async function handleSave(){

        if(notificationItem.calendarItemType === "ratingRequest") {
            const rating = notificationItem as Rating;

            if(rating.sendBy === "email"){
                rating.messageHtml = htmlText;
            } else if(rating.sendBy === "sms"){
                rating.messageText = smsText;
            }

            rating.patientId = patient.id;
            rating.patientGender = patient.gender;
            rating.patientFirstName = patient.firstName;
            rating.patientLastName = patient.lastName;
            rating.patientPhone = patient.mobilePhoneNumber;
            rating.patientEmail = patient.email;

            await RatingsService.updateRating(rating);

        } else if(notificationItem.calendarItemType === "reminder") {
            const reminder = notificationItem as Reminder;

            // add some info for new reminder
            if(!reminder.id){
                const senderName = (currentLocation.notificationsSettings.useCustomSenderName && currentLocation.notificationsSettings.customSenderName) ? currentLocation.notificationsSettings.customSenderName : "Pickadoc";
                reminder.from = senderName;

                reminder.sendBy = sendBy;

                if(reminder.sendBy === "email"){
                    reminder.emailSubject = `${senderName} Nachricht`;

                } else if(reminder.sendBy === "sms"){

                }

                reminder.patient = {
                    id: patient.id,
                    firstName: patient.firstName,
                    lastName: patient.lastName,
                    mobilePhoneNumber: "",
                    email: ""
                }
            }

            if(reminder.sendBy === "email"){
                reminder.emailSubject = emailSubject;
                reminder.emailHtml = htmlText;

                reminder.patient.email = patient.email;

            } else if(reminder.sendBy === "sms"){
                reminder.smsText = smsText;
                reminder.patient.mobilePhoneNumber = patient.mobilePhoneNumber;
            }

            await NotificationsService.updateReminder(reminder);
        }

        onSave();
    }

    function handleClose(){
        onClose();
    }

    function handleDelete(){
        if(window.confirm("Wollen Sie diese Nachricht wirklich löschen?")){
            if(notificationItem.calendarItemType === "ratingRequest") {
                RatingsService.deleteRating(notificationItem.id);

            } else if(notificationItem.calendarItemType === "reminder") {
                NotificationsService.deleteReminder(notificationItem.id);
                NotificationsService.deleteSentReminder(notificationItem.id, currentUser!.clientId, currentUser!.locationId);
            }

            onClose();
        }
    }


    function handlePatientChange(newPatient) {
        if(!newPatient){
            setPatient(new Patient());
        } else if(!canSendEmail(newPatient) && !canSendSms(newPatient)){
            alert(`Sie dürfen oder können ${newPatient.firstName} ${newPatient.lastName} keine SMS oder Email senden.`);
            setPatient(new Patient());
        } else {
            setPatient(newPatient.clone());
        }
    }

    function handleLastNameChange(event) {
        patient.lastName = event.target.value;
        setPatient(patient.clone());
    }

    function handleFirstNameChange(event) {
        patient.firstName = event.target.value;
        setPatient(patient.clone());
    }


    function allowTextEditing() {
        // allow only editing if it has not been sent yet
        return notificationItem && DateUtils.isInFuture(notificationItem.sendAt) && sendBy !== "";
    }

    function getTitle(): string {

        if(notificationItem){

            if(!notificationItem.visitMotiveId) {
                return `${notificationItem.sendBy.toUpperCase()} Nachricht`;

            }else if(notificationItem.calendarItemType === "ratingRequest") {
                return `${notificationItem.sendBy.toUpperCase()} Bewertungsanfrage - ${(notificationItem as Rating).visitMotiveName}`;

            } else if(notificationItem.calendarItemType === "reminder") {
                return `${notificationItem.sendBy.toUpperCase()} Terminerinnerung - ${(notificationItem as Reminder).visitMotiveName}`;
            }
        }

        return "";
    }

    function getMessageText(): string {

        let result = "";

        if(notificationItem) {
            const rating = notificationItem as Rating;

            // Its a rating
            if(rating.rating !== undefined){

                if(rating.sendBy === "sms" || (rating.messageHtml === "" && rating.messageText)){
                    result = rating.messageText.replace(/\+/g, " ").replace(/%0a/g, "\n");
                } else {
                    result = rating.messageHtml;
                }

                result = result.replace(/\[\[ratingId\]\]/g, rating.id);

            } else {
                // Its a reminder
                const reminder = notificationItem as Reminder;

                if(reminder.sendBy === "email"){
                    result = reminder.emailHtml;
                } else {
                    result = reminder.smsText.replace(/\+/g, " ").replace(/%0a/g, "\n");
                }

            }
        }

        // check for script injection
        if(result.toLowerCase().indexOf("<script") > -1){
            result = "";
        }

        return result;
    }

    function canSendSms(_patient: Patient){
        return _patient && isGermanMobileNumber(_patient.mobilePhoneNumber) && _patient.smsAllowed;
    }

    function canSendEmail(_patient: Patient){
        return _patient && isEmail(_patient.email) && _patient.reminderAllowed;
    }

    function allowSaving(): boolean {
        if (patient.id && sendBy !== ""){

            if((sendBy === "sms" && smsText.length > 0) || (sendBy === "email" && htmlText.length > 0)){
                return true;
            }
        }

        return false;
    }

    function handleSendByChange(e){
        if(e && e.target && e.target.value){
            setSendBy(e.target.value as any);
        }
    }

    if(!currentUser) {
        return null;
    }

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={visible}
            maxWidth="lg"
            fullWidth={true}
            disableEnforceFocus={true}
        >
            <DialogTitle id="customized-dialog-title">
                {getTitle()}
            </DialogTitle>

            <DialogContent dividers style={{minHeight:'600px'}}>

                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <SearchInput
                            clientId={currentUser.clientId}
                            locationId={currentUser.locationId}
                            searchField="lastName"
                            firstName={patient.firstName}
                            value={patient.lastName}
                            placeholder="Nachname"
                            autoComplete={`nope`}
                            required
                            disabled={patient.id !== ""}
                            onChange={handleLastNameChange}
                            onPatientChange={handlePatientChange}
                            autofocus={!isMobileDevice()}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SearchInput
                            clientId={currentUser.clientId}
                            locationId={currentUser.locationId}
                            searchField="firstName"
                            lastName={patient.lastName}
                            value={patient.firstName}
                            placeholder="Vorname"
                            autoComplete="new-firstName"
                            required
                            disabled={patient.id !== ""}
                            onChange={handleFirstNameChange}
                            onPatientChange={handlePatientChange}
                            className="kt-border-left"
                        />
                    </Grid>

                    {notificationItem && <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel id="sendBy-select-label">Versenden als</InputLabel>
                            <Select
                                labelId="sendBy-select-label"
                                label="Versenden als"
                                id="sendBy-select"
                                value={sendBy}
                                onChange={handleSendByChange}
                                required
                                disabled={notificationItem?.id !== ""}
                            >
                                <MenuItem value=""></MenuItem>
                                {canSendSms(patient) && <MenuItem value="sms">SMS</MenuItem>}
                                {canSendEmail(patient) && <MenuItem value="email">Email</MenuItem>}

                            </Select>
                        </FormControl>
                    </Grid>}

                    <Grid item xs={12} sm={12}>
                        {sendBy === "sms" ?
                            <TextField
                                fullWidth
                                rows={10}
                                value={smsText}
                                onChange={ e => setSmsText(e.target.value)}
                                label={`SMS-Text (noch ${charactersLeft} Zeichen übrig)`}
                                multiline
                                inputProps={{
                                    maxLength: maxSmsLength
                                  }}
                                disabled={!allowTextEditing()}
                            />
                            :
                            <Editor
                                value={htmlText}
                                onEditorChange={ content => setHtmlText(content)}
                                init={{
                                    height:400,
                                    menubar:false,
                                    default_link_target: "_blank",
                                    plugins: [
                                        'link image imagetools',
                                        'table paste'
                                      ],
                                    toolbar:
                                        'undo redo | formatselect | bold italic forecolor backcolor | \
                                        alignleft aligncenter alignright alignjustify | \
                                        bullist numlist outdent indent | image | removeformat',
                                    language: "de",
                                    content_style: ".mce-content-body {font-size:15px;font-family:Arial,sans-serif;}"
                                }}
                                disabled={!allowTextEditing()}
                            />

                        }


                    </Grid>
                </Grid>


            </DialogContent>

            <DialogActions>
                {notificationItem?.id &&
                <Button onClick={handleDelete} color="secondary">
                    Löschen
                </Button>}

                <div style={{flex: '1 0 0'}} />

                <Button onClick={handleClose} color="secondary">
                    Abbrechen
                </Button>
                <Button onClick={handleSave} color="primary" disabled={!allowSaving()}>
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>

    );
}

export default NotificationDialog;
