
class NotificationsSettings {

    customSenderName: string = "";
    useCustomSenderName: boolean = false;
    confirmationSmsEnabled: boolean = true;
    reminderSmsEnabled: boolean = true;
    recallSmsEnabled: boolean = true;
    successorSmsEnabled: boolean = true;
    marketingSmsEnabled: boolean = true;

    ratingSmsEnabled: boolean = true;
    ratingEmailEnabled: boolean = true;

    confirmationSmsText: string = "";
    reminderSmsText: string = "";

    clone(): NotificationsSettings {
        const c = new NotificationsSettings();

        c.customSenderName = this.customSenderName;
        c.useCustomSenderName = this.useCustomSenderName === true;
        c.confirmationSmsEnabled = this.confirmationSmsEnabled;
        c.reminderSmsEnabled = this.reminderSmsEnabled;
        c.recallSmsEnabled = this.recallSmsEnabled;
        c.successorSmsEnabled = this.successorSmsEnabled;
        c.marketingSmsEnabled = this.marketingSmsEnabled;

        c.ratingSmsEnabled = this.ratingSmsEnabled;
        c.ratingEmailEnabled = this.ratingEmailEnabled;

        c.confirmationSmsText = this.confirmationSmsText;
        c.reminderSmsText = this.reminderSmsText;

        return c;
    }

    toJSON(): object {
        return {
            customSenderName: this.customSenderName,
            useCustomSenderName: this.useCustomSenderName === true,
            confirmationSmsEnabled: this.confirmationSmsEnabled === true,
            reminderSmsEnabled: this.reminderSmsEnabled === true,
            recallSmsEnabled: this.recallSmsEnabled === true,
            successorSmsEnabled: this.successorSmsEnabled === true,
            marketingSmsEnabled: this.marketingSmsEnabled === true,

            ratingSmsEnabled: this.ratingSmsEnabled === true,
            ratingEmailEnabled: this.ratingSmsEnabled === true,

            confirmationSmsText: this.confirmationSmsText,
            reminderSmsText: this.reminderSmsText,
        };
    }

    fromObject(o: any){
        this.customSenderName = o.customSenderName ?? "";
        this.useCustomSenderName = o.useCustomSenderName === true;
        this.confirmationSmsEnabled = o.confirmationSmsEnabled === true;
        this.reminderSmsEnabled = o.reminderSmsEnabled === true;
        this.recallSmsEnabled = o.recallSmsEnabled === true;
        this.successorSmsEnabled = o.successorSmsEnabled === true;
        this.marketingSmsEnabled = o.marketingSmsEnabled === true;

        this.ratingSmsEnabled = o.ratingSmsEnabled === true;
        this.ratingEmailEnabled = o.ratingEmailEnabled === true;

        this.confirmationSmsText = o.confirmationSmsText ?? "";
        this.reminderSmsText = o.reminderSmsText ?? "";
    }
};

export default NotificationsSettings;