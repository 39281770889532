import OpeningHours from "./openingHours";

export type CalendarLicense = "disabled" | "free" | "basic" | "premium" | "custom";

class Calendar {
    id: string;
    clientId: string;
    locationId: string;
    locationName: string;
    userId: string;
    name: string;
    abbreviation: string;
    children: Calendar[];
    selected: boolean;
    selectedOnDashboard: boolean;
    selectedOnCampaign: boolean;
    allowOnlineAppointments: boolean;
    internal: boolean; // if internal, no message will be send to patients

    parentId: string;
    parent: Calendar | null;
    parentName: string;

    avatarUrl: string;

    openingHours: OpeningHours = new OpeningHours();

    license: CalendarLicense = "disabled";
    subscriptionId: string = ""; // the Stripe subscription Id

    cardinality: number;
    __reorder__: string;

    isDeleted: boolean;

    constructor (id = "", clientId = "", userId = "", name = "", selected = true, children: Calendar[] = []) {
        this.id = id;
        this.clientId = clientId;
        this.locationId = "";
        this.locationName = "";
        this.userId = userId;
        this.name = name;
        this.abbreviation = "";
        this.selected = selected === true;
        this.selectedOnDashboard = true;
        this.selectedOnCampaign = true;
        this.allowOnlineAppointments = true;
        this.internal = false;
        this.children = [];

        this.parent = null;
        this.parentId = "";
        this.parentName = "";

        this.avatarUrl = "";

        this.license = "disabled";

        this.cardinality = 0;
        this.__reorder__ = name;
        this.isDeleted = false;
    }

    changeSelection (id: string, isSelected: boolean) : void {
        
        if(this.id === id){
            this.selected = isSelected;
            return;
        }

        for (let i = 0; i < this.children.length; i++) {
            this.children[i].changeSelection(id, isSelected);
        }
    }


    clone(withParentReference: boolean = true): Calendar {
        const clonedCalendar = new Calendar(this.id, this.clientId, this.userId, this.name, this.selected);

        clonedCalendar.abbreviation = this.abbreviation;

        clonedCalendar.selectedOnDashboard = this.selectedOnDashboard === true;
        clonedCalendar.selectedOnCampaign = this.selectedOnCampaign === true;

        clonedCalendar.allowOnlineAppointments = this.allowOnlineAppointments;
        clonedCalendar.internal= this.internal;
        clonedCalendar.locationId = this.locationId;
        clonedCalendar.locationName = this.locationName;

        if(withParentReference){
            clonedCalendar.parent = this.parent;
        }

        clonedCalendar.parentId = this.parentId;
        clonedCalendar.openingHours = this.openingHours.clone();
        
        this.children.forEach(child => {
            const childClone = child.clone();
            
            clonedCalendar.children.push(childClone);
        });

        clonedCalendar.license = this.license;
        clonedCalendar.subscriptionId = this.subscriptionId;

        clonedCalendar.avatarUrl = this.avatarUrl;

        clonedCalendar.cardinality = this.cardinality;

        clonedCalendar.isDeleted = this.isDeleted;

        return clonedCalendar;
    }

    

    toJSON(): object {
        let obj = {
            id: this.id,
            clientId: this.clientId,
            locationId: this.locationId,
            locationName: this.locationName,
            userId: this.userId,
            name: this.name,
            abbreviation: this.abbreviation,
            selected: this.selected,
            allowOnlineAppointments: this.allowOnlineAppointments,
            internal: this.internal,
            parentId: this.parentId ? this.parentId : "",
            parentName: this.parentName ? this.parentName : "",
            children: [...this.children.map((child) => {return child.toJSON()})],
            license: this.license,
            subscriptionId: this.subscriptionId,
            avatarUrl: this.avatarUrl ? this.avatarUrl : "",
            cardinality: this.cardinality,
            isDeleted: this.isDeleted
        }

        return obj;
    }

    fromObject(id:string|null, o:any) {

        this.id = id !== null ? id : o.id;
        this.clientId = o.clientId;
        this.locationId = o.locationId;
        this.locationName = o.locationName;
        this.userId = o.userId;
        this.name = o.name;
        this.abbreviation = o.abbreviation ?? "";
        this.selected = o.selected === true;
        this.allowOnlineAppointments = o.allowOnlineAppointments === true;
        this.internal = o.internal === true;
        this.parentId = o.parentId;
        this.parentName = o.parentName ? o.parentName : "";
        this.avatarUrl = o.avatarUrl;
        this.license = o.license ?? "disabled";
        this.subscriptionId = o.subscriptionId ?? "";
        this.cardinality = o.cardinality ?? 0;
        this.__reorder__ = o.name;
        this.isDeleted = o.isDeleted === true;

        if(o.children){
            for (let i = 0; i < o.children.length; i++) {
                const oChild = o.children[i];
                
                const newChild = new Calendar();
                newChild.fromObject(oChild.id, oChild);
                newChild.parent = this;
                newChild.parentId = this.id;
                newChild.parentName = this.name;

                newChild.userId = o.userId;
                newChild.clientId = o.clientId;

                this.children.push(newChild);
            }
        }
              
    }

    getShortName(){

        // if user has provided an abbreviation, then always use this first
        if(this.abbreviation) return this.abbreviation;

        let name = this.name.toUpperCase();

        name = name.replaceAll("DR.MED.", "");
        name = name.replaceAll("DR.", "");
        name = name.replaceAll("MED.", "");
        name = name.replaceAll("PROF.", "");

        name = name.trimStart();
        
        const parts = name.split(" ");
        name = "";
        for (let i = 0; i < parts.length; i++) {
            const element = parts[i];
            name += element.slice(0,2);
        }

        name = name.replaceAll(".", "").trim();

        return name.replaceAll("-", " "); // to force a line break
    }

    getLicenseDisplayName(): string {
        return Calendar.getLicenseDisplayName(this.license);
    }


    static getLicenseDisplayName(license: CalendarLicense): string {
        switch (license) {
            
            case "basic":
                return "Basic";

            case "premium":
                return "Premium";

            case "custom":
                return "Individuell";

            case "disabled":
                return "Deaktiviert";

            default:
                return "Kostenlos";
        }
    }

    static getCalendarById(calendars: Calendar[], id: string): Calendar | null {
        if(calendars){
            for (let i = 0; i < calendars.length; i++) {
                const element = calendars[i];
                
                if(element.id === id){
                    return element;
                }

                const child = element.children.find((cal) => cal.id === id);
                if(child) return child;
            }
        }
        
        return null;
    }

    static getAsFlatList(calendars: Calendar[]): Calendar[]{
        const result: Calendar[] = [];

        for (let i = 0; i < calendars.length; i++) {
            const element = calendars[i];

            result.push(element);

            for (let c = 0; c < element.children.length; c++) {
                const child = element.children[c];
                result.push(child);
                child.parentId = element.id;
                child.parent = element;
                child.parentName = element.name;
            }

            element.children = [];
            
        }

        return result;
    }

    static cloneCalendars(calendars: Calendar[]): Calendar[] {
        const clonedCalendars: Calendar[] = [];

        calendars.forEach(cal => {
            const clonedCal = new Calendar();
            clonedCal.fromObject(cal.id, cal.toJSON());
            clonedCalendars.push(clonedCal);
        });

        return clonedCalendars;
    }

};

export default Calendar;

