import i18next, { t } from 'i18next';
import React, { useState, useRef, useEffect, useContext } from 'react';
import Avatar from './avatar';
import PatientsService from '../services/patientsService';
import AiChatsService from '../services/aiChatsService';
import GPTChatMessage from '../shared/src/models/clonR/gptChatMessage';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    DialogActions,
    Button,
    CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Appointment from '../models/appointment';
import AppointmentsService from '../services/appointmentsService';
import DateUtils from '../shared/src/utils/dateUtils';
import { GlobalContext } from '../GlobalContext';
import parse from 'html-react-parser';
import Patient from '../models/patient';

interface Props {
    avatarImageUrl: string
}

const ChatBot: React.FC<Props> = ({ avatarImageUrl }) => {

    const { currentClient, currentLocation } = useContext(GlobalContext);
    const [messages, setMessages] = useState<GPTChatMessage[]>([]);
    const [displayMessages, setDisplayMessages] = useState<GPTChatMessage[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isThinking, setIsThinking] = useState(false);
    const messagesEndRef = useRef<HTMLDivElement>(null);

    // This will cache promises for patient fetching
    const patientCache = useRef<Map<string, Promise<Patient | null>>>(new Map());

    useEffect(() => {
        scrollToBottom();
    }, [displayMessages]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const handleClose = () => {
        setIsDialogOpen(false);
        setMessages([]);
        setDisplayMessages([]);
        setInputValue('');
        setIsThinking(false);
        patientCache.current.clear(); // Clear cache when dialog is closed
    };

    const extractPatientIdsFromTable = (): string[] => {
        const patientIds: string[] = [];
        // Adjust the selector if you have multiple tables or a specific one you want to target.
        const table = document.querySelector('table');
        if (!table) {
            console.warn("No table found on the page.");
            return patientIds;
        }

        // Loop through each table row (tr)
        const rows = table.querySelectorAll('tr');
        console.log(rows);
        rows.forEach(row => {
            // Find the hidden cell in the row.
            // We assume the hidden cell is marked with inline style containing "display: none"
            const hiddenCell = row.querySelector('td[style*="display: none"]');
            if (hiddenCell && hiddenCell.textContent) {
                patientIds.push(hiddenCell.textContent.trim().replace("{{hiddenPatientId:", "").replace("}}", ""));
            }
        });

        return patientIds;
    };

    const sendSmsToPatients = (patientIds: string[]) => {
        console.log(JSON.stringify(patientIds));
    };

    const fetchPatientWithCache = async (patientId: string, clientId: string, locationId: string): Promise<Patient | null> => {
        // Check if the patient is already in the cache
        if (patientCache.current.has(patientId)) {
            return patientCache.current.get(patientId) as Promise<Patient | null>;
        }

        // Create a new promise for patient data and store it in the cache
        const patientPromise = PatientsService.getPatient(patientId, clientId, locationId)
            .catch(error => {
                console.error(`Failed to fetch patient ${patientId}:`, error);
                return null; // Return null if there's an error to prevent cache pollution
            });

        patientCache.current.set(patientId, patientPromise);
        return patientPromise;
    };

    const startChatConversation = async (question: string) => {
        try {

            setInputValue('');
            setIsThinking(true); // Start waiting animation

            let _messages: GPTChatMessage[] = [];
            let _displayMessages: GPTChatMessage[] = [];

            setIsDialogOpen(true);

            const requestId = `chat_${Date.now()}`;

            const userMessage: GPTChatMessage = {
                role: 'user',
                content: question
            };

            if (messages.length === 0) {

                const today = new Date();
                const fromDate = DateUtils.subtractDays(today, 21);
                const toDate = DateUtils.addDays(today, 21);

                const appointments = await AppointmentsService.getAnonymousAppointments(fromDate, toDate, currentClient.id, currentLocation.id);

                const assistantMessage: GPTChatMessage = {
                    role: 'system',
                    content: `You are an appointment management calendar system called "Pickadoc" and you can answer questions about patients and their appointments.
Today’s date is ${new Date().toUTCString()}.
Here is a list of appointments: ${JSON.stringify(appointments)}.
Return patient.id in double brackets like {{patientId:patient.id}} for the visible "Patient" column, this syntax is important.
Additionally, include an extra hidden column that contains the patient id using the pattern {{hiddenPatientId:patient.id}} (this column should not be visible but must be present in the HTML).
Additionally, please return any lists as aesthetically pleasing HTML tables with proper inline styling (e.g., borders, padding, alternating row colors, and header rows) to enhance readability.
Add a GUID as id to the table.
Add a button element below the table as a valid HTML button, using this syntax: "<button onClick='alert(\"SMS Senden\")'>SMS senden</button>".
Don't give hints like: "Bitte beachten Sie, dass die Namen der Patienten nicht in den Daten enthalten sind."
Always answer in the user's language.
Always replace "Patient-ID" or similar with just "Patient" in the user's language.
For appointment lists, display the following visible columns: "Date", "Time", "VisitMotive", "Patient", "Status". (In addition, include the hidden patient id column as instructed, but hide it in the UI, but add it to the DOM.)
Write below the table how many entries you have found.
Translate all text to German like "Confirmed" is "Bestätigt".
                        `
                }
                _messages = [assistantMessage, userMessage];
                _displayMessages = [assistantMessage, userMessage];

            } else {
                _messages = [...messages, userMessage];
                _displayMessages = [...displayMessages, userMessage];
            }

            // Decorate messages for user display
            const decorateForDisplay = async (msg: GPTChatMessage): Promise<GPTChatMessage> => {
                if (!msg.content) return msg;

                const regex = /{{patientId:(\w+)}}/g;
                let match;
                let processedContent = msg.content;

                const promises: Promise<void>[] = [];
                while ((match = regex.exec(msg.content)) !== null) {
                    // Aktuellen Match in einer Konstante speichern
                    const currentMatch = match;
                    const patientId = currentMatch[1];
                    promises.push(
                        fetchPatientWithCache(patientId, currentClient.id, currentLocation.id)
                            .then(patient => {
                                if (patient) {
                                    const patientName = `${patient.firstName} ${patient.lastName}`.trim();
                                    processedContent = processedContent.replace(currentMatch[0], patientName);
                                }
                            })
                    );
                }

                await Promise.all(promises);

                return { ...msg, content: processedContent };
            };

            // Decorate the messages to display based on fetching real names via `PatientsService.getPatient`
            const decoratedDisplayMessages = await Promise.all(_displayMessages.map(decorateForDisplay));


            const unsubscribe = AiChatsService.listenForGPTChatResponse(
                requestId,
                async (message, finishReason) => {
                    if (message) {
                        setMessages(prev => {
                            const lastMessage = prev[prev.length - 1];
                            if (lastMessage?.role === 'assistant') {
                                return [...prev.slice(0, -1), message];
                            }
                            return [...prev, message];
                        });

                        const displayMessage = await decorateForDisplay(message);
                        setDisplayMessages(prev => {
                            const lastMessage = prev[prev.length - 1];
                            if (lastMessage?.role === 'assistant') {
                                return [...prev.slice(0, -1), displayMessage];
                            }
                            return [...prev, displayMessage];
                        });

                    }
                    if (finishReason === 'stop') {
                        unsubscribe();
                        setIsThinking(false); // Stop waiting animation
                    }
                }
            );

            AiChatsService.startGPTChat(requestId, _messages);

            setMessages(_messages);
            setDisplayMessages(decoratedDisplayMessages);

        } catch (error) {
            console.error('Error starting chat:', error);
            setIsThinking(false);
        }
    };

    const parseOptions = {
        replace: (domNode) => {
            // Check if the node is a button and has an onclick attribute
            if (domNode.name === 'button' && domNode.attribs && domNode.attribs.onclick) {
                // Optionally, get the button text from the domNode children
                const buttonText =
                    (domNode.children &&
                        domNode.children.length > 0 &&
                        typeof domNode.children[0].data === 'string' &&
                        domNode.children[0].data) ||
                    'Send SMS';

                // Remove the invalid onclick attribute
                delete domNode.attribs.onclick;

                return (
                    <button
                        {...domNode.attribs}
                        onClick={() => {
                            const patientIds = extractPatientIdsFromTable(); // define this function as needed
                            sendSmsToPatients(patientIds); // call your function with the ids                            
                        }}
                    >
                        {buttonText}
                    </button>
                );
            }
        }
    }

    return (
        <>
            <div className="kt-chatBot">
                <div style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
                    <Avatar
                        src={avatarImageUrl}
                        width={70}
                    />
                </div>

                <div style={{
                    position: "relative",
                    marginBottom: "10px",
                    width: "100%",
                    borderRadius: "5px",
                    border: "1px solid #d862c8",
                    padding: "2px"
                }}>
                    <input
                        type='text'
                        id="input-bot-question"
                        placeholder={t("pages.calendarPage.botQuestion")}
                        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === 'Enter') {
                                const question = e.currentTarget.value;
                                // Start chat conversation
                                startChatConversation(question);
                            }
                        }}
                        spellCheck={false}
                        style={{
                            width: "100%",
                            borderRadius: "3px",
                            fontSize: "12px",
                            height: "30px",
                            border: "none",
                            outline: "none",
                            background: "white",
                            padding: "0 10px"
                        }}
                    />
                </div>
            </div>
            <Dialog
                open={isDialogOpen}
                onClose={handleClose}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>
                    Chat
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div style={{
                        height: "400px",
                        overflowY: "auto",
                        marginBottom: "10px",
                        padding: "10px",
                    }}>
                        {displayMessages
                            .filter(message => message.role !== 'system')
                            .map((message, index) => (
                                <div
                                    key={index}
                                    style={{
                                        marginBottom: "10px",
                                        textAlign: message.role === 'user' ? 'right' : 'left'
                                    }}
                                >
                                    <div style={{
                                        display: "inline-block",
                                        padding: "8px 12px",
                                        borderRadius: "12px",
                                        backgroundColor: message.role === 'user' ? '#d862c8' : '#f0f0f0',
                                        color: message.role === 'user' ? 'white' : 'black',
                                        maxWidth: "70%"
                                    }}>
                                        {/* use parse to render HTML tags like a HTML table */}
                                        {parse(message.content!.replace(/onclick=/g, 'onClick=').replace("```html", "").replace("```", ""), parseOptions)}
                                    </div>
                                </div>
                            ))}
                        <div ref={messagesEndRef} />
                    </div>
                    {isThinking && (
                        <div style={{ textAlign: "center", marginBottom: "10px" }}>
                            <CircularProgress />
                        </div>
                    )}
                    <div style={{
                        position: "relative",
                        width: "100%",
                        borderRadius: "5px",
                        border: "1px solid #d862c8",
                        padding: "2px"
                    }}>
                        <input
                            type='text'
                            disabled={isThinking}
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            placeholder={t("pages.calendarPage.botQuestion")}
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                if (e.key === 'Enter' && inputValue.trim()) {
                                    startChatConversation(inputValue.trim());
                                }
                            }}
                            spellCheck={false}
                            style={{
                                width: "100%",
                                borderRadius: "3px",
                                fontSize: "12px",
                                height: "30px",
                                border: "none",
                                outline: "none",
                                background: "white",
                                padding: "0 10px"
                            }}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t("components.buttons.close")}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ChatBot;