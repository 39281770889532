import database from "../components/database";
import PhoneCall, { PhoneCallStatus } from "../shared/src/models/phoneCall";
import { logDbReadOperations } from "../utils";

const db = database.firestore();

const PhoneCallsService = {

    startListenForPhoneCalls(clientId: string, locationId: string, changeCallback: (toDos: PhoneCall[]) => void): () => void {

        return db.collection("clients").doc(clientId)
            .collection("locations").doc(locationId)
            .collection("phoneCalls")
            .orderBy("createdAt", "desc")
            .onSnapshot(function (querySnapshot) {
                const phoneCalls: PhoneCall[] = [];

                querySnapshot.forEach((doc) => {
                    const phoneCall = new PhoneCall();
                    phoneCall.fromObject(doc.data());
                    phoneCall.id = doc.id;

                    phoneCalls.push(phoneCall);

                });

                logDbReadOperations("startListenForPhoneCalls", phoneCalls.length);

                changeCallback(phoneCalls);
            });
    },

    async updatePhoneCall(phoneCall: PhoneCall): Promise<void> {
        await db.collection("clients").doc(phoneCall.clientId)
            .collection("locations").doc(phoneCall.locationId)
            .collection("phoneCalls").doc(phoneCall.id)
            .set(phoneCall.toJSON(true), { merge: true });

        // ToDo: log which user has changed this status
    },

    async deletePhoneCall(phoneCall: PhoneCall): Promise<void> {
        await db.collection("clients").doc(phoneCall.clientId)
            .collection("locations").doc(phoneCall.locationId)
            .collection("phoneCalls").doc(phoneCall.id)
            .delete();
    }

}

export default PhoneCallsService;