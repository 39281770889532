
import React, { useEffect } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';


interface Props {
    visible: boolean;
    title: string;
    label: string | JSX.Element;
    onReject: () => void;
    onConfirm: () => void;
}

const ConfirmDialog: React.FC<Props> = ({ visible, title, label, onReject, onConfirm }) => {

    useEffect(() => {

        window.addEventListener('keydown', listenKeyboard, true);

        // clean up
        return () => {
            window.removeEventListener('keydown', listenKeyboard, true);
        }

    }, []);
    
    function listenKeyboard(event) {
        if (event.keyCode === 27) {
            onReject();
        }
    }
    
    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={visible}
        >
            <DialogTitle id="customized-dialog-title">
                <i className="far fa-question-circle"></i> {title}
            </DialogTitle>

            <DialogContent
                dividers
            >

                <Grid container>
                    <Grid item xs={12}>
                        {label}
                    </Grid>
                </Grid>

            </DialogContent>

            <DialogActions>
                <Button variant="outlined" onClick={onReject} color='primary'>
                    Nein
                </Button>
                <Button variant="outlined"  onClick={onConfirm} color='primary'>
                    JA
                </Button>
            </DialogActions>

        </Dialog>

    );
}

export default ConfirmDialog;
