import database from "../components/database";
import ClonRVoice from "../shared/src/models/clonR/clonRVoice";
import TextToSpeech from "../shared/src/models/clonR/textToSpeech";
import FileUploadsService from "./fileUploadsService";

const db = database.firestore();
const functions = db.app.functions('europe-west3');

const ClonRVoicesService = {


    async addVoice(clientId: string, voiceId: string, voiceName: string, fileExtension: string): Promise<string> {

        try {

            const addClonrVoice = functions.httpsCallable('addClonrVoice');
            const result = await addClonrVoice(
                {
                    clientId: clientId,
                    voiceId: voiceId,
                    voiceName: voiceName,
                    fileExtension: fileExtension
                }
            );

            return result.data;

        } catch (error) {
            console.error(`error in addVoice: ${error}`);
        }

        return "";

    },

    async addSystemVoice(voiceName: string, customVoiceId: string, audioUrl: string): Promise<string> {

        try {

            const voice = new ClonRVoice();
            voice.name = voiceName;
            voice.customVoiceId = customVoiceId;
            voice.isSystem = true;
            voice.audioFileExtension = "mp3";
            voice.audioUrl = audioUrl;

            await db.collection("clonRVoices").doc(voice.id)
                .set(voice.toJSON(), { merge: true });

            return voice.id;

        } catch (error) {
            console.error(`error in addSystemVoice: ${error}`);
        }

        return "";

    },

    // deletes the custom voice from elevenlabs
    async deleteCustomVoice(customVoiceId: string): Promise<void> {

        try {

            const deleteClonrVoice = functions.httpsCallable('deleteClonrVoice');
            const result = await deleteClonrVoice(
                {
                    customVoiceId: customVoiceId
                }
            );

        } catch (error) {
            console.error(`error in deleteCustomVoice: ${error}`);
        }

    },

    async getVoices(clientId: string): Promise<ClonRVoice[] | null> {

        const voices: ClonRVoice[] = [];

        try {

            // load all client voices
            if (clientId) {
                const querySnapshot = await db.collection("clients").doc(clientId)
                    .collection("clonRVoices")
                    .orderBy("createdAt", "desc")
                    .get();


                querySnapshot.forEach((doc) => {

                    const voice = new ClonRVoice();
                    voice.fromObject(doc.id, doc.data());

                    voices.push(voice);

                });

            }

            // load all system voices
            const querySnapshot = await db.collection("clonRVoices")
                .orderBy("name", "asc")
                .get();


            querySnapshot.forEach((doc) => {

                const voice = new ClonRVoice();
                voice.fromObject(doc.id, doc.data());

                voices.push(voice);

            });

            return voices;

        } catch (error) {
            console.log("Error getting voices: ", error);
            return null;
        }

    },

    async getVoice(clientId: string, voiceId: string): Promise<ClonRVoice | null> {

        if (!voiceId) return null;


        const doc = await db.collection("clients").doc(clientId)
            .collection("clonRVoices").doc(voiceId)
            .get();

        try {

            if (doc.exists) {

                const voice = new ClonRVoice();
                voice.fromObject(voiceId, doc.data());

                return voice;

            } else {
                console.log("getVoice: No such document: " + voiceId);
                return null;
            }

        } catch (error) {
            console.log("Error getting voice: ", error);
            return null;
        };

    },



    async updateVoice(voice: ClonRVoice, clientId: string): Promise<string | null> {

        try {

            // convert to system voice
            // voice.isSystem = true;
            // await db.collection("clonRVoices").doc(voice.id)
            //         .set(voice.toJSON(), { merge: true });

            // return voice.id;


            if (voice.id) {
                await db.collection("clients").doc(clientId)
                    .collection("clonRVoices").doc(voice.id)
                    .set(voice.toJSON(), { merge: true });

                return voice.id;

            } else {
                // create a new ClonRVoice
                const docRef = await db.collection("clients").doc(clientId)
                    .collection("clonRVoices")
                    .add(voice.toJSON());

                return docRef.id;
            }


        } catch (error) {
            console.log("Error updating voice: ", error);
        };

        return null;
    },

    async deleteVoice(voice: ClonRVoice, clientId: string): Promise<void> {

        try {
            const documentPath = `clients/${clientId}/clonRVoices/${voice.id}`;
            await db.doc(documentPath).delete();

            // now delete audio file for this voice
            await FileUploadsService.deleteFile(`clients/${clientId}/clonRVoices/${voice.id}.${voice.audioFileExtension}`);

            // and delete also the custom voice from elevenlabs
            await ClonRVoicesService.deleteCustomVoice(voice.customVoiceId);

        } catch (error) {
            console.log("Error deleting ClonRVoice: ", error);
        };
    },

    // TTS generation can take some time, so we use the db listener (table: "textToSpeech") to notify the client, when TTS is finished
    generateTTS(audioId: string, text: string, language: string, avatarId: string, customVoiceId: string, clientId: string): void {
        try {
            const path = `clients/${clientId}/clonr/output`;

            const generateTTS = functions.httpsCallable('generateTTS');
            generateTTS(
                {

                    audioId: audioId,
                    text: text,
                    language: language,
                    avatarId: avatarId,
                    customVoiceId: customVoiceId,
                    path: path
                }
            );


        } catch (error) {
            console.log("Error generating TTS: ", error);
        };

    },

    startListenForTTS(audioId: string, changeCallback: (tts: TextToSpeech) => void): () => void {

        if (!audioId) {
            return () => {
                console.log("error in startListenForTTS: empty parameter");
            };
        }

        return db.collection("textToSpeech")
            .doc(audioId)
            .onSnapshot(function (doc) {

                const tts = new TextToSpeech();

                if (doc.exists) {
                    tts.fromObject(audioId, doc.data());
                    changeCallback(tts);
                }

            });
    }

}

export default ClonRVoicesService;