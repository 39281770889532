import React, { useState, useEffect, useContext, Fragment } from "react";
import { useParams } from "react-router-dom";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import VisitMotivesService from "../../../../services/visitMotivesService";

import LoadingPage from "../../administration/loadingPage";
import SaveStatus from "../../../../models/saveStatus";
import SaveButton from "../../../saveButton";
import VisitMotive from "../../../../models/visitMotive";
import BackButton from "../../../backButton";
import SmsEditor from "../../../smsEditor";
import VisitMotivePicker from "../../../visitMotivePicker";
import LandingPageCtrl from "../../../landingPageCtrl";
import LandingPageData from "../../../../shared/src/models/landingPageData";
import DateUtils from "../../../../shared/src/utils/dateUtils";
import DocumentsService from "../../../../services/documentsService";
import { GlobalContext } from "../../../../GlobalContext";


type VisitMotiveDocument = {
    id: string,
    name: string,
    selected: boolean
}

function VisitMotivePage() {

    const { visitMotiveId, specialityId } = useParams();

    const [visitMotive, setVisitMotive] = useState<VisitMotive | null>(null);

    const {visitMotives, setVisitMotives} = useContext(GlobalContext);
    
    const [isLoading, setIsLoading] = useState(false);
    const {currentClient} = useContext(GlobalContext);
    const {currentLocation} = useContext(GlobalContext);
    const {currentUser} = useContext(GlobalContext);
    const {specialities} = useContext(GlobalContext);

    const [saveStatus, setSaveStatus] = useState(SaveStatus.none);
    const [selectedTab, setSelectedTab] = useState(0);

    const [documentTemplates, setDocumentTemplates] = useState<VisitMotiveDocument[]>([]);


    useEffect(() => {

        if (visitMotiveId && currentClient && visitMotives) {
            updateData(visitMotiveId);
        }

        // eslint-disable-next-line
    }, [visitMotiveId, currentClient, visitMotives]);


    async function updateData(vId: string) {

        if (!currentUser) {
            return;
        }

        try {
            const cId = currentClient.id;

            setIsLoading(true);

            let _visitMotive: null | VisitMotive = new VisitMotive();

            // CREATE a new visitMotiv
            if (vId && vId.toLowerCase() === "create" && cId) {
                _visitMotive = new VisitMotive();
                _visitMotive.clientId = cId;

                if (specialityId) {
                    _visitMotive.specialityId = specialityId;
                } else if (specialities && specialities.length > 0) {
                    _visitMotive.specialityId = specialities[0].id;
                }

                if (visitMotives && visitMotives.length > 0) {
                    _visitMotive.successorId = visitMotives[0].id;
                }

                setVisitMotive(_visitMotive);


            } else {

                // LOAD a visitMotive for editing
                _visitMotive = await VisitMotivesService.getVisitMotive(vId, true, currentClient.id, currentUser.locationId);

                if (_visitMotive) {

                    // if (!_visitMotive.successorId && visitMotives && visitMotives.length > 0) {
                    //     _visitMotive.successorId = visitMotives[0].id;
                    // }

                    // if (!_visitMotive.recallId && visitMotives && visitMotives.length > 0) {
                    //     _visitMotive.recallId = visitMotives[0].id;
                    // }

                    setVisitMotive(_visitMotive);
                }
            }



            const _templateDocs = await DocumentsService.getDocumentTemplates(currentClient.id, currentUser.locationId);
            if (_templateDocs) {
                const _vDocs: VisitMotiveDocument[] = _templateDocs.map( tdoc => {
                    return {id: tdoc.id, name: tdoc.name, selected: false}
                });

                // now check all document templates which this visit motive has selected
                if (_visitMotive && _visitMotive.documentIds) {
                    for (let i = 0; i < _visitMotive.documentIds.length; i++) {
                        const docId = _visitMotive.documentIds[i];

                        _vDocs.forEach(vDoc => {
                            if (vDoc.id === docId) {
                                vDoc.selected = true;
                            }
                        });
                    }
                }

                setDocumentTemplates(_vDocs);
            }

            setIsLoading(false);

        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();

        if (visitMotive !== null) {


            // check if recall and successor sms texts are not empty
            if(visitMotive.successorEnabled && !visitMotive.successorSmsText) {
                alert("Bitte geben Sie noch einen Folgetermin-SMS-Text ein.")
                setSelectedTab(1);
                return;
            }
            if(visitMotive.recurrenceCount !== 0 && !visitMotive.recallSmsText) {
                alert("Bitte geben Sie noch einen Recall-SMS-Text ein.")
                setSelectedTab(2);
                return;
            }


            setSaveStatus(SaveStatus.isSaving);


            visitMotive.calendarIds = [];

            visitMotive.documentIds = [];
            documentTemplates.forEach(vDoc => {
                if (vDoc.selected) {
                    visitMotive.documentIds.push(vDoc.id);
                }
            });


            const newId = await VisitMotivesService.updateVisitMotive(visitMotive, currentClient.id, currentUser?.locationId);

            if (newId) {

                if (visitMotiveId && visitMotiveId.toLowerCase() === "create") {
                    setSaveStatus(SaveStatus.none);

                    // update visit motives cache
                    visitMotives.push(visitMotive);
                    setVisitMotives(visitMotives);

                    window.history.back();
                } else {

                    // update visit motives cache
                    const tempVisitMotives: VisitMotive[] = [];
                    for (let i = 0; i < visitMotives.length; i++) {
                        let element = visitMotives[i];
                        if (element.id === visitMotive.id) {
                            element = visitMotive;
                        }
                        tempVisitMotives.push(element);
                    }
                    setVisitMotives(tempVisitMotives);

                    setSaveStatus(SaveStatus.saved);

                    //window.history.back();

                }
            } else {
                setSaveStatus(SaveStatus.none);
                alert("Der Besuchsgrund konnte nicht gespeichert werden!");
            }
        }
    }

    function toggleDocumentSelection(docId: string) {

        const _docs = [...documentTemplates];

        for (let i = 0; i < _docs.length; i++) {
            if (_docs[i].id === docId) {
                _docs[i].selected = !_docs[i].selected;
            }
        }

        setDocumentTemplates(_docs);
    }

    function onInputChange(event) {
        if (visitMotive) {
            const { name, value } = event.target;

            const v = visitMotive.clone();

            if (event.target.type === "checkbox") {
                v[name] = event.target.checked;

            } else if (event.target.name === "recurrenceIntervalValue") {
                const temp = DateUtils.parseInterval(v.recurrenceInterval);
                v.recurrenceInterval = `${value}-${temp.unit}`;

            } else if (event.target.name === "recurrenceIntervalUnit") {
                const temp = DateUtils.parseInterval(v.recurrenceInterval);
                v.recurrenceInterval = `${temp.value}-${value}`;

            } else if (event.target.name === "successorIntervalValue") {
                const temp = DateUtils.parseInterval(v.successorInterval);
                v.successorInterval = `${value}-${temp.unit}`;

            } else if (event.target.name === "successorIntervalUnit") {
                const temp = DateUtils.parseInterval(v.successorInterval);
                v.successorInterval = `${temp.value}-${value}`;

            } else {
                v[name] = value;
            }

            setVisitMotive(v);
        }
    }

    function onRecallChange(newRecallVisitMotive: VisitMotive | null) {
        if (newRecallVisitMotive && visitMotive) {
            const v = visitMotive.clone();

            v.recallId = newRecallVisitMotive.id;
            v.recallLandingPage = newRecallVisitMotive.landingPage.clone();

            setVisitMotive(v);
        }
    }

    function onSuccessorChange(newSuccessorVisitMotive: VisitMotive | null) {
        if (newSuccessorVisitMotive && visitMotive) {
            const v = visitMotive.clone();

            v.successorId = newSuccessorVisitMotive.id;
            v.successorLandingPage = newSuccessorVisitMotive.landingPage.clone();

            setVisitMotive(v);
        }
    }

    function onRecallSmsTextChange(newValue: string) {
        if (visitMotive) {
            const v = visitMotive.clone();

            v.recallSmsText = newValue;

            setVisitMotive(v);
        }
    }

    function onSuccessorSmsTextChange(newValue: string) {
        if (visitMotive) {
            const v = visitMotive.clone();

            v.successorSmsText = newValue;

            setVisitMotive(v);
        }
    }

    function onReminderSmsTextChange(newValue: string) {
        if (visitMotive) {
            const v = visitMotive.clone();

            v.reminderSmsText = newValue;

            setVisitMotive(v);
        }
    }


    function renderDurationDropdown() {
        return (
            <FormControl fullWidth>
                <InputLabel id="duration-select-label">Dauer</InputLabel>
                <Select
                    name="duration"
                    labelId="duration-select-label"
                    label="Dauer"
                    id="duration-select"
                    value={visitMotive?.duration}
                    onChange={onInputChange}
                >
                    <MenuItem value="5">5 min</MenuItem>
                    <MenuItem value="10">10 min</MenuItem>
                    <MenuItem value="15">15 min</MenuItem>
                    <MenuItem value="20">20 min</MenuItem>
                    <MenuItem value="25">25 min</MenuItem>
                    <MenuItem value="30">30 min</MenuItem>
                    <MenuItem value="35">35 min</MenuItem>
                    <MenuItem value="40">40 min</MenuItem>
                    <MenuItem value="45">45 min</MenuItem>
                    <MenuItem value="55">55 min</MenuItem>
                    <MenuItem value="60">1 Std. 00</MenuItem>
                    <MenuItem value="75">1 Std. 15</MenuItem>
                    <MenuItem value="90">1 Std. 30</MenuItem>
                    <MenuItem value="105">1 Std. 45</MenuItem>
                    <MenuItem value="120">2 Std. 00</MenuItem>
                    <MenuItem value="135">2 Std. 15</MenuItem>
                    <MenuItem value="150">2 Std. 30</MenuItem>
                    <MenuItem value="165">2 Std. 45</MenuItem>
                    <MenuItem value="180">3 Std. 00</MenuItem>
                    <MenuItem value="195">3 Std. 15</MenuItem>
                    <MenuItem value="210">3 Std. 30</MenuItem>
                    <MenuItem value="225">3 Std. 45</MenuItem>
                    <MenuItem value="240">4 Std. 00</MenuItem>
                    <MenuItem value="270">4 Std. 30</MenuItem>
                    <MenuItem value="300">5 Std. 00</MenuItem>
                    <MenuItem value="360">6 Std. 00</MenuItem>
                    <MenuItem value="420">7 Std. 00</MenuItem>
                    <MenuItem value="480">8 Std. 00</MenuItem>

                </Select>
            </FormControl>

        );
    }


    function renderRecurrenceCountDropdown() {
        return (
            <FormControl>
                <InputLabel id="recurrenceCount-select-label">Wiederholungen</InputLabel>
                <Select
                    name="recurrenceCount"
                    labelId="recurrenceCount-select-label"
                    label="Wiederholungen"
                    id="recurrenceCount-select"
                    value={visitMotive?.recurrenceCount}
                    onChange={onInputChange}
                    style={{ width: 100 }}
                >
                    <MenuItem value="0">ohne</MenuItem>
                    <MenuItem value="-1">stetig</MenuItem>
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                </Select>
            </FormControl>
        );
    }



    function handleSuccessorLandingPageChange(landingPage: LandingPageData) {
        if (visitMotive) {
            const v = visitMotive.clone();

            v.successorLandingPage = landingPage.clone();

            setVisitMotive(v);
        }
    }

    function handleRecallLandingPageChange(landingPage: LandingPageData) {
        if (visitMotive) {
            const v = visitMotive.clone();

            v.recallLandingPage = landingPage.clone();

            setVisitMotive(v);
        }
    }

    function handleLandingPageChange(landingPage: LandingPageData) {
        if (visitMotive) {
            const v = visitMotive.clone();

            v.landingPage = landingPage.clone();

            setVisitMotive(v);
        }
    }



    // recall
    function renderRecurrenceIntervalDropdown() {
        return (
            <Fragment>
                <TextField style={{ marginLeft: 100 }}
                    type="number"
                    name="recurrenceIntervalValue"
                    label="Intervall"
                    value={DateUtils.parseInterval(visitMotive!.recurrenceInterval).value}
                    onChange={onInputChange}
                    inputProps={{ min: 1, max: 999 }}
                />
                <FormControl style={{ marginLeft: 40 }}>
                    <Select
                        name="recurrenceIntervalUnit"
                        id="recurrenceIntervalUnit-select"
                        value={DateUtils.parseInterval(visitMotive!.recurrenceInterval).unit}
                        onChange={onInputChange}
                    >
                        <MenuItem value="d">Tag/e</MenuItem>
                        <MenuItem value="w">Woche/n</MenuItem>
                        <MenuItem value="m">Monat/e</MenuItem>
                        <MenuItem value="y">Jahr/e</MenuItem>

                    </Select>
                </FormControl>
            </Fragment>
        );
    }

    function renderSuccessorIntervalDropdown() {
        return (
            <Fragment>
                <TextField style={{ marginLeft: 0 }}
                    type="number"
                    name="successorIntervalValue"
                    label="Nach"
                    value={DateUtils.parseInterval(visitMotive!.successorInterval).value}
                    onChange={onInputChange}
                    inputProps={{ min: 1, max: 999 }}
                />
                <FormControl style={{ marginLeft: 40 }}>
                    <Select
                        name="successorIntervalUnit"
                        id="successorIntervalUnit-select"
                        value={DateUtils.parseInterval(visitMotive!.successorInterval).unit}
                        onChange={onInputChange}
                    >
                        <MenuItem value="h">Stunde/n</MenuItem>
                        <MenuItem value="d">Tag/e</MenuItem>
                        <MenuItem value="w">Woche/n</MenuItem>
                        <MenuItem value="m">Monat/e</MenuItem>
                        <MenuItem value="y">Jahr/e</MenuItem>

                    </Select>
                </FormControl>
            </Fragment>
        );
    }


    if (isLoading || !currentUser || !(specialities && specialities.length > 0)) return <LoadingPage />;

    if (!visitMotive) return (
        <div className="kt-page-content">
            <h2>Besuchsgrund</h2>

            <div>Dieser Besuchsgrund existiert nicht. Bitte überprüfen Sie Ihre URL.</div>
        </div>
    );

    return (
        <Box className="kt-page">
            <h2>Besuchsgrund {visitMotive && visitMotive.name}</h2>

            <form onSubmit={handleSubmit} autoComplete="turnitoff">

                <Tabs
                    value={selectedTab}
                    onChange={(e, newTab) => setSelectedTab(newTab)}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Allgemein" />
                    <Tab label="Folgetermin" />
                    <Tab label="Recall" />
                    {(currentLocation && currentLocation.features.hasDocuments) && <Tab label="Dokumente" />}
                </Tabs>

                <div className="kt-form-section" hidden={selectedTab !== 0}>

                    <Grid container>

                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                name="name"
                                label="Name"
                                value={visitMotive.name}
                                onChange={onInputChange}
                                required
                                inputProps={{ minLength: 3 }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="specialityId-select-label">Fachrichtung</InputLabel>
                                <Select
                                    name="specialityId"
                                    labelId="specialityId-select-label"
                                    label="Fachrichtung"
                                    id="specialityId-select"
                                    value={visitMotive?.specialityId}
                                    onChange={onInputChange}
                                >
                                    {specialities && specialities.map((speciality) =>
                                        <MenuItem key={speciality.id} value={speciality.id}>{speciality.name}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            {renderDurationDropdown()}
                        </Grid>

                        <Grid item xs={6}>
                            <FormControlLabel
                                control={<Checkbox checked={visitMotive.allowOnlineBooking} onChange={onInputChange} name="allowOnlineBooking" color="default" />}
                                label="Online buchbar"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            {visitMotive.allowOnlineBooking && <TextField
                                fullWidth
                                rows={5}
                                name="patientInfo"
                                value={visitMotive.patientInfo}
                                onChange={onInputChange}
                                label="Patienten Information"
                                multiline
                            />}
                        </Grid>

                        <Grid item xs={6}>
                            {visitMotive.allowOnlineBooking && <TextField
                                fullWidth
                                name="nameForPatient"
                                label="Name für Patienten"
                                value={visitMotive.nameForPatient}
                                onChange={onInputChange}
                                inputProps={{ minLength: 3 }}
                            />}
                        </Grid>
                        <Grid item xs={6}>
                        <FormControlLabel
                                control={<Checkbox checked={visitMotive.reminderSmsCustomTextEnabled} onChange={onInputChange} name="reminderSmsCustomTextEnabled" color="default" />}
                                label="Individueller Erinnerungs SMS Text"
                            />
                        </Grid>

                        <Grid item xs={6}>

                        </Grid>

                        <Grid item xs={6}>
                            {visitMotive.reminderSmsCustomTextEnabled &&
                                <SmsEditor templateMode={true} smsText={visitMotive.reminderSmsText} clientId={currentUser.clientId} locationId={currentUser.locationId} onValueChange={onReminderSmsTextChange} name="reminderSmsText" />
                            }
                        </Grid>

                    </Grid>
                </div>
                <div className="kt-form-section" hidden={selectedTab !== 0}>
                    <h4>Landing Page</h4>

                    <LandingPageCtrl
                        id="landingPage"
                        landingPage={visitMotive.landingPage}
                        clientId={currentClient.id}
                        locationId={currentUser.id}
                        previewUrl={`https://pickadoc.de/appointment/${currentUser.clientId}/${currentUser.locationId}/appointment-${visitMotiveId}`}
                        onChange={handleLandingPageChange}
                    />

                </div>


                <div className="kt-form-section" hidden={selectedTab !== 2}>

                    <Grid container>

                        <Grid item xs={6}>
                            {renderRecurrenceCountDropdown()}
                            {visitMotive.recurrenceCount.toString() !== "0" && renderRecurrenceIntervalDropdown()}
                        </Grid>

                        <Grid item xs={6}>

                        </Grid>


                        {(visitMotive.recurrenceCount !== 0 && visitMotive.recurrenceCount.toString() !== "0") &&
                            <React.Fragment>
                                <Grid item xs={6}>
                                    <VisitMotivePicker
                                        selectedVisitMotiveId={visitMotive.recallId}
                                        visitMotives={visitMotives}
                                        specialities={specialities}
                                        appointmentStart={new Date()}
                                        appointmentEnd={new Date()}
                                        showDuration={false}
                                        onVisitMotiveChange={onRecallChange}
                                        onDurationChange={() => { }}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <SmsEditor templateMode={true} smsText={visitMotive.recallSmsText} clientId={currentUser.clientId} locationId={currentUser.locationId} onValueChange={onRecallSmsTextChange} name="recallSmsText" />
                                </Grid>

                            </React.Fragment>
                        }

                    </Grid>
                </div>
                <div className="kt-form-section" hidden={selectedTab !== 2}>
                    <h4>Landing Page Recall</h4>

                    <LandingPageCtrl
                        id="recall"
                        landingPage={visitMotive.recallLandingPage}
                        clientId={currentClient.id}
                        locationId={currentUser.id}
                        previewUrl={`https://pickadoc.de/appointment/${currentUser.clientId}/${currentUser.locationId}/recall-${visitMotiveId}`}
                        onChange={handleRecallLandingPageChange}
                    />

                </div>


                <div className="kt-form-section" hidden={selectedTab !== 1}>

                    <Grid container>

                        <Grid item xs={6}>
                            <FormControlLabel
                                control={<Checkbox checked={visitMotive.successorEnabled} onChange={onInputChange} name="successorEnabled" color="default" />}
                                label="Folgetermin erstellen"
                            />
                        </Grid>

                        {visitMotive.successorEnabled &&

                            <React.Fragment>
                                <Grid item xs={6}>
                                    {renderSuccessorIntervalDropdown()}
                                </Grid>
                                <Grid item xs={6}>
                                    <VisitMotivePicker
                                        selectedVisitMotiveId={visitMotive.successorId}
                                        visitMotives={visitMotives}
                                        specialities={specialities}
                                        appointmentStart={new Date()}
                                        appointmentEnd={new Date()}
                                        showDuration={false}
                                        onVisitMotiveChange={onSuccessorChange}
                                        onDurationChange={() => { }}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <SmsEditor templateMode={true} smsText={visitMotive.successorSmsText} clientId={currentUser.clientId} locationId={currentUser.locationId} onValueChange={onSuccessorSmsTextChange} name="successorSmsText" />
                                </Grid>

                            </React.Fragment>
                        }

                    </Grid>
                </div>

                <div className="kt-form-section" hidden={selectedTab !== 1}>
                    <h4>Landing Page Folgetermin</h4>

                    <LandingPageCtrl
                        id="successor"
                        landingPage={visitMotive.successorLandingPage}
                        clientId={currentClient.id}
                        locationId={currentUser.id}
                        previewUrl={`https://pickadoc.de/appointment/${currentUser.clientId}/${currentUser.locationId}/successor-${visitMotiveId}`}
                        onChange={handleSuccessorLandingPageChange}
                    />

                </div>

                <div className="kt-form-section" hidden={selectedTab !== 3}>
                    <h4>Dokumente</h4>

                    <p>
                        Wählen Sie hier die Dokumente aus, die für diesen Besuchgrund vom Patienten auszufüllen sind.
                    </p>

                    {documentTemplates.map(doc =>
                        <Grid item xs={6} key={doc.id}>
                            <FormControlLabel
                                control={<Checkbox checked={doc.selected} onChange={(e) => toggleDocumentSelection(doc.id)} color="default" />}
                                label={doc.name}
                            />
                        </Grid>
                    )}


                </div>


                <div className="kt-page-footer">
                    <BackButton />

                    <SaveButton saveStatus={saveStatus} />
                </div>

            </form>
        </Box>
    );
}

export default VisitMotivePage;