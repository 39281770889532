import firebase from 'firebase/compat/app';
import firebaseApp from "./../components/database";

import Invoice from "../../src/shared/src/models/invoice/invoice";
import InvoiceItem, { InvoiceItemType } from "../../src/shared/src/models/invoice/invcoiceItem";
import { logDbReadOperations } from "../../src/utils";
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

const db = firebaseApp.firestore();
const functions = firebase.app().functions('europe-west3');


const InvoicesService = {

    async calculateInvoice(clientId: string, locationId: string, forYear: number, forMonth: number): Promise<Invoice | null> {
        const calculateInvoiceFunc = functions.httpsCallable("calculateInvoice");
        const result = await calculateInvoiceFunc(
            {
                clientId: clientId,
                locationId: locationId,
                forYear: forYear,
                forMonth: forMonth
            });

        if (result && result.data) {
            const inv = new Invoice();
            inv.fromObject(null, result.data);

            return inv;
        }

        return null;
    },


    async getInvoices(clientId: string, locationId: string): Promise<Invoice[] | null> {

        const invoices: Invoice[] = [];

        try {

            if (clientId && locationId) {
                const querySnapshot = await db.collection("clients").doc(clientId)
                    .collection("locations").doc(locationId)
                    .collection("invoices")
                    .get();


                querySnapshot.forEach((doc) => {

                    const invoice = new Invoice();
                    invoice.fromObject(doc.id, doc.data());

                    invoices.push(invoice);
                });

            }

            return invoices;

        } catch (error) {
            console.log("Error getting invoices: ", error);
            return null;
        }

    },


    async getInvoice(clientId: string, locationId: string, invoiceId: string): Promise<Invoice | null> {

        if (!invoiceId) return null;

        const doc = await db.collection("clients").doc(clientId)
            .collection("locations").doc(locationId)
            .collection("invoices").doc(invoiceId)
            .get();

        try {

            if (doc.exists) {

                const invoice = new Invoice();
                invoice.fromObject(invoiceId, doc.data());

                return invoice;

            } else {
                console.log("getInvoice: No such document: " + invoiceId);
                return null;
            }

        } catch (error) {
            console.log("Error getting invoice: ", error);
            return null;
        };

    },

    async getInvoicePDFUrl(clientId: string, locationId: string, invoiceId: string): Promise<string> {

        try {

            const storage = getStorage();

            const storageRef = ref(storage, `clients/${clientId}/locations/${locationId}/invoices/${invoiceId}.pdf`);

            return await getDownloadURL(storageRef);

        } catch (err) {
            console.error(`invoice pdf for ${invoiceId} not found`);
            return "";
        }
    },


    async getInvoiceItemsByDateRange(fromDate: Date, toDate: Date, clientId: string, locationId: string): Promise<InvoiceItem[] | null> {

        const invoiceItems: InvoiceItem[] = [];

        try {

            if (clientId && locationId) {
                const querySnapshot = await db.collection("clients").doc(clientId)
                    .collection("locations").doc(locationId)
                    .collection("invoiceItems")
                    .where("createdAt", ">=", fromDate)
                    .where("createdAt", "<=", toDate)
                    .get();


                querySnapshot.forEach((doc) => {

                    const invoiceItem = new InvoiceItem("", InvoiceItemType.none, 0, 0);
                    invoiceItem.fromObject(doc.data());

                    invoiceItems.push(invoiceItem);
                });

            }

            return invoiceItems;

        } catch (error) {
            console.log("Error getting invoice items by date range: ", error);
            return null;
        }

    },


    startListenForInvoiceItems(fromDate: Date, toDate: Date, clientId: string, locationId: string, changeCallback: (invoiceItems: InvoiceItem[])=>void): () => void {

        if(!fromDate || !toDate || !clientId || !locationId){
            return () => {
                console.log("error in startListenForInvoiceItems: empty paramter");
            };
        }

        return db.collection("clients")
            .doc(clientId)
            .collection("locations")
            .doc(locationId)
            .collection("invoiceItems")
            .where("createdAt", ">=", fromDate)
            .where("createdAt", "<=", toDate)
            .onSnapshot(function(querySnapshot) {
                const invoiceItemList: InvoiceItem[] = [];

                    querySnapshot.forEach((doc) => {
                        const item = new InvoiceItem("", InvoiceItemType.none, 0, 0);
                        item.fromObject(doc.data());

                        invoiceItemList.push(item);

                        //console.log(item.toJSON());
                    });

                    logDbReadOperations("startListenForInvoiceItems", invoiceItemList.length);

                    changeCallback(invoiceItemList);
            });
    }



}

export default InvoicesService;