import React, { useEffect, useState } from "react";
import SaveStatus from "../models/saveStatus";
import SaveIcon from '@mui/icons-material/Save';
import { Check } from "@mui/icons-material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";



interface Props {
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    saveStatus: SaveStatus;
}

const SaveButton:React.FC<Props> = ({saveStatus, color}) => {

    const [internalSaveStatus, setInternalSaveStatus] = useState(saveStatus);

    useEffect(()=>{

        setInternalSaveStatus(saveStatus);

        if(saveStatus === SaveStatus.saved) {
            setTimeout(()=>{
                setInternalSaveStatus(SaveStatus.none);
            }, 2000);
        }

    },[saveStatus]);

    function getString(): string {

        switch (internalSaveStatus) {
            case SaveStatus.isSaving:
                return "SPEICHERN";

            case SaveStatus.saved:
                return "GESPEICHERT";

            case SaveStatus.none:
                return "SPEICHERN";

            default:
                return "SPEICHERN";
        }
    }

    function getIcon(): React.ReactNode {

        switch (internalSaveStatus) {
            case SaveStatus.isSaving:
                return <CircularProgress size={20} />;

            case SaveStatus.saved:
                return <Check />;

            case SaveStatus.none:
                return <SaveIcon />;

            default:
                return <SaveIcon />;
        }

    }

    return (
        <Button
            type="submit"
            variant="contained"
            color={color === undefined ? "primary" : color}
            startIcon={getIcon()}
            disabled={internalSaveStatus === SaveStatus.isSaving}
            className="kt-mobile-no-text-button"
        >
            <span className="kt-hide-on-mobile">{getString()}</span>
        </Button>

    );
}

export default SaveButton;
