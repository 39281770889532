import firebase from 'firebase/compat/app';
import firebaseApp from "./../components/database";

import ClientLocation from "../models/clientLocation";

import VisitMotivesService from "../services/visitMotivesService";
import { getFileExtension } from "../utils";
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

const db = firebaseApp.firestore();

const ClientLocationsService = {

    cache: {} as {[key: string]: ClientLocation},

    async getAllLocations(clientId: string):Promise<ClientLocation[] | null> {

		try {
            let locationList:ClientLocation[] = [];

            const querySnapshot = await db.collection(`clients/${clientId}/locations`).orderBy("name").get();

            if(querySnapshot) {
                querySnapshot.forEach((doc) => {
                    const clientLocation = new ClientLocation();
                    clientLocation.fromObject(doc.id, doc.data());

                    clientLocation.id = doc.id;

                    locationList.push(clientLocation);

                    // update cache
                    ClientLocationsService.cache[clientLocation.id] = clientLocation;
                });
            }

            return locationList;

        } catch(error) {
            console.log("Error getting client locations: ", error);
            return null;
        };

    },

    async getLocation(clientId: string, locationId: string, ignoreCache: boolean = false): Promise<ClientLocation | null> {

        try{

            //console.log(`getLocation ${clientId} ${locationId} ${ignoreCache}`);

            if(!clientId || !locationId){
                return null;
            }

            if(!ignoreCache && ClientLocationsService.cache[locationId]){
                return ClientLocationsService.cache[locationId];
            }

            const doc = await db.collection(`clients/${clientId}/locations`).doc(locationId).get();

            if (doc.exists) {
                const clientLocation = new ClientLocation();
                clientLocation.fromObject(locationId, doc.data());

                // update cache
                ClientLocationsService.cache[clientLocation.id] = clientLocation;

                //console.log(`getLocation ${JSON.stringify(clientLocation)}`);

                return clientLocation;

            } else {
                console.log("getLocation: No such document: " + clientId);
                return null;
            }

        } catch(error) {
            console.log("Error getting client location: ", error);
            return null;
        }

    },



    async addUserId(clientId:string, userId:string, locationId: string): Promise<void> {

        try {
            await db.collection(`clients/${clientId}/locations`).doc(locationId).update({
                userIds: firebase.firestore.FieldValue.arrayUnion(userId)
            });

            // update cache
            if(ClientLocationsService.cache[locationId]){
                const clientLocation = ClientLocationsService.cache[locationId];
                if(clientLocation.userIds.indexOf(userId) === -1){
                    clientLocation.userIds.push(userId);
                }
            }

        } catch(error) {
            console.log("Error adding userId to client location: ", error);
        }
    },

    async removeUserId(clientId:string, userId:string, locationId: string): Promise<void> {

        try {
            await db.collection(`clients/${clientId}`).doc(locationId).update({
                userIds: firebase.firestore.FieldValue.arrayRemove(userId)
            });

             // update cache
             if(ClientLocationsService.cache[locationId]){
                const clientLocation = ClientLocationsService.cache[locationId];

                clientLocation.userIds = clientLocation.userIds.filter(item => item !== userId);

            }

        } catch(error) {
            console.log("Error removing userId to client location: ", error);
        }
    },

    async updateClientLocation(clientLocation: ClientLocation): Promise<string | null> {

        if(clientLocation.id) {

            try {

                // update existing client location
                await db.collection(`clients/${clientLocation.clientId}/locations`).doc(clientLocation.id).set(clientLocation.toJSON(), { merge: true });

                // update cache
                ClientLocationsService.cache[clientLocation.id] = clientLocation;

                return clientLocation.id;

            } catch(error) {
                console.log("Error updating client location: ", error);
                return null;
            };

        } else {
            try {
                // create a new client location
                const result = await db.collection(`clients/${clientLocation.clientId}/locations`).add(clientLocation.toJSON())

                // update cache
                ClientLocationsService.cache[result.id] = clientLocation;

                // copy master visit motives to location
                await VisitMotivesService.cloneFromMasterVisitMotives(clientLocation.clientId, result.id)

                return result.id;

            } catch(error) {
                console.log("Error creating new client location: ", error);
                return null;
            };
        }

    },


    async deleteLocation(clientId: string, locationId: string): Promise<void> {

        try {

            await db.collection(`clients/${clientId}/locations`).doc(locationId).delete();

        } catch(error) {
            console.log("Error deleting client location: ", error);
        };
    },

    async uploadLocationLogo(file: File, clientId: string, locationId: string): Promise<string> {

        try {

            let downloadUrl = "";

            const fileExtension = getFileExtension(file.name);
            const storage = getStorage();

            const storageRef = ref(storage, `clients/${clientId}/locations/${locationId}/logo.${fileExtension}`);
            await uploadBytes(storageRef, file);

            downloadUrl = await getDownloadURL(storageRef);

            return downloadUrl;

        } catch(error) {
            console.error("Error uploading file: ", error);
            return "";
        }
    }

}

export default ClientLocationsService;