import { enUS, deDE, frFR, zhCN, elGR, esES } from '@mui/x-data-grid/locales';
import i18next from 'i18next';

const localeMap = {
    en: enUS,
    de: deDE,
    fr: frFR,
    zh: zhCN,
    el: elGR,
    es: esES
};

export const getDataGridLocaleText = () => {
    const locale = localeMap[i18next.language as keyof typeof localeMap] || enUS;

    return locale.components.MuiDataGrid.defaultProps.localeText;
};
