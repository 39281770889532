import Button from '@mui/material/Button';
import React, { useEffect, useState, useRef } from 'react';
import MessageDialog from './dialogs/messageDialog';
import QRCode from 'react-qr-code';
import { isMobileDevice } from '../utils';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const mimeType = "video/mp4";

export enum VideoRecorderStatus {
    inactive,
    recording,
    finished,
    error
}

interface Props {
    onFinishedRecording: (videoUrl: string, videoBlob: Blob) => void
}



const VideoRecorderCtrl: React.FC<Props> = ({ onFinishedRecording }) => {

    const stream = useRef<any>();

    const mediaRecorder = useRef<any>(null);
    const liveVideoStream = useRef<any>(null);

    const [permission, setPermission] = useState(false);
    const [recordingStatus, setRecordingStatus] = useState(VideoRecorderStatus.inactive);
    const [videoChunks, setVideoChunks] = useState<Blob[]>([]);
    const [videoBlob, setVideoBlob] = useState<Blob | null>(null);
    const [recordedVideo, setRecordedVideo] = useState("");
    const [isDeviceNotFoundDialogOpen, setIsDeviceNotFoundDialogOpen] = useState(false);

    const [mediaDevices, setMediaDevices] = useState<MediaDeviceInfo[]>([]);
    const [selectedMediaDeviceId, setSelectedMediaDeviceId] = useState("");

    useEffect(() => {
        getCameraPermissionAndStreams("");

        return () => {
            closeStreams();
        }
    }, []);

    const closeStreams = () => {
        if (stream.current) {
            stream.current.getTracks().forEach(function (track) {
                track.stop();
            });
        }
    }

    const getCameraPermissionAndStreams = async (selectedCameraId: string) => {

        setRecordedVideo("");
        setSelectedMediaDeviceId(selectedCameraId);

        if ("MediaRecorder" in window) {

            try {

                let videoConstraints: any = {};
                if (selectedCameraId === "" || selectedCameraId === "user" || selectedCameraId === "environment") {
                    videoConstraints.facingMode = selectedCameraId === "" ? "user" : "environment";
                } else {
                    videoConstraints.deviceId = { exact: selectedCameraId };
                }

                const constraints = {
                    audio: false,
                    video: videoConstraints,
                };

                const _stream = await navigator.mediaDevices.getUserMedia(constraints);

                setPermission(true);

                stream.current = _stream;

                if (liveVideoStream.current) {
                    //set videostream to live feed player
                    liveVideoStream.current.srcObject = _stream;
                }

                // get all available cameras
                let _devices = await navigator.mediaDevices.enumerateDevices();
                if (_devices && _devices.length > 0) {
                    _devices = _devices.filter(d => d.kind === "videoinput");
                    setMediaDevices(_devices);
                }

            } catch (err: any) {
                setRecordingStatus(VideoRecorderStatus.error);

                console.error("getCameraPermissionAndStreams: " + err);

                if (err && err.name === "NotFoundError") {
                    setIsDeviceNotFoundDialogOpen(true);
                } else {
                    alert(err);
                }
            }

        } else {
            setRecordingStatus(VideoRecorderStatus.error);

            alert("Ihr Browser unterstützt keine Videoaufnahmen.");
        }
    };

    const switchToDevice = (newDeviceId: string) => {
        //first stop running stream
        stopMediaTracks();

        getCameraPermissionAndStreams(newDeviceId);

    }

    const stopMediaTracks = () => {
        if (liveVideoStream && liveVideoStream.current) {
            liveVideoStream.current.srcObject.getTracks().forEach(track => {
                track.stop();
            })
        }
    };

    const startRecording = () => {
        try {

            if (!stream.current) return;

            setRecordingStatus(VideoRecorderStatus.recording);

            const media = new MediaRecorder(stream.current);

            mediaRecorder.current = media;

            mediaRecorder.current.start();
            let localVideoChunks: Blob[] = [];
            mediaRecorder.current.ondataavailable = (event: BlobEvent) => {
                if (typeof event.data === "undefined") return;
                if (event.data.size === 0) return;
                localVideoChunks.push(event.data);
            }

            setVideoChunks(localVideoChunks);

        } catch (err) {
            setRecordingStatus(VideoRecorderStatus.error);
            console.error("startRecording: " + err);

            closeStreams();
        }
    };

    const stopRecording = () => {
        if (mediaRecorder && mediaRecorder.current) {
            mediaRecorder.current.stop();
            mediaRecorder.current.onstop = () => {
                // create a blob file from the videochunks data
                const _videoBlob = new Blob(videoChunks, { type: mimeType })

                // create a playable URL from the blob file
                const _videoUrl = URL.createObjectURL(_videoBlob);
                setVideoBlob(_videoBlob);
                setRecordedVideo(_videoUrl);
                setVideoChunks([]);

                setRecordingStatus(VideoRecorderStatus.finished);

                onFinishedRecording(_videoUrl, _videoBlob);
            }
        }
    }

    const CameraSelector = () => {

        if (isMobileDevice()) {
            // on mobile devices we show only a button to toggle between front and rear camera
            // use front camera ("user") as default
            const newCamera = selectedMediaDeviceId === "user" ? "environment" : "user";

            return <Button 
                        style={{marginTop: "10px"}}
                        variant="outlined" 
                        onClick={e => switchToDevice(newCamera)}>
                            <i className="fa fa-sync-alt"></i>&nbsp;Kamera wechseln
                    </Button>

        } else if (mediaDevices.length > 1) {

            // show the selector if we have more than one camera
            return <FormControl fullWidth>
                <InputLabel id="camera-selector-label">Andere Kamera auswählen</InputLabel>
                <Select
                    labelId="camera-selector-label"
                    id="camera-selector"
                    value={selectedMediaDeviceId}
                    label="Andere Kamera asuwählen"
                    onChange={e => switchToDevice(e.target.value)}
                >
                    {mediaDevices.map(d =>
                        <MenuItem key={d.deviceId} value={d.deviceId}>{d.label}</MenuItem>

                    )}
                </Select>
            </FormControl>
        }

        return <></>;
    }

    return (
        <div style={{ textAlign: "center" }}>

            <MessageDialog
                visible={isDeviceNotFoundDialogOpen}
                onClose={() => setIsDeviceNotFoundDialogOpen(false)}
                title='Kein passendes Aufnahmegerät gefunden!'
                titleIconFaClassName='far fa-exclamation-triangle'
                message={
                    <>
                        <p>Wir konnten keine passende Kamera und Mikrofon finden.
                            <br />Nutzen Sie am besten den QR-Code um auf Ihrem Smartphone das Video aufzunehmen:</p>
                        <div style={{ textAlign: "center" }}>
                            <QRCode
                                value="https://cal.pickadoc.de/clonr"
                                size={120}
                            />
                        </div>
                    </>
                }
            />

            {(recordingStatus !== VideoRecorderStatus.finished && recordingStatus !== VideoRecorderStatus.error) && <video
                id="videoRecorderPreview"
                width="300"
                autoPlay
                muted
                style={{ border: "1px solid white", borderRadius: "10px" }}
                controls={false}
                playsInline={true}
                ref={liveVideoStream}
                crossOrigin='anonymous'
                preload='metadata'
            ></video>}

            <div>
                {(recordingStatus !== VideoRecorderStatus.recording && recordingStatus !== VideoRecorderStatus.error) && <Button variant="outlined" onClick={startRecording}>Aufnahme starten</Button>}
                {recordingStatus === VideoRecorderStatus.recording && <Button variant="outlined" onClick={stopRecording}>Aufnahme stoppen</Button>}
            </div>

            {recordedVideo && <video
                id="recordedVideoPreview"
                src={recordedVideo}
                width="300"
                autoPlay
                muted
                style={{ border: "1px solid white", borderRadius: "10px" }}
                controls={true}
                playsInline={true}
                preload='metadata'
                crossOrigin='anonymous'
            ></video>}

            <CameraSelector />

        </div>
    );
}

export default VideoRecorderCtrl;