import OpeningHours from "./openingHours";
import NotificationsSettings from "./notificationsSettings";

class ClientLocation {
    id: string = "";
    name: string = "";
    clientId: string = "";

    street: string = "";
    city: string = "";
    postalCode: string = "";
    state: string = "";
    country: string = "";

    googleMapsLink: string = "";

    phoneNumber: string = "";
    faxNumber: string = "";
    email: string = "";
    invoiceEmail: string = "";
    homepage: string = "";
    hasElevator: boolean = false;
    floor: number = 0;
    handicapFriendly: boolean = false;
    languages: string[] = [];
    features: {
        hasRecaller: boolean;
        hasSmsCockpit: boolean;
        hasStaffRostering: boolean;
        hasVacationScheduler: boolean;
        hasDocuments: boolean;
    };
    isEnabled: boolean = true;
    profile: string = "";
    accessInformation: string[] = [];
    publicTransports: string[] = [];
    userIds: string[] = [];
    openingHours: OpeningHours = new OpeningHours();


    notificationsSettings: NotificationsSettings = new NotificationsSettings();

    logoUrl: string = "";

    sendInvoice: boolean = false;

    showVirtualAppointments: boolean = false;

    searchTags: string[] = [];

    discount: number = 0; // in %, can be used for invoice

    isAddressVisible: boolean = true;
    isOpenHoursVisible: boolean = true;
    isLogoVisible: boolean = true;
    customBookingButtonText: string = "";
    customBookingHeadlineText: string = "";
    customBookingSubHeadlineText: string = "";
    customDoctorDesignation: string = "";

    constructor() {
        this.features = {
            hasRecaller: false,
            hasSmsCockpit: false,
            hasStaffRostering: false,
            hasVacationScheduler: false,
            hasDocuments: true
        };


        this.notificationsSettings = new NotificationsSettings();

        this.openingHours = new OpeningHours();
        this.openingHours.enabled = true;

    }

    clone(): ClientLocation {
        const c = new ClientLocation();

        c.id = this.id;
        c.name = this.name;
        c.clientId = this.clientId;

        c.street = this.street;
        c.city = this.city
        c.postalCode = this.postalCode;
        c.state = this.state;
        c.country = this.country;

        c.googleMapsLink = this.googleMapsLink;

        c.phoneNumber = this.phoneNumber;
        c.faxNumber = this.faxNumber;
        c.email = this.email;
        c.invoiceEmail = this.invoiceEmail;
        c.homepage = this.homepage;
        c.hasElevator = this.hasElevator === true;
        c.floor = this.floor;
        c.handicapFriendly = this.handicapFriendly === true;
        c.languages = [...this.languages];
        c.features = {
            hasRecaller: this.features.hasRecaller === true,
            hasSmsCockpit: this.features.hasSmsCockpit === true,
            hasStaffRostering: this.features.hasStaffRostering === true,
            hasVacationScheduler: this.features.hasVacationScheduler === true,
            hasDocuments: this.features.hasDocuments === true
        }
        c.isEnabled = this.isEnabled === true;
        c.profile = this.profile;
        c.accessInformation = [...this.accessInformation];
        c.publicTransports = [...this.publicTransports];
        c.userIds = [...this.userIds];
        c.openingHours = this.openingHours.clone();

        c.notificationsSettings = this.notificationsSettings.clone();

        c.logoUrl = this.logoUrl;

        c.sendInvoice = this.sendInvoice === true;

        c.showVirtualAppointments = this.showVirtualAppointments === true;

        c.searchTags = [...this.searchTags];

        c.discount = this.discount;

        c.isAddressVisible = this.isAddressVisible === true;
        c.isOpenHoursVisible = this.isOpenHoursVisible === true;
        c.isLogoVisible = this.isLogoVisible === true;
        c.customBookingButtonText = this.customBookingButtonText;
        c.customBookingHeadlineText = this.customBookingHeadlineText;
        c.customBookingSubHeadlineText = this.customBookingSubHeadlineText;
        c.customDoctorDesignation = this.customDoctorDesignation;

        return c;
    }

    toJSON(): object {
        return {
            id: this.id,
            name: this.name,
            clientId: this.clientId,

            street: this.street,
            city: this.city,
            postalCode: this.postalCode,
            state: this.state,
            country: this.country,

            googleMapsLink: this.googleMapsLink,

            phoneNumber: this.phoneNumber,
            faxNumber: this.faxNumber,
            email: this.email,
            invoiceEmail: this.invoiceEmail,
            homepage: this.homepage,
            hasElevator: this.hasElevator === true,
            floor: this.floor,
            handicapFriendly: this.handicapFriendly === true,
            languages: this.languages,
            features: {
                hasRecaller: this.features.hasRecaller === true,
                hasSmsCockpit: this.features.hasSmsCockpit === true,
                hasStaffRostering: this.features.hasStaffRostering === true,
                hasVacationScheduler: this.features.hasVacationScheduler === true,
                hasDocuments: this.features.hasDocuments === true
            },
            isEnabled: this.isEnabled === true,
            profile: this.profile,
            accessInformation: this.accessInformation,
            publicTransports: this.publicTransports,
            userIds: this.userIds,
            openingHours: this.openingHours.toJSON(),
            notificationsSettings: this.notificationsSettings.toJSON(),
            logoUrl: this.logoUrl,
            sendInvoice: this.sendInvoice === true,
            showVirtualAppointments: this.showVirtualAppointments === true,
            searchTags: this.searchTags,
            discount: this.discount,

            isAddressVisible: this.isAddressVisible === true,
            isOpenHoursVisible: this.isOpenHoursVisible === true,
            isLogoVisible: this.isLogoVisible === true,
            customBookingButtonText: this.customBookingButtonText,
            customBookingHeadlineText: this.customBookingHeadlineText,
            customBookingSubHeadlineText: this.customBookingSubHeadlineText,
            customDoctorDesignation: this.customDoctorDesignation
        };
    }

    fromObject(id: string | null, o: any) {

        this.id = id !== null ? id : o.id;

        this.name = o.name ? o.name : "";

        this.clientId = o.clientId ? o.clientId : "";

        this.street = o.street ? o.street : "";
        this.city = o.city ? o.city : "";
        this.postalCode = o.postalCode ? o.postalCode : "";
        this.state = o.state ? o.state : "";
        this.country = o.country ? o.country : "";

        this.googleMapsLink = o.googleMapsLink ? o.googleMapsLink : "";

        this.phoneNumber = o.phoneNumber ? o.phoneNumber : "";
        this.faxNumber = o.faxNumber ? o.faxNumber : "";
        this.email = o.email ? o.email : "";
        this.invoiceEmail = o.invoiceEmail ? o.invoiceEmail : "";
        this.homepage = o.homepage ? o.homepage : "";
        this.hasElevator = o.hasElevator === true;
        this.floor = o.floor ? o.floor : 0;
        this.handicapFriendly = o.handicapFriendly === true;
        this.languages = o.languages ? o.languages : [];

        if (o.features) {
            this.features = {
                hasRecaller: o.features.hasRecaller === true,
                hasSmsCockpit: o.features.hasSmsCockpit === true,
                hasStaffRostering: o.features.hasStaffRostering === true,
                hasVacationScheduler: o.features.hasVacationScheduler === true,
                hasDocuments: o.features.hasDocuments === true
            };
        } else {
            this.features = {
                hasRecaller: false,
                hasSmsCockpit: false,
                hasStaffRostering: false,
                hasVacationScheduler: false,
                hasDocuments: true
            };
        }

        this.isEnabled = o.isEnabled === true;
        this.profile = o.profile ? o.profile : "";
        this.accessInformation = o.accessInformation ? o.accessInformation : [];
        this.publicTransports = o.publicTransports ? o.publicTransports : [];
        this.userIds = o.userIds ? o.userIds : [];

        if (o.openingHours) {
            this.openingHours.fromObject(o.openingHours);
        } else {
            this.openingHours = new OpeningHours();
        }
        this.openingHours.enabled = true;

        if (o.notificationsSettings) {
            this.notificationsSettings.fromObject(o.notificationsSettings);
        } else {
            this.notificationsSettings = new NotificationsSettings();
        }

        this.logoUrl = o.logoUrl ?? "";

        this.sendInvoice = o.sendInvoice === true;

        this.showVirtualAppointments = o.showVirtualAppointments === true;

        this.searchTags = o.searchTags ?? [];

        this.discount = o.discount ?? 0;

        this.isAddressVisible = o.isAddressVisible === undefined ? true : o.isAddressVisible === true;
        this.isOpenHoursVisible = o.isOpenHoursVisible === undefined ? true : o.isOpenHoursVisible === true;
        this.isLogoVisible = o.isLogoVisible === undefined ? true : o.isLogoVisible === true;
        this.customBookingButtonText = o.customBookingButtonText ?? "";
        this.customBookingHeadlineText = o.customBookingHeadlineText ?? "";
        this.customBookingSubHeadlineText = o.customBookingSubHeadlineText ?? "";
        this.customDoctorDesignation = o.customDoctorDesignation ?? "";
    }

};


export default ClientLocation;

