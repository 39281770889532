import React, { useState, useEffect, useContext } from 'react';

import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';

import Dialog from "../dialog";
import PatientsService from "../../../services/patientsService";
import AppointmentsService from "../../../services/appointmentsService";
import Patient, { PatientScore } from '../../../models/patient';
import Appointment from '../../../models/appointment';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PhoneIcon from '@mui/icons-material/Phone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { DataGrid, deDE } from '@mui/x-data-grid';
import DateUtils from '../../../shared/src/utils/dateUtils';
import Utils from '../../../shared/src/utils/utils';
import StarsInputCtrl from '../../starsInputCtrl';
import SearchTagsDialog from '../searchTagsDialog';
import dayjs from 'dayjs';
import { GlobalContext } from '../../../GlobalContext';
import SignedDocumentsTab from './signedDocumentsTab';
import { DateValidationError } from '@mui/x-date-pickers-pro';


function PatientDialogBody(props) {

    const [patient, setPatient] = useState<Patient>(props.patient);
    const [selectedTab, setSelectedTab] = useState("patient.main");
    const [appointments, setAppointments] = useState<Appointment[]>([]);
    const [isSearchTagsDialogVisible, setIsSearchTagsDialogVisible] = useState(false);

    const [birthDateError, setBirthDateError] = React.useState<DateValidationError | null>(null);
    
    const { currentUser } = useContext(GlobalContext);

    useEffect(() => {
        if (props.visible) {
            setSelectedTab("patient.main");
        }
    }, [props.visible]);

    useEffect(() => {
        setPatient(props.patient);
        setAppointments([]);

        if(props.patient.id) console.log(`patient id: ${props.patient.id}`);

    }, [props.patient]);


    const errorBirthDateMessage = React.useMemo(() => {
        
        switch (birthDateError) {

            case 'invalidDate':
                return 'Bitte geben Sie ein gültiges Geburtsdatum ein.';

            default:
                return '';
        }
    }, [birthDateError]);


    function handleInputChange(event) {

        const target = event.target;
        const name = target.name;
        var value = "" as any;

        switch (target.type) {
            case "checkbox":
                value = target.checked;
                break;

            case "date":
                value = event.target.valueAsDate;
                break;

            default:
                value = target.value;
        }

        if (name === "privateInsurance") {
            value = value === "true";
        }

        const modifiedPatient = patient.clone();

        modifiedPatient[name] = value;

        setPatient(modifiedPatient);

    }

    function handleRatingChange(newScore: PatientScore) {
        patient.score = newScore;
        setPatient(patient.clone());
    }

    async function handleSaveDialog() {
        if (currentUser) {
            await PatientsService.updatePatient(patient, currentUser.clientId, currentUser.locationId);
        }

        props.onSave();
    }

    function handleCloseDialog() {
        props.onClose();
    }


    function getDialogTitle() {
        var p = patient;

        if (p.id) {
            let fullName = `${p.firstName} ${p.lastName.toUpperCase()}`;

            if (p.title && p.title.length > 1) {
                fullName = `${p.title} ${fullName}`;
            }

            return `${p.gender === "m" ? "PATIENT" : "PATIENTIN"} - ${Utils.getGenderString(p.gender)} ${fullName}`;
        } else {
            return "NEUER PATIENT";
        }
    }

    function getBirthDate(): dayjs.Dayjs | null {
        if (patient && patient.birthDate) {
            const bDate = DateUtils.getDate(patient.birthDate);

            if (bDate) {
                const month = Utils.getWithLeadingZero(bDate.getMonth() + 1);
                const day = Utils.getWithLeadingZero(bDate.getDate());

                return dayjs(`${bDate.getFullYear()}-${month}-${day}`);
            }
        }

        return null;
    }

    function handleBirthDateChange(newDate: dayjs.Dayjs | null) {
        if (newDate) {
            const bDate = DateUtils.getDate(newDate.toDate());
            if (bDate) {
                patient.birthDate = bDate;
                setPatient(patient);
            }
        }
    }

    function onDeleteSearchTag(tagName: string) {
        patient.tags = patient.tags.filter(e => e !== tagName.toLowerCase());

        setPatient(patient.clone());
    }

    function onCloseSearchTagsDialog(newSearchTag: string) {

        if (newSearchTag !== "" && !patient.tags.includes(newSearchTag.toLowerCase())) {
            patient.tags.push(newSearchTag);

            setPatient(patient.clone());
        }

        setIsSearchTagsDialogVisible(false);
    }

    async function handleTabChange(newTab) {
        setSelectedTab(newTab);

        if (newTab === "patient.history" && appointments.length === 0 && patient && patient.appointments && currentUser) {
            const items = await AppointmentsService.getAppointmentsByPatientId(patient.id, currentUser.clientId, currentUser.locationId);
            if (items) {

                const tempItems: Appointment[] = [];

                items.forEach(element => {
                    if (element) {
                        tempItems.push(element);
                    }
                });

                tempItems.sort(function compare(a, b) {
                    const dateA = DateUtils.getDate(a.start) as any;
                    const dateB = DateUtils.getDate(b.start) as any;
                    return dateB - dateA;
                });
                setAppointments(tempItems);
            }

        }
    }

    // async function deleteAllAppointments(){
    //     if(window.confirm("Wollen Sie wirklich alle Termine dieses Patienten löschen?")){
    //         if(window.confirm("Dies kann nicht rückgängig gemacht werden. Trotzdem fortfahren?")){
    //             for (let i = 0; i < appointments.length; i++) {
    //                 const appointment = appointments[i];

    //                 if(appointment.patient.lastName === "Tzannis" && appointment.patient.firstName === "Kiriakos"){
    //                     console.log(`deleting appointment on ${appointment.start.toLocaleDateString()} for ${appointment.patient.lastName} ${appointment.patient.firstName}`);
    //                     await AppointmentsService.deleteAppointment(appointment);
    //                 }

    //             }

    //         }
    //     }
    // }

    async function unlockPatient() {
        if (currentUser) {
            await PatientsService.resetWrongLoginsCounter(patient, currentUser.clientId, currentUser?.locationId);
            patient.wrongLoginsCounter = 0;
            setPatient(patient.clone());
        }
    }

    async function updateLastAppointmentStatus() {
        if (currentUser) {
            await PatientsService.updatePatientStatus(patient, currentUser.clientId, currentUser.locationId);
        }
    }

    function renderMainTab() {
        if (selectedTab !== "patient.main") return "";
        return (
            <div className="kt-tab-page" style={{width: "100%"}}>
                {/* <Button onClick={updateLastAppointmentStatus}>Letzten Termin updaten</Button> */}
                <Box className="kt-form-section">
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <FormControl component="fieldset">
                                <RadioGroup row aria-label="position" name="position" defaultValue="left" onChange={handleInputChange}>
                                    <FormControlLabel
                                        value="m"
                                        control={<Radio color="default" />}
                                        label="Herr"
                                        labelPlacement="start"
                                        name="gender"
                                        checked={patient.gender === "m"}
                                    />
                                    <FormControlLabel
                                        value="f"
                                        control={<Radio color="default" />}
                                        label="Frau"
                                        labelPlacement="start"
                                        name="gender"
                                        checked={patient.gender === "f"}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControlLabel
                                control={<Checkbox checked={patient.newPatient} onChange={handleInputChange} name="newPatient" color="default" />}
                                label="Neuer Patient"
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} style={{ paddingTop: "25px" }}>
                            <StarsInputCtrl
                                rating={patient.score}
                                onChange={handleRatingChange}
                            />
                        </Grid>


                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                name="title"
                                label="Titel"
                                value={patient.title}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <span>Tags:</span>
                            <IconButton aria-label="add" onClick={() => setIsSearchTagsDialogVisible(true)}>
                                <AddCircleOutlineIcon />
                            </IconButton>
                            {patient.tags.map((tag, index) =>
                                <Chip
                                    key={index}
                                    label={tag.toUpperCase()}
                                    size="small"
                                    onDelete={() => onDeleteSearchTag(tag)}
                                    style={{ marginRight: "2px" }}
                                />
                            )}

                        </Grid>
                    </Grid>


                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                name="lastName"
                                label="Nachname"
                                value={patient.lastName}
                                //disabled={patient.id !== ""}
                                onChange={handleInputChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                name="firstName"
                                label="Vorname"
                                value={patient.firstName}
                                //disabled={patient.id !== ""}
                                onChange={handleInputChange}
                                required
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                name="birthName"
                                label="Geburtsname"
                                value={patient.birthName}
                                //disabled={patient.id !== ""}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                label="Geburtsdatum"
                                value={getBirthDate()}
                                onError={(newError) => setBirthDateError(newError)}
                                format="DD.MM.YYYY"
                                onChange={handleBirthDateChange}
                                slotProps={{
                                    textField: {
                                      required: true,                                              
                                      helperText: errorBirthDateMessage,
                                      color: getBirthDate() ? "primary" : "error"
                                    },
                                  }}
                            />
                        </Grid>
                    </Grid>


                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                name="mobilePhoneNumber"
                                value={patient.mobilePhoneNumber}
                                label="Handynummer"
                                required
                                onChange={handleInputChange}
                                autoComplete="turnitoff"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <PhoneIphoneIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                name="phoneNumber"
                                value={patient.phoneNumber.toString()}
                                label="Festnetzanschluss"
                                className="kt-border-left"
                                onChange={handleInputChange}
                                autoComplete="turnitoff"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <PhoneIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>


                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                type="email"
                                name="email"
                                value={patient.email}
                                label="E-Mail-Adresse"
                                onChange={handleInputChange}
                                autoComplete="turnitoff"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <AlternateEmailIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="privateInsurance-select-label">Versicherung</InputLabel>
                                <Select
                                    name="privateInsurance"
                                    labelId="privateInsurance-select-label"
                                    label="Versicherung"
                                    id="privateInsurance-select"
                                    value={patient.privateInsurance.toString()}
                                    onChange={handleInputChange}
                                >
                                    <MenuItem value="false">Gesetzlich versichert</MenuItem>
                                    <MenuItem value="true">Privat versichert</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>


                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                name="street"
                                value={patient.street}
                                label="Straße"
                                onChange={handleInputChange}
                                autoComplete="turnitoff"
                            />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <TextField
                                fullWidth
                                name="postalCode"
                                value={patient.postalCode}
                                label="PLZ"
                                onChange={handleInputChange}
                                autoComplete="turnitoff"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                name="city"
                                value={patient.city}
                                label="Stadt"
                                onChange={handleInputChange}
                                autoComplete="turnitoff"
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                name="familyDoctorName"
                                value={patient.familyDoctorName}
                                label="Hausarzt"
                                onChange={handleInputChange}
                                autoComplete="turnitoff"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {(patient.importId && patient.importSource) &&
                                <TextField
                                    fullWidth
                                    label={`${patient.importSource} ID`}
                                    value={patient.importId}
                                    disabled={true}
                                />
                            }
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={patient.smsAllowed}
                                        onChange={handleInputChange}
                                        name="smsAllowed"
                                        color="primary"
                                    />
                                }
                                label="SMS"
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={patient.emailAllowed}
                                        onChange={handleInputChange}
                                        name="emailAllowed"
                                        color="primary"
                                    />
                                }
                                label="Email"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {(patient.smsAllowed || patient.emailAllowed) &&
                                <Grid container>
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={patient.reminderAllowed}
                                                    onChange={handleInputChange}
                                                    name="reminderAllowed"
                                                    color="primary"
                                                />
                                            }
                                            label="Terminerinnerungen"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={patient.marketingAllowed}
                                                    onChange={handleInputChange}
                                                    name="marketingAllowed"
                                                    color="primary"
                                                />
                                            }
                                            label="Marketing-Nachrichten"
                                        />
                                    </Grid>
                                </Grid>
                            }
                        </Grid>

                        {patient.wrongLoginsCounter >= 3 &&
                            <Grid item xs={12} sm={6}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <button type="button" className="kt-delete-button" onClick={unlockPatient}><i className="far fa-unlock"></i> Login entsperren</button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }


                    </Grid>

                </Box>

                {patient.createdAt &&
                    <Grid item xs={12} sm={6}>
                        <Grid container>
                            <Grid item xs={6}>
                                <span style={{ fontSize: "12px" }}>Erstellt: {DateUtils.getDateTimeString(patient.createdAt)}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </div>
        );
    }

    function getComments(appointment: Appointment) {

        let result = "";

        if (appointment.createdBy === "recaller") {
            result = "Recall ";

        } else if (appointment.createdBy === "predecessor") {
            result = "Folgetermin ";
        }

        switch (appointment.status) {
            case "needsConfirmation":
                result += "unbestätigt";
                break;

            case "declined":
                result += "abgesagt";
                break;

            case "confirmed":
                //result += "bestätigt";
                break;

            default:
                break;
        }

        return result;
    }

    function renderHistoryTab() {
        if (selectedTab !== "patient.history") return "";

        let rows: any = [
            { id: 1, start: 'Hello', visitMotive: 'World', calendar: 'Dr. Petsas' },
            { id: 2, start: 'XGrid', visitMotive: 'is Awesome', calendar: 'Dr. Petsas' },
            { id: 3, start: 'Material-UI', visitMotive: 'is Amazing', calendar: 'Dr. Petsas' }
        ];

        rows = appointments.map((item) => {
            return {
                id: item.id,
                start: item.start,
                visitMotive: item.visitMotive.name,
                calendar: item.calendar.name,
                comment: getComments(item)
            }
        });

        const columns: any[] = [
            { field: 'start', headerName: 'Datum', width: 150, type: "date" },
            { field: 'visitMotive', headerName: 'Besuchsgrund', width: 250 },
            { field: 'calendar', headerName: 'Terminkalender', width: 200 },
            { field: 'comment', headerName: 'Anmerkung', width: 250 }
        ];

        function onRowClick(rowParams: any) {
            //alert(rowParams.row.id);
        }

        return (
            <div className="kt-tab-page">
                <Box className="kt-form-section" style={{ height: 600, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        onRowClick={onRowClick}
                        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                    />
                </Box>
                {/* <Button onClick={deleteAllAppointments}>Alle Termine löschen</Button> */}
            </div>
        );

    }

    function renderDocumentsTab() {
        if (selectedTab !== "patient.documents") return "";

        return (
            <SignedDocumentsTab
                patient={patient}
            />
        );
    }

    function renderCommentsTab() {
        if (selectedTab !== "patient.comments") return "";
        return <div className="kt-tab-page">
            <Box className="kt-form-section">
                <TextField
                    fullWidth
                    rows={5}
                    name="comments"
                    value={patient.comments}
                    onChange={handleInputChange}
                    label="Bemerkungen"
                    multiline
                />
            </Box>
        </div>
    }


    if (patient === null) {
        return null;
    }

    return (
        <React.Fragment>
            <Dialog
                visible={props.visible}
                title={getDialogTitle()}
                alertTitle={patient.wrongLoginsCounter >= 3 ? " - LOGIN GESPERRT" : ""}
                onClose={handleCloseDialog}
                onSave={handleSaveDialog}
                tabs={["patient.main", "patient.history", "patient.documents", "patient.comments"]}
                onTabChange={handleTabChange}
                newTabSelection={selectedTab}
                fullWidth={true}
            >
                {renderMainTab()}
                {renderHistoryTab()}
                {renderDocumentsTab()}
                {renderCommentsTab()}
            </Dialog>

            <SearchTagsDialog
                visible={isSearchTagsDialogVisible}
                onClose={(newSelectedTag) => onCloseSearchTagsDialog(newSelectedTag)}
            />

        </React.Fragment>
    );

}

export default PatientDialogBody;
