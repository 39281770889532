import { useContext, useEffect } from "react";

import { Link, useLocation } from "react-router-dom";

import { GlobalContext } from "../../GlobalContext";
import React from "react";
import Tooltip from "@mui/material/Tooltip";
import Fab from "@mui/material/Fab";
import { isMobileDevice } from "../../utils";

interface Props {
    page?: string;
}


const SettingsSideMenu: React.FC<Props> = ({ page }) => {

    const location = useLocation();

    const { currentUser } = useContext(GlobalContext);
    const { currentClient } = useContext(GlobalContext);
    const { isSidebarVisible, setIsSidebarVisible, currentLocation, calendars } = useContext(GlobalContext);

    const [expanded, setExpanded] = React.useState("");

    useEffect(() => {

        // make sure that on page load, the correct item is expanded
        if (isPage("clientlocations") || isPage("rooms") || isPage("devices") || isPage("users") || isPage("calendars")
            || isPage("specialities") || isPage("visitimotives")) setExpanded("calendr");

        if (isPage("documents")) setExpanded("signr");

    }, []);

    const handleOnItemClick = (item: menuItemType) => {
        if(item.link) {
            setIsSidebarVisible(false);
        }
    }

    const handleChange = (menuItem: menuItemType) => {
        if (expanded === menuItem.id) {
            setExpanded("");
        } else {
            setExpanded(menuItem.id);
        }

        handleOnItemClick(menuItem);
    };

    function isLocation(locationToCheck: string) {
        return location?.pathname.toLowerCase().indexOf(locationToCheck.toLowerCase()) !== -1;
    }

    function isPage(page) {
        return page?.toLowerCase().indexOf(page.toLowerCase()) !== -1;
    }

    function getMenuClassName(currentLocation: string | undefined, locationToCheck: string) {
        return currentLocation === locationToCheck ? "kt-side-menu-item kt-selected" : "kt-side-menu-item";
    }

    type menuItemType = {
        id: string,
        icon?: string,
        text: string | JSX.Element,
        link?: string,
        linkTarget?: string,
        visible?: boolean,
        subMenus?: menuItemType[],
    }
    const menuItems: menuItemType[] = [
        {
            id: "calendr",
            text: "CalendR", icon: "fal fa-calendar-alt",
            subMenus: [
                { id: "appointments", text: "Termine", link: "/calendr" },
                { id: "clientlocations", text: "Standorte", link: "/settings/clientlocations", visible: !isMobileDevice() },
                { id: "rooms", text: "Räume", link: "/settings/rooms" },
                { id: "devices", text: "Ressourcen", link: "/settings/devices" },
                { id: "users", text: "Mitarbeiter", link: "/settings/users" },
                { id: "calendars", text: "Kalender", link: "/settings/calendars" },
                { id: "specialities", text: "Besuchsgr. Gruppen", link: "/settings/specialities" },
                { id: "visitmotives", text: "Besuchsgründe", link: "/settings/visitmotives" },
                //{ text: "Import/Export", link: "/settings/export" },
            ]
        },
        {
            id: "signr",
            text: "SignR", icon: "fal fa-paste", visible: (currentLocation && currentLocation.features.hasDocuments),
            subMenus: [
                { id: "documents", text: "Alle Dokumente", link: "/settings/documents" },
            ]
        },
        {
            id: "clonr",
            text: <span>ClonR <i style={{ background: "none", color: "#d862c8" }} className="fa fa-stars"></i></span>, icon: "fal fa-video",
            subMenus: [
                { id: "videoclone", text: "Clone erstellen", link: "/clonr/videoclone" },
                { id: "voice", text: "Voice erstellen", link: "/clonr/voice" },
                { id: "video", text: "Videos", link: "/clonr/video" },
                { id: "clonrsettings", text: "Einstellungen", link: "/clonr/settings" },
            ]
        },
        // {
        //     id: "calr", text: "Anrufe", icon: "fal fa-phone", link: "/todos",
        // },
        {
            id: "notifications", text: "SMS Einstellungen", icon: "fal fa-envelope", link: "/settings/notifications",
        },
        {
            id: "events", text: "Ereignisse", icon: "fal fa-list-ul", link: "/settings/events",
        },
        {
            id: "profile",
            text: "Profil", icon: "fal fa-user",
            subMenus: [
                { id: "billing", text: "Rechnungen / Pakete", link: "https://billing.stripe.com/p/login/28oaGu9Qe1HpeaIbII", linkTarget: "_blank" },
                { id: "clientlandingpage", text: "Praxisprofil", link: `https://pickadoc.de/profile/${currentClient?.id}/${currentUser?.locationId}`, linkTarget: "_blank" },
                { id: "logout", text: "Ausloggen", link: `/logout` },
            ]
        },
        {
            id: "dashboard", text: "Statistiken", icon: "fal fa-tachometer-alt-fast", link: "/dashboard",
        },
        {
            id: "todos", text: "Aufgaben", icon: "fal fa-clipboard-list-check", link: "/todos",
        },
    ]


    return (
        <>
            <div className={isSidebarVisible ? "kt-side-menu kt-white-side-menu kt-settings-side-menu noselect" : "kt-side-menu kt-white-side-menu kt-settings-side-menu kt-hide-on-mobile noselect"}>
                {menuItems.map((item, index) => (
                    <>
                        <Link
                            className={`kt-settings-side-menu-item ${page?.toLowerCase() === item.id ? "kt-active" : ""}`}
                            to={item.link ?? "#"}
                            target={item.linkTarget ?? "_self"}
                            onClick={() => { handleChange(item) }}
                        >
                            <div className="kt-menu-icon">
                                <i className={item.icon} aria-hidden="true"></i>
                            </div>
                            <span className="kt-text">{item.text}</span>

                            {item.subMenus && (
                                expanded === item.id ? <i className="kt-expand-icon fal fa-angle-down"></i> : <i className="kt-expand-icon fal fa-angle-right"></i>
                            )}

                        </Link>
                        {(item.subMenus && expanded === item.id) && (
                            item.subMenus.map((subItem, subIndex) => (
                                <Link
                                    className={`kt-settings-side-submenu-item ${page?.toLowerCase() === subItem.id ? "kt-active" : ""}`}
                                    to={subItem.link ?? "#"}
                                    target={subItem.linkTarget ?? "_self"}
                                    onClick={ () => handleOnItemClick(subItem)}
                                >
                                    <span className="kt-text">{subItem.text}</span>
                                </Link>
                            ))
                        )}
                    </>
                ))}

            </div>

            {isLocation("settings") && (
                <Tooltip title="Brauchen Sie Hilfe beim Einrichten Ihrer Praxis? Wir helfen Ihnen persönlich weiter!" arrow>
                    <Fab
                        color="primary"
                        aria-label="help"
                        style={{
                            position: 'fixed',
                            bottom: '10px',
                            right: '10px',
                            zIndex: 1000,
                            fontSize: '20px',
                        }}
                        component="a"
                        href="https://pickadoc.de/profile/7DT3VZuUqdwwJy4gLWlm/0qi6GDOAxiXeGEUiYL85"
                        target="_blank"
                    >
                        <i className="fal fa-calendar-check" />
                    </Fab>
                </Tooltip>
            )}

        </>
    )

}

export default SettingsSideMenu;