import { Outlet } from "react-router-dom";
import NotAllowedPage from "./notAllowedPage";
import User from "../../models/user";

const ProtectedRoute = ({
    isAllowed = false,
    currentUser = null as (null | User),
    children = null as any
}) => {

    if (!isAllowed) {
        return <NotAllowedPage currentUser={currentUser}/>

    }

    return (children ? children : <Outlet />) as any
};


export default ProtectedRoute;