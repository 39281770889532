
import {useRef, useEffect, useState} from "react";

import moment from 'moment';
import "moment/min/locales.min";

import { isGermanMobileNumber } from "./../utils";

import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";

import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";

import CallIcon from "@mui/icons-material/Call";
import CallEndIcon from "@mui/icons-material/CallEnd";
import SmsIcon from '@mui/icons-material/Sms';

import MessagesService from "../services/messagesService";
import Message from "../models/message";

//import adapter from "webrtc-adapter";

import VideoRoom, { Attendee } from "../models/videoRoom";
import VideoRoomsService from "../services/videoRoomsService";
import SmsService from "../services/smsService";
import DateUtils from "../../src/shared/src/utils/dateUtils";
import { InvoiceItemType } from "../../src/shared/src/models/invoice/invcoiceItem";

import Utils from '../../src/shared/src/utils/utils';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { useContext } from "react";
import { GlobalContext } from "../GlobalContext";



// var ice = {"iceServers": [
//     {"url": "stun:stun.l.google.com:19302"},
//     {"url": "turn:turnserver.com", "username": "user", "credential": "pass"}
//   ]};

const configuration = {
    iceServers: [
        {
            urls: [
                'stun:stun.services.mozilla.com',
                'stun:stun1.l.google.com:19302',
                'stun:stun2.l.google.com:19302'
            ]
        },
        {
            urls: "turn:turn.pickadoc.de:3478",
            username: "pickaturn",
            credential: "pat164888"
        }
    ],
    iceCandidatePoolSize: 10
    //sdpSemantics: 'plan-b' // see: https://github.com/cordova-rtc/cordova-plugin-iosrtc/issues/458
};

enum CallState {
    running,
    calling,
    ended
}

interface Props {
    userId: string,
    videoRoomId: string,
    locationId: string
}

const VideoRoomCtrl: React.FC<Props> = ({userId, videoRoomId, locationId}) => {

    const localStreamRef = useRef<MediaStream | null>(null);
    const localVideoRef = useRef<HTMLVideoElement>(null);

    const remoteStreamRef = useRef<MediaStream | null>(null);
    const remoteVideoRef = useRef<HTMLVideoElement>(null);

    const receivedMessagesIdsRef = useRef<string[]>([]);

    const iceCandidates = useRef<RTCIceCandidate[]>([]);

    const pcRef = useRef<RTCPeerConnection>();

    const answerReceived = useRef<boolean>(false);
    const isOfferer = useRef<boolean>(false);

    const [isHost, setIsHost] = useState(false);


    const [cameraIsRunning, setCameraIsRunning] = useState(true);
    const [micIsRunning, setMicIsRunning] = useState(true);

    const callStateRef = useRef(CallState.ended); // need this ref to check state in the timer intervall
    const [callState, setCallState] = useState(CallState.ended);

    const callStartTime = useRef(new Date());
    const [callDuration, setCallDuration] = useState(moment.duration(0));

    const [videoRoom, setVideoRoom] = useState<VideoRoom | null>(null);

    const [localUser, setLocalUser] = useState<Attendee | null>(null);
    const [remoteUser, setRemoteUser] = useState<Attendee | null>(null);

    const [isSendingSms, setIsSendingSms] = useState(false);

    const {currentClient} = useContext(GlobalContext);
    const {currentLocation} = useContext(GlobalContext);

    const constraints = {
        audio: true,
        video: true
    }

    useEffect(() => {

        isOfferer.current = false;

        //console.log("Browser: " + adapter.browserDetails.browser);

        initLocalStream();

        const unsubscribeMessages = MessagesService.startListenForMessages(readMessages);

        window.addEventListener('beforeunload', onBeforeUnload);

        // clean up
        return () => {

            closeCall();

            closeLocalStream();

            console.log("unsubscribing messages");
            unsubscribeMessages();

            // set user status to offline
            VideoRoomsService.setAttendeesOnlineStatus(videoRoomId, userId, false);

            window.removeEventListener('beforeunload', onBeforeUnload);
        };

    }, []);

    useEffect(() => {
        console.log(`video room id: ${videoRoomId}`);

        let unsubscribeVideoRoom = null as any ;

        if(videoRoomId){
           unsubscribeVideoRoom  = VideoRoomsService.startListenForVideoRoom(videoRoomId, onRoomUpdate)
        }

        // clean up
        return () => {
            if(unsubscribeVideoRoom && typeof unsubscribeVideoRoom === "function"){
                console.log("unsubscribing room");
                unsubscribeVideoRoom();
            }
        };

    }, [videoRoomId]);

    useEffect(() => {
        const durationInterval = setInterval(() => {

            if(callStateRef.current === CallState.running) {
                const start = moment(callStartTime.current);
                const now = moment();

                const duration = moment.duration(now.diff(start));

                setCallDuration(duration);
            }

        }, 1000);

        return ()=> {
            clearInterval(durationInterval);
        }

    }, []);

    function onBeforeUnload(e) {
        e.preventDefault();

        // set user status to offline
        //VideoRoomsService.setAttendeesOnlineStatus(videoRoomId, userId, false);
    }

    async function onRoomUpdate(_videoRoom: VideoRoom){
        if(_videoRoom) {
            console.log("found room");
            setVideoRoom(_videoRoom);

            let _localUser: Attendee | null = null;
            let _remoteUser: Attendee | null = null;

            for (let i = 0; i < _videoRoom.attendees.length; i++) {
                const attendee = _videoRoom.attendees[i];

                if(attendee.id === userId){
                    _localUser = attendee;
                    setIsHost(attendee.isHost === true);
                    isOfferer.current = attendee.isHost === true;

                    if(attendee.isHost){
                        console.log("is host");
                    }

                    if(!attendee.isOnline){
                        attendee.isOnline = true;
                        VideoRoomsService.updateRoom(_videoRoom);
                    }

                } else {
                    _remoteUser = attendee;
                }
            }

            if(_localUser && _remoteUser){
                setLocalUser(_localUser);
                setRemoteUser(_remoteUser);

            } else {
                alert("Dieser Videoraum ist offline, bitte wenden Sie Sich an die Praxis.")
            }

        }
    }

    async function init() {
        console.log("creating new peer connection");
        pcRef.current = undefined;

        pcRef.current = new RTCPeerConnection(configuration);
        const pc = pcRef.current;

        pc.addEventListener("icecandidate", (event) => {
            console.log("onicecandidate");

            if(event.candidate) {
                sendMessage(userId, JSON.stringify({'ice': event.candidate}));
             } else {
                 console.log("Sent All Ice");
             }
        });

        pc.addEventListener("addstream", async (event: any) => {
            if(remoteVideoRef && remoteVideoRef.current) {
                console.log("adding remote stream");
                remoteVideoRef.current.srcObject = event.stream;
            }
        });

        pc.addEventListener('track', event => {
            if(event.streams && event.streams.length > 0){
                console.log('Got remote track:', event.streams[0]);

                event.streams[0].getTracks().forEach(track => {
                    console.log('Add a track to the remoteStream:', track);
                    remoteStreamRef.current?.addTrack(track);
                });
            }
        });

        pc.onconnectionstatechange = (event) => {
            switch(pc.connectionState) {
              case "connected":
                // The connection has become fully connected
                setCallState(CallState.running);
                callStateRef.current = CallState.running;
                break;
              case "disconnected":
                // try to reconnect
                startCall();
                break;

              case "failed":
                // One or more transports has terminated unexpectedly or in an error
                setCallState(CallState.ended);
                callStateRef.current = CallState.ended;
                break;
              case "closed":
                // The connection has been closed
                setCallState(CallState.ended);
                callStateRef.current = CallState.ended;
                break;
            }

            console.log(`%c peer connection state: ${pc.connectionState}`, 'background: #483D8B; color: white');
        }

        pc.onsignalingstatechange = (event) =>  {
            console.log(`%c signaling state: ${pc.signalingState}`, 'background: #222; color: #bada55');
        }

        pc.onnegotiationneeded = (event) => {
            console.log("on negotiation needed");
            initRemoteConnection();
        }

        if(!localStreamRef.current){
            await initLocalStream();
        }

        addLocalStreamTracksToPeerConnection();

        //initRemoteConnection();
    }


    function addLocalStreamTracksToPeerConnection() {
        if(localStreamRef.current && pcRef.current){

            localStreamRef.current.getTracks().forEach((track) => {
                if(pcRef.current && pcRef.current.signalingState !== "closed"){
                    console.log("adding local stream tracks to peer connection");
                    pcRef.current.addTrack(track, localStreamRef.current!);
                } else {
                    console.error("cannot add local stream track to peer connection");
                    if(pcRef.current){
                        console.error("peer coonection signaling state: ", pcRef.current.signalingState);
                    }
                }
            });
        }
    }

    async function initRemoteConnection() {
        const pc = pcRef.current;

        if(pc){
            console.log("init remote connection");

            const offerOptions = {
                offerToReceiveAudio: true,
                offerToReceiveVideo: true,
                iceRestart: true
            };
            const offer = await pc.createOffer(offerOptions);
            if(offer){
                await pc.setLocalDescription(offer);
                answerReceived.current = false;
                console.log("sending offer");
                sendMessage(userId, JSON.stringify({'sdp': pc.localDescription})) ;
            }
        }
    }



    async function sendMessage(senderId: string, data: any) {
        //console.log("Sending message...");

        const messageId = await MessagesService.addMessage(new Message(senderId, data))

        if(messageId) {
            //console.log(`Message was sent: message id: ${messageId} - sender id: ${senderId} `);

            await MessagesService.deleteMessage(messageId);
            //console.log(`Message was removed: message id: ${messageId}`);

        } else {
            console.error("Message could not be sent");
        }
    }

    async function readMessages(messages: Message[]) {

        const pc = pcRef.current;

        if(pc){

            for (let i = 0; i < messages.length; i++) {

                const message = messages[i];

                const messageData =  JSON.parse(message.data);
                const senderId = message.senderId;

                const messageAlreadyReceived = receivedMessagesIdsRef.current.includes(message.id);

                if (senderId !== userId && !messageAlreadyReceived) {

                    //console.log(`received message id: ${message.id} - sender id: ${senderId}`);

                    receivedMessagesIdsRef.current.push(message.id);

                    if (messageData.ice !== undefined) {

                        // collect ice candidates to add them later to the peer connection AFTER the remote description was set
                        if(answerReceived.current === false) {
                            console.log("collecting ICE candidate");
                            iceCandidates.current.push(messageData.ice);

                        } else {
                            if(pc.signalingState !== "closed"){
                                console.log("adding ICE candidate");
                                pc?.addIceCandidate(new RTCIceCandidate(messageData.ice));
                            }
                        }

                    } else if (messageData.sdp && messageData.sdp.type === "offer") {
                        console.log("received offer");

                        try {
                            if(pc.signalingState !== "stable") { // see: https://blog.mozilla.org/webrtc/perfect-negotiation-in-webrtc/
                                if (isHost === true) return;
                                await Promise.all([
                                    pc.setLocalDescription({type: "rollback"}),
                                    pc.setRemoteDescription(new RTCSessionDescription(messageData.sdp))
                                ]);

                            } else {
                                await pc.setRemoteDescription(new RTCSessionDescription(messageData.sdp));
                            }

                            const answer = await pc.createAnswer();
                            await pc.setLocalDescription(answer);

                            console.log("sending answer");
                            await sendMessage(userId, JSON.stringify({'sdp': pc.localDescription}));

                            addQueuedIceCandidates();

                        } catch(error) {
                            // crashes sometimes in Safari, if Safari is used to start the call first
                            console.error(error);
                            startCall();
                        }

                    } else if (messageData.sdp && messageData.sdp.type === "answer") {
                        console.log("received answer");

                        answerReceived.current = true;
                        if(pc.signalingState === "have-local-offer") {
                            await pc.setRemoteDescription(new RTCSessionDescription(messageData.sdp));
                            addQueuedIceCandidates();
                        }


                    } else if(messageData.callState && messageData.callState === "ended") {
                        // the other user closed the call
                        closeCall(false);
                    }
                }
            }

        }

    };

    function addQueuedIceCandidates(){
        if(pcRef.current){
            while(iceCandidates.current.length) {
                const candidate = iceCandidates.current.shift();
                console.log("adding collected ICE candidate");
                pcRef.current.addIceCandidate(new RTCIceCandidate(candidate));
            }
        }
    }

    function handleStreamInitError(error) {
        if (error.name === 'ConstraintNotSatisfiedError') {
            alert(`Ihr Gerät unterstützt leider nicht die erforderliche Auflösung.`);
        } else if (error.name === 'PermissionDeniedError' || error.name === "NotAllowedError") {

        alert("Sie haben den Zugriff auf Ihr Mikrofon und Ihre Kamera verweigert. " +
            "Damit wir die Videosprechstunde starten können, müssen Sie der Seite Zugriff auf Ihre Geräte gewähren.");

        } else if(error.name === "NotFoundError") {
            alert("Bitte stellen Sie sicher, dass Sie ein Mikrofon und eine Kamera angeschlossen haben.")

        } else if(error.name === "NotReadableError") {
            alert("Kamera und Mikrofon sind anscheinend schon in Benutzung durch ein anderes Programm oder Website. Bitte schließen Sie dies und laden Sie diese Seite neu.");

        } else {
            alert(`getUserMedia error: ${error}`);
        }

    }


    async function isAudioAndCameraDeviceAvailable(): Promise<boolean> {
        const devices = await navigator.mediaDevices.enumerateDevices();

        let audioInputDeviceFound = false;
        let audioOutputDeviceFound = false;
        let cameraDeviceFound = false;

        for (let i = 0; i < devices.length; i++) {
            const device = devices[i];
            if(device.kind === "audioinput") {
                audioInputDeviceFound = true;
            } else if(device.kind === "audiooutput") {
                audioOutputDeviceFound = true;
            } else if(device.kind === "videoinput") {
                cameraDeviceFound = true;
            }
        }

        return audioInputDeviceFound && audioOutputDeviceFound && cameraDeviceFound;
    }


    async function initLocalStream() {

        if(await isAudioAndCameraDeviceAvailable()) {

            try {
                const stream = await navigator.mediaDevices.getUserMedia(constraints);

                localStreamRef.current = stream;

                if (localVideoRef.current ) {
                    localVideoRef.current.srcObject = stream;

                    enableLocalAudioTrack(micIsRunning);
                    enableLocalVideoTrack(cameraIsRunning);
                }

            } catch(err) {
                handleStreamInitError(err);
                setCallState(CallState.ended);
                callStateRef.current = CallState.ended;
            }

        }

    }

    function closeLocalStream() {

        console.log("closing local stream");

        if(localStreamRef && localStreamRef.current) {
            localStreamRef.current.getTracks().forEach((track) => track.stop());

            //localStreamRef.current = null;
        }
    }

    function toggleCamera() {
        enableLocalVideoTrack(!cameraIsRunning);
    }

    function enableLocalVideoTrack(enabled: boolean) {
        if(localStreamRef && localStreamRef.current) {
            const tracks = localStreamRef.current.getVideoTracks();
            tracks.forEach(track => {
                track.enabled = enabled;
                setCameraIsRunning(enabled);
            });
        }
    }


    function toggleMic() {
        enableLocalAudioTrack(!micIsRunning);
    }

    function enableLocalAudioTrack(enabled: boolean) {
        if(localStreamRef && localStreamRef.current) {
            const tracks = localStreamRef.current.getAudioTracks();
            tracks.forEach(track => {
                track.enabled = enabled;
                setMicIsRunning(enabled);
            });
        }
    }



    function toggleCall() {
        if(callState !== CallState.ended){
            closeCall(true);
        } else {
            startCall();
        }
    }

    async function startCall() {

        console.log("starting call");

        closeLocalStream();

        await initLocalStream();

        init();

        callStateRef.current = CallState.calling;
        setCallState(CallState.calling);

        callStartTime.current = new Date();
    }

    async function closeCall(notifyOthers: boolean = false) {

        console.log("closing call");

        pcRef.current?.close();
        pcRef.current = undefined; // otherwise the other user could start the call again on both sides

        await initLocalStream();

        if(notifyOthers){
            sendMessage(userId, JSON.stringify({'callState': 'ended'}));
        }

        callStateRef.current = CallState.ended;
        setCallState(CallState.ended);

    }

    async function sendSms() {


        setIsSendingSms(true);

        try {
            if(remoteUser && videoRoom && currentClient){
                const phoneNumber = remoteUser.mobilePhoneNumber;
                if(isGermanMobileNumber(phoneNumber)){

                    const newLine = "\n";
                    let message = `Bitte treten Sie Ihrer Videosprechstunde bei:${newLine}https://cal.pickadoc.de/videocall/${videoRoom.id}/${remoteUser.id}`;

                    const reallySend = window.confirm("Wollen Sie jetzt eine Erinnerungs-SMS an den Patienten senden?");

                    if(reallySend){
                        const from = (currentLocation.notificationsSettings.useCustomSenderName && currentLocation.notificationsSettings.customSenderName) ? currentLocation.notificationsSettings.customSenderName : "Pickadoc";

                        await SmsService.sendSms(phoneNumber, message, from, InvoiceItemType.smsReminder, currentClient.id, locationId );
                    }

                } else {
                    alert("Der Patient besitzt keine gültige deutsche Handynummer, um eine SMS versenden zu können.")
                }
            }
        } catch(error) {
            console.error("Error: could not send SMS: ", error);
        }

        setIsSendingSms(false);

    }

    function getFormattedCallDuration(): string {
        return `${Utils.getWithLeadingZero(callDuration.hours())}:${Utils.getWithLeadingZero(callDuration.minutes())}:${Utils.getWithLeadingZero(callDuration.seconds())}`;
    }

    function getWaitingForRemoteLabel(): string {
        if(videoRoom){
            if(isHost){
                if(callState === CallState.calling){
                    return `Warte auf Patient...`;
                } else {
                    return `Sie können die Videosprechstunde mit Ihrem Patienten nun starten.`;
                }

                // if(remoteUser?.isOnline){
                //     return `Ihr Patient ist nun online und bereit für einen Anruf.`;
                // } else {
                //     return `Ihr Patient ist noch nicht online.`;
                // }
            } else {
                if(callState === CallState.calling){
                    return `Warte auf Arzt...`;
                } else {
                    return `Sie können die Videosprechstunde mit Ihrem Arzt nun starten.`;
                }
                // if(remoteUser?.isOnline){
                //     return `Ihr Arzt ist nun online und bereit für einen Anruf. Bitte habe Sie noch einen kurzen Moment Geduld.`;
                // } else {
                //     return `Ihr Arzt ist noch nicht online. Bitte habe Sie noch einen Moment Geduld.`;
                // }
            }
        } else {
            return `Lade Videosprechstunde...`;
        }
    }

    function getRemoteVideoLabel(): string {
        if(remoteUser){
            if(remoteUser.isOnline){
                return `${remoteUser.getFullName()} - ${getFormattedCallDuration()}`;
            } else {
                return `${remoteUser.getFullName()} - Termin am ${DateUtils.getDateString(videoRoom?.start)} um ${DateUtils.getTimeString(videoRoom?.start)}`;
            }
        } else {
            return `${getFormattedCallDuration()}`;
        }
    }

    return (
        <div className="kt-video-room-container">

            <div className="kt-video-container kt-remote-video">

                <video
                    ref={remoteVideoRef}
                    autoPlay
                    playsInline
                />

            </div>

            {callState !== CallState.running &&
                <div className="kt-waiting-for-remote">{getWaitingForRemoteLabel()}</div>
            }

            {(callState !== CallState.running && isHost) && <Tooltip title="Benachrichtigungs SMS versenden">
                <IconButton onClick={sendSms} className="kt-button-circle kt-button-send-sms" disabled={isSendingSms}>
                    <SmsIcon/>
                </IconButton>
            </Tooltip>}

            <div className="kt-remote-video-label">{getRemoteVideoLabel()}</div>

            <div className="kt-call-buttons">

                <Tooltip title={micIsRunning ? "Mikrofon stummschalten" : "Mikrofon einschalten"}>
                    <IconButton onClick={toggleMic} className="kt-button-circle">
                        {micIsRunning ? <MicIcon/> : <MicOffIcon/>}
                    </IconButton>
                </Tooltip>

                <Tooltip title={cameraIsRunning ? "Kamera ausschalten" : "Kamera einschalten"}>
                    <IconButton onClick={toggleCamera} className="kt-button-circle">
                        {cameraIsRunning ? <VideocamIcon/> : <VideocamOffIcon/>}
                    </IconButton>
                </Tooltip>

                <Tooltip title={callState !== CallState.ended ? "Anruf beenden" : "Anruf starten"}>
                    <IconButton onClick={toggleCall} className={callState !== CallState.ended ? "kt-button-circle kt-background-red" : "kt-button-circle kt-background-green"}>
                        {callState !== CallState.ended ? <CallEndIcon/> : <CallIcon/>}
                    </IconButton>
                </Tooltip>

            </div>

            <div className="kt-bottom-section">
                <div className="kt-video-container kt-local-video">
                    <video
                        ref={localVideoRef}
                        autoPlay={true}
                        muted
                        playsInline
                    />
                </div>
            </div>


        </div>
    );
}

export default VideoRoomCtrl;