import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/lists';
import "tinymce/langs/de.js";
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import { Editor } from "@tinymce/tinymce-react";

import DOMPurify from "dompurify";

import React from "react";

import ParagraphFormItem from '../../../src/shared/src/models/formEditor/paragraphFormItem';
import { FormItemCtrlProps } from './formItemCtrl';
import TextEvaluationService from '../../../src/services/textEvaluationService';
import Grid from '@mui/material/Grid';


interface Props extends FormItemCtrlProps {
    item: ParagraphFormItem
}

const ParagraphFormItemCtrl: React.FC<Props> = (
    {
        item,
        languageKey,
        handleOnInputChange,
        handleOnSignatureDialogStateChange,
        formRows,
        onFormChange,
        selectedItem,
        setSelectedItem,
        renderMode
    }) => {



    function renderHtml(): JSX.Element {

        let label = item.getLanguageProperty(languageKey, "labels");
        // To avoid web attacks and XSS
        const cleanLabel = DOMPurify.sanitize( label , {USE_PROFILES: {html: true}} );

        return (
            <Editor
                value={item.getLanguageProperty(languageKey, "labels")}
                onEditorChange={ content => handleOnInputChange(item, content, "labels")}
                init={{
                    height: 400,
                    inline: true,
                    menubar: false,
                    plugins: [
                        'lists paste'
                        ],
                    toolbar:
                        'undo redo | fontsizeselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
                    language: "de",
                    content_style: ".mce-content-body {font-size:15px;font-family:Arial,sans-serif;}",
                    paste_as_text: true
                }}
            />
        );

    }

    function renderProperties(): JSX.Element {
        return (
            <div>
                <Grid container>

                    <Grid item xs={12}>
                        <strong>Textabsatz</strong>
                    </Grid>

                </Grid>

            </div>
        );
    }

    function renderFinalHtml(): JSX.Element {

        let label = item.getLanguageProperty(languageKey, "labels");
        label = TextEvaluationService.evaluateDocumentText(label);

        // To avoid web attacks and XSS
        const cleanLabel = DOMPurify.sanitize( label , {USE_PROFILES: {html: true}} );

        return (
            <p dangerouslySetInnerHTML={
                {__html: cleanLabel}
            }></p>
        );

    }



    switch (renderMode) {
        case "editor":
            return renderHtml();

        case "properties":
            return renderProperties();

        case "final":
            return renderFinalHtml();

        default:
            return <React.Fragment></React.Fragment>;
    }

}


export default ParagraphFormItemCtrl;