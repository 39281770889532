import Paper from '@mui/material/Paper';
import React, { useContext, useEffect, useState } from 'react';

import { Box, Button } from '@mui/material';
import { GlobalContext } from '../../GlobalContext';
import PaymentsService from '../../services/paymentsService';
import ProgressDialog from '../dialogs/progressDialog';

const ClonRCreditsCtrl: React.FC = () => {

    const { currentUser, currentClient } = useContext(GlobalContext);

    const [isMessageDialogVisible, setIsMessageDialogVisible] = useState(false);

    const [credits, setCredits] = useState(0);

    useEffect(()=>{

        if(currentClient && currentUser){

            const unsubscribeCredits = PaymentsService.startListenForCredits(currentClient.id, (newValue) => setCredits(newValue));

            return () => {
                unsubscribeCredits();
            }
        }

    }, [currentClient]);

    async function buyCredits(quantity: number) {
        setIsMessageDialogVisible(true);

        const sessionUrl = await PaymentsService.createStripeCheckout(quantity, currentClient.id, currentUser.id);
        window.location.href = sessionUrl;
    }

    function renderCreditPanel(quantity: number, price: number) {
        return (
            <div className='kt-creditsPanel'>
                <div className='kt-creditsPanel-icon'><i className='fa fa-coins'></i></div>
                <big className='kt-creditsPanel-quantity'>{new Intl.NumberFormat().format(quantity)} Credits</big>
                <strong className='kt-creditsPanel-price'> {price}€</strong>
                <small className='kt-creditsPanel-price'>{(price/quantity).toFixed(2)}€/credit</small>
                <br/>
                <Button variant='outlined' onClick={()=>buyCredits(quantity)}>KAUFEN</Button>
            </div>
        )
    }

    return (
        <>

        <ProgressDialog
            visible={isMessageDialogVisible}
            title='Weiterleitung zum Zahlungsprovider'            
            message={
                <div style={{textAlign:"center"}}>
                    <div style={{fontSize:"40px"}}><i className='fal fa-shopping-cart'></i></div>
                    <h3>Sie werden nun zu unserem Zahlungsprovider umgeleitet...</h3>
                </div>
            }
        />

            <Paper className="kt-paper">

                <div className='kt-paper-title'>Ihr Guthaben</div>

                <h1 style={{textAlign:"center"}}><i className='far fa-coins'></i> Credits: <span className='kt-primary-color'>{credits}</span></h1>
            
            
                <h3 style={{textAlign: "center"}}>Kaufen Sie Credits, um beeindruckende AI-Videos zu erstellen!</h3>

                <Box display={"flex"} columnGap={2} justifyContent={"center"}>
                    {renderCreditPanel(100, 10)}
                    {renderCreditPanel(300, 27)}
                    {renderCreditPanel(500, 40)}
                    {renderCreditPanel(1000, 70)}
                </Box>
            </Paper>
        </>
    );
}

export default ClonRCreditsCtrl;