import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { useEffect, useState, useContext } from "react"
import User from "../../../models/user"
import VisitMotive from "../../../models/visitMotive"
import Appointment from "../../../models/appointment"
import FreeTimeSlotsCtrl from "./freeTimeSlotsCtrl"
import moment from 'moment';
import AppointmentsService from "../../../services/appointmentsService"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import Box from "@mui/material/Box"
import VisitMotivePicker from "../../visitMotivePicker"
import Calendar from "../../../models/calendar"
import UsersService from "../../../services/usersService"
import Patient from "../../../models/patient"
import { GlobalContext } from "../../../GlobalContext"


interface Props {
    patient: Patient,
    visible: boolean,
    onClose: () => void,
    onAppointmentCreated: (patient: Patient) => void
}

const BookingDialog: React.FC<Props> = ({ patient, visible, onClose, onAppointmentCreated }) => {

    const {currentUser} = useContext(GlobalContext);
    const {currentLocation} = useContext(GlobalContext);
    const {publicHolidays} =  useContext(GlobalContext);
    const {calendars} = useContext(GlobalContext);
    const {specialities} = useContext(GlobalContext);
    const {visitMotives} = useContext(GlobalContext);

    const [doctor, setDoctor] = useState<User>(new User());
    const [appointment, setAppointment] = useState<Appointment>(new Appointment());
    const [appointments, setAppointments] = useState<any[]>([]);
    const [visitMotive, setVisitMotive] = useState<VisitMotive | null>(null);
    const [calendar, setCalendar] = useState<Calendar>(new Calendar());

    const [today, setToday] = useState(new Date());

    useEffect(() => {
        updateAppointments();
    }, [doctor]);

    useEffect(() => {
        setCalendar(new Calendar());
        setDoctor(new User());
        setVisitMotive(null);

        const _today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
        setToday(_today);

    }, [visible]);

    async function updateAppointments() {

        if (currentUser && calendar.id) {
            const currentDate = new Date();
            const endDate = moment(currentDate).add(30, "day").toDate();

            const pAppointments = await AppointmentsService.getAppointmentsByDateRangeAndCalendar(currentDate, endDate, calendar.id, currentUser.clientId, currentUser.locationId);

            if (pAppointments) {
                setAppointments(pAppointments);
                console.log("BookingDialog: appointments: " + pAppointments.length);
            }
        }

    }


    async function handleCalendarChange(calId: string) {
        const cal = Calendar.getCalendarById(calendars, calId);
        if (cal && currentUser) {
            setCalendar(cal);
            const doc = await UsersService.getUserByCalendarId(currentUser.clientId, currentUser.locationId, calId);
            if(doc) setDoctor(doc);
        }
    }


    if (!currentLocation) {
        return <></>;
    }

    return <>
        <Dialog
            open={visible}
            onClose={onClose}
        >
            <DialogTitle>
                Termin-Schnellbuchung
            </DialogTitle>
            <DialogContent
                style={{minWidth: "450px", minHeight: "600px"}}
            >

                <Box mt={1}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="calendar-select-label">Kalender</InputLabel>
                        <Select
                            name="appointment.calendar"
                            labelId="calendar-select-label"
                            label="Behandler"
                            id="calendar-select"
                            value={calendar.id}
                            onChange={e => handleCalendarChange(e.target.value)}
                            required
                        >
                            {Calendar.getAsFlatList(calendars).map(calendar => <MenuItem key={calendar.id} value={calendar.id}>{calendar.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Box>

                {calendar.id && <Box mt={3}>
                    <VisitMotivePicker
                        selectedVisitMotiveId={ visitMotive ? visitMotive.id : ""}
                        visitMotives={visitMotives}
                        specialities={specialities}
                        appointmentStart={appointment.start}
                        appointmentEnd={appointment.end}
                        showDuration={false}
                        onVisitMotiveChange={vMotive => setVisitMotive(vMotive)}
                        onDurationChange={eDate => console.log(eDate)}
                    />
                </Box>}

                {(visitMotive && visitMotive.id) && <FreeTimeSlotsCtrl
                    doctor={doctor}
                    patient={patient}
                    appointments={appointments}
                    publicHolidays={publicHolidays}
                    visitMotiveId={ visitMotive ? visitMotive.id : ""}
                    visitMotives={visitMotives}
                    locationId={currentLocation.id}
                    onAppointmentCreated={onAppointmentCreated}
                    minDate={today}
                    locationOpeningHours={currentLocation.openingHours}
                />}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Schließen</Button>
            </DialogActions>
        </Dialog>
    </>
}

export default BookingDialog;