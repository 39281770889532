
import React, { useContext, useEffect } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { GlobalContext } from '../../GlobalContext';
import CalendarsService from '../../services/calendarsService';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { PickerChangeHandlerContext, TimePicker, TimeValidationError } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';


interface Props {
    visible: boolean;
    onClose: () => void
}

const CalendarViewSettingsDialog: React.FC<Props> = ({ visible, onClose }) => {

    const { calendarViewSettings, setCalendarViewSettings } = useContext(GlobalContext);

    useEffect(() => {

        window.addEventListener('keydown', listenKeyboard, true);

        // clean up
        return () => {
            window.removeEventListener('keydown', listenKeyboard, true);
        }

    }, []);

    function listenKeyboard(event) {
        if (event.keyCode === 27) {
            handleOnClose();
        }
    }

    const handleOnClose = () => {
        CalendarsService.saveCalendarViewSettings(calendarViewSettings);
        onClose();
    }

    const handleInputChange = (event) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const settings = calendarViewSettings.clone();

        settings.slotMinTime = value;

        setCalendarViewSettings(settings);

        CalendarsService.saveCalendarViewSettings(settings);
    }

    const handleSlotMinTimeChange = (value: Dayjs | null, context: PickerChangeHandlerContext<TimeValidationError>) => {

        if (value && context.validationError === null) {
            const settings = calendarViewSettings.clone();

            settings.slotMinTime = value.format("HH:mm");

            setCalendarViewSettings(settings);

            CalendarsService.saveCalendarViewSettings(settings);
        }
    }

    const handleSlotMaxTimeChange = (value: Dayjs | null, context: PickerChangeHandlerContext<TimeValidationError>) => {

        if (value && context.validationError === null) {
            const settings = calendarViewSettings.clone();

            settings.slotMaxTime = value.format("HH:mm");

            setCalendarViewSettings(settings);

            CalendarsService.saveCalendarViewSettings(settings);
        }
    }

    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={visible}
        >
            <DialogTitle id="customized-dialog-title">
                {t("dialogs.calendarViewSettingsDialog.title")} 
            </DialogTitle>

            <DialogContent
                dividers
            >

                <Grid container>
                    <Grid item xs={12}>
                        <span>{t("dialogs.calendarViewSettingsDialog.description")} </span>
                    </Grid>



                    <Grid item xs={12} mb={-3}>
                        <strong>{t("dialogs.calendarViewSettingsDialog.visibleTimeslot")} </strong>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TimePicker
                                label={t("dialogs.calendarViewSettingsDialog.startTime")}
                                timeSteps={{ hours: 1, minutes: 15 }}
                                maxTime={dayjs("2025-01-01" + calendarViewSettings.slotMaxTime).subtract(1, "hour")}
                                value={dayjs("2025-01-01" + calendarViewSettings.slotMinTime)}
                                onChange={handleSlotMinTimeChange}
                                slotProps={{
                                    textField: {
                                        sx: { width: "150px" }
                                    },
                                }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TimePicker
                                label={t("dialogs.calendarViewSettingsDialog.endTime")}
                                timeSteps={{ hours: 1, minutes: 15 }}
                                minTime={dayjs("2025-01-01" + calendarViewSettings.slotMinTime).add(1, "hour")}
                                value={dayjs("2025-01-01" + calendarViewSettings.slotMaxTime)}
                                onChange={handleSlotMaxTimeChange}

                                slotProps={{
                                    textField: {
                                        sx: { width: "150px" }
                                    },
                                }}
                            />
                        </FormControl>
                    </Grid>

                </Grid>

            </DialogContent>

            <DialogActions>
                <Button onClick={() => handleOnClose()} autoFocus color='primary'>
                    {t("components.buttons.ok")} 
                </Button>
            </DialogActions>

        </Dialog>

    );
}

export default CalendarViewSettingsDialog;
