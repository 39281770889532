import Paper from '@mui/material/Paper';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import firebaseApp from '../database';
import { GlobalContext } from '../../GlobalContext';
import PaymentsService from '../../services/paymentsService';


interface Props {
    view: string;
}

const ClonRMenu: React.FC<Props> = ({ view }) => {

    const { currentUser, currentClient } = useContext(GlobalContext);

    const [credits, setCredits] = useState(0);

    useEffect(() => {

        if (currentClient && currentUser) {

            const unsubscribeCredits = PaymentsService.startListenForCredits(currentClient.id, (newValue) => setCredits(newValue));

            return () => {
                unsubscribeCredits();
            }
        }

    }, [currentClient]);

    function logOut() {
        firebaseApp.auth().signOut();
    }

    return (
        <div className="kt-clonr-menu">
            <Paper className="kt-paper">
                <div className='kt-top kt-text-center'>
                    <a href="https://www.pickadoc.de" target="_blank" className="navbar-brand" rel="noreferrer" >
                        <img src="/images/logo.png" width={32} alt="Picksdoc Logo" /> <div style={{ color: "white", fontSize: "20px" }}>Pickadoc.AI</div>
                    </a>
                    <div style={{ borderBottom: "1px solid white", marginTop: "10px", marginBottom: "10px" }}></div>
                </div>

                <div className='kt-middle'>
                    <Link to="/clonr" className={view === "" || view.toLowerCase() === "home" ? 'kt-menu-item kt-selected' : 'kt-menu-item'}>
                        <i className="fal fa-home"></i> <span>Home</span>
                    </Link>
                    <Link to="/clonr/videoclone" className={view && view.toLowerCase() === "videoclone" ? 'kt-menu-item kt-selected' : 'kt-menu-item'}>
                        <i className="fal fa-user"></i> <span>Clones</span>
                    </Link>
                    <Link to="/clonr/voice" className={view && view.toLowerCase() === "voice" ? 'kt-menu-item kt-selected' : 'kt-menu-item'}>
                        <i className="fal fa-waveform-path"></i> <span>Stimmen</span>
                    </Link>
                    <Link to="/clonr/video" className={view && view.toLowerCase() === "video" ? 'kt-menu-item kt-selected' : 'kt-menu-item'}>
                        <i className="fal fa-video"></i> <span>Videos</span>
                    </Link>
                </div>

                <div className='kt-bottom' >

                    <div className='kt-menu-credit-balance'>
                        <i className="fal fa-coins"></i> <span>Guthaben: <br/> {credits} Credits</span>
                    </div>

                    <Link to="/clonr/credits" className={view && view.toLowerCase() === "credits" ? 'kt-menu-item kt-selected' : 'kt-menu-item'}>
                        <i className="fal fa-coins"></i> <span>Credits kaufen</span>
                    </Link>
                    <Link to="/clonr/settings" className={view && view.toLowerCase() === "settings" ? 'kt-menu-item kt-selected' : 'kt-menu-item'}>
                        <i className="fal fa-cog"></i> <span>Einstellungen</span>
                    </Link>
                    <Link to="/" className='kt-menu-item'>
                        <i className="fal fa-calendar"></i> <span>CalendR</span>
                    </Link>
                    <Link to="#" className='kt-menu-item' onClick={logOut}>
                        <i className="fal fa-sign-out"></i> <span>Abmelden</span>
                    </Link>

                </div>


            </Paper>
        </div>

    );
}

export default ClonRMenu;