import React from 'react';
import { Link } from 'react-router-dom';
import firebaseApp from '../database';

interface Props {
    view: string;
}

const ClonRMobileMenu: React.FC<Props> = ({ view }) => {

    function logOut() {
        firebaseApp.auth().signOut();
    }
    
    return (

        <div className="kt-clonr-mobile-menu">

            <Link to="/clonr" className={view === "" || view.toLowerCase() === "home" ? 'kt-menu-item kt-selected' : 'kt-menu-item'}>
                <i className="fal fa-home"></i> <span>Home</span>
            </Link>
            <Link to="/clonr/videoclone" className={view && view.toLowerCase() === "videoclone" ? 'kt-menu-item kt-selected' : 'kt-menu-item'}>
                <i className="fal fa-user"></i> <span>Clones</span>
            </Link>
            <Link to="/clonr/voice" className={view && view.toLowerCase() === "voice" ? 'kt-menu-item kt-selected' : 'kt-menu-item'}>
                <i className="fal fa-microphone"></i> <span>Stimmen</span>
            </Link>
            <Link to="/clonr/video" className={view && view.toLowerCase() === "video" ? 'kt-menu-item kt-selected' : 'kt-menu-item'}>
                <i className="fal fa-video"></i> <span>Videos</span>
            </Link>
            <Link to="#" className='kt-menu-item' onClick={logOut}>
                <i className="fal fa-sign-out"></i> <span>Abmelden</span>
            </Link>

        </div>
    );
}

export default ClonRMobileMenu;