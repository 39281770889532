import { useContext, useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import { isGermanMobileNumber } from '../../utils';
import SmsService from '../../services/smsService';
import { GlobalContext } from '../../GlobalContext';
import { InvoiceItemType } from '../../shared/src/models/invoice/invcoiceItem';
import { t } from 'i18next';


interface Props {
    visible: boolean;
    defaultSmsText?: string;
    mobilePhoneNumber: string;
    firstName?: string;
    lastName?: string;
    onSendSms: (smsText: string) => void;
    onSendSmsFinished: (smsText: string) => void;
    onClose: () => void;
}

const SendSmsDialog: React.FC<Props> = ({ visible, defaultSmsText, mobilePhoneNumber, firstName, lastName, onSendSms, onSendSmsFinished, onClose }) => {

    const maxSmsLength = 160;

    const [smsText, setSmsText] = useState("");
    const [charactersLeft, setCharactersLeft] = useState(0);

    const { currentClient } = useContext(GlobalContext);
    const { currentLocation } = useContext(GlobalContext);

    useEffect(() => {
        if (defaultSmsText) {
            setSmsText(defaultSmsText);
        } else {
            setSmsText("");
        }
    }, [visible]);

    useEffect(() => {
        setCharactersLeft(maxSmsLength - smsText.length);
    }, [smsText])

    async function handleSendSms() {

        const senderName = (currentLocation.notificationsSettings.useCustomSenderName && currentLocation.notificationsSettings.customSenderName) ? currentLocation.notificationsSettings.customSenderName : "Pickadoc";

        onSendSms(smsText);

        await SmsService.sendSms(mobilePhoneNumber, smsText, senderName, InvoiceItemType.none, currentClient.id, currentLocation.id);

        onSendSmsFinished(smsText);
    }

    function handleClose() {
        onClose();
    }

    function canSendSms() {
        return smsText.length > 0 && isGermanMobileNumber(mobilePhoneNumber);
    }

    function getTitle() {
        if (firstName || lastName) {
            return t("dialogs.sendSmsDialog.titleLong", {firstName, lastName, mobilePhoneNumber});
        } else {
            return t("dialogs.sendSmsDialog.titleShort", {mobilePhoneNumber});

        }
    }

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={visible}
            maxWidth="md"
            fullWidth={true}
            disableEnforceFocus={true}
        >
            <DialogTitle id="customized-dialog-title">
                {getTitle()}
            </DialogTitle>

            <DialogContent dividers style={{ minHeight: '250px' }}>

                <Grid container>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            rows={10}
                            value={smsText}
                            onChange={e => setSmsText(e.target.value)}
                            label={t("dialogs.sendSmsDialog.charactersLeft", {charactersLeft})}
                            multiline
                            inputProps={{
                                maxLength: maxSmsLength
                            }}
                        />
                    </Grid>
                </Grid>


            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    {t("components.buttons.cancel")}
                </Button>
                <Button onClick={handleSendSms} color="primary" disabled={!canSendSms()}>
                    {t("components.buttons.sendSms")}
                </Button>
            </DialogActions>
        </Dialog>

    );
}

export default SendSmsDialog;
