import React from "react";

import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';

import AnswerFormItem from "../../../src/shared/src/models/formEditor/answerFormItem";
import { FormItemEnum, FormItemType } from "../../../src/shared/src/models/formEditor/formItem";
import FormRow from "../../../src/shared/src/models/formEditor/formRow";
import TextEvaluationService from "../../../src/services/textEvaluationService";
import { FormItemCtrlProps } from "./formItemCtrl";
import FormRowCtrl from "./formRowCtrl";



interface Props extends FormItemCtrlProps {
    item: AnswerFormItem,
    required: boolean
}

const AnswerFormItemCtrl: React.FC<Props> = (
    {
        patientId,
        documentId,
        item,
        required,
        languageKey,
        handleOnInputChange,
        handleOnSignatureDialogStateChange,
        formRows,
        setFormRows,
        onFormChange,
        selectedItem,
        setSelectedItem,
        renderMode,
        formUser
    }) => {


    function deleteRow(formRow: FormRow, formRows: FormRow[]) {

        const newRows = FormRow.deleteRow(formRow, formRows);

        onFormChange(newRows);
    }

    function handleOnClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>, formItem: FormItemType, setSelectedItem) {

        e.bubbles = false;
        e.stopPropagation();

        setSelectedItem(formItem);
    }


    function renderHtml(): JSX.Element {

        const langAnswer = item.getLanguageProperty(languageKey, "labels");

        return (
            <div className="kt-form-answer" onClick={(e) => handleOnClick(e, item, setSelectedItem)}>

                <div className="kt-answer-control">
                    {item.type === FormItemEnum.checkboxAnswer ?
                        <i className="far fa-square" />
                        :
                        <i className="far fa-circle" />
                    }
                    {item.required && <span>*</span>}
                    <input
                        type="text"
                        value={langAnswer}
                        onChange={(e) => handleOnInputChange(item, e.target.value, "labels")}
                        placeholder="Antwort"
                    />
                </div>

                <div className="kt-form-rows">
                    {item.formRows.map((formRow, index) => (
                        <div
                            key={formRow.id}
                            draggable={true}
                            className='kt-form-row-wrapper'
                        >

                            <div
                                className="kt-drop-area kt-drop-area-top"
                            ></div>
                            <div
                                className={`kt-form-row`}
                                key={formRow.id}
                            >
                                <div className="kt-row-gripper">
                                    <i className={`far fa-grip-lines`}></i>
                                </div>
                                <div className="kt-form-row-content">
                                    <FormRowCtrl
                                        patientId={patientId}
                                        documentId={documentId}
                                        row={formRow}
                                        languageKey={languageKey}
                                        handleOnInputChange={handleOnInputChange}
                                        handleOnSignatureDialogStateChange={handleOnSignatureDialogStateChange}
                                        formRows={formRows}
                                        setFormRows={setFormRows}
                                        onFormChange={onFormChange}
                                        selectedItem={selectedItem}
                                        setSelectedItem={setSelectedItem}
                                        renderMode="editor"
                                        formUser={formUser}
                                    />
                                </div>

                                <div className="kt-row-edit-buttons">
                                    <div onClick={() => deleteRow(formRow, formRows)}>
                                        <i className='far fa-trash' />
                                    </div>
                                </div>

                            </div>
                            <div
                                className="kt-drop-area kt-drop-area-bottom"
                            ></div>

                        </div>
                    ))}
                </div>

            </div>
        );

    }

    function renderProperties(): JSX.Element {
        return (
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <strong>Antwort</strong>
                    </Grid>

                    {item.type === FormItemEnum.checkboxAnswer &&
                        <Grid item xs={6}>
                            <FormControlLabel
                                label="Pflichtfeld"
                                control={
                                    <Checkbox
                                        checked={item.required}
                                        onChange={(e) => handleOnInputChange(item, e.target.checked, "required")}
                                    />
                                }
                            />
                        </Grid>
                    }
                </Grid>
            </div>
        );
    }

    function renderFinalHtml(): JSX.Element {
        let langAnswer = item.getLanguageProperty(languageKey, "labels");
        langAnswer = TextEvaluationService.evaluateDocumentText(langAnswer);

        if (item.type === FormItemEnum.checkboxAnswer) {

            return (
                <div key={item.id}>
                    <FormControlLabel
                        key={item.id}
                        control={
                            <Checkbox
                                checked={item.checked}
                                required={item.required}
                                onChange={(e) => handleOnInputChange(item, e.target.checked, "checked")}
                            />
                        }
                        label={langAnswer}
                    />

                    {item.checked && (item.formRows.map((formRow, index) => (

                        <div
                            className={`kt-form-row`}
                            key={formRow.id}
                        >
                            <div className="kt-form-row-content">
                                <FormRowCtrl
                                    patientId={patientId}
                                    documentId={documentId}
                                    row={formRow}
                                    languageKey={languageKey}
                                    handleOnInputChange={handleOnInputChange}
                                    handleOnSignatureDialogStateChange={handleOnSignatureDialogStateChange}
                                    formRows={formRows}
                                    setFormRows={setFormRows}
                                    onFormChange={onFormChange}
                                    selectedItem={selectedItem}
                                    setSelectedItem={setSelectedItem}
                                    renderMode="final"
                                    formUser={formUser}
                                />
                            </div>
                        </div>

                    )))
                    }

                </div>
            )

        } else {

            return (
                <div key={item.id}>
                    <FormControlLabel
                        key={item.id}
                        control={
                            <Radio value={langAnswer} required={required} />
                        }
                        label={langAnswer}
                    />

                    {item.checked && (item.formRows.map((formRow, index) => (

                        <div
                            className={`kt-form-row`}
                            key={formRow.id}
                        >
                            <div className="kt-form-row-content">
                                <FormRowCtrl
                                    patientId={patientId}
                                    documentId={documentId}
                                    row={formRow}
                                    languageKey={languageKey}
                                    handleOnInputChange={handleOnInputChange}
                                    handleOnSignatureDialogStateChange={handleOnSignatureDialogStateChange}
                                    formRows={formRows}
                                    setFormRows={setFormRows}
                                    onFormChange={onFormChange}
                                    selectedItem={selectedItem}
                                    setSelectedItem={setSelectedItem}
                                    renderMode="final"
                                    formUser={formUser}
                                />
                            </div>
                        </div>

                    )))
                    }

                </div>
            )

        }
    }

    console.log(`answerFormItemCtrl render mode: ${renderMode}`);

    switch (renderMode) {
        case "editor":
            return renderHtml();

        case "properties":
            return renderProperties();

        case "final":
            return renderFinalHtml();

        default:
            return <React.Fragment></React.Fragment>;
    }


}


export default AnswerFormItemCtrl;