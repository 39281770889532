import { useContext, useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';

import PDocument from '../../../src/shared/src/models/formEditor/pdocument';
import { FormItemEnum, FormItemType } from "../../../src/shared/src/models/formEditor/formItem";
import FormRow from '../../../src/shared/src/models/formEditor/formRow';
import PatientDocsService from '../../../src/services/patientDocsService';
import FormRowCtrl from './formRowCtrl';
import SignatureDialog from '../dialogs/signatureDialog';
import SignatureFormItem from '../../../src/shared/src/models/formEditor/signatureFormItem';
import MessageDialog from '../dialogs/messageDialog';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { GlobalContext } from '../../GlobalContext';
import User from '../../models/user';
import UserRole from '../../models/userRole';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '../avatar';


export type FollowUpActionType = "print" | "sentByEmail" | "moveToSent" | "qrcode" | "abort" | "none";

interface Props {
    patientId: string,
    documents: PDocument[],
    clientId: string,
    locationId: string,
    isDialogVisible: boolean,
    followUpAction: FollowUpActionType,
    onClose: (documents: PDocument[], followUpAction: FollowUpActionType) => void;
}


const MissingDoctorFieldsDialog: React.FC<Props> = ({ patientId, documents, clientId, locationId, isDialogVisible, followUpAction, onClose }) => {

    const { users } = useContext(GlobalContext);

    const [incompleteDocs, setIncompleteDocs] = useState<PDocument[]>([]);

    const [currentDocument, setCurrentDocument] = useState<PDocument>(new PDocument());

    const [formRows, setFormRows] = useState<FormRow[]>([]);
    const [selectedItem, setSelectedItem] = useState<FormItemType | null>(null);

    const [isSignatureDialogVisible, setIsSignatureDialogVisible] = useState(false);
    const [selectedSignatureId, setSelectedSignatureId] = useState("");

    const [isMessageDialogVisible, setIsMessageDialogVisible] = useState(false);
    const [messageDialogTitle, setMessageDialogTitle] = useState("");
    const [messageDialogLabel, setMessageDialogLabel] = useState("");

    const [documentIndex, setDocumentIndex] = useState(0);

    useEffect(() => {

        async function initDialog() {

            setDocumentIndex(0);

            if (documents && clientId) {
                const _incompleteDocs = await PatientDocsService.getIncompleteDocuments(documents, clientId);
                if (_incompleteDocs.length > 0) {
                    setIncompleteDocs(_incompleteDocs);
                    onDocumentChange(_incompleteDocs[0]);
                }
            }
        }

        initDialog();

    }, [documents]);



    async function onDocumentChange(newDocument: PDocument) {

        setCurrentDocument(newDocument);
        setFormRows([...newDocument.formRows]);
    }


    function handleOnInputChange(formItem: FormItemType, newValue: any, propertyName: string) {
        if (formItem && formItem.hasOwnProperty(propertyName)) {
            formItem[propertyName] = newValue;
        }

        setFormRows([...formRows]);

        onFormChange(formRows);
    }

    function onFormChange(changedFormRows: FormRow[]) {

    }


    async function handleSubmit(e: React.FormEvent<HTMLFormElement>): Promise<void> {

        e.preventDefault();
        e.stopPropagation();

        // first check if all required signatures are signed
        const missingSignatures = SignatureFormItem.getAllUnsignedSignaturesBySigner("doctor", formRows);

        if (missingSignatures.length > 0) {

            setMessageDialogTitle(`${missingSignatures.length === 1 ? "Fehlende Unterschrift" : "Fehlende Unterschriften"}`);
            setMessageDialogLabel("Das Dokument ist noch nicht vollständig unterzeichnet.");
            setIsMessageDialogVisible(true);

            return;
        }

        // save document changes
        const originalDoc = documents.find(doc => doc.id === currentDocument.id);
        if (originalDoc) {
            originalDoc.mergeDocumentFormItemValues(currentDocument);
        }


        // now goto next document
        if (documentIndex < incompleteDocs.length - 1) {
            onDocumentChange(incompleteDocs[documentIndex + 1]);
            setDocumentIndex(documentIndex + 1);
        } else {
            onClose([...documents], followUpAction);
        }
    }

    function handleOnSignatureDialogStateChange(isVisible: boolean, signatureId: string) {
        setSelectedSignatureId(signatureId);
        setIsSignatureDialogVisible(isVisible);
    }

    function getFormItemById(id: string): FormItemType | null {
        for (let r = 0; r < formRows.length; r++) {
            const row = formRows[r];

            for (let c = 0; c < row.columns.length; c++) {
                const colum = row.columns[c];

                if (colum.id === id) {
                    return colum
                }
            }
        }

        return null;
    }

    function handleSignatureChange(newSignatureImage: string) {

        setIsSignatureDialogVisible(false);

        const signatureFormItem = getFormItemById(selectedSignatureId);

        if (signatureFormItem) {
            (signatureFormItem as SignatureFormItem).signature = newSignatureImage;
        }

        setFormRows([...formRows]);

        onFormChange(formRows);
    }

    const setDoctorId = (newId: string) => {

        currentDocument.doctorId = newId;
       
        const doc = (users as User[]).find(u=>u.id === newId);
        if(doc) {

            // get all missing doctor signatures of this document
            const doctorSignatures = PatientDocsService.getDoctorSignatures(currentDocument, false);
            for (let i = 0; i < doctorSignatures.length; i++) {
                const doctorSignature = doctorSignatures[i];

                // fill missing signatures
                (doctorSignature as SignatureFormItem).signature = doc.signature;
            }
        }

               
        onDocumentChange(currentDocument.clone());
    }


    if (!currentDocument) {
        return <></>
    }

    return (

        <>
            <SignatureDialog
                visible={isSignatureDialogVisible}
                title="Unterschrift"
                label="Bitte unterschreiben Sie hier"
                onSave={handleSignatureChange}
                onClose={() => setIsSignatureDialogVisible(false)}
            />

            <MessageDialog
                visible={isMessageDialogVisible}
                title={messageDialogTitle}
                titleIconFaClassName='far fa-exclamation-triangle'
                message={messageDialogLabel}
                onClose={() => setIsMessageDialogVisible(false)}
            />



            <Dialog
                open={isDialogVisible}
                fullWidth={true}
                maxWidth={'sm'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {currentDocument.name}
                </DialogTitle>
                <DialogContent className='kt-form-preview'>

                    <DialogContentText id="alert-dialog-description">
                        Bitte ergänzen Sie noch folgende Angaben, bevor Sie die Dokumente dem Patienten überreichen:
                    </DialogContentText>


                    <form className="kt-form-rendering kt-form-rendering-small" onSubmit={handleSubmit}>

                        <div className="kt-form-rows-scroll">

                            <div className="kt-form-rows">

                                <div className='kt-form-row'>
                                    <div className='kt-form-row-content'>
                                        <FormControl fullWidth>
                                            <InputLabel id="doctor-select-label">Behandler</InputLabel>
                                            <Select
                                                name="doctor"
                                                labelId="cdoctor-select-label"
                                                label="Behandler"
                                                id="doctor-select"
                                                value={currentDocument.doctorId}
                                                onChange={e => setDoctorId(e.target.value)}
                                                required
                                            >
                                                {users.filter((u: User) => u.role === UserRole.doctor).map(doctor => 
                                                    <MenuItem key={doctor.id} value={doctor.id}>
                                                        <div style={{display: "flex", alignItems: "center"}}>
                                                            <Avatar src={doctor.avatarUrl} width={30}/>
                                                            <span style={{marginLeft: "10px"}}>{doctor.getFullName()}</span>
                                                        </div>
                                                    </MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                {formRows.map((formRow, index) => (

                                    <div
                                        className={`kt-form-row`}
                                        key={formRow.id}
                                    >
                                        <div className="kt-form-row-content">
                                            <FormRowCtrl
                                                patientId={currentDocument.patientId}
                                                documentId={currentDocument.id}
                                                row={formRow}
                                                languageKey={"de"}
                                                handleOnInputChange={handleOnInputChange}
                                                handleOnSignatureDialogStateChange={handleOnSignatureDialogStateChange}
                                                formRows={formRows}
                                                setFormRows={setFormRows}
                                                onFormChange={onFormChange}
                                                selectedItem={selectedItem}
                                                setSelectedItem={setSelectedItem}
                                                renderMode="final"
                                                formUser="doctor"
                                            />
                                        </div>
                                    </div>

                                ))}

                            </div>

                            <div className='kt-button-wrapper'>
                                <Button variant='outlined' onClick={() => onClose([...documents], "abort")}>ABBRECHEN</Button>
                                <Button variant='contained' type='submit'>
                                    WEITER <i className="fal fa-angle-right"></i>
                                </Button>
                            </div>


                        </div>

                    </form>



                </DialogContent>


            </Dialog>

        </>
    );
}

export default MissingDoctorFieldsDialog;