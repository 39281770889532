import DateUtils from "../utils/dateUtils";


export class ImportResult {

    id: string = "";

    success: boolean = false;
    totalPatients: number = 0;
    importedPatients: number = 0;
    skippedPatients: number = 0;
    errorPatients: number = 0;
    duplicatePatients: number = 0;
    mergedPatients: number = 0;
    mergedPatientFields: number = 0;
    importDurationInSeconds: number = 0;
    createdAt: Date = new Date();
    errorMessage: string = "";
    progress: number = 0; // in percent

    duplicateHandling: "allow" | "replace" | "merge" | "skip" = "merge";

    clone(): ImportResult {

        const c = new ImportResult();
        c.fromObject(this.toJSON(true));

        return c;
    }

    toJSON(useDate: boolean): object {

        const obj: any = {
            id: this.id,
            success: this.success === true,
            totalPatients: this.totalPatients,
            importedPatients: this.importedPatients,
            skippedPatients: this.skippedPatients,
            errorPatients: this.errorPatients,
            duplicatePatients: this.duplicatePatients,
            mergedPatients: this.mergedPatients,
            mergedPatientFields: this.mergedPatientFields,
            importDurationInSeconds: this.importDurationInSeconds,
            errorMessage: this.errorMessage,
            duplicateHandling: this.duplicateHandling,
            progress: this.progress
        };

        if (this.createdAt) {
            if (useDate) {
                obj.createdAt = this.createdAt;
            } else {
                obj.createdAt = this.createdAt.toJSON();
            }
        }

        return obj;
    }

    fromObject(o: any) {

        if (o.id) {
            this.id = o.id;
        }

        this.success = o.success === true;
        this.totalPatients = o.totalPatients ?? 0;
        this.importedPatients = o.importedPatients ?? 0;
        this.skippedPatients = o.skippedPatients ?? 0;
        this.errorPatients = o.errorPatients ?? 0;
        this.duplicatePatients = o.duplicatePatients ?? 0;
        this.mergedPatients = o.mergedPatients ?? 0;
        this.mergedPatientFields = o.mergedPatientFields ?? 0;
        this.importDurationInSeconds = o.importDurationInSeconds ?? 0;

        this.createdAt = DateUtils.getDate(o.createdAt) ?? new Date();

        this.errorMessage = o.errorMessage ?? "";

        this.duplicateHandling = o.duplicateHandling ?? "merge";

        this.progress = o.progress ?? 0;
    }

}


export default ImportResult;