
import React, { useEffect, useContext, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import QRCode from "react-qr-code";

import Appointment from '../../../models/appointment';

import { InvoiceItemType } from "../../../../src/shared/src/models/invoice/invcoiceItem";

import PDocument from '../../../../src/shared/src/models/formEditor/pdocument';
import DocumentsService from '../../../services/documentsService';
import ShortUrlsService from "../../../services/shortUrlsService";
import SmsService from "../../../services/smsService";

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import PatientDocsService from '../../../services/patientDocsService';
import PDFService from '../../../../src/services/pdfService';
import { NewSmsLine } from "../../../../src/services/notificationsService";

import { Toast } from '../../toaster';
import Patient, { PatientDocsStatus } from "../../../../src/models/patient";
import DateUtils from '../../../../src/shared/src/utils/dateUtils';
import PatientsService from "../../../../src/services/patientsService";
import DeleteIcon from '@mui/icons-material/Delete';
import Utils from "../../../../src/shared/src/utils/utils";
import VisitMotivesService from "../../../../src/services/visitMotivesService";
import MissingDoctorFieldsDialog, { FollowUpActionType } from "../../../../src/components/formEditor/missingDoctorFieldsDialog";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { GlobalContext } from '../../../GlobalContext';
import ClientLocationsService from '../../../services/clientLocationsService';
import { downloadFile, printPDF } from '../../../utils';
import { Tooltip } from '@mui/material';
import EmailDialog from '../emailDialog';
import Email from '../../../../src/shared/src/models/email';
import EmailService from '../../../services/emailService';
import TrafficLightCtrl from '../../trafficLightCtrl';
import firebaseApp from '../../database';


// a little function to help us with reordering the result
const reorder = (list: PDocument[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};


interface Props {
    appointment?: Appointment,
    patient: Patient,
    onSelectedDocumentsChanged?: () => void,
    onClose: () => void
}

const DocumentsTab: React.FC<Props> = ({ appointment, patient, onSelectedDocumentsChanged, onClose }) => {

    const { currentUser } = useContext(GlobalContext);
    const { currentClient } = useContext(GlobalContext);
    const { currentLocation } = useContext(GlobalContext);

    const [documentTemplates, setDocumentTemplates] = useState<PDocument[]>([]);

    const [sentDocuments, setSentDocuments] = useState<PDocument[]>([]);
    const [signedDocuments, setSignedDocuments] = useState<PDocument[]>([]);
    const [selectedDocuments, setSelectedDocuments] = useState<PDocument[]>([]);

    const [isQRCodeDialogVisible, setIsQRCodeDialogVisible] = useState(false);
    const [isMissingDoctorFieldsDialogVisible, setIsMissingDoctorFieldsDialogVisible] = useState(false);
    const [missingDoctorFieldsDialogFollowUpAction, setMissingDoctorFieldsDialogFollowUpAction] = useState<FollowUpActionType>("none");
    const [isEmailDialogVisible, setIsEmailDialogVisible] = useState(false);
    
    const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);

    const [patientDocsStatus, setPatientDocsStatus] = useState<PatientDocsStatus>("none");

    const [email, setEmail] = useState(new Email());

    const { setNewToast } = useContext(GlobalContext);

    useEffect(() => {
        if (patient && patient.id) {
            updateTabData();
        }
    }, [appointment, patient]);

    useEffect(() => {
        updatePatientDocsStatus(selectedDocuments, sentDocuments);
    }, [selectedDocuments, sentDocuments]);

    async function updateTabData() {

        if (currentUser) {

            console.log("update document tab data...");

            setIsLoadingDocuments(true);

            const _signedDocs: PDocument[] = [];
            let _unsignedDocs: PDocument[] = [];
            const _sentDocuments: PDocument[] = [];
           
            // get all patient documents
            const _patDocs = await PatientDocsService.getDocuments(patient.id, currentUser.clientId, currentUser.locationId);
            if (_patDocs) {

                let sortIndex = 0;

                for (let i = 0; i < _patDocs.length; i++) {
                    const doc = _patDocs[i];

                    if (doc.status === "signed" && doc.pdfCreatedAt) {
                        _signedDocs.push(doc);

                    } else if (doc.status === "sent") {
                        _sentDocuments.push(doc);

                    } else if (doc.status === "none") {
                        doc.sortIndex = sortIndex;
                        _unsignedDocs.push(doc);
                        sortIndex++;
                    }
                }

                _signedDocs.sort((a, b) => {
                    return b.pdfCreatedAt!.getTime() - a!.pdfCreatedAt!.getTime();
                });



            }

            // get all template documents for location
            const _documentTemplates = await DocumentsService.getDocumentTemplates(currentUser.clientId, currentUser.locationId);
            if (_documentTemplates) {
                // now remove those we have already selected
                const selDocs = _unsignedDocs.concat(_sentDocuments);
                let filteredDocs = _documentTemplates.filter(docTemplate => !selDocs?.some(selDoc => selDoc.templateId === docTemplate.id));

                // now remove the missing ones from the left list (location documents alias document templates)
                // and add them to the right list (selected documents)
                // but do this only if the selected list is empty
                // if the selected list is not empty then the doctor has made already his own selection,
                // which we dont want to overwrite
                if (_unsignedDocs.length === 0 && (!appointment || (appointment && appointment.autoSelectDocuments))) {
                    _unsignedDocs = await getMissingDocuments(filteredDocs, _signedDocs, appointment?.visitMotive?.id);
                    saveDocumentsSelection(_unsignedDocs);
                }

                setDocumentTemplates(filteredDocs);
            }


            setSelectedDocuments(_unsignedDocs);
            setSignedDocuments(_signedDocs);
            setSentDocuments(_sentDocuments);

            setTimeout(() => setIsLoadingDocuments(false), 500);            
        }
    }

    function updatePatientDocsStatus(_selectedDocuments, _sentDocuments) {
        if (_selectedDocuments.length > 0) {
            // RED light
            setPatientDocsStatus("red");

        } else if (_sentDocuments.length > 0) {
            // YELLOW light
            setPatientDocsStatus("yellow");

        } else {
            // GREEN light
            setPatientDocsStatus("green");
        }
    }

    async function getMissingDocuments(docTemplates: PDocument[], signedDocs: PDocument[], visitMotiveId?: string): Promise<PDocument[]> {
        const now = Date.now();
        let mandatoryDocs: PDocument[] = [];

        // if we render this inside the appointment dialog, we have a visit motive
        // and some visit motives have mandatory documents
        if (visitMotiveId) {
            const visitMotive = await VisitMotivesService.getVisitMotive(visitMotiveId, false, currentClient.id, currentUser!.locationId);
            if (visitMotive) mandatoryDocs = docTemplates.filter(d => d.mandatory || visitMotive.documentIds.includes(d.id));
        }

        const signedValidDocs = signedDocs.filter(d => d.expiresAt !== null && d.expiresAt.getTime() >= now);

        const missingDocs = [] as PDocument[];

        for (let i = 0; i < mandatoryDocs.length; i++) {
            const mDoc = mandatoryDocs[i];

            if (signedValidDocs.find(d => d.templateId === mDoc.id) === undefined) {
                missingDocs.push(mDoc);
            }
        }

        return missingDocs;
    }


    function getList(droppableId: string): PDocument[] {
        return droppableId === "leftDroppable" ? documentTemplates : selectedDocuments;
    }

    function handleDragEnd(result) {

        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                getList(source.droppableId),
                source.index,
                destination.index
            );

            if (source.droppableId === 'rightDroppable') {

                for (let i = 0; i < items.length; i++) {
                    items[i].sortIndex = i;
                }

                saveDocumentsSelection(items);
            } else {
                setDocumentTemplates(items);
            }

        } else {

            // check if we removed an item from the selected items list
            if (source.droppableId === 'rightDroppable' && destination.droppableId === "leftDroppable" && currentUser) {
                if (source.index >= 0 && source.index < selectedDocuments.length) {
                    const idToRemove = selectedDocuments[source.index].id;
                    //selectedDocuments[source.index].id = "";

                    PatientDocsService.deleteDocument(currentUser.clientId, currentUser.locationId, patient.id, idToRemove);
                }
            }


            const result = move(
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination
            );

            for (let i = 0; i < result["rightDroppable"].length; i++) {
                result["rightDroppable"][i].sortIndex = i;
            }

            setDocumentTemplates(result["leftDroppable"]);
            saveDocumentsSelection(result["rightDroppable"]);

        }

    }

    async function saveDocumentsSelection(newSelection: PDocument[], updateDocumentsSentsStatus: boolean = true) {

        if (!currentUser) return;

        const result = [] as PDocument[];

        for (let i = 0; i < newSelection.length; i++) {
            const selectedDocument = newSelection[i].clone();

            selectedDocument.sortIndex = i;

            if (!selectedDocument.templateId) {
                selectedDocument.templateId = selectedDocument.id;
                //selectedDocument.id = "";
                selectedDocument.patientId = patient.id;
                if (appointment) {
                    selectedDocument.doctorId = appointment.calendar.userId;
                    selectedDocument.appointmentId = appointment.id;
                }
            }

            const id = await PatientDocsService.updateDocument(selectedDocument, currentClient.id, currentUser.locationId, patient.id);
            if (id) {
                selectedDocument.id = id;
                result.push(selectedDocument);
            }
        }

        setSelectedDocuments(result);

        if(appointment && onSelectedDocumentsChanged) onSelectedDocumentsChanged();

        if (updateDocumentsSentsStatus) {
            // set sent status to false, because we changed the selection and this selection was not sent (old version, maybe we can delete this)
            await PatientsService.setPatientDocumentsSentStatus(patient.id, currentClient.id, currentUser.locationId, false);
            PatientDocsService.updatePatientDocumentsStatus(currentClient.id, currentUser.locationId, patient.id);
        }


    }

    async function deleteSentDocument(documentId: string) {

        const itemToRemove = sentDocuments.find(doc => doc.id === documentId);

        if (itemToRemove) {

            itemToRemove.status = "none";

            itemToRemove.resetDoctorInputs();

            documentTemplates.push(itemToRemove);
            setDocumentTemplates([...documentTemplates]);

            const newList = Utils.removeItemByIdFromArray(documentId, sentDocuments);
            setSentDocuments(newList);

            await PatientDocsService.deleteDocument(currentUser!.clientId, currentUser!.locationId, patient.id, documentId);

            PatientDocsService.updatePatientDocumentsStatus(currentClient.id, currentUser!.locationId, patient.id);
        }
    }

    async function openPatientPDF(documentId: string) {

        if (currentUser) {
            let downloadUrl = await PDFService.getPatientPDF(currentUser.clientId, currentUser.locationId, patient.id, documentId);

            if (downloadUrl) {
                downloadUrl += "&rnd=" + Math.random();
                const newWindow = window.open(downloadUrl, '_blank', 'noopener,noreferrer');
                if (newWindow) newWindow.opener = null;
            }
        }
    }

    async function downloadPatientPDF(document: PDocument) {

        if (currentUser) {
            let downloadUrl = await PDFService.getPatientPDF(currentUser.clientId, currentUser.locationId, patient.id, document.id);

            if (downloadUrl) {
                downloadFile(downloadUrl, document.name + ".pdf");
            }
        }
    }

    async function printDocument(document: PDocument) {

        if (currentUser) {
            let downloadUrl = await PDFService.getPatientPDF(currentUser.clientId, currentUser.locationId, patient.id, document.id);

            if (downloadUrl) {
                printPDF(downloadUrl, document.name + ".pdf");
            }
        }
    }


    function generateQRCodePrintPage() {

        const qrCode = document.getElementById("kt-hidden-documents-qrcode-wrapper");

        if (qrCode) {
            const qrCodeHtmlString = qrCode.innerHTML;

            return "<html>" +
                "<head>" +
                "  <script>" +
                "    function printAndCloseDelayed(){" +
                "      setTimeout('printAndClose()', 10);" +
                "    }" +
                "    function printAndClose(){" +
                "      window.print();" +
                "      window.close()" +
                "    }" +
                "  </script>" +
                "</head>" +
                "<body onload='printAndCloseDelayed()' style='text-align:center; font-family: Arial; font-size: 14px'>" +
                `  <p>${patient.getGenderAndFullName()},<br/>` +
                "  bitte scannen Sie den QRCode um Ihre Unterlagen auszufüllen:</p>" + qrCodeHtmlString +
                `<p>${currentClient.name}</p>` +
                "</body>" +
                "</html>";

        }

        return "";
    }

    async function sendPatientPDFByEmail(document: PDocument) {
        if (currentUser) {
            let downloadUrl = await PDFService.getPatientPDF(currentUser.clientId, currentUser.locationId, patient.id, document.id);

            const _email = new Email();

            _email.senderEmail = currentLocation.email;
            _email.senderName = currentLocation.name;

            _email.subject = 'Ihre Gesundheitsunterlagen';
            _email.toAddresses = [patient.email];
            _email.htmlBody = `<p>${patient.getGenderAndFullName()},<br/> anbei erhalten Sie eine Kopie Ihres unterzeichneten Dokuments.</p><p>Mit freundlichen Grüßen,<br/>${currentLocation.name}.</p>`;
            _email.attachments = [{ id: Utils.getUUID(), documentId: document.id, name: document.name + ".pdf", url: downloadUrl, createdOn: document.pdfCreatedAt ?? undefined }];

            _email.patientId = patient.id;
            _email.clientId = currentClient.id;
            _email.locationId = currentLocation.id;
            _email.createdByUserId = currentUser.id;

            setEmail(_email);
            setIsEmailDialogVisible(true);
        }
    }

    async function sendDocumentEmailToSign() {

        if (!currentUser) return;

        if (!patient.email) {
            alert("Es kann keine Dokumenten Email versendet werden. Dieser Patient besitzt keine gültige Email.");
            return;
        }

        if (!patient.emailAllowed) {
            alert("Es kann keine Dokumenten Email versendet werden. Dieser Patient möchte keine Emails bekommen.");
            return;
        }

        if (await hasMissingDoctorInputs("sentByEmail")) {
            return;
        }

        const location = await ClientLocationsService.getLocation(currentClient.id, currentUser.locationId);
        if (!location) {
            console.error("Error sending document email: could not find location");
            return null;
        }

        // setSentStatusForSelection();

        const documentsLink = PatientDocsService.getDocumentsLink(patient.id, currentClient.id, currentUser.locationId);

        const _email = new Email();

        _email.senderEmail = currentLocation.email;
        _email.senderName = currentLocation.name;

        _email.subject = 'Ihre Gesundheitsunterlagen';
        _email.toAddresses = [patient.email];
        _email.htmlBody = `<p>${patient.getGenderAndFullName()},<br/> bitte füllen Sie Ihre Unterlagen für Ihren Termin aus.</p><p>Klicken Sie dazu auf folgenden Link:<br/><a href="${documentsLink}">${documentsLink}</a></p><p>Mit freundlichen Grüßen,<br/>${currentLocation.name}.</p>`;

        _email.patientId = patient.id;
        _email.clientId = currentClient.id;
        _email.locationId = currentLocation.id;
        _email.createdByUserId = currentUser.id;

        setEmail(_email);
        setIsEmailDialogVisible(true);
    }

    // check all documents before sending, if there are fields that the doctor has to fill out and are missing
    async function hasMissingDoctorInputs(followUpAction: FollowUpActionType) {

        if (currentUser) {
            let foundMissingInput = await PatientDocsService.hasMissingDoctorInputs(selectedDocuments, currentUser.clientId);

            setMissingDoctorFieldsDialogFollowUpAction(followUpAction);
            setIsMissingDoctorFieldsDialogVisible(foundMissingInput);

            return foundMissingInput;
        }

        return false;
    }

    async function setSentStatusForSelection() {
        const modifiedDocs = [] as PDocument[];


        // now set sent status on all selected documents
        for (let i = 0; i < selectedDocuments.length; i++) {
            const selectedDocument = selectedDocuments[i];
            if (selectedDocument.status !== "sent") {
                selectedDocument.status = "sent";
                modifiedDocs.push(selectedDocument);
            }
        }
        const _sentDocumnents = sentDocuments.concat(modifiedDocs);
        setSentDocuments(_sentDocumnents);
        setSelectedDocuments([]);

        // only now save to db, otherwise UI would be too slow
        for (let i = 0; i < modifiedDocs.length; i++) {
            const modifiedDocument = modifiedDocs[i];
            await PatientDocsService.updateDocument(modifiedDocument, currentClient.id, currentUser!.locationId, patient.id);
        }

        await PatientsService.setPatientDocumentsSentStatus(patient.id, currentClient.id, currentUser!.locationId, true);

        PatientDocsService.updatePatientDocumentsStatus(currentClient.id, currentUser!.locationId, patient.id);
    }

    async function printQRCode() {

        // if (await hasMissingDoctorInputs("print")) {
        //     return;
        // }

        const pwa = window.open("about:blank", "_new");
        if (pwa) {
            pwa.document.open();
            pwa.document.write(generateQRCodePrintPage());
            pwa.document.close();

            // setSentStatusForSelection();
        }
    }

    async function checkForMissingDoctorInputsAndSetSentStatusForSelection(){
        if (await hasMissingDoctorInputs("moveToSent")) {
            return;
        }

        setSentStatusForSelection();
    }

    async function showQRCodePopup() {
        // if (await hasMissingDoctorInputs("qrcode")) {
        //     return;
        // }

        // setSentStatusForSelection();        
        setIsQRCodeDialogVisible(true);
    }


    function renderDocumentDetails(document: PDocument) {
        return <div>
            <span>{document.pdfCreatedAt?.toLocaleString()}</span>
            <br />
            {(document.expiresAt && document.expiresAt.getTime() < Date.now()) &&
                <span>Abgelaufen <i className="far fa-times kt-red-color"></i></span>
            }
            {(document.expiresAt && document.expiresAt.getTime() >= Date.now()) &&
                <span>Gültig bis {DateUtils.getDateString(document.expiresAt)} <i className="fa fa-check kt-green-color"></i></span>
            }
            {(document.expiresAt === null) &&
                <span>Unbefristet gültig <i className="fa fa-check kt-green-color"></i></span>
            }
        </div>
    }

    function handleLeftSideDoubleClick(e, index: number) {
        if (e.detail === 2) {

            const item = {
                source: {
                    index: index,
                    droppableId: "leftDroppable"
                },
                destination: {
                    index: 0,
                    droppableId: "rightDroppable"
                }
            };

            handleDragEnd(item);
        }
    }

    function handleRightSideDoubleClick(e, index: number) {
        if (e === null || e.detail === 2) {

            const item = {
                source: {
                    index: index,
                    droppableId: "rightDroppable"
                },
                destination: {
                    index: 0,
                    droppableId: "leftDroppable"
                }
            };

            handleDragEnd(item);
        }
    }

    function getDocumentsLink() {
        if (patient && currentUser) {
            return PatientDocsService.getDocumentsLink(patient.id, currentClient.id, currentUser.locationId);
        }

        return "";
    }

    async function handleCloseMissingDoctorFieldsDialog(_documents: PDocument[], followUpAction: FollowUpActionType) {
        setIsMissingDoctorFieldsDialogVisible(false);
        await saveDocumentsSelection(_documents, false);

        switch (followUpAction) {
            case "qrcode":
                showQRCodePopup();
                break;

            case "sentByEmail":
                sendDocumentEmailToSign();
                break;

            case "print":
                printQRCode();
                break;

            case "moveToSent":
                checkForMissingDoctorInputsAndSetSentStatusForSelection();
                break;

            default:
                break;
        }
    }

    if (!currentUser) {
        return null;
    }

    return (
        <React.Fragment>

            <EmailDialog
                visible={isEmailDialogVisible}
                dialogTitle='Dokumente per Email versenden'
                email={email}
                allDocuments={signedDocuments}
                onSend={() => setIsEmailDialogVisible(false)}
                onClose={() => setIsEmailDialogVisible(false)}
            />

            <MissingDoctorFieldsDialog
                isDialogVisible={isMissingDoctorFieldsDialogVisible}
                patientId={patient.id}
                documents={[...selectedDocuments]}
                clientId={currentClient.id}
                locationId={currentUser.locationId}
                followUpAction={missingDoctorFieldsDialogFollowUpAction}
                onClose={handleCloseMissingDoctorFieldsDialog}
            ></MissingDoctorFieldsDialog>

            <Dialog
                open={isQRCodeDialogVisible}
                onClose={() => setIsQRCodeDialogVisible(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"QR Code scannen"}
                </DialogTitle>
                <DialogContent className="kt-text-center">
                    <div className="kt-qrcode-wrapper">
                        <a href={getDocumentsLink()} target="_new">
                            <QRCode
                                value={getDocumentsLink()}
                                size={120}
                            >
                            </QRCode>
                        </a>
                    </div>
                    <br />
                    <DialogContentText id="alert-dialog-description">
                        Scannen Sie den QR-Code für den Patienten mit dem Tablett ein.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsQRCodeDialogVisible(false)} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <div id="kt-hidden-documents-qrcode-wrapper">
                <QRCode
                    value={getDocumentsLink()}
                    size={120}
                >
                </QRCode>
            </div>

            <div className="kt-documents-tab kt-dialog-left-panel kt-hide-mobile">
                {(patient) ?
                    <div>

                        {sentDocuments.length > 0 &&
                            <>
                                <div>Bereitgestellte Dokumente</div>
                                <List>
                                    {sentDocuments.map((document) =>
                                        <ListItem key={document.id} className="kt-content-rectangle">
                                            <ListItemText
                                                primary={<React.Fragment>{document.name}</React.Fragment>}
                                            />
                                            <ListItemSecondaryAction>
                                                <Tooltip title="Dokument löschen" placement='right' arrow>
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="delete"
                                                        onClick={() => deleteSentDocument(document.id)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )}
                                </List>
                                <br />
                            </>
                        }


                        <div>Unterschriebene Dokumente</div>
                        {signedDocuments.length > 0 ?
                            <List>
                                {signedDocuments.map((document) =>
                                    <ListItem key={document.id} onClick={() => openPatientPDF(document.id)} className="kt-link kt-content-rectangle">
                                        <ListItemText
                                            primary={<React.Fragment><i className="fal fa-file-pdf"></i> {document.name}</React.Fragment>}
                                            secondary={renderDocumentDetails(document)}
                                        />
                                        <ListItemSecondaryAction>
                                            <div style={{ display: "flex", flexDirection: "column", width: "40px" }}>
                                                <Tooltip title="Dokument herunterladen" placement='right' arrow>
                                                    <div className='kt-document-details-button' onClick={() => downloadPatientPDF(document)}><i className='fa fa-download'></i></div>
                                                </Tooltip>
                                                <Tooltip title="Dokument per Email senden" placement='right' arrow>
                                                    <div className='kt-document-details-button' onClick={() => sendPatientPDFByEmail(document)}><i className='fa fa-share'></i></div>
                                                </Tooltip>
                                                <Tooltip title="Dokument drucken" placement='right' arrow>
                                                    <div className='kt-document-details-button' onClick={() => printDocument(document)}><i className='fa fa-print'></i></div>
                                                </Tooltip>
                                            </div>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )}
                            </List>
                            :
                            <List>
                                <ListItem>
                                    <ListItemText primary="Keine" />
                                </ListItem>
                            </List>
                        }

                    </div>
                    :
                    <div>

                    </div>
                }
            </div>

            <div className="kt-documents-tab kt-dialog-inner-body">
                <div className="kt-tab-page kt-full-height">

                    <Box className="kt-form-section" display={"flex"} flexDirection={"column"}>
                        <DragDropContext onDragEnd={handleDragEnd}>

                            <Typography>Dokumente vorbereiten</Typography>

                            <div className="kt-documents-transfer-list">

                                <div className="kt-documents-transfer-list-column">
                                    <h5>Alle Dokumente:</h5>
                                    <Droppable droppableId="leftDroppable">
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                className={`kt-form-section kt-drop-target ${snapshot.isDraggingOver ? "kt-drop-active" : ""}`}
                                            >
                                                {documentTemplates.map((locDoc, index) => (
                                                    <Draggable
                                                        key={locDoc.id}
                                                        draggableId={locDoc.id}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className={`kt-draggable-document-item ${snapshot.isDragging ? "kt-dragging-active" : ""}`}
                                                                onClick={(e) => handleLeftSideDoubleClick(e, index)}
                                                            >
                                                                {/* <i className="fal fa-notes-medical"></i> */}
                                                                <span className="kt-doc-name">{locDoc.name}</span>
                                                                {locDoc.mandatory ? <i className="far fa-exclamation"></i> : <div></div>}
                                                            </div>
                                                        )}

                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>

                                <div className="kt-documents-transfer-list-column">
                                    <h5>Ihre Auswahl:</h5>
                                    <Droppable droppableId="rightDroppable">
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                className={`kt-form-section kt-drop-target ${snapshot.isDraggingOver ? "kt-drop-active" : ""}`}
                                            >

                                                {selectedDocuments.map((locDoc, index) => (
                                                    <Draggable
                                                        key={locDoc.id}
                                                        draggableId={locDoc.id}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className={`kt-draggable-document-item ${snapshot.isDragging ? "kt-dragging-active" : ""}`}
                                                                onClick={(e) => handleRightSideDoubleClick(e, index)}
                                                            >
                                                                {/* <i className="fal fa-notes-medical"></i> */}
                                                                <span className="kt-doc-name">{locDoc.name}</span>
                                                                <div>
                                                                    {locDoc.mandatory && <i className="far fa-exclamation"></i>}
                                                                    <IconButton
                                                                        edge="end"
                                                                        aria-label="delete"
                                                                        onClick={() => handleRightSideDoubleClick(null, index)}
                                                                    >
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>

                            </div>

                        </DragDropContext>

                        {selectedDocuments.length > 0 && <Button 
                            variant='contained' 
                            style={{width: "300px", marginTop: "25px", alignSelf:"end"}}
                            onClick={()=>checkForMissingDoctorInputsAndSetSentStatusForSelection()}
                        >DOKUMENTE BEREITSTELLEN</Button>}

                    </Box>


                </div>


            </div>

            <div className="kt-dialog-right-panel kt-hide-mobile kt-action-buttons">

                <Button onClick={showQRCodePopup}><i className="far fa-qrcode"></i> QR-Code anzeigen</Button>

                <div className="kt-row-spacer-10"></div>
                <Button onClick={printQRCode}><i className="far fa-print"></i> QR-Code DRUCKEN</Button>

                <div className="kt-row-spacer-10"></div>
                <Button onClick={sendDocumentEmailToSign}><i className="far fa-share"></i> Link senden</Button>

                <div className='kt-hide-on-mobile'
                    style={{ display: "flex", flexDirection: "column", color: "white", marginTop: "40px" }}
                >
                    <div style={{ marginBottom: "10px" }}>Dokumenten Status:</div>
                    <div style={{ alignSelf: "center", justifyItems: "center", textAlign: "center", fontSize: "13px" }}>
                        <div style={{ margin: "10px" }}>
                            <TrafficLightCtrl activeLight={isLoadingDocuments ? "none" : patientDocsStatus} />
                        </div>
                        {!isLoadingDocuments && <>
                            {patientDocsStatus === "red" && <span>Es müssen noch Dokumente bereitgestellt werden.</span>}
                            {patientDocsStatus === "yellow" && <span>Patient muss noch Dokumente unterschreiben.</span>}
                            {patientDocsStatus === "green" && <span>Aktuell keine Aktion notwendig.</span>}
                        </>}
                        
                    </div>
                </div>


                <img className="kt-module-logo" src="../images/p-sign-white.svg" alt="p-sign" />

            </div>


        </React.Fragment >

    );
}

export default DocumentsTab;
