import DateUtils from "../utils/dateUtils";
import Gender from "./gender";
import ToDo from "./todo";
// import { t } from 'i18next';

export enum PhoneCallCategory {
    appointment,
    message,
    prescription,
    cancellation,
    question,
    medicalReport,
    medicalReferral
}

export enum PhoneCallStatus {
    none,           // initial status
    queued,         // The call is ready and waiting in line before dialing.
    ringing,        // The call is currently ringing.
    inProgress,     // The call was answered and is currently in progress.
    transcribing,   // Transcribing by Elevenlabs
    transbcribed,   // Transcription endet
    callCompleted,  // The call was answered and has ended normally.
    busy,           // The caller received a busy signal.
    failed,         // The call could not be completed as dialed, most likely because the provided number was invalid.
    noAnswer,       // There was no answer or the call was rejected.
    canceled,       // The call was hung up while it was queued or ringing.
    done            // The call was viewed by someone and marked as done
}

export interface TranscriptItem {
    role: "user" | "agent";
    message: string;
    timeInCallSecs: number;
}

export class PhoneCall {

    id: string = "";
    direction: "inbound" | "outbound" = "inbound";

    status: PhoneCallStatus = PhoneCallStatus.none;

    categories: PhoneCallCategory[] = [];
    tasks: ToDo[] = [];

    callerPhoneNumber: string = "";
    callerCountry: string = "";
    callerGender: Gender = Gender.female;
    callerFirstName: string = "";
    callerLastName: string = "";
    callerBirthDate: Date | null = null;

    audioRecordingUrl: string = "";
    callDuration: number = 0;       // in seconds

    calledNumber: string = "";
    agentId: string = "";
    callSid: string = "";           // Twilio call id
    conversationId: string = "";    // Elevenlabs conversation id

    transcript: TranscriptItem[] = []; // Elevenlabs transcription of the call

    patientId: string = "";
    createdByUserId: string = "";
    createdAt: Date = new Date();
    clientId: string = "";
    locationId: string = "";

    clone(): PhoneCall {

        const p = new PhoneCall();
        p.fromObject(this.toJSON(true));

        return p;
    }

    toJSON(useDate: boolean): object {

        const obj: any = {
            id: this.id,
            direction: this.direction,

            status: this.status,

            categories: this.categories,
            tasks: [],

            callerPhoneNumber: this.callerPhoneNumber,
            callerCountry: this.callerCountry,
            callerGender: this.callerGender,
            callerFirstName: this.callerFirstName,
            callerLastName: this.callerLastName,
            callerBirthDate: this.callerBirthDate ? DateUtils.toDateSecondsWithoutTimezoneInfo(this.callerBirthDate) : null,

            audioRecordingUrl: this.audioRecordingUrl,
            callDuration: this.callDuration,

            calledNumber: this.calledNumber,
            agentId: this.agentId,
            callSid: this.callSid,
            conversationId: this.conversationId,

            transcript: this.transcript,

            patientId: this.patientId,
            createdByUserId: this.createdByUserId,
            createdAt: useDate ? this.createdAt : this.createdAt.toJSON(),
            clientId: this.clientId,
            locationId: this.locationId
        };

        obj.tasks = this.tasks.map(task => task.toJSON(useDate));

        return obj;
    }

    fromObject(o: any) {

        if (o.id) {
            this.id = o.id;
        }

        this.direction = o.direction ?? "inbound";

        this.status = o.status ?? PhoneCallStatus.none;

        this.categories = o.categories ? [...o.categories] : [];

        this.tasks = o.tasks ? o.tasks.map((taskObject: any) => {
            const newTask = new ToDo();
            newTask.fromObject(null, taskObject);
            return newTask;
        }) : [];

        this.callerPhoneNumber = o.callerPhoneNumber ?? "";
        this.callerCountry = o.callerCountry ?? "";
        this.callerGender = o.callerGender ?? Gender.female;
        this.callerFirstName = o.callerFirstName ?? "";
        this.callerLastName = o.callerLastName ?? "";
        this.callerBirthDate = DateUtils.getDate(o.callerBirthDate);

        this.audioRecordingUrl = o.audioRecordingUrl ?? "";
        this.callDuration = o.callDuration ?? 0;

        this.calledNumber = o.calledNumber ?? "";
        this.agentId = o.agentId ?? "";
        this.callSid = o.callSid ?? "";
        this.conversationId = o.conversationId ?? "";

        this.transcript = o.transcript ? [...o.transcript] : [];

        this.patientId = o.patientId ?? "";
        this.createdByUserId = o.createdByUserId ?? "";
        this.createdAt = DateUtils.getDate(o.createdAt) ?? new Date();
        this.clientId = o.clientId ?? "";
        this.locationId = o.locationId ?? "";
    }

    setNewStatus(status: string) {
        const statusMapping: Record<string, PhoneCallStatus> = {
            queued: PhoneCallStatus.queued,
            ringing: PhoneCallStatus.ringing,
            inprogress: PhoneCallStatus.inProgress,
            transcribing: PhoneCallStatus.transcribing,
            transcribed: PhoneCallStatus.transbcribed,
            completed: PhoneCallStatus.callCompleted,
            busy: PhoneCallStatus.busy,
            failed: PhoneCallStatus.failed,
            noanswer: PhoneCallStatus.noAnswer,
            canceled: PhoneCallStatus.canceled,
            done: PhoneCallStatus.done,
        };

        this.status = statusMapping[status.toLowerCase()] || PhoneCallStatus.none;
    }

    getStatusString(): string {
        return PhoneCall.getStatusString(this.status);
    }

    static getStatusString(status: PhoneCallStatus): string {

        switch (status) {
            case PhoneCallStatus.queued:
                return "In Warteschlange";
            case PhoneCallStatus.ringing:
                return "Klingelt";
            case PhoneCallStatus.inProgress:
                return "Im Gespräch";
            case PhoneCallStatus.transcribing:
                return "Transkribieren";
            case PhoneCallStatus.transbcribed:
                return "Transkribiert";
            case PhoneCallStatus.callCompleted:
                return "Gespräch beendet";
            case PhoneCallStatus.busy:
                return "Besetzt";
            case PhoneCallStatus.failed:
                return "Fehlgeschlagen";
            case PhoneCallStatus.noAnswer:
                return "Keine Antwort";
            case PhoneCallStatus.canceled:
                return "Abgebrochen";
            case PhoneCallStatus.done:
                return "Erledigt";
            default:
                return "";
        }
    }

    static getCategoryString(category: PhoneCallCategory): string {
        switch (category) {
            case PhoneCallCategory.appointment:
                return "Termin";
            case PhoneCallCategory.cancellation:
                return "Stornierung";
            case PhoneCallCategory.medicalReferral:
                return "Überweisung";
            case PhoneCallCategory.medicalReport:
                return "Arztbericht";
            case PhoneCallCategory.message:
                return "Nachricht";
            case PhoneCallCategory.prescription:
                return "Rezept";
            case PhoneCallCategory.question:
                return "Frage";
            default:
                return "";
        }
    }


    // static getStatusString(status: PhoneCallStatus): string {

    //     switch (status) {
    //         case PhoneCallStatus.queued:
    //             return t("pages.phoneCallsPage.phoneCallStatus.queued");
    //         case PhoneCallStatus.ringing:
    //             return t("pages.phoneCallsPage.phoneCallStatus.ringing");
    //         case PhoneCallStatus.inProgress:
    //             return t("pages.phoneCallsPage.phoneCallStatus.inProgress");
    //         case PhoneCallStatus.transcribing:
    //             return t("pages.phoneCallsPage.phoneCallStatus.transcribing");
    //         case PhoneCallStatus.transbcribed:
    //             return t("pages.phoneCallsPage.phoneCallStatus.transcribed");
    //         case PhoneCallStatus.callCompleted:
    //             return t("pages.phoneCallsPage.phoneCallStatus.callCompleted");
    //         case PhoneCallStatus.busy:
    //             return t("pages.phoneCallsPage.phoneCallStatus.busy");
    //         case PhoneCallStatus.failed:
    //             return t("pages.phoneCallsPage.phoneCallStatus.failed");
    //         case PhoneCallStatus.noAnswer:
    //             return t("pages.phoneCallsPage.phoneCallStatus.noAnswer");
    //         case PhoneCallStatus.canceled:
    //             return t("pages.phoneCallsPage.phoneCallStatus.canceled");
    //         case PhoneCallStatus.done:
    //             return t("pages.phoneCallsPage.phoneCallStatus.done");
    //         default:
    //             return "";
    //     }
    // }

    // static getCategoryString(category: PhoneCallCategory): string {
    //     switch (category) {
    //         case PhoneCallCategory.appointment:
    //             return t("pages.phoneCallsPage.phoneCallCategory.appointment");
    //         case PhoneCallCategory.cancellation:
    //             return t("pages.phoneCallsPage.phoneCallCategory.cancellation");
    //         case PhoneCallCategory.medicalReferral:
    //             return t("pages.phoneCallsPage.phoneCallCategory.medicalReferral");
    //         case PhoneCallCategory.medicalReport:
    //             return t("pages.phoneCallsPage.phoneCallCategory.medicalReport");
    //         case PhoneCallCategory.message:
    //             return t("pages.phoneCallsPage.phoneCallCategory.message");
    //         case PhoneCallCategory.prescription:
    //             return t("pages.phoneCallsPage.phoneCallCategory.prescription");
    //         case PhoneCallCategory.question:
    //             return t("pages.phoneCallsPage.phoneCallCategory.question");
    //         default:
    //             return "";
    //     }
    


}
export default PhoneCall;