import React, { useEffect, useState } from 'react';


interface Props {
    id: string;
    videoUrl: string;
    thumbnailUrl?: string;
    forcePreloading?: boolean;
    className?: string;
}

const VideoPlayerCtrl: React.FC<Props> = ({ id, videoUrl, thumbnailUrl, forcePreloading, className }) => {

    const [isVideoPlaying, setIsVideoPlaying] = useState(false);


    const handleOnClickVideoClone = () => {
        const vidElement = document.getElementById(id) as any;
        if (vidElement) {

            if (vidElement.currentTime > 0 && !vidElement.paused && !vidElement.ended && vidElement.readyState > 2) {
                vidElement.pause();
                setIsVideoPlaying(false);
            } else {
                vidElement.play();
                setIsVideoPlaying(true);
            }
        }
    }

    const handleOnVideoEnded = () => {
        const vidElement = document.getElementById(id) as any;
        if (vidElement) {
            setIsVideoPlaying(false);
        }
    }

    
    return (
        <div className='kt-video-player-ctrl'>
            <video
                className={'kt-video ' + className ? className : ""}
                playsInline={true}
                id={id}
                preload='metadata'                
                src={`${videoUrl}${forcePreloading ? "#t=0.001" : ""}`}
                onClick={handleOnClickVideoClone}
                onPlay={()=>setIsVideoPlaying(true)}
                onPause={()=>setIsVideoPlaying(false)}
                onEnded={handleOnVideoEnded}
                poster={thumbnailUrl}
            />
            <div className={isVideoPlaying ? 'kt-play-button kt-hide' : 'kt-play-button'}><i className='far fa-play-circle'></i></div>
        </div>
    );
}

export default VideoPlayerCtrl;