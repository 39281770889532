import Utils from "../../utils/utils";
import CheckboxFormItem from "./checkboxFormItem";
import HeadlineFormItem from "./headlineFormItem";
import InputTextFormItem from "./inputTextFormItem";
import LogoFormItem from "./logoFormItem";
import ParagraphFormItem from "./paragraphFormItem";
import RadioFormItem from "./radioFormItem";
import SignatureFormItem from "./signatureFormItem";
import PlaceholderFormItem from "./placeholderFormItem";
import LineFormItem from "./lineFormItem";
import AnswerFormItem from "./answerFormItem";
import FormRow from "./formRow";
import DropdownFormItem from "./dropdownFormItem";
import VideoFormItem from "./videoFormItem";


export enum FormItemEnum {
    image,
    headline,
    paragraph,
    checkbox,
    emptyRow,
    inputText,
    inputArea,
    fullName,
    radio,
    signature,
    logo,
    placeholder,
    line,
    checkboxAnswer,
    radioAnswer,
    dropdown,
    video
}

export enum Align {
    left,
    center,
    right
}

export type FormItemType = FormItem | HeadlineFormItem | ParagraphFormItem | InputTextFormItem |
    CheckboxFormItem | RadioFormItem | SignatureFormItem | LogoFormItem |
    PlaceholderFormItem | LineFormItem | AnswerFormItem | DropdownFormItem | VideoFormItem;

export interface FormItemInterface {
    id: string;
    type: FormItemEnum;
    formRows?: FormRow[] | undefined;
    answers?: AnswerFormItem[] | undefined;
    clone(): FormItemType;
    toJSON(includeUserInputs: boolean): object;
    fromObject(id: string | null, o: any): void;
    getLanguageProperty(languageKey: string, propertyName: string): string;
    setLanguageProperty(languageKey: string, newValue: string, propertyName: string): void;
}

export type InputUserType = "patient" | "doctor" | "all";

export interface InputFormItemInterface {
    required: boolean,
    inputUser: InputUserType
}

abstract class FormItem implements FormItemInterface {
    id: string = "";
    parentId: string = "";
    type: FormItemEnum = FormItemEnum.paragraph;
    formRows?: FormRow[] | undefined;
    answers?: AnswerFormItem[] | undefined;

    labels: { key: string, value: string }[] = []; // array of all translations



    constructor(parentId: string) {
        this.id = Utils.getUUID();
        this.parentId = parentId;
    }

    abstract clone(): FormItemType


    toJSON(includeUserInputs: boolean): object {
        return {
            id: this.id,
            parentId: this.parentId,

            type: this.type,
            labels: this.labels
        };
    }

    fromObject(id: string | null, o: any) {

        this.id = id !== null ? id : o.id;
        this.parentId = o.parentId ?? "";

        this.type = o.type ?? FormItemEnum.paragraph;

        this.labels = o.labels ? [...o.labels] : [];
    }

    getLanguageProperty(languageKey: string, propertyName: string): string {

        try {

            if (propertyName === "labels") {
                let lang: any = null;

                if (this.hasOwnProperty(propertyName)) {
                    lang = this[propertyName].find(element => element.key === languageKey);
                }

                if (lang) {
                    return lang.value;
                } else if (this[propertyName].length > 0) {
                    return this[propertyName][0].value;
                }
            }

        } catch (error) {
            console.error(`error in getLanguageProperty languageKey: ${languageKey} propertyName: ${propertyName}`);
            console.error(error);
        }
        return "";
    }

    setLanguageProperty(languageKey: string, newValue: string, propertyName: string) {

        let lang: any = null;

        if (propertyName === "labels") {
            if (this.hasOwnProperty(propertyName)) {
                lang = this[propertyName].find(element => element.key === languageKey);
            }

            if (lang) {
                lang.value = newValue;
            } else {
                const newLanguage = {
                    key: languageKey,
                    value: newValue
                }

                this[propertyName].push(newLanguage);
            }
        }
    }

    isInputType(): boolean {
        return this.type === FormItemEnum.checkbox ||
            this.type === FormItemEnum.radio ||
            this.type === FormItemEnum.dropdown ||
            this.type === FormItemEnum.inputArea ||
            this.type === FormItemEnum.inputText ||
            this.type === FormItemEnum.signature;
    }

    hasValue(): boolean {
        const that = this as any; // some casting, otherwise we get compiler errors

        switch (this.type) {
            case FormItemEnum.inputText:
            case FormItemEnum.radio:
            case FormItemEnum.dropdown:
                return (that as InputTextFormItem).value.length > 0;

            case FormItemEnum.signature:
                return (that as SignatureFormItem).signature.length > 7800;

            case FormItemEnum.checkbox:
                return (that as CheckboxFormItem).hasOneOrMoreAnswersChecked();

            default:
                return false;
        }
    }

    static getIconByType(formRowType: FormItemEnum) {
        switch (formRowType) {
            case FormItemEnum.headline:
                return "fa-heading";

            case FormItemEnum.paragraph:
                return "fa-align-justify";

            case FormItemEnum.image:
                return "fa-image";

            case FormItemEnum.inputText:
                return "fa-edit";

            case FormItemEnum.radio:
                return "fa-check-circle";

            case FormItemEnum.checkbox:
                return "fa-check-square";

            case FormItemEnum.signature:
                return "fa-file-signature";

            case FormItemEnum.placeholder:
                return "fa-expand-alt";

            case FormItemEnum.line:
                return "fa-minus";

            case FormItemEnum.checkboxAnswer:
            case FormItemEnum.radioAnswer:
                return "fa-grip-lines";

            case FormItemEnum.dropdown:
                return "fa-caret-down";

            case FormItemEnum.video:
                return "fa-video";

            default:
                return "";
        }
    }
}


export default FormItem;