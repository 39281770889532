import DateUtils from "../../src/shared/src/utils/dateUtils";
import Gender from "../../src/shared/src/models/gender";

export class Attendee {
    id: string = "";
    gender: Gender = Gender.female;
    firstName: string = "";
    lastName: string = "";

    mobilePhoneNumber: string = "";

    isOnline?: boolean = false;
    isHost?: boolean = false;

    clone(): Attendee {
        const a = new Attendee();

        a.fromObject(this.id, this.toJSON());

        return a;
    }

    toJSON(): object {
        const obj = {
            id: this.id,

            gender: this.gender,
            firstName: this.firstName,
            lastName: this.lastName,

            mobilePhoneNumber: this.mobilePhoneNumber,

            isOnline: this.isOnline === true,
            isHost: this.isHost === true

        };

        return obj;
    }

    fromObject(id: string|null, o:any){
        this.id = id !== null ? id : o.id;

        this.gender = o.gender ?? Gender.female;
        this.firstName = o.firstName ?? "";
        this.lastName = o.lastName ?? "";

        this.mobilePhoneNumber = o.mobilePhoneNumber ?? "";

        this.isOnline = o.isOnline === true;
        this.isHost = o.isHost === true;
    }

    getFullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }
}

class VideoRoom {
    id: string = "";

    start: Date = new Date();
    end: Date = new Date();

    attendees: Attendee[] = [];

    clone(): VideoRoom {
        const v = new VideoRoom();

        v.fromObject(this.id, this.toJSON());

        return v;
    }

    toJSON(): object {
        const obj = {
            id: this.id,

            start: new Date(this.start),
            end: new Date(this.end),

            attendees: [] as any[]
        };

        for (let i = 0; i < this.attendees.length; i++) {
            const attendee = this.attendees[i];

            obj.attendees.push(attendee.toJSON());
        }

        return obj;
    }

    fromObject(id: string|null, o:any){

        this.id = id !== null ? id : o.id;

        this.start = DateUtils.getDate(o.start) ?? new Date();
        this.end = DateUtils.getDate(o.end) ?? new Date();

        this.attendees = [];

        if(o.attendees) {

            for (let a = 0; a < o.attendees.length; a++) {
                const attendeeObj = o.attendees[a];

                const newAttendee = new Attendee();
                newAttendee.fromObject(null, attendeeObj);
                this.attendees.push(newAttendee);
            }

        }

    }

};

export default VideoRoom;