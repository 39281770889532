import React, { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";

import QRCode from "react-qr-code";

import AppointmentsService, { EventType } from "../../../services/appointmentsService";
import Dialog, { DialogDefaultButton } from "../dialog";
import PatientDialog from "../patientDialog/patientDialog";
import PatientsService from '../../../services/patientsService';
import SearchInput from '../../searchInput';
import { isMobileDevice } from '../../../utils';

import moment from 'moment';
import Patient, { PatientScore } from '../../../models/patient';
import Calendar from "../../../models/calendar";
import VisitMotive from '../../../models/visitMotive';
import Appointment, { PatientStatus } from '../../../models/appointment';
import VisitMotivePicker from '../../visitMotivePicker';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PhoneIcon from '@mui/icons-material/Phone';
import CreateIcon from '@mui/icons-material/Create';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import DeleteIcon from '@mui/icons-material/Delete';

import RoomsService from '../../../services/roomsService';
import DevicesService from '../../../services/devicesService';
import UsersService from '../../../services/usersService';
import CalendarsService from '../../../services/calendarsService';
import SpecialitiesService from '../../../services/specialitiesService';
import { UserGroup, getGroupedSortedList } from '../../../models/user';
import { Toast } from '../../toaster';
import DocumentsTab from './documentsTab';
import DateUtils from '../../../../src/shared/src/utils/dateUtils';
import LoggingService from '../../../../src/services/loggingService';
import { LogItemType } from '../../../../src/shared/src/models/logItem';
import StarsInputCtrl from '../../starsInputCtrl';
import SearchTagsDialog from '../searchTagsDialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import ListSubheader from '@mui/material/ListSubheader';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import dayjs from 'dayjs';
import { GlobalContext } from '../../../GlobalContext';
import { TimeValidationError, DateValidationError } from '@mui/x-date-pickers/models';
import { colors } from '@mui/material';

function AppointmentDialog(props) {

    const [appointment, setAppointment] = useState<Appointment>(props.appointment);
    const [oldAppointment, setOldAppointment] = useState<Appointment | null>(null);
    const [patient, setPatient] = useState(new Patient());
    const [oldPatient, setOldPatient] = useState<Patient | null>(null);
    const [patientAppointments, setPatientAppointments] = useState<Appointment[]>([]);
    const [isPatientDialogVisible, showPatientDialog] = useState(false);
    const [isSearchTagsDialogVisible, setIsSearchTagsDialogVisible] = useState(false);
    const [selectedTab, setSelectedTab] = useState("appointment.main");

    const { rooms } = useContext(GlobalContext);
    const { devices } = useContext(GlobalContext);
    const { visitMotives } = useContext(GlobalContext);
    const { specialities } = useContext(GlobalContext);
    const { currentUser } = useContext(GlobalContext);
    const { calendars } = useContext(GlobalContext);
    const { currentClient } = useContext(GlobalContext);
    const { currentLocation } = useContext(GlobalContext);
    const { users } = useContext(GlobalContext);

    const [groupedUsers, setGroupedUsers] = useState<UserGroup[]>([]);

    const { setNewToast } = useContext(GlobalContext);

    const [endTimeError, setEndTimeError] = React.useState<TimeValidationError | null>(null);
    const [endDateError, setEndDateError] = React.useState<DateValidationError | null>(null);

    const [birthDateError, setBirthDateError] = React.useState<DateValidationError | null>(null);

    const [isDocumentsFeatureEnabled, setIsDocumentsFeatureEnabled] = useState(false);

    const errorEndTimeMessage = React.useMemo(() => {
        switch (endTimeError) {

            case 'minTime':
                return 'Die End-Uhrzeit muss sich nach der Start-Uhrzeit befinden.';

            default:
                return '';
        }
    }, [endTimeError]);

    const errorEndDateMessage = React.useMemo(() => {
        switch (endDateError) {

            case 'minDate':
                return 'Das End-Datum muss sich nach dem Start-Datum befinden.';

            case 'invalidDate':
                return 'Ihr End-Datum ist ungültig';

            default:
                return '';
        }
    }, [endDateError]);


    const errorBirthDateMessage = React.useMemo(() => {
        
        switch (birthDateError) {

            case 'invalidDate':
                return 'Bitte geben Sie ein gültiges Geburtsdatum ein.';

            default:
                return '';
        }
    }, [birthDateError]);

    useEffect(() => {
        if (props.visible) {
            setSelectedTab("appointment.main");
        }
    }, [props.visible]);

    useEffect(() => {

        updateDialogData(props.appointment);

        // eslint-disable-next-line
    }, [props.appointment])


    useEffect(() => {
        if (users && users.length > 0) {
            setGroupedUsers(getGroupedSortedList(users, false));
        }
    }, [users]);

    useEffect(() => {
        updatePatientAppointments();
    }, [patient]);

    useEffect(() => {

        (async () =>  {
            const enabled = await hasDocumentsFeatureEnabled();
            setIsDocumentsFeatureEnabled(enabled);
        })();

    }, [appointment]);

    async function updatePatientAppointments() {
        if (patient && patient.id && currentUser) {
            // now load the patients appointments
            let appointments = await AppointmentsService.getAppointmentsByPatientId(patient.id, currentUser.clientId, currentUser.locationId);
            if (appointments) {
                appointments = appointments.filter(appointment => DateUtils.isInFuture(appointment.start));
                appointments = appointments.filter(appointment => appointment.status !== "declined");

                appointments.forEach(appointment => appointment.updateColorBySpeciality(specialities));

                setPatientAppointments(appointments);
            } else {
                setPatientAppointments([]);
            }
        } else {
            setPatientAppointments([]);
        }
    }

    async function updateDialogData(inAppointment: Appointment) {

        try {

            if(appointment.id) console.log(`appointment id: ${appointment.id}`);

            const modifiedAppointment = inAppointment.clone();

            if (inAppointment.patient && inAppointment.patient.id && currentUser) {
                const tempPatient = await PatientsService.getPatient(inAppointment.patient.id, currentUser.clientId, currentUser.locationId);

                if (tempPatient !== null) {

                    // we need to clone as JSON otherwise we only copy the reference
                    const clonedPatient = tempPatient.clone();

                    setPatient(tempPatient);
                    setOldPatient(clonedPatient); // we need oldPatient later to know if we have to save the patient

                }


            } else {
                const emptyPatient = new Patient();

                const emptyClonedPatient = emptyPatient.clone();

                setPatient(emptyPatient);
                setOldPatient(emptyClonedPatient);

            }



            // set the correct calendar from the list for the appointments
            // otherwise if the user does not change the dropdown selection
            // calendar will allways be empty
            //if((!inAppointment.calendar || !inAppointment.calendar.id) && calendars.length>0){
            if (calendars.length > 0) {

                if (inAppointment.resourceId && typeof inAppointment.resourceId === "string") {
                    const selCal = await Calendar.getCalendarById(calendars, inAppointment.resourceId)
                    modifiedAppointment.calendar = selCal ? selCal : calendars[0];
                } else {
                    modifiedAppointment.calendar = calendars[0];
                }
            }




            // set the first visit motive from the list for the appointments
            // otherwise if the user does not change the dropdown selection
            // visitMotive will allways be empty
            // NOTE: we had to disable this, because it starts to flicker the tabs, if you open an existing appointment
            // and then open a new appointment

            // if ((!inAppointment.visitMotive || !inAppointment.visitMotive.id) && visitMotives.length > 0 && specialities.length > 0) {
            //     const firstVisitMotive = VisitMotivesService.getFirstVisitMotive(specialities, visitMotives);

            //     modifiedAppointment.visitMotive = firstVisitMotive ? firstVisitMotive.clone() : visitMotives[0].clone();
            // }


            if (!inAppointment.roomId || inAppointment.roomId === "without-room") {
                const room = await getPredefinedRoom(modifiedAppointment);
                if (room) {
                    modifiedAppointment.roomId = room.id;
                    modifiedAppointment.roomName = room.name
                }
            }

            setAppointment(modifiedAppointment);

            setOldAppointment(modifiedAppointment.clone());


        } catch (error) {
            console.log(error);
        }

    }

    async function getPredefinedRoom(inAppointment: Appointment): Promise<{ id: string, name: string } | null> {

        // check if doctor has default rooms defined
        if (inAppointment.calendar && inAppointment.calendar.id && currentUser) {

            const calendar = await CalendarsService.getCalendar(currentUser.clientId, currentUser.locationId, inAppointment.calendar.id)

            if (calendar) {

                const doctor = await UsersService.getUser(currentUser.clientId, calendar.userId);
                if (doctor && doctor.roomIds && doctor.roomIds.length > 0) {

                    const room = await RoomsService.getRoom(currentUser.clientId, currentUser.clientId, doctor.roomIds[0]);
                    if (room) {
                        return {
                            id: room.id,
                            name: room.name
                        }
                    }
                }

            }
        }

        return null;
    }



    function handlePatientChange(newPatient) {
        setPatient(newPatient.clone());

        appointment.patient = newPatient.clone();
        setAppointment(appointment.clone());
    }

    async function handlePatientDialogSave() {
        showPatientDialog(false);

        if (currentUser) {
            const tempPatient = await PatientsService.getPatient(appointment.patient.id, currentUser.clientId, currentUser.locationId);

            if (tempPatient !== null) {
                setPatient(tempPatient.clone());
            }
        }
    }


    async function hasDocumentsFeatureEnabled(): Promise<boolean> {
        if (appointment.calendar && appointment.calendar.id && currentUser) {

            const calendar = await CalendarsService.getCalendar(currentUser.clientId, currentUser.locationId, appointment.calendar.id)

            if (calendar && !calendar.internal && (calendar.license !== "free")) {
                return currentLocation && currentLocation.features && currentLocation.features.hasDocuments === true;
            }
        }

        return false;
    }

    function handleTabChange(newTab) {
        if (newTab !== selectedTab) {
            setSelectedTab(newTab);
        }
    }

    async function handleNextButton() {

        if (await hasDocumentsFeatureEnabled()) {
            if (await handleSaveDialog(false)) {
                handleTabChange("appointment.documents");
            }
        } else {
            handleSaveDialog(true);
        }

    }

    async function handleSaveDialog(closeDialog: boolean = true): Promise<boolean> {

        if (appointment.calendarItemType === "absence") {

            if (appointment.end.getTime() < appointment.start.getTime()) {
                alert("Wählen Sie bitte einen Endzeitpunkt der nach dem Start Ihrer Abwesenheit liegt.");
                return false;
            }

            AppointmentsService.updateAppointment(appointment, patient, currentClient, currentLocation, currentUser.id, undefined, undefined, async (eventType, newAppointment) => {
                if (eventType === EventType.create) {
                    const doctor = await UsersService.getUserByCalendarId(currentClient.id, currentLocation.id, appointment.calendar.id);
                    const message = `Abwesenheit am ${moment(newAppointment.start).format("DD.MM.YYYY")} für ${appointment.calendar.name} erstellt`;
                    LoggingService.log(LogItemType.userAction, appointment.id, "", "", doctor?.id, currentUser.id, message, currentClient.id, appointment.locationId);
                }
            });

            if (closeDialog) {
                handleCloseDialog(false);
            }

            setOldAppointment(appointment.clone());

            return true;

        } else if (patient && currentUser) {

            // the dropdown sets only the visitmotive id
            const motive = visitMotives.find(x => x.id === appointment.visitMotive.id);
            if (motive) {
                appointment.visitMotive = motive;
            }

            const speciality = await SpecialitiesService.getSpeciality(currentClient.id, currentUser.locationId, appointment.visitMotive.specialityId);

            let resultPatientId: null | string = null;

            // first check if we have to create or update the patient
            if (!patient.id || (oldPatient && (patient.toJSON() !== oldPatient.toJSON()))) {
                resultPatientId = await PatientsService.updatePatient(patient, currentUser.clientId, currentUser.locationId);
            }

            if (resultPatientId && speciality) {

                patient.id = resultPatientId;
                appointment.patient = patient.clone();
                appointment.patient.id = resultPatientId;
                appointment.isVideoCall = speciality.isVideoCall;

                AppointmentsService.updateAppointment(appointment, patient, currentClient, currentLocation, currentUser.id, undefined, undefined, (eventType, newAppointment) => {
                    if (eventType === EventType.create) {
                        switch (newAppointment.createdBy) {
                            case "recaller":
                                appointment.recallId = newAppointment.id;
                                setNewToast(new Toast("success", `Recall am ${moment(newAppointment.start).format("DD.MM.YYYY")} erstellt`));
                                break;

                            case "predecessor":
                                appointment.predecessorId = newAppointment.id;
                                setNewToast(new Toast("success", `Folgetermin am ${moment(newAppointment.start).format("DD.MM.YYYY")} erstellt`));
                                break;

                            default:
                                setNewToast(new Toast("success", `Termin am ${moment(newAppointment.start).format("DD.MM.YYYY")} erstellt`));
                                break;
                        }

                    }
                });

                if (closeDialog) {
                    handleCloseDialog(false);
                }

                setOldAppointment(appointment.clone());

                return true;

            } else {
                alert("Die Änderungen konnten nicht gespeichert werden.");
            }


        } else {
            alert("Bitte geben Sie einen Patienten an!");
        }

        return false;
    }

    function onDeleteSearchTag(tagName: string) {
        patient.tags = patient.tags.filter(e => e !== tagName.toLowerCase());

        setPatient(patient.clone());
    }

    function onCloseSearchTagsDialog(newSearchTag: string) {

        if (newSearchTag !== "" && !patient.tags.includes(newSearchTag.toLowerCase())) {
            patient.tags.push(newSearchTag);

            setPatient(patient.clone());
        }

        setIsSearchTagsDialogVisible(false);
    }

    function handleCloseDialog(checkForChanges: boolean = true) {

        let closeDialog = true;

        if (checkForChanges && oldAppointment && oldAppointment.id && JSON.stringify(oldAppointment.toJSON()) !== JSON.stringify(appointment.toJSON())) {
            closeDialog = window.confirm("Sie haben nicht gespeicherte Änderungen. Wollen Sie diesen Dialog wirklich schließen?");
        }

        if (closeDialog) {
            props.onClose();

            setOldPatient(null);
        }

    }

    function getDialogTitle() {
        const a = appointment;

        if (a && a.id && a.patient && a.calendarItemType !== "absence") {

            let appointmentType = a.isVideoCall ? "Videosprechstunde" : "Termin";

            if (a.createdBy === "recaller") {
                appointmentType = "Recall-Termin";
            } else if (a.createdBy === "predecessor") {
                appointmentType = "Folgetermin";
            }

            let fullName = `${a.patient.lastName} ${a.patient.firstName}`;
            if (patient && patient.title && patient.title.length > 1) {
                fullName = `${patient.title} ${fullName}`;
            }

            let title = `${appointmentType} mit ${fullName}`;

            if (a.status === "needsConfirmation") {
                title += " - UNBESTÄTIGT";
            }
            return title;

        } else if (a && a.calendarItemType === "absence") {
            return "ABWESENHEIT";

        } else {
            return "NEUER TERMIN";
        }
    }

    function handleAppointmentDurationChange(newEndDate: Date) {

        if (appointment && newEndDate) {
            const ap = appointment.clone();

            ap.end = newEndDate;

            setAppointment(ap);
        }
    }

    function handleAppointmentStartTimeChange(newStartDate: dayjs.Dayjs | null) {

        if (appointment && newStartDate) {

            const ap = appointment.clone();
            const apStart = DateUtils.getDate(ap.start);

            const duration = DateUtils.getDiffInMinutes(ap.start, ap.end);

            // the MaterialUiPickersDate looses the date information sometimes
            // so we have to copy the day information from the appointment
            const newStart = newStartDate.toDate();

            if (apStart && newStart) {
                apStart.setHours(newStart.getHours());
                apStart.setMinutes(newStart.getMinutes());

                ap.start = apStart;

                if (ap.calendarItemType !== "absence") {
                    ap.end = moment(ap.start).add(duration, "m").toDate();
                    setAppointment(ap);

                } else {
                    setAppointment(ap);

                    // if(ap.end.getTime() < ap.start.getTime()){
                    //     setAppointment(ap);
                    // } else {
                    //     alert("Das End-Datum muss größer als das Start-Datum sein!");
                    // }
                }
            }
        }
    }

    function handleAppointmentStartDateChange(newDate: dayjs.Dayjs | null) {
        const ap = appointment.clone();

        if (ap && newDate) {

            const apStart = DateUtils.getDate(ap.start);

            if (apStart) {
                const hours = apStart.getHours();
                const minutes = apStart.getMinutes();
                const duration = DateUtils.getDiffInMinutes(ap.start, ap.end);

                ap.start = newDate.toDate();
                ap.start.setHours(hours);
                ap.start.setMinutes(minutes);

                if (ap.calendarItemType !== "absence") {
                    ap.end = moment(ap.start).add(duration, "m").toDate();
                    setAppointment(ap);

                } else {
                    setAppointment(ap);

                    // if(ap.end.getTime() < ap.start.getTime()){
                    //     setAppointment(ap);
                    // } else {
                    //     alert("Das End-Datum muss größer als das Start-Datum sein!");
                    // }
                }

            }
        }
    }

    function handleAppointmentEndTimeChange(newEndDate: dayjs.Dayjs | null) {

        if (appointment && newEndDate) {

            const ap = appointment.clone();
            const apEnd = DateUtils.getDate(ap.end);

            // the MaterialUiPickersDate looses the date information sometimes
            // so we have to copy the day information from the appointment
            const newEnd = newEndDate.toDate();

            if (apEnd && newEnd) {
                apEnd.setHours(newEnd.getHours());
                apEnd.setMinutes(newEnd.getMinutes());

                ap.end = apEnd;

                setAppointment(ap);
            }
        }
    }

    function handleAppointmentEndDateChange(newDate: dayjs.Dayjs | null) {
        const ap = appointment.clone();

        if (ap && newDate) {

            const apEnd = DateUtils.getDate(ap.end);

            if (apEnd) {
                ap.end = newDate.toDate();

                setAppointment(ap);
            }
        }
    }



    function getAppointmentStartDate(): dayjs.Dayjs {
        const result = DateUtils.getDate(appointment.start);
        return dayjs(result ?? new Date());
    }

    function getAppointmentEndDate(): dayjs.Dayjs {
        const result = DateUtils.getDate(appointment.end);
        return dayjs(result ?? new Date());
    }

    function getMinEndTime(): dayjs.Dayjs | undefined {
        const startDate = DateUtils.getDate(appointment.start);
        const endDate = DateUtils.getDate(appointment.end);

        if (startDate && endDate && DateUtils.isSameDay(startDate, endDate)) {
            return dayjs(startDate);
        }

        return undefined;
    }


    async function handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const names = name.split('.');

        if (names.length === 2 && currentUser) {
            const objName = names[0];
            const propertyName = names[1];

            const stateObject = objName === "patient" ? patient : appointment;

            if (name === "appointment.calendar") {
                const calendarId = value;
                stateObject[propertyName] = Calendar.getCalendarById(calendars, calendarId);

                // checks if the user of that calendar has a predefined room
                const room = await getPredefinedRoom(appointment);
                if (room) {
                    appointment.roomId = room.id;
                    appointment.roomName = room.name;
                    setAppointment(appointment);
                }

            } else if (name === "appointment.visitMotive") {
                const motiveId = value;
                const newMotive = visitMotives.find((motive) => motive.id === motiveId);

                if (newMotive) {
                    stateObject[propertyName] = newMotive;

                    // set the new duration from the new visit motive
                    const ap = stateObject as Appointment;
                    ap.end = moment(ap.start).add(newMotive.duration, "m").toDate();
                    setAppointment(ap);
                }

            } else if (name === "appointment.roomId") {
                const room = await RoomsService.getRoom(currentUser.clientId, currentUser.locationId, value);

                appointment.roomId = room ? room.id : "";
                appointment.roomName = room ? room.name : "";

            } else if (name === "appointment.deviceId") {
                const device = await DevicesService.getDevice(currentUser.clientId, currentUser.locationId, value);

                appointment.deviceId = device ? device.id : "";
                appointment.deviceName = device ? device.name : "";

            } else if (name === "patient.privateInsurance") {
                stateObject[propertyName] = value === "true";

                // make sure to update the private insurance property also on apppointment if changed
                const appointmentClone = appointment.clone()
                appointmentClone.patient.privateInsurance = (stateObject as Patient).privateInsurance;
                setAppointment(appointmentClone);


            } else if (name === "patient.title") {
                stateObject[propertyName] = value;

                // make sure to update the title property also on apppointment if changed
                const appointmentClone = appointment.clone()
                appointmentClone.patient.title = value;
                setAppointment(appointmentClone);

            } else {
                stateObject[propertyName] = value;
            }

            if (objName === "patient") {
                setPatient((stateObject as Patient).clone());

            } else if (objName === "appointment") {
                setAppointment((stateObject as Appointment).clone());
            }

        }
    }

    function handleRatingChange(newScore: PatientScore) {
        patient.score = newScore;
        setPatient(patient.clone());
    }

    function handleLastNameChange(event) {
        patient.lastName = event.target.value;
        setPatient(patient.clone());
    }

    function handleFirstNameChange(event) {
        patient.firstName = event.target.value;
        setPatient(patient.clone());
    }



    function onVisitMotiveChange(newVisitMotive: VisitMotive | null) {

        if (newVisitMotive) {
            const ap = appointment.clone();

            ap.visitMotive = newVisitMotive;

            // set the new duration from the new visit motive
            ap.end = moment(ap.start).add(newVisitMotive.duration, "m").toDate();
            setAppointment(ap);
        }
    }

    function handleBirthDateChange(newDate: dayjs.Dayjs | null) {
        if (newDate) {
            const bd = DateUtils.getDate(newDate.toDate());

            if (bd) {
                patient.birthDate = bd;
                setPatient(patient);
            }
        }
    }

    function getBirthDate(): dayjs.Dayjs | null {
        if (patient && patient.birthDate) {
            return dayjs(DateUtils.getDate(patient.birthDate));
        }

        return null;
    }

    function getCalendarName(calendar) {
        if (calendar) {
            let name = calendar.name;

            if (calendar.parent) {
                name = calendar.parent.name + " - " + name;
            }

            return name;
        }

        return "";
    }

    async function confirmAppointment() {
        const _appointment = appointment.clone();
        _appointment.status = "confirmed";

        AppointmentsService.confirmRecallAppointment(_appointment);

        setAppointment(_appointment);
    }

    async function unlockPatient() {
        if (currentUser) {
            await PatientsService.resetWrongLoginsCounter(patient, currentClient.id, currentUser?.locationId);
            patient.wrongLoginsCounter = 0;
            setPatient(patient.clone());
        }
    }

    async function deleteAppointment() {

        let question = "Wollen Sie diesen Termin wirklich löschen?";

        if (appointment.isPartOfChain()) {
            question = "Dieser Termin ist Teil einer Behandlungskette! " + question;
        }

        const realyDelete = window.confirm(question);

        if (realyDelete) {

            AppointmentsService.deleteAppointment(appointment, currentUser.id);
            handleCloseDialog(false);
        }
    }

    async function moveNDays(days: number) {
        const start = new Date(appointment.start);
        const end = new Date(appointment.end);

        appointment.patientStatus = PatientStatus.none;
        appointment.start.setDate(start.getDate() + days);
        appointment.end.setDate(end.getDate() + days);

        setAppointment(appointment);

        let message = `${appointment.getTitle()} für ${appointment.patient.lastName} von ${DateUtils.getDateTimeString(start)} auf ${DateUtils.getDateTimeString(appointment.start)} verschoben`;

        LoggingService.log(LogItemType.userAction, appointment.id, "", appointment.patient.id, appointment.calendar.userId, currentUser.id, message, appointment.clientId, appointment.locationId);

        setTimeout(() => handleSaveDialog(), 200);
    }

    function setPatientStatus(newStatus: PatientStatus) {
        const clonedAppointment = appointment.clone();
        if (clonedAppointment.patientStatus === newStatus) {
            clonedAppointment.patientStatus = PatientStatus.none; // because we do not have a "none" button
        } else {
            clonedAppointment.patientStatus = newStatus;
        }
        setAppointment(clonedAppointment);
    }

    function changePatient() {
        if (appointment === null || !appointment.id) {
            setPatient(new Patient());
        }
    }

    function generateAppointmentPrintPage() {

        const qrCode = document.getElementsByClassName("kt-appointment-qrcode-wrapper");

        if (qrCode && qrCode.length > 0) {
            const qrCodeHtmlString = qrCode[0].innerHTML;

            return "<html>" +
                "<head>" +
                "  <script>" +
                "    function printAndCloseDelayed(){" +
                "      setTimeout('printAndClose()', 10);" +
                "    }" +
                "    function printAndClose(){" +
                "      window.print();" +
                "      window.close()" +
                "    }" +
                "  </script>" +
                "</head>" +
                "<body onload='printAndCloseDelayed()' style='text-align:center; font-family: Arial; font-size: 14px'>" +
                `  <p>${appointment.patient.getGenderAndFullName()},<br/>` +
                "  Ihr Termin:</p>" +
                qrCodeHtmlString +
                `<p>${DateUtils.getDateString(appointment.start)} um ${DateUtils.getTimeString(appointment.start)}</p>` +
                `<p>${currentClient.name}</p>` +
                "</body>" +
                "</html>";

        }

        return "";
    }


    function printAppointment() {
        const pwa = window.open("about:blank", "_new");
        if (pwa) {
            pwa.document.open();
            pwa.document.write(generateAppointmentPrintPage());
            pwa.document.close();
        }
    }


    // handle deleting patient appointments on the left list
    async function handleDeletePatientAppointmentClick(_appointment: Appointment): Promise<void> {

        if (appointment.id === _appointment.id) {
            if (window.confirm("Wollen Sie wirklich den in diesem Dialog geöffneten Termin löschen?")) {
                AppointmentsService.deleteAppointment(_appointment, currentUser.id);
                handleCloseDialog();
            }

        } else if (_appointment.recallId === appointment.id) {
            if (window.confirm("Wollen Sie diesen Termin wirklich löschen? Dadurch wird auch der in diesem Dialog geöffneten Recall-Termin gelöscht?")) {
                AppointmentsService.deleteAppointment(_appointment, currentUser.id);
                handleCloseDialog();
            }

        } else if (_appointment.successorId === appointment.id) {
            if (window.confirm("Wollen Sie diesen Termin wirklich löschen? Dadurch wird auch der in diesem Dialog geöffneten Folgetermin gelöscht?")) {
                AppointmentsService.deleteAppointment(_appointment, currentUser.id);
                handleCloseDialog();
            }

        } else if (window.confirm("Wollen Sie diesen Termin wirklich löschen?")) {
            await AppointmentsService.deleteAppointment(_appointment, currentUser.id);
            updatePatientAppointments();
        }

    }

    async function handleSelectedDocumentsChanged() {
        if(appointment.autoSelectDocuments) {
            
            const ap = appointment.clone();
            ap.autoSelectDocuments = false;
            setAppointment(ap);

            await AppointmentsService.updateAppointmentProperty(appointment.id, currentClient.id, appointment.locationId, "autoSelectDocuments", false);
        }
    }

    function renderMainTab() {

        if (!currentUser) return "";

        return (
            <React.Fragment>
                <div className="kt-dialog-left-panel kt-hide-mobile">
                    {((appointment.id || patientAppointments.length > 0) && appointment.calendarItemType !== "absence") && <div>
                        <div>Anstehende Termine</div>
                        {patientAppointments.length > 0 ?
                            <List>
                                {patientAppointments.map((patientAppointment) =>
                                    <ListItem key={patientAppointment.id} className="kt-content-rectangle" style={{ borderLeftColor: patientAppointment.color }}>
                                        <ListItemText
                                            primary={patientAppointment.getTitle()}
                                            secondary={`${DateUtils.getDateTimeString(patientAppointment.start)}`}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={() => handleDeletePatientAppointmentClick(patientAppointment)}
                                                style={{ color: "white" }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )}
                            </List>
                            :
                            <List>
                                <ListItem>
                                    <ListItemText primary="Keine" />
                                </ListItem>
                            </List>
                        }
                    </div>

                    }
                </div>

                <div className="kt-dialog-inner-body">
                    <div className="kt-tab-page">

                        <input id="kt-appointment-dialog-patient-id" type="text" hidden value={patient.importId} readOnly></input>
                        <input id="kt-appointment-dialog-patient-lastName" type="text" hidden value={patient.lastName} readOnly></input>
                        <input id="kt-appointment-dialog-patient-firstName" type="text" hidden value={patient.firstName} readOnly></input>

                        {(!appointment.id) &&
                            <Box className="kt-form-section kt-border-primary-color kt-margin-bottom-10">
                                <Grid container>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl component="fieldset">
                                            <FormLabel id="calendarItemType-label" className='kt-primary-color'>Terminart:</FormLabel>
                                            <RadioGroup row aria-label="position" name="position" defaultValue="left" onChange={handleInputChange}>
                                                <FormControlLabel
                                                    id="appointmentDialog-type-appointment"
                                                    value="appointment"
                                                    control={<Radio color="default" />}
                                                    label="Termin"
                                                    name="appointment.calendarItemType"
                                                    checked={appointment.calendarItemType === "appointment"}
                                                />
                                                <FormControlLabel
                                                    value="absence"
                                                    control={<Radio color="default" />}
                                                    label="Abwesenheit"
                                                    name="appointment.calendarItemType"
                                                    checked={appointment.calendarItemType === "absence"}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                        }


                        {appointment.calendarItemType !== "absence" && <Box className="kt-form-section">
                            <Grid container>
                                <Grid item xs={12} sm={6}>
                                    <FormControl component="fieldset">
                                        <RadioGroup row aria-label="position" name="position" defaultValue="left" onChange={handleInputChange}>
                                            <FormControlLabel
                                                value="m"
                                                control={<Radio color="default" />}
                                                label="Herr"
                                                labelPlacement="start"
                                                name="patient.gender"
                                                checked={patient.gender === "m"}
                                            />
                                            <FormControlLabel
                                                value="f"
                                                control={<Radio color="default" />}
                                                label="Frau"
                                                labelPlacement="start"
                                                name="patient.gender"
                                                checked={patient.gender === "f"}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={patient.newPatient} onChange={handleInputChange} name="patient.newPatient" color="default" />}
                                        label="Neuer Patient"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} style={{ paddingTop: "25px" }}>
                                    <StarsInputCtrl
                                        rating={patient.score}
                                        onChange={handleRatingChange}
                                    />
                                </Grid>

                                <Grid item xs={6} sm={3}>
                                    <TextField
                                        fullWidth
                                        name="patient.title"
                                        label="Titel"
                                        value={patient.title}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <div style={{ marginTop: "15px", color: "gray" }}>{(patient.importSource && patient.importId) ? `${patient.importSource} ID: ${patient.importId}` : ""}</div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <span>Tags:</span>
                                    <IconButton aria-label="add" onClick={() => setIsSearchTagsDialogVisible(true)}>
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                    {patient.tags.map((tag, index) =>
                                        <Chip
                                            key={index}
                                            label={tag.toUpperCase()}
                                            size="small"
                                            onDelete={() => onDeleteSearchTag(tag)}
                                            style={{ marginRight: "2px" }}
                                        />
                                    )}

                                </Grid>
                            </Grid>


                            <Grid container>
                                <Grid item xs={12} sm={6}>
                                    <SearchInput
                                        id="appointmentDialog-lastName"
                                        clientId={currentUser.clientId}
                                        locationId={currentUser.locationId}
                                        searchField="lastName"
                                        firstName={patient.firstName}
                                        value={patient.lastName}
                                        placeholder="Nachname"
                                        autoComplete={`nope`}
                                        required
                                        disabled={patient.id !== ""}
                                        onChange={handleLastNameChange}
                                        onPatientChange={handlePatientChange}
                                        autofocus={!isMobileDevice()}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SearchInput
                                        clientId={currentUser.clientId}
                                        locationId={currentUser.locationId}
                                        searchField="firstName"
                                        lastName={patient.lastName}
                                        value={patient.firstName}
                                        placeholder="Vorname"
                                        autoComplete="new-firstName"
                                        required
                                        disabled={patient.id !== ""}
                                        onChange={handleFirstNameChange}
                                        onPatientChange={handlePatientChange}
                                        className="kt-border-left"
                                    />
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        name="patient.birthName"
                                        label="Geburtsname"
                                        value={patient.birthName}
                                        disabled={patient.id !== ""}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <DatePicker
                                        label="Geburtsdatum"
                                        value={getBirthDate()}
                                        onError={(newError) => setBirthDateError(newError)}
                                        format="DD.MM.YYYY"
                                        onChange={handleBirthDateChange}
                                        slotProps={{
                                            textField: {
                                              required: true,                                              
                                              helperText: errorBirthDateMessage,
                                              color: getBirthDate() ? "primary" : "error"
                                            },
                                          }}
                                    />
                                </Grid>
                            </Grid>


                            <Grid container>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        name="patient.mobilePhoneNumber"
                                        value={patient.mobilePhoneNumber}
                                        label="Handynummer"
                                        required
                                        onChange={handleInputChange}
                                        autoComplete="turnitoff"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <PhoneIphoneIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        name="patient.phoneNumber"
                                        value={patient.phoneNumber.toString()}
                                        label="Festnetzanschluss"
                                        className="kt-border-left"
                                        onChange={handleInputChange}
                                        autoComplete="turnitoff"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <PhoneIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>


                            <Grid container>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        type="email"
                                        name="patient.email"
                                        value={patient.email}
                                        label="E-Mail-Adresse"
                                        onChange={handleInputChange}
                                        autoComplete="turnitoff"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <AlternateEmailIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="privateInsurance-select-label">Versicherung</InputLabel>
                                        <Select
                                            name="patient.privateInsurance"
                                            labelId="privateInsurance-select-label"
                                            label="Versicherung"
                                            id="privateInsurance-select"
                                            value={patient.privateInsurance.toString()}
                                            onChange={handleInputChange}
                                        >
                                            <MenuItem value="false">Gesetzlich versichert</MenuItem>
                                            <MenuItem value="true">Privat versichert</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>


                            <Grid container>
                                <Grid item xs={12} sm={6}>
                                    {(!appointment.id && patient.id) &&
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={changePatient}
                                            startIcon={<DeleteIcon />}>Patienten wechseln</Button>
                                    }
                                </Grid>
                                <Grid item sm>

                                </Grid>
                            </Grid>

                        </Box>}

                        <Box className="kt-form-section" mt={1}>

                            <FormControl fullWidth>
                                <InputLabel id="calendar-select-label">Behandler</InputLabel>
                                <Select
                                    name="appointment.calendar"
                                    labelId="calendar-select-label"
                                    label="Behandler"
                                    id="calendar-select"
                                    value={appointment.calendar.id}
                                    onChange={handleInputChange}
                                    required
                                // IconComponent = {() => <Icon className="fa fa-user-md"/>}
                                >
                                    {calendars.filter(c => c.license !== "disabled").map(calendar => <MenuItem key={calendar.id} value={calendar.id}>{getCalendarName(calendar)}</MenuItem>)}
                                </Select>
                            </FormControl>

                            {appointment.calendarItemType !== "absence" && <Box mt={5}>
                                <VisitMotivePicker
                                    selectedVisitMotiveId={appointment.visitMotive.id}
                                    visitMotives={visitMotives}
                                    specialities={specialities}
                                    appointmentStart={appointment.start}
                                    appointmentEnd={appointment.end}
                                    showDuration={true}
                                    onVisitMotiveChange={onVisitMotiveChange}
                                    onDurationChange={handleAppointmentDurationChange}
                                />
                            </Box>}

                            <Grid container>
                                <Grid item xs={12} sm={9}>
                                    <DatePicker
                                        label="Datum"
                                        value={getAppointmentStartDate()}
                                        onChange={handleAppointmentStartDateChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TimePicker
                                        label="Uhrzeit"
                                        value={getAppointmentStartDate()}
                                        onChange={handleAppointmentStartTimeChange}
                                        viewRenderers={{
                                            hours: renderTimeViewClock,
                                            minutes: renderTimeViewClock,
                                            seconds: renderTimeViewClock,
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            {appointment.calendarItemType === "absence" && <Grid container>
                                <Grid item xs={12} sm={9}>
                                    <DatePicker
                                        label="End-Datum"
                                        value={getAppointmentEndDate()}
                                        onChange={handleAppointmentEndDateChange}
                                        onError={(newError) => setEndDateError(newError)}
                                        slotProps={{
                                            textField: {
                                                helperText: errorEndDateMessage
                                            }
                                        }}
                                        minDate={getAppointmentStartDate()}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TimePicker
                                        label="End-Uhrzeit"
                                        value={getAppointmentEndDate()}
                                        onChange={handleAppointmentEndTimeChange}
                                        onError={(newError) => setEndTimeError(newError)}
                                        slotProps={{
                                            textField: {
                                                helperText: errorEndTimeMessage
                                            }
                                        }}
                                        minTime={getMinEndTime()}
                                        viewRenderers={{
                                            hours: renderTimeViewClock,
                                            minutes: renderTimeViewClock,
                                            seconds: renderTimeViewClock,
                                        }}
                                    />
                                </Grid>
                            </Grid>}

                            {appointment.calendarItemType !== "absence" && <Grid container>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="room-select-label">Raum</InputLabel>
                                        <Select
                                            name="appointment.roomId"
                                            labelId="room-select-label"
                                            label="Raum"
                                            id="room-select"
                                            value={appointment.roomId}
                                            onChange={handleInputChange}
                                        >
                                            {rooms.map(room => <MenuItem key={room.id} value={room.id}>{room.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="device-select-label">Ressource</InputLabel>
                                        <Select
                                            name="appointment.deviceId"
                                            labelId="device-select-label"
                                            label="Ressource"
                                            id="device-select"
                                            value={appointment.deviceId}
                                            onChange={handleInputChange}
                                        >
                                            {devices.map(device => <MenuItem key={device.id} value={device.id}>{device.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>

                            </Grid>}

                        </Box>

                        <Box className="kt-form-section" mt={1}>

                            <FormControl fullWidth>
                                <InputLabel id="creator-select-label">Erstellt von</InputLabel>
                                <Select
                                    name="appointment.createdBy"
                                    labelId="creator-select-label"
                                    label="Erstellt von"
                                    id="creator-select"
                                    value={appointment.createdBy}
                                    onChange={handleInputChange}
                                    IconComponent={CreateIcon}
                                    required
                                    disabled={appointment.id !== null && appointment.id !== ""}
                                >
                                    <MenuItem value=""></MenuItem>
                                    {appointment.createdBy === "campaign" && <MenuItem value="campaign">Recaller-Kampagne</MenuItem>}
                                    {appointment.createdBy === "online" && <MenuItem value="online">Patient (online erstellt)</MenuItem>}
                                    {appointment.createdBy === "recaller" && <MenuItem value="recaller">Recaller</MenuItem>}
                                    {appointment.createdBy === "phoneCampaign" && <MenuItem value="phoneCampaign">Telefon-Kampagne</MenuItem>}
                                    {appointment.createdBy === "predecessor" && <MenuItem value="predecessor">Folgetermin</MenuItem>}
                                    {appointment.createdBy === "import" && <MenuItem value="import">Import</MenuItem>}
                                    {groupedUsers.map(group => {

                                        const children: any = [];

                                        children.push(<ListSubheader>{group.name.toUpperCase()}</ListSubheader>);
                                        group.users.forEach(user => {
                                            children.push(<MenuItem key={user.id} value={user.id}>{user.abbreviation !== "" ? user.abbreviation : user.lastName}</MenuItem>)
                                        })

                                        return children;
                                    }
                                    )}

                                </Select>
                            </FormControl>
                        </Box>

                        <Box className="kt-form-section" mt={1}>
                            <TextField
                                fullWidth
                                minRows={5}
                                name="appointment.comments"
                                value={appointment.comments}
                                onChange={handleInputChange}
                                label="Terminnotiz"
                                multiline
                            />
                        </Box>

                        <Box className="kt-form-section kt-show-mobile kt-action-buttons" mt={1}>
                            {appointment.calendarItemType !== "absence" && <button type="button" onClick={() => showPatientDialog(true)}><i className="far fa-address-card"></i> Patientenakte öffnen</button>}
                            <button type="button" className="kt-delete-button" onClick={deleteAppointment}><i className="far fa-trash"></i> Termin löschen</button>
                            {patient.wrongLoginsCounter >= 3 && <button type="button" className="kt-delete-button" onClick={unlockPatient}><i className="far fa-unlock"></i> Login entsperren</button>}
                        </Box>

                    </div>
                </div>

                {appointment.id && <div className="kt-dialog-right-panel kt-hide-mobile kt-action-buttons">

                    {appointment.calendarItemType !== "absence" ? <React.Fragment>
                        <div className="kt-label">STATUS</div>

                        {appointment.status === "confirmed" &&
                            <React.Fragment>
                                <button type="button" onClick={() => setPatientStatus(PatientStatus.inWaitingRoom)} className={appointment.patientStatus === PatientStatus.inWaitingRoom ? "kt-button-selected" : ""}><i className="far fa-hourglass"></i> Im Wartezimmer</button>
                                <button type="button" onClick={() => setPatientStatus(PatientStatus.treated)} className={appointment.patientStatus === PatientStatus.treated ? "kt-button-selected" : ""}><i className="far fa-stethoscope"></i> Behandelt</button>

                                <div className="kt-row-spacer"></div>
                            </React.Fragment>
                        }

                        {appointment.status === "needsConfirmation" &&
                            <div><p className="kt-red-color">Dieser Terminvorschlag wurde noch nicht vom Patienten bestätigt.</p></div>
                        }

                        {appointment.status === "declined" &&
                            <div><p className="kt-red-color">Dieser Termin wurde vom Patienten abgesagt.</p></div>
                        }

                        <div className="kt-label">AKTIONEN</div>

                        {appointment.status === "needsConfirmation" &&
                            <React.Fragment>
                                <button type="button" onClick={confirmAppointment}><i className="far fa-user-check"></i> Termin bestätigen</button>

                                <div className="kt-row-spacer"></div>
                            </React.Fragment>
                        }

                        {/* <button type="button"><i className="far fa-calendar"></i> Termin verschieben</button> */}
                        <button type="button" onClick={() => moveNDays(1)}><i className="far fa-calendar-day"></i> 1 Tag verschieben</button>
                        <button type="button" onClick={() => moveNDays(7)}><i className="far fa-calendar-week"></i> 1 Woche verschieben</button>

                        <div className="kt-row-spacer"></div>

                        <button type="button" onClick={() => showPatientDialog(true)}><i className="far fa-address-card"></i> Patientenakte öffnen</button>

                        <button type="button" onClick={() => printAppointment()}><i className="far fa-print"></i> Termin drucken</button>
                        <div className="kt-appointment-qrcode-wrapper">
                            <QRCode
                                value={`https://pickadoc.de/appointment/${appointment.clientId}/${appointment.locationId}/${appointment.id}`}
                                size={120}
                            >
                            </QRCode>
                        </div>

                        {/* <button type="button" id="kt-open-patient-in-pms"><i className="far fa-external-link"></i> Dampsoft öffnen</button> */}

                        {/* <div className="kt-row-spacer"></div>

                            <button type="button" onClick={openDocumentsManager}><i className="far fa-file-alt"></i> DOCUMENTA</button> */}

                        <div className="kt-row-spacer"></div>

                        <button type="button" className="kt-delete-button" onClick={deleteAppointment}><i className="far fa-trash"></i> Termin löschen</button>

                        {patient.wrongLoginsCounter >= 3 && <button type="button" className="kt-delete-button" onClick={unlockPatient}><i className="far fa-unlock"></i> Login entsperren</button>}

                        <div className="kt-row-spacer"></div>

                        {appointment.createdAt && <div className="kt-info-text">Erstellt: {DateUtils.getDateString(appointment.createdAt)} {DateUtils.getTimeString(appointment.createdAt)}</div>}

                    </React.Fragment>
                        :
                        <React.Fragment>
                            <button type="button" className="kt-delete-button" onClick={deleteAppointment}><i className="far fa-trash"></i> Abwesenheit löschen</button>
                        </React.Fragment>
                    }

                    {appointment.isVideoCall && <React.Fragment>
                        <div className="kt-row-spacer"></div>

                        <Link to={`/videocall/${appointment.id}/${appointment.calendar.userId}`} target="_blank" className="kt-button kt-primary"><i className="far fa-video"></i> Videosprechstunde starten</Link>
                    </React.Fragment>}

                </div>}
            </React.Fragment>
        );
    }



    function renderDocumentsTab() {

        return (
            <DocumentsTab
                appointment={appointment}
                patient={patient}
                onSelectedDocumentsChanged={handleSelectedDocumentsChanged}
                onClose={handleCloseDialog}
            />
        );
    }

    function getTabs(): string[] {

        if (appointment.id && appointment.calendarItemType !== "absence") {
            return isDocumentsFeatureEnabled ? ["appointment.main", "appointment.documents"] : ["appointment.main"];
        }
        return [];
    }

    function getDefaultButton(): DialogDefaultButton {
        if (appointment.calendarItemType === "absence" || isDocumentsFeatureEnabled === false) {
            return DialogDefaultButton.save;
        } else {
            return selectedTab === "appointment.main" ? DialogDefaultButton.next : DialogDefaultButton.close;
        }
    }

    if (appointment === null || !currentUser || !rooms || !devices) {
        return null;
    }

    return (
        <React.Fragment>

            <Dialog
                visible={props.visible}
                fullWidth={false}
                title={getDialogTitle()}
                titleIcon={appointment.isVideoCall ? "fa fa-video" : ""}
                alertTitle={patient.wrongLoginsCounter >= 3 ? "- LOGIN GESPERRT!" : ""}
                onClose={handleCloseDialog}
                onSave={handleSaveDialog}
                onNext={handleNextButton}
                tabs={getTabs()}
                newTabSelection={selectedTab}
                onTabChange={handleTabChange}
                defaultButton={getDefaultButton()}
            >

                {selectedTab === "appointment.main" ? renderMainTab() : renderDocumentsTab()}

            </Dialog>

            <PatientDialog
                visible={isPatientDialogVisible}
                patient={patient}
                onClose={() => showPatientDialog(false)}
                onSave={() => handlePatientDialogSave()}
            />

            <SearchTagsDialog
                visible={isSearchTagsDialogVisible}
                onClose={(newSelectedTag) => onCloseSearchTagsDialog(newSelectedTag)}
            />

        </React.Fragment>
    );
}

export default AppointmentDialog;