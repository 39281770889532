import FormItem, { FormItemEnum } from "./formItem";
import FormRow from "./formRow";


export class AnswerFormItem extends FormItem {

    checked: boolean = false;

    required: boolean = false;

    formRows: FormRow[] = [];

    constructor(parentId: string) {
        super(parentId);
        this.type = FormItemEnum.checkboxAnswer;
        this.setLanguageProperty("de", "Antwort", "labels");
    }


    clone(): AnswerFormItem {

        const c = new AnswerFormItem(this.parentId);
        c.fromObject(null, this.toJSON(true));

        return c;
    }

    toJSON(includeUserInputs: boolean): object {
        const obj: any = super.toJSON(includeUserInputs);

        if(includeUserInputs){
            obj.checked = this.checked;
        }

        obj.required = this.required;

        obj.formRows = [];

        for (let i = 0; i < this.formRows.length; i++) {
            obj.formRows.push(this.formRows[i].toJSON(includeUserInputs));
        }

        return obj;
    }

    fromObject(id: string | null, o: any) {
        super.fromObject(id, o);

        this.checked = o.checked === true;

        this.required = o.required === true;

        this.formRows = [];

        if (o.formRows) {
            for (let i = 0; i < o.formRows.length; i++) {
                const oFormRow = o.formRows[i];

                const newFormRow = new FormRow();
                newFormRow.fromObject(oFormRow);

                this.formRows.push(newFormRow);
            }
        }
    }

    addRow() {
        const newRow = new FormRow();
        this.formRows.push(newRow);
    }

}


export default AnswerFormItem;