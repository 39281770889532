import DateUtils from "../utils/dateUtils";

export enum LogItemType {
    none,
    error,
    verbose,
    information,
    patientAction,                  // example: patient clicks on a button
    userAction,                     // example: calendar user clicks on a button
    recallLandingPageLoaded,        // example: patient clicks on the link in the recall sms and then the recall landing page opens in a browser
    successorLandingPageLoaded,
    appointmentLandingPageLoaded,
    onlineAppointmentBooked,
    onlineAppointmentDeclined,
    patientRegistered,
    patientLogin,
    doctorProfileLoaded,
    documentSigned,
    patientDeletedAppointment,
    doctorHasBeenRated,
    onlineAppointmentPostponed,
    onlineRecallAppointmentBooked,
    campaignLandingPageLoaded,
    emailHasBeenSent,
    selfCheckinPatient,
}


export class LogItem {

    id: string;

    appointmentId: string;
    documentId: string;

    patientId: string;
    doctorId: string;
    userId: string;

    type: LogItemType = LogItemType.none;

    message: string;

    createdAt: Date;

    constructor(id: string, appointmentId: string, documentId: string, patientId: string, doctorId: string, userId: string | undefined, type: LogItemType, message: string) {
        this.id = id;
        this.appointmentId = appointmentId;
        this.documentId = documentId;

        this.patientId = patientId;
        this.doctorId = doctorId;
        this.userId = userId ?? "";

        this.type = type;

        this.message = message;

        this.createdAt = new Date();
    }

    clone(): LogItem {

        const c = new LogItem(this.id, this.appointmentId, this.documentId, this.patientId, this.doctorId, this.userId, this.type, this.message);
        c.fromObject(this.toJSON(true));

        return c;
    }

    toJSON(useDate: boolean): object {

        const obj: any = {};

        if (this.id) {
            obj.id = this.id;
        }

        if (this.appointmentId) {
            obj.appointmentId = this.appointmentId;
        }

        if (this.documentId) {
            obj.documentId = this.documentId;
        }

        if (this.patientId) {
            obj.patientId = this.patientId;
        }

        if (this.doctorId) {
            obj.doctorId = this.doctorId;
        }

        if (this.userId) {
            obj.userId = this.userId;
        }

        if (this.type !== LogItemType.none) {
            obj.type = this.type;
        }

        if (this.message) {
            obj.message = this.message;
        }

        if (this.createdAt) {
            if (useDate) {
                obj.createdAt = this.createdAt;
            } else {
                obj.createdAt = this.createdAt.toJSON();
            }
        }

        return obj;
    }

    fromObject(o: any) {

        if (o.id) {
            this.id = o.id;
        }

        this.appointmentId = o.appointmentId ?? "";
        this.documentId = o.documentId ?? "";

        this.patientId = o.patientId ?? "";
        this.doctorId = o.doctorId ?? "";
        this.userId = o.userId ?? "";

        this.type = o.type ?? LogItemType.none;

        this.message = o.message ?? "";

        this.createdAt = DateUtils.getDate(o.createdAt) ?? new Date();
    }

    getTitle(): string {

        switch (this.type) {
            case LogItemType.error:
                return "Fehler";

            case LogItemType.information:
                return "Information";

            case LogItemType.verbose:
                return "Ausführliche-Information";

            case LogItemType.patientAction:
                return "Patienten Aktion";

            case LogItemType.userAction:
                return "Benutzer Aktion";

            case LogItemType.recallLandingPageLoaded:
                return "Recall-LandingPage geladen";

            case LogItemType.successorLandingPageLoaded:
                return "Folgetermin-LandingPage geladen";

            case LogItemType.documentSigned:
                return "Dokument ausgefüllt";

            case LogItemType.doctorHasBeenRated:
                return "Bewertung erhalten";

            case LogItemType.campaignLandingPageLoaded:
                return "Kampagnen-LandingPage geladen";

            default:
                return "";
        }
    }

    isBotType(): boolean {
        switch (this.type) {

            case LogItemType.userAction:
                return true;

            default:
                return false;
        }
    }

    toString(): string {

        const dateString = `${DateUtils.getDateString(this.createdAt)} ${DateUtils.getTimeString(this.createdAt)}`;

        return `${dateString} - ${this.toStringShort()}`;

    }

    toStringShort(): string {

        return this.message ? this.message : this.getTitle();

    }

    getColor(): string {
        switch (this.type) {
            case LogItemType.patientDeletedAppointment:
                return "red";

            case LogItemType.appointmentLandingPageLoaded:
            case LogItemType.recallLandingPageLoaded:
            case LogItemType.successorLandingPageLoaded:
            case LogItemType.campaignLandingPageLoaded:
                return "#399150";

            case LogItemType.onlineAppointmentBooked:
            case LogItemType.onlineAppointmentPostponed:
            case LogItemType.onlineRecallAppointmentBooked:
                return "#71cd2c"

            case LogItemType.documentSigned:
                return "#d862c8"

            case LogItemType.doctorHasBeenRated:
                return "orange"

            case LogItemType.emailHasBeenSent:
                return "#03dbfc";

            case LogItemType.selfCheckinPatient:
                return "#FF7F50";

            default:
                return ""
        }
    }

    getCssIconString(): string {

        switch (this.type) {
            case LogItemType.patientDeletedAppointment:
                return "fa fa-trash";

            case LogItemType.onlineAppointmentBooked:
            case LogItemType.onlineAppointmentPostponed:
            case LogItemType.onlineRecallAppointmentBooked:
                return "fa fa-calendar-check";

            case LogItemType.documentSigned:
                return "fal fa-file-signature";

            case LogItemType.appointmentLandingPageLoaded:
            case LogItemType.doctorProfileLoaded:
            case LogItemType.recallLandingPageLoaded:
            case LogItemType.successorLandingPageLoaded:
            case LogItemType.campaignLandingPageLoaded:
                return "far fa-eye";

            case LogItemType.doctorHasBeenRated:
                return "far fa-star";

            case LogItemType.emailHasBeenSent:
                return "far fa-envelope";

            case LogItemType.selfCheckinPatient:
                return "far fa-user";

            default:
                return "fal fa-info-circle";
        }
    }

}


export default LogItem;