
class ClientSettings {
    numberOfLicenses: number = 0;
    customPricePerLicense: number = 80;
    hasCustomPricePerLicense: boolean = false;

    clone(): ClientSettings {
        const c = new ClientSettings();

        c.numberOfLicenses = this.numberOfLicenses;
        c.customPricePerLicense = this.customPricePerLicense;
        c.hasCustomPricePerLicense = this.hasCustomPricePerLicense === true;

        return c;
    }

    toJSON(): object {
        return {
            numberOfLicenses: this.numberOfLicenses,
            customPricePerLicense: this.customPricePerLicense,
            hasCustomPricePerLicense: this.hasCustomPricePerLicense === true
        };
    }

    fromObject(o: any){
        this.numberOfLicenses = o.numberOfLicenses ? o.numberOfLicenses : 0;
        this.customPricePerLicense = o.customPricePerLicense ? o.customPricePerLicense : 80;
        this.hasCustomPricePerLicense = o.hasCustomPricePerLicense === true;
    }
};

export default ClientSettings;