
import React from "react";
import Utils from "../../../src/shared/src/utils/utils";
import FormItem, { FormItemEnum, FormItemType } from "../../../src/shared/src/models/formEditor/formItem";
import FormRow from "../../../src/shared/src/models/formEditor/formRow";
import AnswerFormItemCtrl from "./answerFormItemCtrl";
import CheckboxFormItemCtrl from "./checkboxFormItemCtrl";
import HeadlineFormItemCtrl from "./headlineFormItemCtrl";
import ImageFormItemCtrl from "./imageFormItemCtrl";
import InputTextFormItemCtrl from "./inputTextFormItemCtrl";
import LineFormItemCtrl from "./lineFormItemCtrl";
import LogoFormItemCtrl from "./logoFormItemCtrl";
import ParagraphFormItemCtrl from "./paragraphFormItemCtrl";
import PlaceholderFormItemCtrl from "./placeholderFormItemCtrl";
import RadioFormItemCtrl from "./radioFormItemCtrl";
import SignatureFormItemCtrl from "./signatureFormItemCtrl";
import DropdownFormItemCtrl from "./dropdownFormItemCtrl";
import VideoFormItemCtrl from "./videoFormItemCtrl";


function handleOnClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>, formItem: FormItemType, setSelectedItem) {

    e.bubbles = false;
    e.stopPropagation();

    setSelectedItem(formItem);
}

let mouseDownTarget: any = null;
function handleOnMouseDown(e: any) {
    mouseDownTarget = e.target;
}

function handleOnMouseUp(e: any) {
    mouseDownTarget = null;
}

function handleOnDragStartItem(e: React.DragEvent<HTMLDivElement>, formItem: FormItem, setSelectedItem) {
    e.stopPropagation();

    if (mouseDownTarget && (mouseDownTarget.classList.contains("kt-item-gripper") || (mouseDownTarget.parentNode && mouseDownTarget.parentNode.classList.contains("kt-item-gripper")))){

        const element = document.getElementsByClassName("kt-form-rows")[0];
        if (element) {
            element.classList.add("kt-is-dragging-item");
        }

        const id = `item:${formItem.parentId}---${formItem.id}`;
        e.dataTransfer.setData("text/plain", id);

        setSelectedItem(formItem);

    } else {
        e.preventDefault();
        return false;
    }

}

function handleOnDragEndItem(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();

    const element = document.getElementsByClassName("kt-form-rows")[0];
    if (element) {
        element.classList.remove("kt-is-dragging-item");
    }

    mouseDownTarget = null;
}

function handleOnDragEnter(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    // e.stopPropagation();

    const target = e.target as HTMLDivElement;
    target.classList.add("kt-on-drag-enter");
}

function handleOnDragLeave(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();

    const target = e.target as HTMLDivElement;

    target.classList.remove("kt-on-drag-enter");
}

function handleOnDragOver(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    // e.stopPropagation();

    e.dataTransfer.dropEffect = "move";
}



function handleOnDrop(e: React.DragEvent<HTMLDivElement>, targetItem: FormItem | null, targetRow: FormRow, formRows: FormRow[], setFormRows, offset) {
    e.preventDefault();
    e.stopPropagation();

    const target = e.target as HTMLDivElement;
    target.classList.remove("kt-on-drag-enter");

    const element = document.getElementsByClassName("kt-form-rows")[0];
    if (element) {
        element.classList.remove("kt-is-dragging-item");
    }

    const data = e.dataTransfer.getData("text/plain");

    if (!data) return;

    let targetIndex = targetRow.columns.findIndex(column => column.id === targetItem?.id);

    // data should be id:rowId---itemId
    if (data.indexOf("item:") === 0) {
        const sourceIdsString = data.replace("item:", "");
        const sourceIds = sourceIdsString.split("---");

        if (sourceIds.length === 2) {
            const sourceRowId = sourceIds[0];
            const sourceColumnId = sourceIds[1];

            const sourceRow = FormRow.getFormRowById(sourceRowId, formRows);
            const sourceColumn = getColumnById(sourceColumnId, formRows);

            if (!sourceRow) return;

            const sourceIndex = sourceRow.columns.findIndex(column => column.id === sourceColumnId);

            if (sourceIndex > -1 && sourceRow && sourceColumn) {

                // target is an empty row
                if (targetIndex === -1) {

                    let removed: FormItemType[] = [];

                    // remove item from source row
                    removed = sourceRow.columns.splice(sourceIndex, 1);

                    // and push it to the target row
                    if (removed.length === 1) {

                        // set new parent id
                        removed[0].parentId = targetRow.id;
                        targetRow.columns.push(removed[0]);
                    }

                    const sRow = FormRow.getFormRowById(sourceRow.id, formRows);
                    const tRow = FormRow.getFormRowById(targetRow.id, formRows);

                    if (sRow && tRow) {
                        sRow.columns = sourceRow.columns;

                        tRow.columns = targetRow.columns;
                    }

                    setFormRows([...formRows]);


                } else if (sourceRowId === targetRow.id && targetIndex > -1) {
                    // same row, just reorder columns

                    // if same position do nothing
                    if (sourceIndex === targetIndex) return;

                    // drag from left to right
                    if (sourceIndex < targetIndex) {
                        targetIndex -= (1 - offset);
                    } else {
                        // drag from bottom to top
                        targetIndex += (offset);
                    }

                    sourceRow.columns = Utils.reorder(targetRow.columns, sourceIndex, targetIndex);

                    setFormRows([...formRows]);

                } else {
                    // move column to a different row

                    // first check if our target is a child of our source, which is not allowed
                    if (isChildRowOfItem(targetRow, sourceColumn)) return;

                    const moveResult = Utils.move<FormItemType>(sourceRow.columns, targetRow.columns, sourceIndex, targetIndex + offset);

                    sourceColumn.parentId = targetRow.id;

                    const sRow = FormRow.getFormRowById(sourceRow.id, formRows);
                    const tRow = FormRow.getFormRowById(targetRow.id, formRows);

                    if (sRow && tRow) {
                        sRow.columns = moveResult.sourceList;

                        tRow.columns = moveResult.destinationList;
                    }

                    setFormRows([...formRows]);
                }

            }
        }

    }

    mouseDownTarget = null;
}



function isChildRowOfItem(childRow: FormRow, startItem: FormItemType): boolean {

    if (childRow && startItem && startItem.answers) {
        for (let a = 0; a < startItem.answers.length; a++) {
            const answer = startItem.answers[a];

            if (answer.formRows) {
                for (let r = 0; r < answer.formRows.length; r++) {
                    const row = answer.formRows[r];

                    if (childRow.id === row.id) return true;

                    if (childRow.columns) {
                        for (let c = 0; c < childRow.columns.length; c++) {
                            const column = childRow.columns[c];

                            if (column.formRows) {
                                for (let sr = 0; sr < column.formRows.length; sr++) {
                                    const subRow = column.formRows[sr];

                                    const isChild = isChildRowOfItem(subRow, startItem);
                                    if (isChild) return true;
                                }
                            }
                        }
                    }
                }
            }

        }
    }

    return false;

}

function getColumnById(id: string, formRows: FormRow[]): FormItemType | null {

    for (let r = 0; r < formRows.length; r++) {
        const row = formRows[r];
        for (let c = 0; c < row.columns.length; c++) {
            const column = row.columns[c];
            if (column.id === id) {
                return column;
            }

            if (column.answers) {
                for (let a = 0; a < column.answers.length; a++) {
                    const answer = column.answers[a];

                    const subColumn = getColumnById(id, answer.formRows);

                    if (subColumn) return subColumn;
                }
            }
        }

    }

    return null;
}





export interface Props {
    patientId?: string,
    documentId: string,
    row: FormRow
    languageKey: string
    handleOnInputChange: any
    handleOnSignatureDialogStateChange?: any
    formRows: FormRow[]
    setFormRows: any
    onFormChange: any
    selectedItem: FormItemType | null
    setSelectedItem: any
    renderMode: "editor" | "properties" | "final"
    formUser: "patient" | "doctor"
}

const FormRowCtrl: React.FC<Props> = (
    {
        patientId,
        documentId,
        row,
        languageKey,
        handleOnInputChange,
        handleOnSignatureDialogStateChange,
        formRows,
        setFormRows,
        onFormChange,
        selectedItem,
        setSelectedItem,
        renderMode,
        formUser
    }) => {

    function deleteColumn(column: FormItemType) {

        setSelectedItem(null);

        row.columns = Utils.removeItemFromArray<FormItemType>(column, row.columns);

        handleOnInputChange(row.columns, [...row.columns], "columns");
    }

    function renderFormItemCtrl(formItem: FormItemType, renderMode: "editor" | "properties" | "final"): JSX.Element {

        const props = {
            patientId,
            documentId,
            item: formItem as any,
            languageKey,
            handleOnInputChange,
            handleOnSignatureDialogStateChange,
            formRows,
            setFormRows,
            onFormChange,
            selectedItem,
            setSelectedItem,
            renderMode,
            formUser
        };

        switch (formItem.type) {
            case FormItemEnum.radioAnswer:
            case FormItemEnum.checkboxAnswer:
                return <AnswerFormItemCtrl required={false} {...props}></AnswerFormItemCtrl>

            case FormItemEnum.checkbox:
                return <CheckboxFormItemCtrl {...props}></CheckboxFormItemCtrl>

            case FormItemEnum.radio:
                return <RadioFormItemCtrl {...props}></RadioFormItemCtrl>

            case FormItemEnum.headline:
                return <HeadlineFormItemCtrl {...props}></HeadlineFormItemCtrl>

            case FormItemEnum.image:
                return <ImageFormItemCtrl {...props}></ImageFormItemCtrl>

            case FormItemEnum.inputText:
                return <InputTextFormItemCtrl {...props}></InputTextFormItemCtrl>

            case FormItemEnum.line:
                return <LineFormItemCtrl {...props}></LineFormItemCtrl>

            case FormItemEnum.logo:
                return <LogoFormItemCtrl {...props}></LogoFormItemCtrl>

            case FormItemEnum.paragraph:
                return <ParagraphFormItemCtrl {...props}></ParagraphFormItemCtrl>

            case FormItemEnum.placeholder:
                return <PlaceholderFormItemCtrl {...props}></PlaceholderFormItemCtrl>

            case FormItemEnum.signature:
                return <SignatureFormItemCtrl {...props}></SignatureFormItemCtrl>

            case FormItemEnum.dropdown:
                return <DropdownFormItemCtrl {...props}></DropdownFormItemCtrl>

            case FormItemEnum.video:
                return <VideoFormItemCtrl {...props}></VideoFormItemCtrl>

            default:
                return <React.Fragment></React.Fragment>
        }
    }

    function renderHtml(): JSX.Element {

        return (

            <div className="kt-form-items">

                {row.columns.length === 0 &&
                    <div
                        className="kt-form-item kt-dummy-column"
                        key="dummycolumn"
                    >
                        <div
                            className="kt-drop-area kt-drop-area-left"
                            onDragEnter={(e) => handleOnDragEnter(e)}
                            onDragLeave={(e) => handleOnDragLeave(e)}
                            onDragOver={(e) => handleOnDragOver(e)}
                            onDrop={(e) => handleOnDrop(e, null, row, formRows, setFormRows, 1)}
                        ></div>
                    </div>
                }
                {row.columns.map((formItem, index) => (
                    <div
                        className={`${selectedItem?.id === formItem.id ? "kt-form-item kt-form-item-selected" : "kt-form-item"}`}
                        key={formItem.id}
                        onClick={(e) => handleOnClick(e, formItem, setSelectedItem)}
                        onDragStart={(e) => handleOnDragStartItem(e, formItem, setSelectedItem)}
                        onDragEnd={(e) => handleOnDragEndItem(e)}
                        draggable={true}
                    >
                        <div
                            className="kt-drop-area kt-drop-area-left"
                            onDragEnter={(e) => handleOnDragEnter(e)}
                            onDragLeave={(e) => handleOnDragLeave(e)}
                            onDragOver={(e) => handleOnDragOver(e)}
                            onDrop={(e) => handleOnDrop(e, formItem, row, formRows, setFormRows, 0)}
                        ></div>

                        <div className="kt-item-gripper"
                            onMouseDown={(e) => handleOnMouseDown(e)}
                            onMouseUp={(e) => handleOnMouseUp(e)}
                        >
                            <i className={`far ${FormItem.getIconByType(formItem.type)}`}></i>
                        </div>

                        <div className="kt-form-item-content">
                            {renderFormItemCtrl(formItem, "editor")}
                        </div>

                        <div className="kt-item-edit-buttons">
                            <div onClick={() => deleteColumn(formItem)}>
                                <i className='far fa-trash' />
                            </div>
                        </div>

                        <div
                            className="kt-drop-area kt-drop-area-right"
                            onDragEnter={(e) => handleOnDragEnter(e)}
                            onDragLeave={(e) => handleOnDragLeave(e)}
                            onDragOver={(e) => handleOnDragOver(e)}
                            onDrop={(e) => handleOnDrop(e, formItem, row, formRows, setFormRows, 1)}
                        ></div>

                    </div>
                ))}

            </div>

        );


    }

    function renderProperties(): JSX.Element {
        return (
            <div>

            </div>
        );
    }


    // renders the final form where you can enter data
    function renderFinalHtml(): JSX.Element {

        return (
            <div className="kt-form-items">
                {row.columns.map((formItem, index) => (

                    <div
                        className={`kt-form-item`}
                        key={formItem.id}
                    >
                        <div className="kt-form-item-content">
                            {renderFormItemCtrl(formItem, "final")}
                        </div>

                    </div>

                ))}
            </div>
        );

    }


    switch (renderMode) {
        case "editor":
            return renderHtml();

        case "properties":
            return renderProperties();

        case "final":
            return renderFinalHtml();

        default:
            return <React.Fragment></React.Fragment>;
    }


}


export default FormRowCtrl;