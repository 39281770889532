import firebase from 'firebase/compat/app';

const functions = firebase.app().functions('europe-west3');

const StorageService = {

    async moveFile(sourceFilePath: string, destinationFilePath: string): Promise<string> {
        const moveFileFunc = functions.httpsCallable("moveFile");
        const result = await moveFileFunc(
            {
                sourceFilePath: sourceFilePath,
                destinationFilePath: destinationFilePath
            });

        return result.data;
    }

}

export default StorageService;