import React, { useState, useRef, useEffect } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import EditIcon from "@mui/icons-material/Edit";
import Avatar from './avatar';
import AvatarEditor from 'react-avatar-editor'
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';

interface Props {
    src: string
    alt?: string;
    width: number;
    onValueChange: (base64Image: string) => void
}

const ImageInputCtrl:React.FC<Props> = ({src, alt, width, onValueChange}) => {

    const editorRef = useRef();

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const [scale, setScale] = useState(1);
    const [rotation, setRotation] = useState(0);

    const [imageSrc, setImageSrc] = useState(src);

    const [crossOrigin, setCrossOrigin] = useState<"" | "*" | "anonymous" | "use-credentials">("");

    useEffect(()=>{
        if(isDialogOpen){
            setScale(1);
            setRotation(0);
            setImageSrc("");
        }
    }, [isDialogOpen]);

    function onEditButtonClick() {
        setIsDialogOpen(true);
    }

    function handleClose(){
        setIsDialogOpen(false);
    }

    function handleApply(){

        if(imageSrc === ""){
            onValueChange("");
        } else if(editorRef != undefined && editorRef.current != undefined){
            const canvasImage = (editorRef!.current! as any).getImage();

            onValueChange(canvasImage.toDataURL());
        }

        setIsDialogOpen(false);
    }


    function handleScaleChange(event, newValue: number | number[]){
        const _newValue = newValue as number;

        setScale(_newValue);
    }

    function handleRotationChange(event, newValue: number | number[]){
        const _newValue = newValue as number;

        setRotation(_newValue);
    }

    function onFileChange(event) {

        if(event && event.target && event.target.files.length > 0) {

            let reader = new FileReader();
            reader.onload = (e) => {
                if(e && e.target && e.target && e.target.result){
                    setImageSrc(e.target.result as string);
                }
            };
            reader.readAsDataURL(event.target.files[0]);
        }

    }

    return (
        <div className="kt-image-input-ctrl">
            <div className="kt-form-ctrl-wrapper">
                <Avatar src={src} alt={alt} width={width} />
                <Button
                    variant="outlined"
                    startIcon={<EditIcon />}
                    onClick={onEditButtonClick}
                >Bild ändern</Button>
            </div>

            <Dialog open={isDialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Bildauswahl</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Wählen Sie ein Bild aus. Sie können den Ausschnitt des Bildes nach Ihren Wünschen ändern.
                    </DialogContentText>

                    <div className="kt-avatar-editor-wrapper">
                        <AvatarEditor
                            image={imageSrc}
                            alt={alt}
                            width={128}
                            height={128}
                            borderRadius={64}
                            scale={scale}
                            rotate={rotation}
                            disableBoundaryChecks={true}
                            ref={editorRef}
                            crossOrigin={crossOrigin}
                        />

                        <Typography id="zoom-slider" gutterBottom>
                            Zoom
                        </Typography>
                        <Slider
                            min={0}
                            max={2}
                            step={0.02}
                            defaultValue={1}
                            value={scale}
                            aria-labelledby="zoom-slider"
                            onChange={handleScaleChange} />

                        <Typography id="rotation-slider" gutterBottom>
                            Drehen
                        </Typography>
                        <Slider
                            min={-180}
                            max={180}
                            step={1}
                            defaultValue={0}
                            value={rotation}
                            aria-labelledby="rotation-slider"
                            onChange={handleRotationChange} />

                        <input
                            type="file"
                            onChange={onFileChange}
                            accept="image/*"
                            id="select-image-button-file"
                        />
                        <label htmlFor="select-image-button-file">
                            <Button variant="outlined" color="secondary" component="span">
                            Bild ändern
                            </Button>
                        </label>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Abbrechen
                    </Button>
                    <Button onClick={handleApply} color="primary">
                        Übernehmen
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}

export default ImageInputCtrl;