

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';

import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useContext } from 'react';
import ClientLocationsService from '../../services/clientLocationsService';
import { GlobalContext } from '../../GlobalContext';



interface Props {
    visible: boolean;
    onClose: (selectedTag: string) => void;
}

const SearchTagsDialog: React.FC<Props> = ({ visible, onClose }) => {

    const {currentLocation, setCurrentLocation} = useContext(GlobalContext);

    const [newTag, setNewTag] = useState("");

    function handleClose(selectedTag: string) {
        setNewTag("");
        onClose(selectedTag);
    }

    async function addNewTag(e) {

        e.preventDefault();
        e.stopPropagation();

        // do not create duplicates
        if (!currentLocation.searchTags.includes(newTag)) {

            currentLocation.searchTags.push(newTag);

            setCurrentLocation(currentLocation.clone());

            await ClientLocationsService.updateClientLocation(currentLocation);
        }

        handleClose(newTag);

    }

    function deleteSearchTag(tagName: string) {
        currentLocation.searchTags = currentLocation.searchTags.filter(e => e !== tagName.toLowerCase());

        setCurrentLocation(currentLocation.clone());

        ClientLocationsService.updateClientLocation(currentLocation);
    }

    if(!currentLocation) return null;

    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={visible}
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle id="customized-dialog-title">
                Such Tags
            </DialogTitle>

            <IconButton
                edge="end"
                color="inherit"
                onClick={() => handleClose("")}
                aria-label="close"
                style={{
                    position: 'absolute',
                    right: 20,
                    top: 8
                }}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent
                dividers
            >
                <p>Sie können Patienten mit Tags zur besseren Nachverfolgung versehen. Erstellen Sie Tags wie z.B. 'KVA erstellt' oder 'HKP erstellt'.
                    <br/>
                    Erlaubt sind Buchstaben, Zahlen und Leerzeichen.
                </p>
                <form onSubmit={addNewTag}>
                    <Grid container>
                        <Grid item xs={9}>
                            <TextField
                                required
                                fullWidth
                                label="Neuer Tag"
                                value={newTag.toUpperCase()}
                                inputProps={{
                                    minLength: 2,
                                    maxLength: 20,
                                    pattern: "[a-zA-Z0-9 ]+"
                                }}
                                onChange={e => setNewTag(e.currentTarget.value.toLowerCase())}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Box ml={1} textAlign={'right'} mt={1}>
                                <Button variant='contained' type="submit">Erstellen</Button>
                            </Box>
                        </Grid>
                    </Grid>

                </form>

                {currentLocation.searchTags.length > 0 && <>
                    <p>Oder wählen Sie aus bereits vorhandenen Tags aus:</p>

                    {currentLocation.searchTags.map((sTag, index) =>
                        <Chip
                            key={index}
                            label={sTag.toUpperCase()}
                            onClick={() => handleClose(sTag)}
                            onDelete={() => deleteSearchTag(sTag)}
                        />
                    )}
                </>}


            </DialogContent>

            <DialogActions>
                <Button onClick={() => handleClose("")}>SCHLIEßEN</Button>
            </DialogActions>

        </Dialog>

    );
}

export default SearchTagsDialog;
