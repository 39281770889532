
import React from "react";

import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from "@mui/material/FormLabel";

import { FormItemCtrlProps } from "./formItemCtrl";
import SignatureFormItem from "../../../src/shared/src/models/formEditor/signatureFormItem";
import TextEvaluationService from "../../../src/services/textEvaluationService";



interface Props extends FormItemCtrlProps {
    item: SignatureFormItem
}

const SignatureFormItemCtrl: React.FC<Props> = (
    {
        item,
        languageKey,
        handleOnInputChange,
        handleOnSignatureDialogStateChange,
        formRows,
        onFormChange,
        selectedItem,
        setSelectedItem,
        renderMode,
        formUser
    }) => {


    function onSignatureClick(id: string) {
        // first check if editing is allowed
        if(item.inputUser === "doctor" && formUser === "patient") {
            return;
        }

        if (handleOnSignatureDialogStateChange && typeof handleOnSignatureDialogStateChange === "function") {
            handleOnSignatureDialogStateChange(true, id);
        }
    }

    function renderHtml(): JSX.Element {

        const langQuestion = item.getLanguageProperty(languageKey, "labels");

        return (
            <div>
                <img className="kt-signature" src="/images/signature.svg" alt="signature" />

                <div className="kt-inline-input-wrapper">
                    {item.inputUser === "patient" && <span>*</span>}

                    <input
                        type="text"
                        value={langQuestion}
                        onChange={(e) => handleOnInputChange(item, e.target.value, "labels")}
                        placeholder="Unterzeichner"
                    />
                </div>
            </div>
        );

    }

    function renderProperties(): JSX.Element {
        return (
            <div>
                <Grid container>

                    <Grid item xs={12}>
                        <strong>Unterschrift</strong>
                    </Grid>


                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">Darf ausfüllen:</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="patient"
                                name="radio-buttons-group"
                                value={item.inputUser}
                                onChange={(e) => handleOnInputChange(item, e.target.value, "inputUser")}
                            >
                                <FormControlLabel value="patient" control={<Radio />} label="Patient" />
                                <FormControlLabel value="doctor" control={<Radio />} label="Arzt" />
                                <FormControlLabel value="all" control={<Radio />} label="Jeder" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    {item.inputUser === "doctor" &&
                        <Grid item xs={12}>
                            <FormControlLabel
                                label="Automatisch unterschreiben"
                                control={
                                    <Checkbox
                                        checked={item.autoSign}
                                        onChange={(e) => handleOnInputChange(item, e.target.checked, "autoSign")}
                                    />
                                }
                            />
                        </Grid>
                    }

                </Grid>

            </div>
        );
    }

    function renderFinalHtml(): JSX.Element {

        let langQuestion = item.getLanguageProperty(languageKey, "labels");
        langQuestion = TextEvaluationService.evaluateDocumentText(langQuestion);

        return (
            <div>

                <div className="kt-signature" onClick={() => onSignatureClick(item.id)}>
                    {item.signature !== "" ?
                        <img src={item.signature} alt="Unterschrift" />
                        :
                        <React.Fragment>
                            <i className="fal fa-hand-point-up" />
                            <span>Klicken Sie hier zum Unterschreiben.</span>
                        </React.Fragment>
                    }

                </div>

                <div >

                    <span>{langQuestion}</span>

                </div>
            </div>
        );

    }

    switch (renderMode) {
        case "editor":
            return renderHtml();

        case "properties":
            return renderProperties();

        case "final":
            return renderFinalHtml();

        default:
            return <React.Fragment></React.Fragment>;
    }

}


export default SignatureFormItemCtrl;