import React from 'react';


interface Props {
    title: string;
    subTitle?: string;
    icon: string;
    color: "primary" | "secondary";
    absoluteNumber: number;
    percentage: number
}

const NumberWidget:React.FC<Props> = ({title, subTitle, icon, color, absoluteNumber, percentage}) => {

    function handleOnClick(){
        
    }

    return (

        <div className={`kt-number-widget ${color}`} onClick={handleOnClick}>
            <div className="kt-widget-title">{title}</div>
            
            <div className="icon"><i className={icon}></i></div>
            
            <div className="kt-widget-content">
                {subTitle && <div className="kt-sub-title">{subTitle}</div>}
                <div className="absoluteNumber">{absoluteNumber}</div>
                <div className="percentageBar">
                    <div style={{width: percentage}}></div>
                </div>
                <div>{Math.round(percentage)}%</div>
            </div>                        
        
        </div>

    );
}

export default NumberWidget;