import DateUtils from "../utils/dateUtils";
import Utils from "../utils/utils";

class ToDo {
    id: string = Utils.getUUID();
    name: string = "";
    done: boolean = false;

    patientId: string = "";
    patientName: string = "";

    createdById: string = "";       // user id of the user who created the to-do item
    createdByName: string = "";

    assignedToId: string = "";       // user id of the user who is assigned to the to-do item
    assignedToName: string = "";

    calendarId: string = "";         // calendar id of the calendar to which the to-do item is assigned

    dueDate: Date | null = null;
    createdAt: Date = new Date();

    clone(): ToDo {
        const t = new ToDo();
        t.fromObject(null, this.toJSON(true));

        return t;
    }

    toJSON(useDate: boolean): object {
        return {
            id: this.id,
            name: this.name,
            done: this.done === true,

            patientId: this.patientId,
            patientName: this.patientName,

            createdById: this.createdById,
            createdByName: this.createdByName,

            assignedToId: this.assignedToId,
            assignedToName: this.assignedToName,

            calendarId: this.calendarId,

            dueDate: useDate ? this.dueDate : this.dueDate?.toJSON(),
            createdAt: useDate ? this.createdAt : this.createdAt?.toJSON()
        };
    }

    fromObject(id: string|null, o:any){
 
        this.id = id !== null ? id : o.id;
        this.name = o.name ?? "";
        this.done = o.done === true;

        this.patientId = o.patientId ?? "";
        this.patientName = o.patientName ?? "";

        this.createdById = o.createdById ?? "";
        this.createdByName = o.createdByName ?? "";

        this.assignedToId = o.assignedToId ?? "";
        this.assignedToName = o.assignedToName ?? "";

        this.calendarId = o.calendarId ?? "";

        this.dueDate = DateUtils.getDate(o.dueDate);
        this.createdAt = DateUtils.getDate(o.createdAt) ?? new Date();

    }
};

export default ToDo;