import {useState, useEffect, useContext} from "react";
import {useParams} from "react-router-dom";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import DevicesService from "../../../services/devicesService";

import LoadingPage from "../administration/loadingPage";
import SaveStatus from "../../../models/saveStatus";
import SaveButton from "../../saveButton";
import Device from "../../../models/device";
import BackButton from "../../backButton";
import { GlobalContext } from "../../../GlobalContext";


function DevicePage() {

    const { deviceId } = useParams();

    const [device, setDevice] = useState<Device | null>(null);

    const [isLoading, setIsLoading] = useState(false);
    const {currentClient} = useContext(GlobalContext);
    const {currentUser} = useContext(GlobalContext);

    const [saveStatus, setSaveStatus] = useState(SaveStatus.none);


    useEffect(() => {

        if(deviceId && currentClient){
            updateData(deviceId);
        }

        // eslint-disable-next-line
    }, [deviceId, currentClient]);



    async function updateData(devId:string) {

        if(!currentUser){
            return;
        }

        try {
            const cId = currentClient.id;

            setIsLoading(true);

            if(devId && devId.toLowerCase() === "create" && cId){
                const newDevice = new Device();

                setDevice(newDevice);


            } else {

                const result = await DevicesService.getDevice(currentClient.id, currentUser.locationId, devId, true);

                if(result) {
                    setDevice(result);
                }
            }


            setIsLoading(false);

        } catch(error) {
            console.log(error);
            setIsLoading(false);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();

        if(device !== null && currentUser) {
            setSaveStatus(SaveStatus.isSaving);


            const newId = await DevicesService.updateDevice(device, currentClient.id, currentUser.locationId);

            if(newId){

                setSaveStatus(SaveStatus.saved);

                window.history.back();

            } else {
                setSaveStatus(SaveStatus.none);
                alert("Die Ressource konnte nicht gespeichert werden!");
            }
        }
    }

    function onInputChange(event){
        if(device){
            const {name, value} = event.target;

            const dev = device.clone();

            if(event.target.type === "checkbox"){
                dev[name] = event.target.checked;
            } else {
                dev[name] = value;
            }

            setDevice(dev);
        }
    }




    if(isLoading) return <LoadingPage/>;

    if(!device) return (
        <div className="kt-page-content">
            <h2>Ressource</h2>

            <div>Diese Ressource existiert nicht. Bitte überprüfen Sie Ihre URL.</div>
        </div>
    );

    return (
        <Box className="kt-page">
            <h2>Ressource</h2>

            <form onSubmit={handleSubmit} autoComplete="turnitoff">

                <div className="kt-form-section">

                    <Grid container>

                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                name="name"
                                label="Name"
                                value={device.name}
                                onChange={onInputChange}
                                required
                                inputProps={{minLength:1}}
                            />
                        </Grid>

                    </Grid>
                </div>

                <div className="kt-page-footer">
                    <BackButton />

                    <SaveButton saveStatus={saveStatus} />
                </div>

            </form>
        </Box>
    );
}

export default DevicePage;