import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React, { useContext, useEffect, useState } from 'react';
import { DataGridPro, GridCellModes, GridCellModesModel, GridCellParams, GridColumnHeaderParams, deDE, useGridApiRef } from '@mui/x-data-grid-pro';
import Dropzone from 'react-dropzone';
import FileImporter, { DuplicateHandling, FileImporterConfig, ImportedColumn, TargetColumn } from './fileImporter';
import Utils from '../../shared/src/utils/utils';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl/FormControl';
import RadioGroup from '@mui/material/RadioGroup/RadioGroup';
import Radio from '@mui/material/Radio';
import ProgressDialog from './progressDialog';
import ConfirmDialog from './confirmDialog';
import PatientsService from '../../services/patientsService';
import { GlobalContext } from '../../GlobalContext';
import User from '../../models/user';
import ImportResultsService from '../../services/importResultsService';
import ImportResult from '../../shared/src/models/importResult';
import MessageDialog from './messageDialog';

interface Props {
    visible: boolean;
    onClose: () => void;
}

const importConfig: FileImporterConfig = {
    targetColumns: [
        { field: "importId", headerName: "ImportID" },
        { field: "gender", headerName: "Anrede" },
        { field: "title", headerName: "Titel" },
        { field: "firstName", headerName: "Vorname" },
        { field: "lastName", headerName: "Nachname" },
        { field: "email", headerName: "Email" },
        { field: "phoneNumber", headerName: "Telefon" },
        { field: "mobilePhoneNumber", headerName: "Handy" },
        { field: "street", headerName: "Straße" },
        { field: "postalCode", headerName: "Postleitzahl" },
        { field: "city", headerName: "Ort" },
        { field: "birthDate", headerName: "Geburtstag" }
    ]
}
const importer = new FileImporter(importConfig);


const ImportDialog: React.FC<Props> = ({ visible, onClose }) => {

    const { currentUser } = useContext(GlobalContext);

    const [activeStep, setActiveStep] = useState(0);

    const [firstLineContainsColumnHeader, setFirstLineContainsColumnHeader] = useState(true);

    const [rows, setRows] = useState<any[]>([]);

    const [importedRowCount, setImportedRowCount] = useState(0);
    const [importedValidRowCount, setImportedValidRowCount] = useState(0);
    const [firstNameCount, setFirstNameCount] = useState(0);
    const [lastNameCount, setLastNameCount] = useState(0);

    const [importedColumns, setImportedColumns] = useState<ImportedColumn[]>([]);

    const [csvFile, setCsvFile] = useState<File | null>(null);

    const [hasErrors, setHasErrors] = useState(false);
    const [showOnlyErrorRows, setShowOnlyErrorRows] = useState(false);

    const [duplicateHandling, setDuplicateHandling] = useState<DuplicateHandling>("merge");
    const [importTag, setImportTag] = useState("");

    const [isImportRunning, setIsImportRunning] = useState(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [isImportResultDialogOpen, setIsImportResultDialogOpen] = useState(false);

    const [importResult, setImportResult] = useState(new ImportResult());

    const gridApiRef = useGridApiRef();

    let importId = "";

    useEffect(() => {
        importId = "";
        setImportResult(new ImportResult());
        setActiveStep(0);
        setRows([]);
        setImportedRowCount(0);
        setImportedValidRowCount(0);
        setFirstNameCount(0);
        setLastNameCount(0);
        setShowOnlyErrorRows(false);
        setCsvFile(null);

        setImportedColumns(getTableColumns([]));

    }, [visible]);


    function onColumnMappingChange(sourceColumnName: string, targetColumnName: string) {

        const scrollPosition = gridApiRef.current.getScrollPosition();

        importer.setNewColumnMapping(sourceColumnName, targetColumnName);

        setImportedColumns(getTableColumns(importer.importedColumns));

        setRows(importer.targetRows);

        setFirstLineContainsColumnHeader(importer.isFirstRowColumnHeader);

        setImportedValidRowCount(importer.validRowCount);
        setFirstNameCount(importer.firstNameCount);
        setLastNameCount(importer.lastNameCount);

        setHasErrors(importer.hasErrors());


        setTimeout(() => {
            gridApiRef.current.scroll(scrollPosition);
        }, 200);

    }

    const getTableColumns = (importedColumns: ImportedColumn[]) => {

        const columns: any[] = [...importedColumns];

        // add some rendering functions
        for (let i = 0; i < columns.length; i++) {
            const column = columns[i];

            column['width'] = 300;
            column['sortable'] = false;
            // column['editable'] = true;

            column['renderHeader'] = (params: GridColumnHeaderParams) => (
                <select
                    // defaultValue={'ignore'}
                    value={importer.targetColumns.find(targetColumn => targetColumn.field === column.targetField)?.field ?? 'ignore'}
                    onChange={e => onColumnMappingChange(column.field, e.target.value)}
                >
                    <option value={'ignore'}>---</option>
                    {importConfig.targetColumns.map(targetColumn => <option value={targetColumn.field} key={targetColumn.field}>{targetColumn.headerName}</option>)}
                </select>
            )

            if (column.field === "gender") {
                column['renderCell'] = (params) => (<span tabIndex={params.tabIndex}>{Utils.getGenderString(params.row.gender)}</span>);
            }

            if (column.field === "privateInsurance") {
                column['renderCell'] = (params) => (<span tabIndex={params.tabIndex}>{
                    params.row.privateInsurance + "" === "true" ?
                        <CheckRoundedIcon />
                        :
                        params.row.privateInsurance + "" === "false" ? null : <QuestionMarkIcon />
                }
                </span>);
            }


        }

        return columns;
    }

    const steps = [
        'DATEI',
        'FELDZUORDNUNG',
        'DUBLETTENPRÜFUNG',
        'IMPORT'
    ];

    function handleCloseClick(event: object, reason: string) {
        if (reason !== "backdropClick") onClose();
    }

    function handlePreviousClick() {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        }
    }

    function handleNextClick() {
        if (activeStep < steps.length) {
            setActiveStep(activeStep + 1);
        }
    }

    async function startImport() {
        setIsConfirmDialogOpen(false);

        setIsImportRunning(true);

        importId = Utils.getUUID();
        PatientsService.startCSVImport(currentUser.clientId, currentUser.locationId, importId, importer.importedColumns, importer.importedRows, duplicateHandling);

        ImportResultsService.startListenForImportResult(currentUser.clientId, currentUser.locationId, importId, importResult => {
            console.log(importResult);
            if (importResult && importResult.id !== undefined) {
                setImportResult(importResult);
                if (importResult.success || (!importResult.success && importResult.errorMessage)) {
                    setIsImportRunning(false);
                    setIsImportResultDialogOpen(true);
                }
            }
        });

    }

    async function handleFileChange(files: File[]) {

        if (files && files.length === 1) {
            const file = files[0];
            const fileName = file.name.toLowerCase();

            if (!fileName.endsWith(".csv")) {
                alert("Falsches Dateiformat!\nBitte wählen Sie eine gültige Patientenliste im CSV Format.")
            } else {

                setCsvFile(file);

                readCSVFile(file, true);

                setActiveStep(1);

            }

        }

    }

    function readCSVFile(file: File, autoFixData: boolean) {

        importer.readCSVFile(file, "utf-8", autoFixData, () => {
            setRows(importer.targetRows);

            setFirstLineContainsColumnHeader(importer.isFirstRowColumnHeader);

            setImportedRowCount(importer.rowCount);
            setImportedValidRowCount(importer.validRowCount);
            setFirstNameCount(importer.firstNameCount);
            setLastNameCount(importer.lastNameCount);

            setHasErrors(importer.hasErrors());

            setImportedColumns(getTableColumns(importer.importedColumns));

        })

    }

    function onShowOnlyErrorRowsChange(newValue: boolean) {
        setShowOnlyErrorRows(newValue);
    }


    function isNextButtonDisabled(): boolean {

        switch (activeStep) {
            case 0:
                return csvFile === null;

            case 1:
                return importedValidRowCount === 0;

            case 2:
                return false;

            default:
                return true;
        }
    }

    function SettingsPanel() {

        return <Grid container>

            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <h1>Laden Sie nun Ihre Patientenliste als CSV-Datei hoch.</h1>
            </Grid>

            <Grid item xs={12}>
                <Dropzone
                    onDrop={handleFileChange}
                    accept={{ "text/csv": [".csv"] }}
                >
                    {({ getRootProps, getInputProps }) => (
                        <section className='kt-dropzone'>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p><i className='fal fa-cloud-upload'></i></p>
                                <p>Ziehen Sie Ihre Patientenliste hier in dieses Feld oder klicken Sie.</p>
                            </div>
                        </section>
                    )}
                </Dropzone>
            </Grid>
        </Grid>
    }


    function ColumnMappingPanel() {

        return <Grid container>

            <Grid item xs={6}>
                {!firstLineContainsColumnHeader && <p style={{ color: "red" }}><i className='fa fa-exclamation-triangle'></i> Ihre Datei enthält keine Spaltenüberschriften!<br />Bitte setzen Sie die Spaltenzuordnung manuell.</p>}

                <span style={{ fontWeight: 700 }}><i className='fa fa-database'></i> Gefundene Datensätze: {importedRowCount}</span>
                {(importedRowCount - firstNameCount) > 0 && <span style={{ color: "red", fontWeight: 700 }}><br /><i className='fa fa-exclamation-triangle'></i> Fehlende Vornamen: {importedRowCount - firstNameCount}</span>}
                {(importedRowCount - lastNameCount) > 0 && <span style={{ color: "red", fontWeight: 700 }}><br /><i className='fa fa-exclamation-triangle'></i> Fehlende Nachnamen: {importedRowCount - lastNameCount}</span>}
            </Grid>

            {hasErrors && <Grid item xs={6}>
                <FormControlLabel
                    control={<Switch checked={showOnlyErrorRows} onChange={e => onShowOnlyErrorRowsChange(e.currentTarget.checked)} />}
                    label="Nur fehlerhafte Datensätze anzeigen"
                />
            </Grid>}

            <Grid item xs={12}>
                <h2>Prüfen Sie nun, ob die Spalten aus Ihrer Datei richtig zugeordnet wurden.</h2>
                <div style={{ height: "300px" }}>
                    <DataGridPro
                        apiRef={gridApiRef}
                        rows={showOnlyErrorRows ? rows.filter(r => r.hasErrors) : rows}
                        columns={importedColumns}
                        hideFooter
                        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                        showColumnVerticalBorder={true}
                        showCellVerticalBorder={true}
                        getRowClassName={(params) => params.row.hasErrors ? "kt-data-grid-row-error" : ""}

                    />
                </div>
            </Grid>

        </Grid>
    }

    function DuplicatePanel() {

        return <Grid container>

            <Grid item xs={12} style={{ textAlign: "center" }}>
                <FormControl>
                    <h1>Wie sollen Duplikate behandelt werden?</h1>
                    <RadioGroup
                        value={duplicateHandling}
                        onChange={e => setDuplicateHandling(e.currentTarget.value as any)}
                        defaultValue={"merge"}
                    >
                        <FormControlLabel value={"merge"} control={<Radio />} label={getDuplicateHandlingText("merge")} />
                        <FormControlLabel value={"replace"} control={<Radio />} label={getDuplicateHandlingText("replace")} />
                        <FormControlLabel value={"allow"} control={<Radio />} label={getDuplicateHandlingText("allow")} />
                        <FormControlLabel value={"skip"} control={<Radio />} label={getDuplicateHandlingText("skip")} />
                    </RadioGroup>
                </FormControl>
            </Grid>


        </Grid>
    }

    function getDuplicateHandlingText(input: DuplicateHandling) {
        switch (input) {
            case "allow":
                return "Erlauben (Alle Daten aus Ihrer Datei werden importiert. Es wird nicht auf Dubletten überprüft.)";

            case "replace":
                return "Ersetzen (Die Felder aus Ihrer Datei überschreiben vorhandene Felder.)";

            case "merge":
                return "Zusammenführen (Nur fehlende Felder werden mit Daten aus Ihrer Datei befüllt.)";

            case "skip":
                return "Überspringen (Dubletten werden nicht importiert)";

            default:
                return "";
        }
    }

    function SummaryPanel() {

        return <Grid container>

            <Grid item xs={12} style={{ textAlign: "center" }}>
                <h1>Zusammenfassung</h1>

                <h4>{importedValidRowCount} {(importedValidRowCount === 1) ? <span>Datensatz wird importiert</span> : <span>Datensätze werden importiert</span>}</h4>
                {(importedRowCount - importedValidRowCount === 1) &&
                    <h4>1 fehlerhafter Datensatz wird ignoriert</h4>
                }
                {(importedRowCount - importedValidRowCount > 1) &&
                    <h4>{importedRowCount - importedValidRowCount} fehlerhafte Datensätze werden ignoriert</h4>
                }
                <h4>Dubletten: {getDuplicateHandlingText(duplicateHandling)}</h4>


            </Grid>

            {/* <Grid item xs={12} style={{ textAlign: "center" }}>
                <span>Sie können nun noch Ihren Import mit einem Such-Tag versehen.<br />So können Sie später nach Patienten aus genau diesem Import suchen.</span><br />
                <TextField
                    value={importTag}
                    label="Such-Tag"
                    onChange={e => setImportTag(e.currentTarget.value)}
                />
            </Grid> */}

            <Grid item xs={12} style={{ textAlign: "center" }}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ fontSize: '30px' }}
                    onClick={() => setIsConfirmDialogOpen(true)}
                >
                    IMPORT STARTEN
                </Button>
            </Grid>


        </Grid>
    }

    function renderImportResultDialogMessage() {
        return <>
            {(importResult.errorMessage && !importResult.success) && <strong style={{color: "red"}}>Der Import ist fehlgeschlagen. Bitte wenden Sie Sich an unseren Support.</strong>}
            {importResult.success && <strong>Der Import wurde erfolgreich abgeschlossen.</strong>}
            <table>
                <tbody>
                    <tr><td>Patienten in Ihrer Datei gefunden</td><td>{importResult.totalPatients}</td></tr>
                    {importResult.errorPatients > 0 && <tr><td>Davon fehlerhaft</td><td>{importResult.errorPatients}</td></tr>}
                    <tr><td>Patienten importiert</td><td>{importResult.importedPatients}</td></tr>
                    {importResult.skippedPatients > 0 && <tr><td>Patienten ignoriert</td><td>{importResult.skippedPatients}</td></tr>}
                    {importResult.duplicatePatients > 0 && <tr><td>Doppelte Patienten in Ihrer Datenbank gefunden</td><td>{importResult.duplicatePatients}</td></tr>}
                </tbody>
            </table>
        </>
    }

    return (
        <>
            <ConfirmDialog
                visible={isConfirmDialogOpen}
                title='Import bestätigen'
                label='Wollen Sie den Import nun starten?'
                onReject={() => setIsConfirmDialogOpen(false)}
                onConfirm={startImport}
            />

            <ProgressDialog
                visible={isImportRunning}
                title="Die Datenbank wird aktualisiert..."
                message="Dies kann einige Minuten dauern. Bitte schließen Sie nicht das Browserfenster."
                progress={importResult.progress}
            />

            <MessageDialog
                visible={isImportResultDialogOpen}
                title='Import Ergebniss'
                titleIconFaClassName='far fa-exclamation-triangle'
                message={renderImportResultDialogMessage()}
                onClose={() => {
                    setIsImportResultDialogOpen(false);
                    onClose();
                }}
            />

            <Dialog
                onClose={handleCloseClick}
                aria-labelledby="customized-dialog-title"
                open={visible}
                maxWidth="lg"
                fullWidth={true}
                disableEnforceFocus={true}
            >
                <DialogTitle id="customized-dialog-title">
                    <i className="fal fa-users" style={{ fontSize: "30px", marginRight: "5px", marginBottom: "3px" }}></i> Patienten Import
                </DialogTitle>

                <DialogContent dividers style={{ minHeight: '650px' }}>

                    <Box sx={{ width: '100%' }} mb={5}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label} sx={{ fontSize: "48px" }}>
                                    <StepLabel classes={{ label: "fontSize: 44px" }}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>

                    <Box sx={{ width: '90%' }} m='auto'>

                        {activeStep === 0 && <SettingsPanel />}
                        {activeStep === 1 && <ColumnMappingPanel />}
                        {activeStep === 2 && <DuplicatePanel />}
                        {activeStep === 3 && <SummaryPanel />}
                    </Box>


                </DialogContent>

                <DialogActions>
                    {activeStep > 0 &&
                        <Button onClick={handlePreviousClick} color="secondary" variant='outlined' style={{ marginRight: "auto" }}>
                            <i className="fal fa-angle-left" style={{ fontSize: "25px", marginRight: "5px", marginBottom: "3px" }}></i> ZURÜCK
                        </Button>
                    }

                    <Button onClick={e => handleCloseClick(e, "buttonClick")} color="secondary" variant='outlined'>
                        Abbrechen
                    </Button>
                    <Button onClick={handleNextClick} color="primary" variant='contained' disabled={isNextButtonDisabled()}>
                        WEITER <i className="fal fa-angle-right" style={{ fontSize: "25px", marginLeft: "5px", marginBottom: "3px" }}></i>
                    </Button>

                </DialogActions>
            </Dialog >

        </>


    );
}

export default ImportDialog;
