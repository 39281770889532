import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Speciality from '../models/speciality';
import VisitMotive from '../models/visitMotive';
import DurationPicker from './durationPicker';


interface Props {
    selectedVisitMotiveId: string;
    visitMotives: VisitMotive[];
    specialities: Speciality[];

    appointmentStart: Date;
    appointmentEnd: Date;

    showDuration: boolean;

    margin?: "none";

    onVisitMotiveChange: (newVisitMotive: VisitMotive | null) => void;
    onDurationChange: (newEndDate: Date) => void;
}


const VisitMotivePicker: React.FC<Props> = ({ selectedVisitMotiveId, visitMotives, specialities, appointmentStart, appointmentEnd, showDuration, margin, onVisitMotiveChange, onDurationChange }) => {

    const [selectedSpeciality, setSelectedSpeciality] = useState<Speciality>(new Speciality());

    const [filteredVisitMotives, setFilteredVisitMotives] = useState<VisitMotive[]>([]);

    const [searchText, setSearchText] = useState("");

    const [_selectedVisitMotiveId, _setSelectedVisitMotiveId] = useState("");

    useEffect(()=>{
        // console.log("sel: " + selectedVisitMotiveId);
        // console.log("_sel: " + _selectedVisitMotiveId);
        // console.log("selSpec: " + selectedSpeciality);
        // console.log("filVM: " + filteredVisitMotives);
        // setSelectedSpeciality(new Speciality());
        // _setSelectedVisitMotiveId("");
    }, []);

    useEffect(() => {
        setSelectedSpecialityBySelectedVisitMotiveId(selectedVisitMotiveId, visitMotives, specialities);

    }, [selectedVisitMotiveId, visitMotives]);

    useEffect(() => {

        const fMotives = visitMotives.filter(v => v.specialityId === selectedSpeciality.id);
        setFilteredVisitMotives(fMotives);

        if (selectedVisitMotiveId && visitMotives && fMotives.length > 0) {
            const selectedVisitMotive = visitMotives.find(v => v.id === selectedVisitMotiveId);
            if (selectedVisitMotive && selectedVisitMotive.specialityId !== selectedSpeciality.id) {
                _setSelectedVisitMotiveId(fMotives[0].id);
                onVisitMotiveChange(fMotives[0]);
            }
        }

    }, [selectedSpeciality]);

    useEffect(() => {

        if (filteredVisitMotives && filteredVisitMotives.length > 0) {
            const v = filteredVisitMotives.find(v => v.id === selectedVisitMotiveId);

            if (v) {
                _setSelectedVisitMotiveId(selectedVisitMotiveId);
            }
        }

    }, [filteredVisitMotives, selectedVisitMotiveId])

    function setSelectedSpecialityBySelectedVisitMotiveId(_selectedVisitMotiveId: string, _visitMotives: VisitMotive[], _specialities: Speciality[]){
        if (_selectedVisitMotiveId && _visitMotives && _specialities && _specialities.length > 0) {
            const v = _visitMotives.find(v => v.id === _selectedVisitMotiveId);

            if (v && v.specialityId) {
                const s = _specialities.find(s => s.id === v.specialityId);
                if (s) {
                    setSelectedSpeciality(s);
                }
            }
        }
    }

    function handleVisitMotiveChange(event) {

        const value = event.target.value;
        const v = visitMotives.find(v => v.id === value);

        if (v) {
            onVisitMotiveChange(v);
        }
    }


    function onTabClick(speciality: Speciality) {
        setSearchText("");
        setSelectedSpeciality(speciality);
    }

    function inSearchMode() {
        return searchText && searchText.length > 2;
    }

    if (visitMotives?.length === 0) {
        return null;
    }

    return (
        <div className="kt-visitMotivePicker">
            <div className={margin && margin === "none" ? "kt-tabs noselect kt-tabs-no-margin" : "kt-tabs noselect"} >
                {specialities && specialities.map((speciality) =>
                    <div
                        key={speciality.id}
                        className={(speciality.id === selectedSpeciality.id && !inSearchMode()) ? "kt-tab kt-selected" : "kt-tab"}
                        onClick={() => onTabClick(speciality)}
                    >
                        {speciality.shortName}
                    </div>
                )}
            </div>

            <Box mt={1}>
                <Grid container>
                    <Grid item xs={12} sm={showDuration ? 9 : 12}>
                        <FormControl fullWidth>
                            <InputLabel id="motive-select-label">Besuchsgrund</InputLabel>
                            <Select
                                required
                                name="appointment.visitMotive"
                                labelId="motive-select-label"
                                label="Besuchsgrund"
                                id="motive-select"
                                value={_selectedVisitMotiveId}
                                onChange={handleVisitMotiveChange}
                                defaultValue={""}
                            >
                                {filteredVisitMotives.length === 0 ?
                                    <MenuItem disabled value="">Bitte wählen Sie zunächst eine Besuchsgrund-Gruppe aus.</MenuItem>
                                    :
                                    <MenuItem disabled value="">Bitte wählen Sie einen Besuchsgrund aus.</MenuItem>
                                }

                                {filteredVisitMotives.map(visitMotive =>
                                    <MenuItem key={visitMotive.id} value={visitMotive.id}>{visitMotive.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    {showDuration && <Grid item xs={12} sm={3}>
                        <DurationPicker
                            startDate={appointmentStart}
                            endDate={appointmentEnd}
                            onValueChange={onDurationChange}
                        />
                    </Grid>}
                </Grid>
            </Box>

        </div>
    );
}

export default VisitMotivePicker;
