import { useState, useEffect, useRef, useContext } from 'react';
import { Link } from "react-router-dom";
import firebaseApp from "./../database";

import CalendarCtrl, { TimeRangeViewType } from "../calendar/calendarCtrl";
import DatePicker from "../datePicker/datePicker";
import ResourceFilter from "../resourceFilter";
import AppointmentsService from "../../services/appointmentsService";
import NotificationsService from '../../services/notificationsService';
import Reminder from '../../models/reminder';
import Rating from '../../models/rating';
import RatingsService from '../../services/ratingsService';
import DateUtils from '../../../src/shared/src/utils/dateUtils';
import Appointment from '../../../src/models/appointment';
import { GlobalContext } from '../../GlobalContext';
import LogItemList from '../logItemList';
import Client from '../../models/client';
import i18next, { t } from 'i18next';
import { Input, TextField } from '@mui/material';
import ChatBot from '../chatBot';

// import { Steps } from "intro.js-react";
// import "intro.js/introjs.css";

// const introJsSteps = [
//     {
//         title: "Eine kleine Tour",
//         intro: "Wir erstellen nun gemeinsam einen ersten Demo-Termin..."
//     },
//     {
//         title: "Termin erstellen",
//         element: ".fc-scrollgrid-section-liquid",
//         intro: "Klicken Sie hier in ein Feld, um einen Termin mit SMS Versand zu erstellen.",
//         position: "top"
//     },
//     {
//         element: "#appointmentDialog-lastName",
//         intro: "Geben Sie die ersten Buchstaben Ihres Nachnamens ein, bis Ihr Name aus der Auswahlliste erscheint und drücken Sie dann ENTER."
//     },
//     {
//         element: "#appointmentDialog-type-appointment",
//         intro: "Da LIVE BOT macka Aktion"
//     }
// ];


function CalendarPage(props) {


    const visitMotiveColorsRef = useRef();
    const publicHolidaysRef = useRef();
    const unsubscribeAppointmentsRef = useRef<any>();
    const unsubscribeMultiDayAppointmentsRef = useRef<any>();
    const unsubscribeRemindersRef = useRef<any>();
    const unsubscribeSentRemindersRef = useRef<any>();
    const unsubscribeRatingRequestsRef = useRef<any>();

    const remindersRef = useRef<Reminder[]>([]);
    const sentRemindersRef = useRef<Reminder[]>([]);
    const ratingRequestsRef = useRef<Rating[]>([]);

    const [selectedView, setSelectedView] = useState<"calendars" | "rooms" | "devices">("calendars");
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [lastSelectedDate, setLastSelectedDate] = useState(new Date());
    const [timeRangeView, setTimeRangeView] = useState<TimeRangeViewType>("none");
    const [lastTimeRangeView, setLastTimeRangeView] = useState<TimeRangeViewType>("none");

    const [appointments, setAppointments] = useState<any[]>([]);

    const [lastSelectedMode, setLastSelectedMode] = useState("");

    const { publicHolidays } = useContext(GlobalContext);
    const { calendars } = useContext(GlobalContext);
    const { specialities } = useContext(GlobalContext);
    const { rooms } = useContext(GlobalContext);
    const { devices } = useContext(GlobalContext);
    const { visitMotives } = useContext(GlobalContext);
    const { currentUser } = useContext(GlobalContext);
    const { currentClient } = useContext(GlobalContext);
    const { isSidebarVisible, setIsSidebarVisible } = useContext(GlobalContext);
    const { logItems } = useContext(GlobalContext);

    const [introJsStepsEnabled, setIntroJsStepsEnabled] = useState(false);

    let tempAppointments: Appointment[] = [];
    let tempMultiDayAppointments: Appointment[] = [];

    useEffect(() => {
        if (currentUser && currentUser.showTour) {
            //setTimeout(() => setIntroJsStepsEnabled(true), 4000);
        }
    }, [currentUser]);

    useEffect(() => {

        if (visitMotives && visitMotives.length > 0 && publicHolidays && visitMotiveColorsRef && publicHolidaysRef) {
            const colors = {} as any;

            // create a lookup table to get the visitmotives color for each appointment instantly
            for (let i = 0; i < visitMotives.length; i++) {
                const element = visitMotives[i];
                colors[element.id] = element.color;
            }

            visitMotiveColorsRef.current = colors;
            publicHolidaysRef.current = publicHolidays as any;

            // now update the colors
            handleCalendarItemsChange(null, null);
        }

    }, [visitMotives, publicHolidays]);

    useEffect(() => {

        if (currentUser && currentClient) {

            //if (lastSelectedMode !== props.mode || lastSelectedFirstDayOfMonth.getTime() !== firstDayOfMonth.getTime()) {
            if (lastSelectedMode !== props.mode || lastSelectedDate.getTime() !== selectedDate.getTime() || timeRangeView !== lastTimeRangeView) {


                let daysInThePast = 0;
                let daysInTheFuture = 0;

                switch (timeRangeView) {
                    case "listDay":
                    case "resourceTimeGridDay":
                        daysInThePast = 0;
                        daysInTheFuture = 0;
                        break;

                    case "resourceTimeGridWeek":
                        daysInThePast = 0;
                        daysInTheFuture = 7;
                        break;

                    case "dayGridMonth":
                        const offset = 10; // month calendar view also shows days of the last and next month
                        const dayOfMonth = selectedDate.getDate();
                        daysInThePast = dayOfMonth + offset;
                        daysInTheFuture = 31 - dayOfMonth + offset;
                        break;

                    default:
                        return;
                }



                const fromDate = new Date(selectedDate);
                fromDate.setDate(selectedDate.getDate() - daysInThePast);
                fromDate.setHours(0, 0, 0);

                const toDate = new Date(selectedDate);
                toDate.setDate(selectedDate.getDate() + daysInTheFuture);
                toDate.setHours(23, 59, 59);

                // console.log(`%cfrom: ${fromDate} to: ${toDate} #############`, "color: green");

                if (typeof unsubscribeAppointmentsRef.current === "function" && unsubscribeAppointmentsRef.current !== undefined) {
                    console.log("unsubscribe appointments");
                    unsubscribeAppointmentsRef.current();
                }
                if (typeof unsubscribeMultiDayAppointmentsRef.current === "function" && unsubscribeMultiDayAppointmentsRef.current !== undefined) {
                    console.log("unsubscribe multi day appointmens");
                    unsubscribeMultiDayAppointmentsRef.current();
                }
                if (typeof unsubscribeRemindersRef.current === "function" && unsubscribeRemindersRef.current !== undefined) {
                    console.log("unsubscribe reminders");
                    unsubscribeRemindersRef.current();
                }
                if (typeof unsubscribeSentRemindersRef.current === "function" && unsubscribeSentRemindersRef.current !== undefined) {
                    console.log("unsubscribe sent reminders");
                    unsubscribeSentRemindersRef.current();
                }
                if (typeof unsubscribeRatingRequestsRef.current === "function" && unsubscribeRatingRequestsRef.current !== undefined) {
                    console.log("unsubscribe rating requests");
                    unsubscribeRatingRequestsRef.current();
                }


                if (props.mode === "notifications") {
                    console.log("subscribe for reminders");
                    unsubscribeRemindersRef.current = NotificationsService.startListenForReminders(fromDate, toDate, currentUser.locationId, (result) => handleNotificationItemsChange(result, sentRemindersRef.current, ratingRequestsRef.current));

                    console.log("subscribe for sent reminders");
                    unsubscribeSentRemindersRef.current = NotificationsService.startListenForSentReminders(fromDate, toDate, currentUser.clientId, currentUser.locationId, (result) => handleNotificationItemsChange(remindersRef.current, result, ratingRequestsRef.current));

                    console.log("subscribe for rating requests");
                    unsubscribeRatingRequestsRef.current = RatingsService.startListenForRatings(fromDate, toDate, currentUser.locationId, (result) => handleNotificationItemsChange(remindersRef.current, sentRemindersRef.current, result));
                } else {
                    console.log("subscribe for appointments");
                    unsubscribeAppointmentsRef.current = AppointmentsService.startListenForAppointments(fromDate, toDate, currentUser.clientId, currentUser.locationId, (result) => handleCalendarItemsChange(result, null));

                    console.log("subscribe for multi day appointments");
                    unsubscribeMultiDayAppointmentsRef.current = AppointmentsService.startListenForMultiDayAppointments(fromDate, toDate, currentUser.clientId, currentUser.locationId, (result) => handleCalendarItemsChange(null, result));
                }

                setLastSelectedMode(props.mode);
                setLastTimeRangeView(timeRangeView);
                setLastSelectedDate(selectedDate);
            }

        }

        // eslint-disable-next-line
    }, [props.mode, currentUser, selectedDate, currentClient, timeRangeView]);

    function handleOnTourExit() {
        setIntroJsStepsEnabled(false);
    }

    function handleNotificationItemsChange(_reminders: any[], _sentReminders: any[], _ratings: any[]) {
        remindersRef.current = _reminders;
        sentRemindersRef.current = _sentReminders;
        ratingRequestsRef.current = _ratings;

        const _result: any[] = _reminders.concat(_ratings).concat(_sentReminders);
        handleCalendarItemsChange(_result, tempMultiDayAppointments);
    }

    function handleCalendarItemsChange(newAppointments: Appointment[] | null, newMultiDayAppointments: Appointment[] | null) {

        if (newAppointments) tempAppointments = newAppointments;

        if (newMultiDayAppointments) tempMultiDayAppointments = newMultiDayAppointments;

        const newAppointmentsList = tempAppointments.concat(tempMultiDayAppointments);

        const visitMotivesColors = visitMotiveColorsRef.current;

        if (visitMotivesColors) {
            for (let i = 0; i < newAppointmentsList.length; i++) {
                const newAppointment = newAppointmentsList[i];
                if (newAppointment.visitMotive) {
                    const color = visitMotivesColors[newAppointment.visitMotive.id];
                    if (color) {
                        newAppointment.color = color;
                        if (newAppointment.visitMotive) {
                            newAppointment.visitMotive.color = color;
                        }
                    }
                }
            }
        }

        if (publicHolidaysRef && publicHolidaysRef.current !== undefined && (publicHolidaysRef.current as any).length > 0) {
            const a = newAppointmentsList.concat(publicHolidaysRef.current!);
            setAppointments(a);
        } else {
            setAppointments(newAppointmentsList);
        }

    }

    function handleSelectedDateChange(newDate) {
        setSelectedDate(newDate);
    }

    function handleTimeRangeViewChange(newTimeRangeView: TimeRangeViewType) {
        setTimeRangeView(newTimeRangeView);
    }


    function logout() {
        firebaseApp.auth().signOut();
        setIsSidebarVisible(false);
    }




    function SideMenu() {

        console.log("render side menu");

        return (
            <div className={isSidebarVisible ? "kt-side-menu kt-white-side-menu noselect" : "kt-side-menu kt-white-side-menu kt-hide-on-mobile noselect"}>
                <div className="kt-calendar-page-left">
                    <div className="kt-top-spacer kt-hide-on-mobile"></div>
                    <DatePicker
                        firstWeekDay={1}
                        selectedDateProp={selectedDate}
                        onDateChange={handleSelectedDateChange}
                        locale={i18next.language}
                        showDateInput={false}
                    />
                    <ResourceFilter mode="calendar" showOnlyCalendars={props.mode === "notifications"} onViewChange={(newView) => setSelectedView(newView)} />

                    {(currentUser && currentUser.isAdmin) &&
                        <Link to="/clonr" className='kt-btn kt-clonr-link' onClick={() => setIsSidebarVisible(false)}>
                            <i className="far fa-video" aria-hidden="true"></i> Video ClonR&nbsp;<i className="fa fa-stars"></i>
                        </Link>
                    }

                    {(currentUser && currentUser.isAdmin) &&
                        <Link to="/settings" className='kt-btn kt-settings-link kt-hide-on-desktop' onClick={() => setIsSidebarVisible(false)}>
                            <i className="far fa-wrench" aria-hidden="true"></i> {t("components.buttons.settings")}
                        </Link>
                    }

                    <div className="kt-side-menu-spacer kt-hide-on-desktop"></div>

                    {/* <div className="kt-side-menu-item kt-side-menu-item-light kt-hide-on-desktop" onClick={() => gotoSettingsPage()}><i className="fa fa-wrench"></i> EINSTELLUNGEN</div> */}
                    <div className="kt-side-menu-logout-button kt-hide-on-desktop" onClick={() => logout()}><i className="fal fa-sign-out-alt"></i> {t("components.buttons.logout")}</div>

                </div>
            </div>
        );

    }


    function RightPanel() {

        return (
            <div id="kt-right-panel" className="kt-right-panel kt-hide-on-mobile">

                <div className="kt-header">
                    <div className="ring-container">
                        <div className="ringring"></div>
                        <div className="circle"></div>
                    </div>
                    <Link to="/notifications" ><div className="kt-label">LIVE BOT</div></Link>
                </div>

                <div className="kt-body">
                    <div id="kt-appointment-preview-panel">
                    </div>

                    <LogItemList />

                    {currentClient && currentClient.id === "MEe4ZQHEzOPzLcexyhdT" &&
                        <div
                            style={{
                                width: "180px",
                                position: "absolute",
                                bottom: "0"
                            }}
                        >
                            <ChatBot
                                avatarImageUrl="images/robotaface-small.jpg"
                            />
                        </div>
                    }
                </div>
            </div>
        );
    }

    if (calendars.length === 0 || specialities.length === 0) return null;

    console.log("render calendarPage");

    return (

        <div className="kt-page-with-side-menu kt-with-info-column">
            {/* <Steps
                enabled={introJsStepsEnabled}
                steps={introJsSteps}
                initialStep={0}
                onExit={handleOnTourExit}
                options={{
                    nextLabel: "Weiter >",
                    prevLabel: "< Zurück",
                    doneLabel: "Fertig",
                    showBullets: false,
                    disableInteraction: false,
                    hidePrev: true,
                    tooltipClass: "customTourTooltip",
                    helperElementPadding: 0,
                    exitOnOverlayClick: false
                }}
            /> */}

            <SideMenu />
            <CalendarCtrl
                appointments={appointments}
                resources={calendars.filter(c => c.license !== "disabled")}
                specialities={specialities}
                rooms={rooms}
                devices={devices}
                selectedDate={selectedDate}
                onDateChange={handleSelectedDateChange}
                onTimeRangeViewChange={handleTimeRangeViewChange}
                selectedView={selectedView}
                mode={props.mode}
            />
            <RightPanel />
        </div>
    )
}


export default CalendarPage;