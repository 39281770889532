import DateUtils from "../../utils/dateUtils";

export enum ScriptMode {
    text,
    audio
}

export type Translation = {
    language: string,
    text: string,
    audioId: string,
    audioUrl: string,
    audioLength: number, // in seconds
    audioTextHash: number, // we store the hash value of the text and the voice id we used when we generate the audio to know when we need to regenerate the audio
    status: "" | "generatingAudio" | "generatingText"
}

export default class ClonRScript {

    id: string = "";

    name: string = "";

    scriptMode: ScriptMode = ScriptMode.text;

    translations: Translation[] = [];

    defaultLanguage: string = "de";

    createdAt: Date;
    createdBy: string = ""; // userId

    constructor(defaultLang: string) {
        this.createdAt = new Date();
        
        this.defaultLanguage = defaultLang;

        const defaultLanguageTranslation: Translation = {
            language: defaultLang,
            text: "",
            audioId: "",
            audioUrl: "",
            audioLength: 0,
            audioTextHash: 0,
            status: ""
        }

        this.translations = [defaultLanguageTranslation];
    }


    clone(): ClonRScript {
        const c = new ClonRScript(this.defaultLanguage);
        c.fromObject(this.id, this.toJSON());

        return c;
    }
    
    toJSON(): object {
        return {
            id: this.id,
            name: this.name,

            scriptMode: this.scriptMode,

            translations: this.translations,
            defaultLanguage: this.defaultLanguage,

            createdAt: this.createdAt,
            createdBy: this.createdBy
        };
    }

    fromObject(id: string | null, o: any) {

        this.id = id !== null ? id : o.id;

        this.name = o.name ?? "";

        this.scriptMode = o.scriptMode ?? ScriptMode.text;
                
        this.translations = o.translations ? [...o.translations] : [];
        this.defaultLanguage = o.defaultLanguage ?? "de";

        this.createdAt = DateUtils.getDate(o.createdAt) ?? new Date();

        this.createdBy = o.createdBy ?? "";

    }

    getDefaultLanguageTranslation(): Translation {
        const transl = this.translations.find(t => t.language === this.defaultLanguage);

        if(transl){
            return transl;
        }

        return {
            language: this.defaultLanguage,
            text: "",
            audioId: "",
            audioUrl: "",
            audioLength: 0,
            audioTextHash: 0,
            status: ""
        }

    }

    getTranslationByLanguagKey(key: string): Translation | undefined {
        return this.translations.find(t => t.language === key)
    }

    getTranslationTextByLanguagKey(key: string): string {
        const transl = this.translations.find(t => t.language === key);
        return transl ? transl.text : "";
    }

}