import FormItem, { FormItemEnum, FormItemType, InputFormItemInterface, InputUserType } from "./formItem";
import FormRow from "./formRow";


export class SignatureFormItem extends FormItem implements InputFormItemInterface {

    required: boolean = true;

    signature: string = ""; // base64 image

    inputUser: InputUserType = "patient";

    autoSign: boolean = false; // if "inputuser"" is "doctor" then we can fill out the signature by the user settings

    constructor(rowId: string) {
        super(rowId);

        this.type = FormItemEnum.signature;
        this.setLanguageProperty("de", "Unterschrift", "labels");

    }

    clone(): FormItemType {

        const c = new SignatureFormItem(this.parentId);
        c.fromObject(null, this.toJSON(true));

        return c;
    }

    toJSON(includeUserInputs: boolean): object {
        const obj: any = super.toJSON(includeUserInputs);

        obj.required = this.required;

        obj.inputUser = this.inputUser;

        obj.autoSign = this.autoSign;

        if(includeUserInputs){
            obj.signature = this.signature;
        }

        return obj;
    }

    fromObject(id: string | null, o: any) {
        super.fromObject(id, o);

        this.required = o.required === true;

        this.signature = o.signature ?? "";

        this.inputUser = o.inputUser ?? "patient";

        this.autoSign = o.autoSign === true;
    }




    static getAllSignatures(formRows: FormRow[]): SignatureFormItem[] {
        let result: SignatureFormItem[] = [];


        for (let r = 0; r < formRows.length; r++) {
            const formRow = formRows[r];

            for (let c = 0; c < formRow.columns.length; c++) {
                const column = formRow.columns[c];

                if(column.type === FormItemEnum.signature){
                    result.push(column as SignatureFormItem);

                } else if (column.answers) {
                    for (let a = 0; a < column.answers.length; a++) {
                        const answer = column.answers[a];
                        result = result.concat(SignatureFormItem.getAllSignatures(answer.formRows));
                    }

                }
            }
        }

        return result;
    }

    static getAllUnsignedSignaturesBySigner(signer: "patient" | "doctor" | "other",  formRows: FormRow[]): SignatureFormItem[] {
        const result: SignatureFormItem[] = [];

        let signatures = SignatureFormItem.getAllSignatures(formRows);

        for (let s = 0; s < signatures.length; s++) {
            const signature = signatures[s];

            if(signature.inputUser === signer && signature.signature.length < 8000){
                result.push(signature);
            }
        }

        return result;
    }

}


export default SignatureFormItem;