import Paper from '@mui/material/Paper';
import React from 'react';
import ClonRVoice from '../../shared/src/models/clonR/clonRVoice';
import ClonRVoiceCardCtrl from './clonRVoiceCardCtrl';


interface Props {
    className?: string,
    ownVoices: ClonRVoice[],
    publicVoices: ClonRVoice[],
    selectedClonRVoice: ClonRVoice,
    isPlayingVoiceAudio: boolean,
    showSelectionBorder: boolean,
    onSelectionChange: (clonRVoice: ClonRVoice) => void,
    handleStopVoiceClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    handlePlayVoiceClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, voice: ClonRVoice) => void
}


const ClonRVoiceListCtrl: React.FC<Props> = ({
    className,
    ownVoices,
    publicVoices,
    selectedClonRVoice,
    showSelectionBorder,
    isPlayingVoiceAudio,
    onSelectionChange,
    handleStopVoiceClick,
    handlePlayVoiceClick }) => {

    return (
        <div className={`kt-clonr-voice-list-ctrl ${className ?? ""}`}>
            <Paper className='kt-paper kt-voices-list-wrapper'>
                <h3 style={{ width: "100%" }}>Eigene Stimmen ({ownVoices.length})</h3>
                <div className='kt-voices-list'>
                    {ownVoices.map(v =>
                        <ClonRVoiceCardCtrl
                            key={v.id}
                            voice={v}
                            selectedClonRVoice={selectedClonRVoice}
                            showSelectionBorder={showSelectionBorder}
                            isPlayingVoiceAudio={isPlayingVoiceAudio}
                            onSelectionChange={onSelectionChange}
                            handleStopVoiceClick={handleStopVoiceClick}
                            handlePlayVoiceClick={handlePlayVoiceClick}
                        />
                    )}
                </div>

                <h3 style={{ width: "100%" }}>Bibliothek ({publicVoices.length})</h3>
                <div className='kt-voices-list'>
                    {publicVoices.map(v =>
                        <ClonRVoiceCardCtrl
                            key={v.id}
                            voice={v}
                            selectedClonRVoice={selectedClonRVoice}
                            showSelectionBorder={showSelectionBorder}
                            isPlayingVoiceAudio={isPlayingVoiceAudio}
                            onSelectionChange={onSelectionChange}
                            handleStopVoiceClick={handleStopVoiceClick}
                            handlePlayVoiceClick={handlePlayVoiceClick}
                        />
                    )}
                </div>
            </Paper>
        </div>
    );
}

export default ClonRVoiceListCtrl;