import { useState, useEffect, useContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import InvoicesService from "../../../services/invoicesService";

import LoadingPage from "../administration/loadingPage";
import BackButton from "../../backButton";
import Invoice, { InvoiceStatus } from "../../../../src/shared/src/models/invoice/invoice";
import { DataGrid, GridSortDirection, deDE } from '@mui/x-data-grid'
import Utils from '../../../../src/shared/src/utils/utils';
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { GlobalContext } from "../../../GlobalContext";


const moment = require("moment");



function InvoicePage() {

    const navigate = useNavigate();

    const { invoiceId } = useParams();

    const [invoice, setInvoice] = useState<Invoice | null>(null);
    const [invoices, setInvoices] = useState<Invoice[]>([]);

    const [isLoading, setIsLoading] = useState(false);
    const {currentClient} = useContext(GlobalContext);
    const {currentUser} = useContext(GlobalContext);

    const sortModel = [
        {
            field: 'createdAt',
            sort: 'desc' as GridSortDirection
        }
    ];

    useEffect(() => {

        if (invoiceId && currentClient) {
            updateInvoiceDetails(invoiceId);

            updateInvoicesList();
        }

        // eslint-disable-next-line
    }, [invoiceId, currentClient]);



    async function updateInvoiceDetails(invId: string) {

        if (!currentUser) {
            return;
        }

        try {
            const cId = currentClient.id;

            setIsLoading(true);

            if (invId && invId.toLowerCase() === "current" && cId) {

                const now = new Date();
                const result = await InvoicesService.calculateInvoice(currentClient.id, currentUser.locationId, now.getFullYear(), now.getMonth());

                if (result) {
                    setInvoice(result);
                }


            } else if(invoiceId) {

                const result = await InvoicesService.getInvoice(currentClient.id, currentUser.locationId, invoiceId);

                if (result) {
                    setInvoice(result);
                }
            }


            setIsLoading(false);

        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }

    async function updateInvoicesList() {
        if (currentUser) {
            const result = await InvoicesService.getInvoices(currentClient.id, currentUser.locationId);
            if (result) {
                setInvoices(result);
            }
        }
    }

    function getHeadline(): string {
        if (invoiceId && invoiceId.toLowerCase() === "current") {

            const mom = new moment();

            return `Kosten des laufenden Monats ${mom.format("MMMM YYYY")}`;

        } else if (invoice) {
            const mom = new moment(new Date(invoice.forYear, invoice.forMonth));

            return `Rechnung ${mom.format("MMMM YYYY")}`;

        } else {
            return ``;
        }
    }


    function renderInvoiceCosts() {
        return (

            <Paper
                style={{
                    padding: 8
                }}
            >

                {(invoiceId && invoiceId.toLowerCase() !== "current" && invoice) &&
                    <>
                        <h4>RechnungsNr.: {invoice.invoiceNumber}</h4>
                        <span>Erstellt am {invoice.createdAt.toLocaleString()}</span>

                        {invoice.status === InvoiceStatus.paid &&
                            <h4>Status: {getStatusIcon(invoice)} <strong>BEZAHLT</strong></h4>
                        }

                        {(invoice.calculateInvoiceStatus() === InvoiceStatus.unpaid) &&
                            <>
                                <h4>Status: {getStatusIcon(invoice)} <strong>OFFEN</strong> Zahlungsziel: {invoice.dueDate.toLocaleDateString()}</h4>
                            </>
                        }

                        {(invoice.calculateInvoiceStatus() === InvoiceStatus.overdue) &&
                            <>
                                <h4>Status: {getStatusIcon(invoice)} <strong>ÜBERFÄLLIG</strong> Zahlungsziel: {invoice.dueDate.toLocaleDateString()}</h4>
                            </>
                        }

                    </>

                }

                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nr.</TableCell>
                                <TableCell>Position</TableCell>
                                <TableCell align="right">
                                    Menge
                                </TableCell>
                                <TableCell align="right">
                                    Preis
                                </TableCell>
                                <TableCell align="right">
                                    Summe
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {invoice!.getInvoiceItems()
                                .map((item, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{item.getTitle()}</TableCell>
                                            <TableCell align="right">{item.quantity} </TableCell>
                                            <TableCell align="right">
                                                {" "}
                                                {(item.price).toFixed(2)}{" "}
                                            </TableCell>
                                            <TableCell align="right">
                                                {(item.total).toFixed(2)}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}

                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell align="right">
                                    <strong>Zwischensumme</strong>
                                </TableCell>
                                <TableCell align="right">
                                    {invoice!.getSubTotalAfterDiscount().toFixed(2)}{" €"}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell align="right">
                                    {invoice!.taxRate}% MwSt.
                                </TableCell>
                                <TableCell align="right">
                                    {invoice!.getTaxes().toFixed(2)}{" €"}
                                </TableCell>
                            </TableRow>


                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell align="right">
                                    <strong>Summe</strong>
                                </TableCell>
                                <TableCell align="right">
                                    {invoice!.getTotal().toFixed(2)}{" €"}
                                </TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        );
    }

    function getStatusIcon(_invoice: Invoice) {

        if (_invoice) {

            switch (_invoice.calculateInvoiceStatus()) {
                case InvoiceStatus.paid:
                    return <i className="fal fa-check kt-green-color"></i>

                case InvoiceStatus.overdue:
                    return <i className="fas fa-exclamation-triangle kt-red-color"></i>

                default:
                    return <i className="fal fa-exclamation-circle kt-orange-color"></i>
            }
        }
    }

    async function showInvoicePDF(_invoice: Invoice) {
        if (_invoice) {
            const url = await InvoicesService.getInvoicePDFUrl(_invoice.clientId, _invoice.locationId, _invoice.id);

            if (url) {
                window.open(url, '_blank')?.focus();
            }
        }
    }

    function renderInvoicesList() {
        if (invoices.length > 0) {

            const columns: any[] = [
                {
                    field: 'forYearMonth', headerName: 'Rechnungszeitraum', width: 300, renderCell: (params) => (
                        <Link to={`/settings/invoice/${params.row.id}`}>{params.row.forYear}-{Utils.getWithLeadingZero(parseInt(params.row.forMonth) + 1)}</Link>
                    )
                },
                {
                    field: 'invoiceNumber', headerName: 'RechnungsNr.', width: 300, renderCell: (params) => (
                        <Link to={`/settings/invoice/${params.row.id}`}>{params.row.invoiceNumber}</Link>
                    )
                },
                {
                    field: 'status', headerName: 'Status', width: 200, renderCell: (params) => (
                        <span>{getStatusIcon(params.row)} {params.row.getStatusLabel()}</span>
                    )
                },
                {
                    field: 'createdAt', headerName: 'Erstellt am', width: 200, renderCell: (params) => (
                        <span>{params.row.createdAt.toLocaleDateString()}</span>
                    )
                },
                {
                    field: 'dueDate', headerName: 'Fällig am', width: 200, renderCell: (params) => (
                        <span>{params.row.dueDate.toLocaleDateString()}</span>
                    )
                },
                {
                    field: 'total', headerName: 'Summe', width: 130, renderCell: (params) => (
                        <span>{params.row.total.toFixed(2)}€</span>
                    )
                },
                {
                    field: 'download', headerName: " ", width: 64, renderCell: (params) => (
                        <IconButton onClick={(e) => showInvoicePDF(params.row)}>
                            <i className="fa fa-download"></i>
                        </IconButton>)
                }
            ];


            return (
                <>
                    <br />
                    <h2>Ihre Rechnungen</h2>
                    <Box className="kt-data-grid-container">
                        <DataGrid
                            rows={invoices}
                            columns={columns}
                            sortModel={sortModel}
                            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </Box>
                </>
            );
        }
    }


    if (isLoading) return <LoadingPage />;

    if (!invoice) return (
        <Box className="kt-page">
            <h2>Rechnung</h2>

            <div>Diese Rechnung existiert nicht. Bitte überprüfen Sie Ihre URL.</div>
        </Box>
    );


    return (
        <Box className="kt-page">
            <h2>{getHeadline()}</h2>

            {renderInvoiceCosts()}

            {(invoiceId && invoiceId.toLowerCase() !== "current") &&
                <Button
                    style={{marginTop: "20px"}}
                    variant="contained"
                    onClick={() => navigate("/settings/invoice/current")}
                >
                    ZUM AKTUELLEN MONAT
                </Button>
            }

            {renderInvoicesList()}

            <div className="kt-page-footer kt-margin-top-20">
                <BackButton />
            </div>

        </Box>
    );
}

export default InvoicePage;