import firebase from 'firebase/compat/app';
import firebaseApp from "./../components/database";

import PDocument from "../../src/shared/src/models/formEditor/pdocument";
import { getFileExtension } from "../utils";
import Patient from "../../src/models/patient";
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';


const db = firebaseApp.firestore();
const functions = firebase.app().functions('europe-west3');

export type PatientDocumentsModeData = {
    patient: Patient,
    clientLogoUrl: string,
    documents: PDocument[]
}

const DocumentsService = {

    async getDocumentTemplates(clientId: string, locationId: string): Promise<PDocument[] | null> {

        const pdocuments: PDocument[] = [];

        try {

            if (clientId && locationId) {
                const querySnapshot = await db.collection("clients").doc(clientId)
                    .collection("locations").doc(locationId)
                    .collection("pdocuments")
                    .get();


                querySnapshot.forEach((doc) => {

                    const pdocument = new PDocument();
                    pdocument.fromObject(doc.id, doc.data());

                    pdocuments.push(pdocument);
                });

            }

            pdocuments.sort((a,b) => a.cardinality > b.cardinality ? 1 : -1);

            return pdocuments;

        } catch (error) {
            console.log("Error getting document templates: ", error);
            return null;
        }

    },

    async getDocumentTemplate(clientId: string, locationId: string, pdocumentId: string): Promise<PDocument | null> {

        if (!pdocumentId) return null;

        const doc = await db.collection("clients").doc(clientId)
            .collection("locations").doc(locationId)
            .collection("pdocuments").doc(pdocumentId)
            .get();

        try {

            if (doc.exists) {

                const pdocument = new PDocument();
                pdocument.fromObject(pdocumentId, doc.data());

                return pdocument;

            } else {
                console.log("getDocumentTemplate: No such document: " + pdocumentId);
                return null;
            }

        } catch (error) {
            console.log("Error getting document template: ", error);
            return null;
        };

    },



    async updateDocumentTemplate(pdocument: PDocument, clientId: string, locationId: string): Promise<string | null> {

        try {

            if (pdocument.id) {
                await db.collection("clients").doc(clientId)
                    .collection("locations").doc(locationId)
                    .collection("pdocuments").doc(pdocument.id)
                    .set(pdocument.toJSON(false), { merge: true });

                return pdocument.id;

            } else {
                // create a new PDocument
                const docRef = await db.collection("clients").doc(clientId)
                    .collection("locations").doc(locationId)
                    .collection("pdocuments")
                    .add(pdocument.toJSON(false));

                return docRef.id;
            }


        } catch (error) {
            console.log("Error updating document template: ", error);
        };

        return null;
    },


    async cloneDocumentTemplate(pdocumentId: string, clientId: string, locationId: string): Promise<string | null> {

        try {
            const doc = await DocumentsService.getDocumentTemplate(clientId, locationId, pdocumentId);

            if (doc) {
                doc.id = ""; // remove id so "updateLocationDocument" will save the doc in the db with a new id
                doc.name += " Kopie";

                const newId = await DocumentsService.updateDocumentTemplate(doc, clientId, locationId);

                // now copy all resources of the document
                if (newId !== null) {
                    const copyFolder = functions.httpsCallable('copyFolder');
                    await copyFolder(
                        {
                            sourcePath: `clients/${clientId}/locations/${locationId}/documents/${pdocumentId}`,
                            destinationPath: `clients/${clientId}/locations/${locationId}/documents/${newId}`
                        }
                    );

                }
            }

        } catch (error) {
            console.log("Error cloning document template: ", error);
        };

        return null;
    },

    async deleteDocumentTemplate(pdocumentId: string, clientId: string, locationId: string): Promise<void> {

        try {
            const documentPath = `clients/${clientId}/locations/${locationId}/pdocuments/${pdocumentId}`;
            await db.doc(documentPath).delete();

            const deleteFolder = functions.httpsCallable('deleteFolder');
            await deleteFolder(
                {
                    pathToDelete: `clients/${clientId}/locations/${locationId}/documents/${pdocumentId}`
                }
            );

        } catch (error) {
            console.log("Error deleting document template: ", error);
        };
    },

    async uploadTemplateFile(fileId: string, file: File, clientId: string, locationId: string, documentId: string): Promise<string> {

        try {

            let downloadUrl = "";

            const fileExtension = getFileExtension(file.name);
            const storage = getStorage();

            const storageRef = ref(storage, `clients/${clientId}/locations/${locationId}/documents/${documentId}/${fileId}.${fileExtension}`);
            await uploadBytes(storageRef, file);

            downloadUrl = await getDownloadURL(storageRef);

            return downloadUrl;

        } catch (error) {
            console.error("Error uploading template file: ", error);
            return "";
        }
    },

    async getPatientDocumentsModeData(clientId: string, locationId: string, patientId: string, patientVerification: string): Promise<{data:PatientDocumentsModeData | null | string, success:boolean,error:string}> {

        try {

            const getPatientDocumentsModeDataFunc = functions.httpsCallable("getPatientDocumentsModeData");
            const result = await getPatientDocumentsModeDataFunc(
                {
                    clientId: clientId,
                    locationId: locationId,
                    patientId: patientId,
                    patientVerification: patientVerification
                });

            if(result){

                const resData = JSON.parse(result.data);

                const patient = new Patient();
                patient.fromObject(patientId, resData.patient);

                resData.patient = patient;

                const docs: PDocument[] = [];

                for (let i = 0; i < resData.documents.length; i++) {
                    const doc = resData.documents[i];

                    const newDoc = new PDocument();
                    newDoc.fromObject(null, doc);

                    docs.push(newDoc);
                }
                resData.documents = docs;

                return {data: resData, success: true, error: ""};
            } else {
                return {data: null, success: false, error: "unknown"};
            }


        } catch (err) {
            if(err && (err as any).code){
                return {data: (err as any).message, success: false, error: (err as any).code};
            } else {
                return {data: null, success: false, error: "unknown"};
            }

        }
    }

}

export default DocumentsService;