import React, { useState, useEffect } from 'react';
import moment from 'moment';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DateUtils from '../../src/shared/src/utils/dateUtils';


interface Props {
    startDate: Date;
    endDate: Date;
    onValueChange: (newEndDate:Date) => void
}


const DurationPicker:React.FC<Props> = ({startDate, endDate, onValueChange}) => {


    const [duration, setDuration] = useState(15);

    useEffect(() => {

        const d = getDuration();

        if(!isNaN(d) && d !== 0) {
            setDuration(d);
        }


    }, [startDate, endDate]);

    function handleInputChange(event) {
        const target = event.target;
        const value = target.value;

        const startD = DateUtils.getDate(startDate);
        const end = moment(startD).add(value, 'm').toDate();

        onValueChange(end);
    }


    function getDuration(): number {

        if (startDate && endDate) {

            var startD = DateUtils.getDate(startDate);
            var endD = DateUtils.getDate(endDate);

            return DateUtils.getDiffInMinutes(startD, endD);
        }

        return 15;
    }



    return (
        <FormControl fullWidth>
            <InputLabel id="duration-select-label">Dauer</InputLabel>
            <Select
            name="duration"
            labelId="duration-select-label"
            label="Dauer"
            id="duration-select"
            value={duration}
            onChange={handleInputChange}
            >
                <MenuItem value="5">5 min</MenuItem>
                <MenuItem value="10">10 min</MenuItem>
                <MenuItem value="15">15 min</MenuItem>
                <MenuItem value="20">20 min</MenuItem>
                <MenuItem value="25">25 min</MenuItem>
                <MenuItem value="30">30 min</MenuItem>
                <MenuItem value="35">35 min</MenuItem>
                <MenuItem value="40">40 min</MenuItem>
                <MenuItem value="45">45 min</MenuItem>
                <MenuItem value="55">55 min</MenuItem>
                <MenuItem value="60">1 Std. 00</MenuItem>
                <MenuItem value="75">1 Std. 15</MenuItem>
                <MenuItem value="90">1 Std. 30</MenuItem>
                <MenuItem value="105">1 Std. 45</MenuItem>
                <MenuItem value="120">2 Std. 00</MenuItem>
                <MenuItem value="135">2 Std. 15</MenuItem>
                <MenuItem value="150">2 Std. 30</MenuItem>
                <MenuItem value="165">2 Std. 45</MenuItem>
                <MenuItem value="180">3 Std. 00</MenuItem>
                <MenuItem value="195">3 Std. 15</MenuItem>
                <MenuItem value="210">3 Std. 30</MenuItem>
                <MenuItem value="225">3 Std. 45</MenuItem>
                <MenuItem value="240">4 Std. 00</MenuItem>
                <MenuItem value="270">4 Std. 30</MenuItem>
                <MenuItem value="300">5 Std. 00</MenuItem>
                <MenuItem value="360">6 Std. 00</MenuItem>
                <MenuItem value="420">7 Std. 00</MenuItem>
                <MenuItem value="480">8 Std. 00</MenuItem>
            </Select>
        </FormControl>

    );
}

export default DurationPicker;
