import Button from "@mui/material/Button";
import React, { useState, useRef, useEffect } from "react";


const mimeType = "audio/mp4";

export enum AudioRecorderStatus {
    inactive,
    recording,
    finished,
    error
}

interface Props {
    audioUrl: string
    onStatusChanged?: (newStatus: AudioRecorderStatus) => void
    onFinishedRecording: (audioUrl: string, audioBlob: Blob) => void
}

const AudioRecorderCtrl: React.FC<Props> = ({ audioUrl, onStatusChanged, onFinishedRecording }) => {
    const stream = useRef<any>();

    const mediaRecorder = useRef<MediaRecorder | null>(null);

    const [permission, setPermission] = useState(false);
    const [recordingStatus, setRecordingStatus] = useState(AudioRecorderStatus.inactive);
    const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
    const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
    const [recordedAudioUrl, setRecordedAudioUrl] = useState<string>("");


    useEffect(() => {

        return () => {
            closeStreams();
        }

    }, []);

    useEffect(() => {
        if (onStatusChanged) onStatusChanged(recordingStatus);

        if (onFinishedRecording && recordingStatus === AudioRecorderStatus.finished && audioBlob) {

            if(recordedAudioUrl){
                onFinishedRecording(recordedAudioUrl, audioBlob);
            } else {
                onFinishedRecording(audioUrl, audioBlob);
            }

            //releaseStream();

        }

    }, [recordingStatus]);

    const closeStreams = () => {
        if (stream.current) {
            stream.current.getTracks().forEach(function (track) {
                track.stop();
            });
        }
    }

    const resetRecorder = () => {
        setRecordingStatus(AudioRecorderStatus.inactive);
        stream.current = null;
    }


    const getMicrophonePermission = async (): Promise<{ _permission: boolean, _stream: MediaStream | null }> => {
        if ("MediaRecorder" in window) {
            try {

                console.log("getMicrophonePermission");

                const streamData = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video: false,
                });
                setPermission(true);
                stream.current = streamData;

                return { _permission: true, _stream: streamData }

            } catch (err) {
                alert((err as any).message);
            }
        } else {
            alert("Ihr Browser unterstützt keine Audioaufnahmen.");
        }

        setRecordingStatus(AudioRecorderStatus.error);

        return { _permission: false, _stream: null }
    };

    const startRecording = async () => {

        let _permission = permission;

        if (!_permission || !stream) {
            const result = await getMicrophonePermission();
            _permission = result._permission;
        }

        if (_permission && stream.current) {
            setRecordingStatus(AudioRecorderStatus.recording);

            try {
                //create new Media recorder instance using the stream
                const media = new MediaRecorder(stream.current);

                //set the MediaRecorder instance to the mediaRecorder ref
                mediaRecorder.current = media;

                //invokes the start method to start the recording process
                mediaRecorder.current.start();
                let localAudioChunks: Blob[] = [];
                mediaRecorder.current.ondataavailable = (event: BlobEvent) => {
                    if (typeof event.data === "undefined") return;
                    if (event.data.size === 0) return;
                    localAudioChunks.push(event.data);
                };
                setAudioChunks(localAudioChunks);

            } catch (err) {
                closeStreams();
                console.error(err);
            }

        }

    };

    const stopRecording = () => {

        if (mediaRecorder && mediaRecorder.current) {
            //stops the recording instance
            mediaRecorder.current.stop();
            mediaRecorder.current.onstop = () => {
                //creates a blob file from the audiochunks data
                const _audioBlob = new Blob(audioChunks, { type: mimeType });
                //creates a playable URL from the blob file.
                const _audioUrl = URL.createObjectURL(_audioBlob);
                setRecordedAudioUrl(_audioUrl);
                setAudioBlob(_audioBlob)
                setAudioChunks([]);

                setRecordingStatus(AudioRecorderStatus.finished);
            };

        }

    };

    return (
        <div className="kt-audio-recorder">
            <main>
                <div style={{textAlign: "center"}}>

                    {(audioUrl === "" && recordingStatus === AudioRecorderStatus.inactive) ? (
                        <Button variant="outlined" onClick={startRecording} type="button">
                            <i className="fal fa-microphone"></i>&nbsp;AUFNAHME STARTEN
                        </Button>
                    ) : null}

                    {recordingStatus === AudioRecorderStatus.recording ? (
                        <div style={{ textAlign: "center" }}>
                            <div className="kt-pulse"></div>
                            <div>Audio wird aufgenommen</div>

                            <Button variant="outlined" onClick={stopRecording} type="button" style={{ marginTop: "20px" }}>
                                <i className="fas fa-stop"></i>&nbsp;AUFNAHME BEENDEN
                            </Button>
                        </div>

                    ) : null}
                </div>

                {(audioUrl && recordingStatus !== AudioRecorderStatus.recording) ? (
                    <div style={{textAlign: "center"}}>
                        <div className="audio-container">
                            <audio src={audioUrl} controls></audio>
                        </div>
                        <Button variant="outlined" onClick={startRecording} type="button">
                            <i className="fal fa-microphone"></i>&nbsp;Erneut aufnehmen
                        </Button>
                    </div>
                ) : null}
            </main>
        </div>
    );
};


export default AudioRecorderCtrl;