import { useState, useEffect, useContext, useRef } from 'react';

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { debounce } from "../../utils";
import PatientsService from "../../services/patientsService";
import Patient from '../../models/patient';

import { DataGrid, deDE, elGR, enUS, esES, frFR, zhCN } from '@mui/x-data-grid'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from "@mui/icons-material/Delete";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import SearchIcon from "@mui/icons-material/Search";
import DateUtils from '../../../src/shared/src/utils/dateUtils';
import PatientDialog from '../dialogs/patientDialog/patientDialog';
import Utils from '../../../src/shared/src/utils/utils';
import StarsCtrl from '../starsCtrl';
import Link from '@mui/material/Link';
import { GlobalContext } from '../../GlobalContext';
import i18next, { t } from 'i18next';
import { getDataGridLocaleText } from '../dataGridLocaleText';

function PatientsPage() {

    const currentLocaleText = getDataGridLocaleText();

    const {currentLocation} = useContext(GlobalContext);

    const [patients, setPatients] = useState<Patient[]>([]);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState("");
    const [searchTag, setSearchTag] = useState("");

    const {currentUser} = useContext(GlobalContext);

    const [isPatientDialogVisible, setIsPatientDialogVisible] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(new Patient());

    useEffect(() => {
        if(filter !== "") {
            updatePatientsListDebounced(filter, "");
        } else if(searchTag !== "") {
            updatePatientsListDebounced("", searchTag)
        } else {
            updatePatientsListDebounced("");
        }

        // eslint-disable-next-line
    }, [filter, searchTag]);

    async function getPatientsData(_filter, _tag) {

        if (currentUser) {
            const res = await PatientsService.getAllPatients(_filter, _tag, currentUser.clientId, currentUser.locationId);
            setPatients(res ? res : []);
            setPage(0);
        }
    }

    const updatePatientsListDebounced = useRef(debounce((_filter, _tag) => getPatientsData(_filter, _tag), 400)).current;

    function onFilterChanged(e) {
        const newFilter = e.target.value;
        setFilter(newFilter);

        setSearchTag("");
    }

    async function onDeleteRowClick(patient: Patient) {
        if (window.confirm(t("pages.patientsPage.reallyDeletePatient", {firstName: patient.firstName, lastName: patient.lastName})) && currentUser) {
            await PatientsService.deletePatient(patient.id, currentUser.clientId, currentUser.locationId);
            updatePatientsListDebounced(filter);
        }
    }

    function handlePatientRowClick(patient: Patient) {
        setSelectedPatient(patient);
        setIsPatientDialogVisible(true);
    }

    function handleCreatePatientClick() {
        setSelectedPatient(new Patient());
        setIsPatientDialogVisible(true);
    }

    function handleSearchTagChange(e) {
        const val = e.target.value === "none" ? "" : e.target.value as string;
        setSearchTag(val);

        if(val !== "") {
            setFilter("");
        }
    }

    function getLastAppointmentDateString(lastAppointmentDate: Date) {
        const result = DateUtils.getDateString(lastAppointmentDate);

        if(result.indexOf("1900") !== -1) return "";

        return result;
    }

    const columns: any[] = [
        {
            field: "gender", headerName: t("pages.patientsPage.salutation"), width: 100, renderCell: (params) => (
                <span>{Utils.getGenderString(params.row.gender)}</span>
            )
        },
        {
            field: "firstName", headerName: t("pages.patientsPage.firstName"), width: 200, renderCell: (params) => (
                <Link onClick={() => handlePatientRowClick(params.row)} color="textPrimary" className="kt-underline">{params.row.firstName}</Link>
            )
        },
        {
            field: "lastName", headerName: t("pages.patientsPage.lastName"), width: 200, renderCell: (params) => (
                <Link onClick={() => handlePatientRowClick(params.row)} color="textPrimary" className="kt-underline">{params.row.lastName}</Link>
            )
        },
        {
            field: "phoneNumber", headerName: t("pages.patientsPage.phoneNumber"), width: 200, renderCell: (params) => (
                <span>{params.row.getMobileOrPhoneNumber()}</span>
            )
        },
        {
            field: "birthDate", headerName: t("pages.patientsPage.birthdate"), width: 200, renderCell: (params) => (
                <span>{DateUtils.getBirthdayString(params.row.birthDate)}</span>
            )
        },
        {
            field: "privateInsurance", headerName: t("pages.patientsPage.privateInsurance"), width: 140, renderCell: (params) => (
                <span>{params.row.privateInsurance ? <CheckRoundedIcon /> : null}</span>
            )
        },
        {
            field: "score", headerName: t("pages.patientsPage.stars"), width: 100, renderCell: (params) => (
                <StarsCtrl rating={params.row.score} showNumber={false} fontSize='12px' />
            )
        },
        { field: "comments", headerName: t("pages.patientsPage.notes"), width: 200 },
        { field: "importId", headerName: t("pages.patientsPage.importId"), width: 150 },
        {
            field: "lastAppointmentDate", headerName: t("pages.patientsPage.lastAppointment"), width: 130, renderCell: (params) => (
                <span>{getLastAppointmentDateString(params.row.lastAppointmentDate)}</span>
            )
        },
        {
            field: 'delete', headerName: " ", width: 64, renderCell: (params) => (
                <IconButton onClick={(e) => onDeleteRowClick(params.row)}>
                    <DeleteIcon />
                </IconButton>)
        }
    ];


    return (

        <>
            <PatientDialog
                visible={isPatientDialogVisible}
                patient={selectedPatient}
                onClose={() => setIsPatientDialogVisible(false)}
                onSave={() => setIsPatientDialogVisible(false)}
            />

            <Box className="kt-page">

                <h2>{t("pages.patientsPage.title")}</h2>

                <Grid container alignItems="center">
                    <Grid item>
                        <Button
                            onClick={handleCreatePatientClick}
                            variant="outlined"
                            startIcon={<AddCircleOutlineIcon />}
                        >
                            {t("pages.patientsPage.createNewPatient")}
                        </Button>
                    </Grid>

                    <Grid item>

                        <TextField
                            size="small"
                            id="input-with-icon-textfield"
                            label={t("pages.patientsPage.searchPatient")}
                            value={filter}
                            onChange={onFilterChanged}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                spellCheck: false
                            }}
                        />

                    </Grid>

                    <Grid item>
                        {(currentLocation && currentLocation.searchTags.length > 0) && <>
                            <FormControl size="small" style={{ minWidth: "200px" }}>
                                <InputLabel id="tag-select-label">{t("pages.patientsPage.searchTag")}</InputLabel>
                                <Select
                                    labelId="tag-select-label"
                                    label={t("pages.patientsPage.searchTag")}
                                    value={searchTag === "" ? "none" : searchTag}
                                    displayEmpty
                                    onChange={handleSearchTagChange}
                                    required
                                >
                                    <MenuItem value="none">{t("pages.patientsPage.none")}</MenuItem>
                                    {currentLocation.searchTags.map((tag, index) => <MenuItem key={index} value={tag}>{tag.toUpperCase()}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </>}
                    </Grid>
                </Grid>

                <Box className="kt-data-grid-container">
                    <DataGrid
                        pagination
                        rows={patients}
                        columns={columns}
                        localeText={currentLocaleText}
                    />
                </Box>

            </Box>

        </>

    );

}

export default PatientsPage;