
import React, {useContext, useState} from "react";

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';

import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import ImageFormItem from "../../../src/shared/src/models/formEditor/imageFormItem";
import { FormItemCtrlProps } from "./formItemCtrl";
import { Align } from "../../../src/shared/src/models/formEditor/formItem";
import ImageDrawDialog from "../dialogs/imageDrawDialog";
import FileUploadsService from "../../../src/services/fileUploadsService";
import ButtonGroup from "@mui/material/ButtonGroup";
import { GlobalContext } from "../../GlobalContext";


interface Props extends FormItemCtrlProps {
    item: ImageFormItem
}

const ImageFormItemCtrl: React.FC<Props> = (
    {
        patientId,
        documentId,
        item,
        languageKey,
        handleOnInputChange,
        handleOnSignatureDialogStateChange,
        formRows,
        onFormChange,
        selectedItem,
        setSelectedItem,
        renderMode,
        formUser
    }) => {


    const [isDialogVisible, setIsDialogVisible] = useState(false);

    const {currentUser} = useContext(GlobalContext);

    async function handleOnImageDrawDialogSave(newBase64Image: string) {

        if(newBase64Image) {
            if(renderMode === "editor") {
                handleOnInputChange(item, newBase64Image, "imageSourceModified");

            } else if( renderMode === "final" && currentUser) {
                // convert the base64 string to a file, because its too large to save it in the db
                const fileName = `m-${item.id}.png`;
                const targetLocation = `clients/${currentUser.clientId}/locations/${currentUser.locationId}/patients/${patientId}/documents/${documentId}`;
                const imageUrl = await FileUploadsService.uploadBase64ImageToFile(newBase64Image, fileName, targetLocation);

                if(imageUrl) {
                    handleOnInputChange(item, imageUrl, "imageSourceModified");
                }
            }
        }

        setIsDialogVisible(false)
    }

    function renderHtml(): JSX.Element {

        const textAlignStrings: any = ["left", "center", "right"];
        const textAlignString = textAlignStrings[item.imageAlign];


        if (item.imageSource) {
            return (
                <div style={{ textAlign: textAlignString }}>
                    <img
                        style={{ width: item.imageWidth + "px" }}
                        src={item.imageSource}
                        alt=""
                    />
                </div>
            );

        } else {
            return (
                <div style={{ textAlign: textAlignString }}>
                    <img
                        style={{ width: item.imageWidth + "px" }}
                        src="../../images/image-placeholder.svg"
                        alt=""
                    />
                </div>
            );
        }


    }

    function renderProperties(): JSX.Element {
        return (
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <strong>Bild</strong>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            component="label"
                            style={{color: "white"}}
                            startIcon={<i className='fal fa-image'></i>}
                        >
                            Bild wählen
                            <input
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={(e) => handleOnInputChange(item, null, "imageSource", e.target.files)}
                            />
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            type="number"
                            inputProps={{ min: "1", max: "600", step: "1" }}
                            label="Breite"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">px</InputAdornment>,
                            }}
                            value={item.imageWidth}
                            onChange={(e) => handleOnInputChange(item, e.target.value, "imageWidth")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonGroup variant="outlined" size="small">
                            <Button
                                startIcon={<FormatAlignLeftIcon />}
                                variant={item.imageAlign === Align.left ? "contained" : "outlined"}
                                onClick={(e) => handleOnInputChange(item, Align.left, "imageAlign")}
                            />
                            <Button
                                startIcon={<FormatAlignCenterIcon />}
                                variant={item.imageAlign === Align.center ? "contained" : "outlined"}
                                onClick={(e) => handleOnInputChange(item, Align.center, "imageAlign")}
                            />
                            <Button
                                startIcon={<FormatAlignRightIcon />}
                                variant={item.imageAlign === Align.right ? "contained" : "outlined"}
                                onClick={(e) => handleOnInputChange(item, Align.right, "imageAlign")}
                            />
                        </ButtonGroup>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel
                            label="Zeichnen erlauben"
                            control={
                                <Checkbox
                                    checked={item.allowDrawing}
                                    onChange={(e) => handleOnInputChange(item, e.target.checked, "allowDrawing")}
                                />
                            }
                        />
                    </Grid>

                </Grid>

            </div>
        );
    }

    function renderFinalHtml(): JSX.Element {
        const textAlignStrings: any = ["left", "center", "right"];
        const textAlignString = textAlignStrings[item.imageAlign];

        const imgSource = item.imageSourceModified !== "" ? item.imageSourceModified : item.imageSource;

        if (imgSource) {
            return (
                <div className="kt-imageFormItemCtrl">
                    {item.allowDrawing &&
                        <ImageDrawDialog
                            visible={isDialogVisible}
                            title="Anmerkungen"
                            imgSrc={imgSource}
                            onSave={(newBase64Image)=>handleOnImageDrawDialogSave(newBase64Image)}
                            onClose={()=>setIsDialogVisible(false)}
                        ></ImageDrawDialog>
                    }
                    <div style={{ textAlign: textAlignString }}>
                        <img
                            style={{ width: item.imageWidth + "px" }}
                            src={imgSource}
                            alt=""
                        />
                        {item.allowDrawing &&
                            <Button variant="outlined" onClick={()=>setIsDialogVisible(true)}><i className="fal fa-pen"></i> Zeichnen</Button>
                        }
                    </div>
                </div>
            );

        } else {
            return (
                <div  className="kt-imageFormItemCtrl" style={{ textAlign: textAlignString }}>
                    <img
                        style={{ width: item.imageWidth + "px" }}
                        src="../../images/image-placeholder.svg"
                        alt=""
                    />
                </div>
            );
        }
    }


    switch (renderMode) {
        case "editor":
            return renderHtml();

        case "properties":
            return renderProperties();

        case "final":
            return renderFinalHtml();

        default:
            return <React.Fragment></React.Fragment>;
    }


}


export default ImageFormItemCtrl;