import firebase from 'firebase/compat/app';
import database from "../components/database";

import ClonRVideo, { VideoGroup } from "../shared/src/models/clonR/clonRVideo";

const db = database.firestore();

const functions = firebase.app().functions('europe-west3');


const ClonRVideosService = {

    startListenForClonRVideos(clientId: string, changeCallback: (videos: ClonRVideo[]) => void): () => void {

        if (!clientId) {
            return () => {
                console.log("error in startListenForClonRVideos: empty paramter");
            };
        }

        return db.collection("clients")
            .doc(clientId)
            .collection("clonRVideos")
            .where("isDeleted", "==", false)
            .orderBy("createdAt", "desc")
            .onSnapshot(function (querySnapshot) {
                const videoList: ClonRVideo[] = [];

                querySnapshot.forEach((doc) => {
                    const video = new ClonRVideo();
                    video.fromObject(doc.id, doc.data());

                    videoList.push(video);
                });

                changeCallback(videoList);
            });
    },
    

    async getVideos(clientId: string): Promise<ClonRVideo[]> {

        const videos: ClonRVideo[] = [];

        try {

            if (clientId) {
                const querySnapshot = await db.collection("clients").doc(clientId)
                    .collection("clonRVideos")
                    .where("isDeleted", "==", false)
                    .orderBy("createdAt", "desc")
                    .get();


                querySnapshot.forEach((doc) => {

                    const video = new ClonRVideo();
                    video.fromObject(doc.id, doc.data());

                    videos.push(video);
                });

            }


        } catch (error) {
            console.log("Error getting videos: ", error);
        }

        return videos;

    },

    async loadAndSaveVideos(clientId) {
        const videos = await ClonRVideosService.getVideos(clientId);
        for (let i = 0; i < videos.length; i++) {
            const video = videos[i];

            console.log(`updating video ${video.id} in group ${video.groupId}`);

            await db.collection("clients").doc(clientId)
                    .collection("clonRVideos").doc(video.id)
                    .set(video.toJSON(), { merge: true });
            
        }

        console.log("finished loadAndSaveVideos");
    },

    buildVideoGroups(_videos: ClonRVideo[]): VideoGroup[] {

        const _videoGroups = [] as VideoGroup[];
    
        if (_videos) {
    
            // now we group the videos
            for (let i = 0; i < _videos.length; i++) {
                const vid = _videos[i];
    
                let group = _videoGroups.find(g => g.id === vid.getGroupId());
                if (!group) {
                    group = {
                        id: vid.getGroupId(),
                        name: vid.getGroupName(),
                        videos: [vid],
                        latestVideoDate: vid.createdAt
                    };
                    _videoGroups.push(group);
                }
    
                // check if video is already in group
                if (group.videos.findIndex(v => v.id === vid.id) === -1) {
                    group.videos.push(vid);
                    if (vid.createdAt.getTime() > group.latestVideoDate.getTime()) group.latestVideoDate = vid.createdAt;
                }
    
                group.videos.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
            }
    
            _videoGroups.sort((a, b) => b.latestVideoDate.getTime() - a.latestVideoDate.getTime());
    
        }
    
        return _videoGroups;
    },

    async getVideoGroups (clientId: string): Promise<VideoGroup[]> {

        const _videos = await ClonRVideosService.getVideos(clientId);

        return ClonRVideosService.buildVideoGroups(_videos);
    },

    async getVideo(clientId: string, videoId: string): Promise<ClonRVideo | null> {

        if (!videoId) return null;


        const doc = await db.collection("clients").doc(clientId)
            .collection("clonRVideos").doc(videoId)
            .get();

        try {

            if (doc.exists) {

                const video = new ClonRVideo();
                video.fromObject(videoId, doc.data());

                return video;

            } else {
                console.log("getVideo: No such document: " + videoId);
                return null;
            }

        } catch (error) {
            console.log("Error getting video: ", error);
            return null;
        };

    },


    // mark as deleted
    async deleteVideo(video: ClonRVideo, deletedByUserId: string, clientId: string): Promise<void> {

        try {
            await db.collection("clients").doc(clientId)
                    .collection("clonRVideos").doc(video.id)
                    .set({
                        isDeleted: true,
                        deletedAt: new Date(),
                        deletedBy: deletedByUserId
                    }, { merge: true });

            // now delete video file
            //await FileUploadsService.deleteFile(`clients/${clientId}/clonRVideos/${video.id}.${video.videoFileExtension}`);

        } catch (error) {
            console.log("Error deleting ClonRVideo: ", error);
        };
    },

    async generateClonrVideo(clientId: string, avatarId: string, voiceId: string, scriptId: string, targetLanguage: string, userId: string):Promise<void> {

        const generateClonrVideoFunc = functions.httpsCallable("generateClonrVideo");

        await generateClonrVideoFunc(
            {
                clientId: clientId,
                avatarId: avatarId,
                voiceId: voiceId,
                scriptId: scriptId,
                targetLanguage: targetLanguage,
                userId: userId
            });

        return;
    }

}

export default ClonRVideosService;




